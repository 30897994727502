import { ImageStyle, StyleProp, View, ViewStyle } from "react-native";
import { Gradient } from "./Gradient/Gradient";
import { globalStyleColors } from "./GlobalStyles";

export const PhotoPlaceholder = (props: { color?: string; style: StyleProp<ViewStyle | ImageStyle> }) => {
  return (
    <View style={[props.style, { overflow: "hidden" }]}>
      <Gradient
        type="vertical"
        fromColor="white"
        toColor={props.color ?? globalStyleColors.rgba("colorGreyDark", "opaque")}
      />
    </View>
  );
};
