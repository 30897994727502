import { bottomThrow } from "@eatbetter/common-shared";
import { PhotoMediaType, UploadPhotoRequestInfo } from "@eatbetter/photos-shared";
import { getAxiosInstance } from "../lib/ApiClientBase";
import { log } from "../Log";
import { SetWaitingHandler } from "./Types";

export const uploadPhoto = async (
  uploadInfo: UploadPhotoRequestInfo,
  photoUri: string,
  waitingHandler?: SetWaitingHandler
) => {
  try {
    waitingHandler?.(true);
    const photo = await fetch(photoUri);
    const photoBlob = await photo.blob();

    const axios = getAxiosInstance();
    await axios.request({
      method: "PUT",
      url: uploadInfo.url,
      data: photoBlob,
      // In Axios, Blobs are identified by calling Object.prototype.toString on the object. However, on React Native,
      // calling Object.prototype.toString on a Blob returns '[object Object]' instead of '[object Blob]', which causes
      // Axios to treat Blobs as generic Javascript objects (and calls JSON.stringify). Therefore we need a to use a custom request transformer
      // that bypasses Axios' object handling and returns the Blob as it is, which will result in a binary payload (the Blob's body).
      // https://github.com/aws-amplify/amplify-js/blob/55a78d6471306143eebbd855c246af225e8d90d8/packages/storage/src/providers/axios-http-handler.ts#L157
      transformRequest: data => data,

      // this needs to be set for the proper content-type to get set on the object
      headers: {
        "Content-Type": uploadInfo.contentType,
      },
    });
  } catch (err) {
    log.errorCaught("Unexpected error in uploadPhoto", err, { uploadInfo });
    throw err;
  } finally {
    waitingHandler?.(false);
  }
};

export function getMediaType(uri: string): PhotoMediaType {
  let mediaType: PhotoMediaType;

  if (uri.startsWith("data:image")) {
    // On desktop, uri contains a data uri (e.g. data:image/jpeg;base64...)
    mediaType = uri.slice(uri.indexOf("/") + 1, uri.indexOf(";")) as PhotoMediaType;
  } else if (uri.startsWith("file://")) {
    // On app, uri contains a file path (e.g. file:///...)
    mediaType = uri.slice(uri.lastIndexOf(".") + 1) as PhotoMediaType;
  } else {
    log.error("Unrecognized photo uri.", { uri });
    throw new Error("Unrecognized photo uri.");
  }

  // The expo packages we're currently using predictably output jpeg/png, but these
  // are the most common supported formats.
  switch (mediaType) {
    case "jpg":
    case "jpeg":
      return "jpeg";
    case "tif":
    case "tiff":
      return "tiff";
    case "png":
    case "gif":
    case "heic":
    case "heif":
    case "bmp":
    case "webp":
      return mediaType;
    default:
      log.error("Unrecognized photo type.", { mediaType });
      bottomThrow(mediaType, log);
  }
}
