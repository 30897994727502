import { useEffect, useRef } from "react";
import { Animated, Easing, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { switchReturn } from "@eatbetter/common-shared";
import { useFontFamilyMap } from "./Typography";
import { globalStyleColors } from "./GlobalStyles";
import React from "react";

/**
 * A spring-based rotation animation that "wobbles" the `Animated.View` it's assigned to. Useful
 * for pressables you want to draw attention to.
 *
 * @returns a react native animated `ViewStyle` that can be passed to an `Animated.View` instance,
 * along with functions to start and stop the animation (call from `useEffect`)
 */
export function useWobbleAnimation(config?: { delay?: number; loop?: boolean; wobbleAmount?: "small" | "large" }) {
  const rotateAnim = useRef(new Animated.Value(0)).current;
  const stop = useRef<() => void>();

  const wobbleAmount = switchReturn(config?.wobbleAmount ?? "small", {
    small: 0.1,
    large: 0.2,
  });

  const startAnimation = () => {
    const delay = Animated.delay(config?.delay ?? 0);
    const wobble = Animated.sequence([
      Animated.timing(rotateAnim, {
        toValue: wobbleAmount,
        duration: 0,
        useNativeDriver: Platform.OS !== "web",
      }),
      Animated.spring(rotateAnim, {
        toValue: 0,
        friction: 2.5,
        tension: 60,
        useNativeDriver: Platform.OS !== "web",
      }),
    ]);
    const loop = Animated.loop(Animated.sequence([wobble, Animated.delay(500)]));

    const seq = Animated.sequence([delay, config?.loop ? loop : wobble]);
    stop.current = () => {
      seq.stop();
      wobble.stop();
      loop.stop();
    };

    seq.start();
  };

  const stopAnimation = () => {
    if (stop.current) {
      stop.current();
    }

    rotateAnim.stopAnimation(() => {
      // reset to original position after animation is stopped
      rotateAnim.setValue(0);
    });
  };

  const rotate = rotateAnim.interpolate({
    inputRange: [-0.05, 0, 0.05],
    outputRange: ["-3deg", "0deg", "3deg"],
  });

  const animatedStyle: Animated.WithAnimatedObject<ViewStyle> = {
    transform: [{ rotate }],
  };

  return {
    animatedStyle,
    startAnimation,
    stopAnimation,
  };
}

/**
 * A scale-based animation that "pulses" the `Animated.View` it's assigned to. Useful
 * for pressables you want to draw attention to.
 *
 * @returns a react native animated `ViewStyle` that can be passed to an `Animated.View` instance,
 */
export function usePulseAnimation(config?: {
  delay?: number;
  iterations?: number | "loop";
  pulseSize?: "small" | "medium" | "large";
  hideUntilStart?: boolean;
}) {
  const scaleAnim = useRef(new Animated.Value(1)).current;
  const opacityAnim = useRef(new Animated.Value(config?.hideUntilStart ? 0 : 1)).current;

  const pulseSize = switchReturn(config?.pulseSize ?? "medium", {
    small: 1.1, // 10%
    medium: 1.2, // 20%
    large: 1.3, // 30%
  });

  const startAnimation = () => {
    const delay = Animated.delay(config?.delay ?? 0);
    const opacity = Animated.timing(opacityAnim, { toValue: 1, duration: 0, useNativeDriver: Platform.OS !== "web" });
    const pulse = Animated.sequence([
      Animated.timing(scaleAnim, {
        toValue: pulseSize,
        duration: 300,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: Platform.OS !== "web",
      }),
      Animated.timing(scaleAnim, {
        toValue: 1, // return to original size
        duration: 800,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: Platform.OS !== "web",
      }),
    ]);
    const loop = Animated.loop(pulse, {
      iterations: typeof config?.iterations === "number" ? config.iterations : undefined,
    });

    Animated.sequence([delay, opacity, config?.iterations ? loop : pulse]).start();
  };

  const stopAnimation = () => {
    scaleAnim.stopAnimation();
    scaleAnim.setValue(1); // reset to original size
  };

  const animatedStyle: Animated.WithAnimatedObject<ViewStyle> = {
    transform: [{ scale: scaleAnim }],
    opacity: opacityAnim,
  };

  return {
    animatedStyle,
    startAnimation,
    stopAnimation,
  };
}

export interface BurstTextProps {
  text: string;
  delay?: number;
  duration?: number;
  fontSize?: number;
  fontColor?: Exclude<keyof typeof globalStyleColors, "rgba">;
  fontFamily?: keyof ReturnType<typeof useFontFamilyMap>;
}

/**
 * Animates provided text with a per-character burst animation from left to right
 */
export const BurstText = React.memo((props: BurstTextProps) => {
  const fontFamilyMap = useFontFamilyMap();

  const chars = props.text.split("");

  const delay = props.delay ?? 0;
  const duration = props.duration ?? 1500;
  const delayInterval = duration / chars.length; // The delay in milliseconds for each character

  const fontFamily = fontFamilyMap[props.fontFamily ?? "sansSerif"];
  const fontColor = globalStyleColors[props.fontColor ?? "black"];

  // Create an animated value for each character
  const animatedValues = chars.map(() => new Animated.Value(0));

  useEffect(() => {
    // Animate each character in sequence
    const animations = chars.map((_, i) => {
      return Animated.sequence([
        // Initial scaling up for the "burst" effect
        Animated.timing(animatedValues[i]!, {
          toValue: 1.2,
          duration: delayInterval,
          useNativeDriver: Platform.OS !== "web",
        }),
        // Scale back to normal size
        Animated.timing(animatedValues[i]!, {
          toValue: 1,
          duration: delayInterval,
          useNativeDriver: Platform.OS !== "web",
        }),
      ]);
    });

    Animated.sequence([Animated.delay(delay), Animated.stagger(delayInterval, animations)]).start();
  }, []);

  return (
    <View style={styles.textContainer}>
      {chars.map((char, i) => {
        return (
          <Animated.Text
            key={i}
            allowFontScaling={false}
            style={[
              { color: fontColor, fontFamily, fontSize: props.fontSize },
              { transform: [{ scale: animatedValues[i]! }] },
            ]}
          >
            {char}
          </Animated.Text>
        );
      })}
    </View>
  );
});

const styles = StyleSheet.create({
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
