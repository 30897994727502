import React, { useCallback, useEffect, useRef, useState } from "react";
import { RecipeId } from "@eatbetter/recipes-shared";
import { useFetchRecipe } from "../lib/recipes/UseFetchRecipe";
import { useDispatch } from "../lib/redux/Redux";
import { RecipeDetailScreenComponent } from "./recipes/RecipeDetailScreenComponent";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { Haptics } from "./Haptics";
import { maybePromptForReview } from "../lib/system/SystemThunks";
import { useScreen } from "../navigation/ScreenContainer";
import { LayoutAnimation } from "react-native";
import { reportViewScreenRecipeDetailViewed } from "../lib/recipes/RecipesThunks";
import { SearchSessionId } from "../lib/search/SearchSlice";

export interface ViewRecipeScreenComponentProps {
  context: "search" | "post" | "share";
  recipeId: RecipeId;
  onSaveRecipe: () => Promise<void>;
  // only relevant if context is search, and only used for analytics
  searchSessionId?: SearchSessionId;
  searchResultIndex?: number;
}

/**
 * This component holds everything in common between Share/Post/SearchViewRecipe, leaving only data fetching
 * and the save recipe call to the respective screen components.
 */
export const ViewRecipeScreenComponent = React.memo((props: ViewRecipeScreenComponentProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { fetchedRecipe, libraryRecipe } = useFetchRecipe(props.recipeId);
  const recipe = libraryRecipe ?? fetchedRecipe;

  const [waitingSaveRecipe, setWaitingSaveRecipe] = useState(false);

  const reported = useRef(false);
  useEffect(() => {
    if (reported.current === false && recipe) {
      dispatch(
        reportViewScreenRecipeDetailViewed(
          props.context,
          recipe,
          props.searchSessionId ? { sessionId: props.searchSessionId, resultIndex: props.searchResultIndex } : undefined
        )
      );
      reported.current = true;
    }
  }, [recipe]);

  const onSaveRecipe = useCallback(async () => {
    try {
      setWaitingSaveRecipe(true);
      await props.onSaveRecipe();
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setWaitingSaveRecipe(false);
      Haptics.feedback("operationSucceeded");
      if (props.context === "post") {
        setTimeout(() => {
          dispatch(maybePromptForReview("Recipe Saved from Social Post"));
        }, 500);
      }
    } catch (err) {
      displayUnexpectedErrorAndLog("Error in ViewRecipeScreenComponent.onSaveRecipe", err);
    }
  }, [
    libraryRecipe?.id,
    screen.nav.switchTab,
    props.onSaveRecipe,
    props.context,
    dispatch,
    screen.nav.switchTab,
    setWaitingSaveRecipe,
  ]);

  const saveRecipe = !libraryRecipe ? onSaveRecipe : undefined;

  return (
    <RecipeDetailScreenComponent
      recipe={recipe}
      onSaveRecipe={saveRecipe}
      waitingSaveRecipe={waitingSaveRecipe}
      context={props.context}
    />
  );
});
