import { UrlString } from "@eatbetter/common-shared";
import { useSelector } from "../redux/Redux";
import { WebViewSessionId } from "./WebViewSlice";
import { log } from "../../Log";

export const useWebViewInitialUrl = (sessionId: WebViewSessionId | undefined): UrlString | undefined =>
  useSelector(s => {
    if (!sessionId) {
      return undefined;
    }
    return s.webView.sessions[sessionId]?.initialUrl;
  });

export const useWebViewCurrentUrl = (sessionId: WebViewSessionId | undefined): UrlString | undefined =>
  useSelector(s => {
    if (!sessionId) {
      return undefined;
    }
    return s.webView.sessions[sessionId]?.currentUrl;
  });

export const useWebViewCanGoBack = (sessionId: WebViewSessionId | undefined): boolean =>
  useSelector(s => {
    if (!sessionId) {
      return false;
    }
    return !!s.webView.sessions[sessionId]?.canGoBack;
  });

export const useWebViewCanGoForward = (sessionId: WebViewSessionId | undefined): boolean =>
  useSelector(s => {
    if (!sessionId) {
      return false;
    }
    return !!s.webView.sessions[sessionId]?.canGoForward;
  });

export const useWebViewLoadingProgress = (sessionId: WebViewSessionId | undefined): number =>
  useSelector(s => {
    if (!sessionId) {
      return 0;
    }
    return s.webView.sessions[sessionId]?.loadingProgress ?? 0;
  });

export const useWebViewIsNavigated = (sessionId: WebViewSessionId | undefined): boolean =>
  useSelector(s => {
    if (!sessionId) {
      return false;
    }
    const session = s.webView.sessions[sessionId];
    // we consider the view navigated if it can go back AND the URL has a different host/path.
    // We don't consider hash and query string here because sites use it in their sign-in flow (NYT, Substack, for example)
    // and we end up showing browsign mode after sign-in, even though the user is looking at the recipe
    // We require canGoBack to handle situations where the URL is changed on load, at which point isRecipeUrl will return false (assumign a path/domain change)
    // but that redirect should not push to the back stack if the site is implemented well
    return !!session?.canGoBack && !isRecipeUrl(session.initialUrl, session.currentUrl);
  });

function isRecipeUrl(initialUrl: string, currentUrl: string): boolean {
  if (initialUrl === currentUrl) {
    return true;
  }

  try {
    const u1 = new URL(initialUrl);
    const u2 = new URL(currentUrl);
    return u1.host === u2.host && u1.pathname === u2.pathname;
  } catch (err) {
    log.errorCaught(`Error comparing ${initialUrl} to ${currentUrl}`, err, { initialUrl, currentUrl });
    return false;
  }
}
