import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BottomActionBar, BottomActionBarProps, bottomActionBarConstants } from "../BottomActionBar";
import { useDispatch } from "../../lib/redux/Redux";
import { createCookingSession } from "../../lib/cooking/CookingSessionsThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { AppRecipe, PartialRecipeId, UserRecipeId, haveRecipeIngredients } from "@eatbetter/recipes-shared";
import { Haptics } from "../Haptics";
import { addRecipeIngredientsToGroceryList } from "../../lib/lists/ListsThunks";
import { useIdempotentId } from "../../lib/util/UseIdempotentId";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { usePaywallStatus } from "../PaywallDetector";
import { useLibraryRecipe, useRecipeProcessingTimedOut, useRecipeStatus } from "../../lib/recipes/RecipesSelectors";
import { navTree } from "../../navigation/NavTree";
import { useCheckpointCompleted } from "../../lib/system/SystemSelectors";
import { RecipeStatusBanner, RecipeStatusBannerProps } from "./RecipeStatusBanner";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { LayoutAnimation } from "react-native";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportRecipeStatusBannerDismissed } from "../../lib/analytics/AnalyticsEvents";
import { WebViewSessionId } from "../../lib/webview/WebViewSlice";
import { useWebViewCurrentUrl, useWebViewIsNavigated } from "../../lib/webview/WebViewSelectors";
import { addRecipeFromWebView } from "../../lib/recipes/RecipesThunks";
import { log } from "../../Log";

const strings = {
  startCooking: "Start Cooking",
  addToGroceries: "Add to Groceries",
  saveRecipe: "Add to Your Library",
  openRecipe: "Open Recipe",
  switchRecipe: "Switch Recipe",
  endCooking: "End Cooking",
  timerRemainingTitle: "A timer is running",
  timerRemainingBody: "It will be deleted if you end now. Do you still want to end cooking?",
};

const config = {
  buttonWobbleDelayMs: 2800,
};

export function useRecipeDetailActionBarHeight(): { height: number; onChangeHeight: (e: { height: number }) => void } {
  const [height, setHeight] = useState(0);

  const onChangeHeight = useCallback(
    (e: { height: number }) => {
      setHeight(e.height);
    },
    [setHeight]
  );

  return useMemo(() => ({ height, onChangeHeight }), [height, onChangeHeight]);
}

export interface RecipeDetailActionBarProps {
  webViewSessionId?: WebViewSessionId;
  recipe: AppRecipe;
  onSaveRecipe?: () => Promise<void>;
  waitingSaveRecipe?: boolean;
  topBorderWidth?: "thin" | "thick";
  onChangeHeight?: (e: { height: number }) => void;
  context: "library" | "search" | "share" | "post";
}

export const RecipeDetailActionBar = React.memo((props: RecipeDetailActionBarProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const [newGroceryListItemId, refreshNewGroceryListItemId] = useIdempotentId<GroceryListItemId>();
  const [newCookingSessionId, refreshNewCookingSessionId] = useIdempotentId<CookingSessionId>();
  const [newRecipeId, refreshNewRecipeId] = useIdempotentId<PartialRecipeId>();
  const [waitingAddToGrocery, setWaitingAddToGrocery] = useState(false);
  const [waitingCookNow, setWaitingCookNow] = useState(false);
  const [statusBannerDismissed, setStatusBannerDismissed] = useState(false);
  const [waitingSaveRecipeWebView, setWaitingSaveRecipeWebView] = useState(false);
  const [savedWebViewRecipes, setSavedWebViewRecipes] = useState<Record<string, UserRecipeId>>({});

  const recipeAddedToGroceryCheckpoint = useCheckpointCompleted("recipeAddedToGrocery");
  const cookingSessionStartedCheckpoint = useCheckpointCompleted("cookingSessionStarted");
  const readerModeToggledCheckpoint = useCheckpointCompleted("recipeReaderModeToggled");
  const recipeActionTaken = recipeAddedToGroceryCheckpoint || cookingSessionStartedCheckpoint;

  const recipe = props.recipe;
  const haveIngredients = haveRecipeIngredients(recipe.ingredients);
  const { paywallStatus } = usePaywallStatus(recipe);

  const libraryRecipe = useLibraryRecipe(props.recipe.id);
  const libraryRecipeId = libraryRecipe?.id;
  const recipeStatus = useRecipeStatus(libraryRecipeId);
  const recipeProcessingTimedOut = useRecipeProcessingTimedOut(libraryRecipeId);

  const webViewCurrentUrl = useWebViewCurrentUrl(props.webViewSessionId);
  const isNavigated = useWebViewIsNavigated(props.webViewSessionId);

  const topBorderWidth = props.topBorderWidth ?? "thin";

  const onAddToGroceries = useCallback(async () => {
    if (!libraryRecipeId) {
      displayUnexpectedErrorAndLog(
        "RecipeBottomActionBar.onAddToGroceries() called but libraryRecipeId is falsy",
        {},
        { libraryRecipeId }
      );
      return;
    }

    try {
      Haptics.feedback("itemStatusChanged");
      await dispatch(addRecipeIngredientsToGroceryList(libraryRecipeId, newGroceryListItemId, setWaitingAddToGrocery));
      refreshNewGroceryListItemId();
      Haptics.feedback("operationSucceeded");
    } catch (err) {
      displayUnexpectedErrorAndLog("Add ingredients to grocery list failed", err, { libraryRecipeId });
    }
  }, [libraryRecipeId, newGroceryListItemId, setWaitingAddToGrocery, refreshNewGroceryListItemId, dispatch]);

  const onPressCookNow = useCallback(async () => {
    if (!libraryRecipeId) {
      displayUnexpectedErrorAndLog(
        "RecipeBottomActionBar.onPressCookNow() called but userRecipeId is falsy",
        {},
        { libraryRecipeId }
      );
      return;
    }

    await dispatch(
      createCookingSession(
        {
          id: newCookingSessionId,
          sourceRecipeId: libraryRecipeId,
          paywallDetectionOutcome: paywallStatus,
        },
        setWaitingCookNow
      )
    );

    // We navigate differently if we're in the recipes tab versus in another tab. We always want cooking sessions to open in
    // the recipes tab to keep things simpler.
    switch (props.context) {
      case "library": {
        screen.nav.goTo("replace", navTree.get.screens.recipeInKitchen);
        break;
      }
      case "post":
      case "search":
      case "share": {
        // We first want to close out the recipe detail screen and then switch tabs. If the user goes back and re-opens the recipe,
        // they will be navigated back to the cooking session. This is consistent with the recipes tab case above.
        screen.nav.goBack();
        screen.nav.switchTab("recipesTab", navTree.get.screens.recipeInKitchen);
        break;
      }
      default:
        bottomThrow(props.context);
    }

    refreshNewCookingSessionId();
  }, [
    dispatch,
    newCookingSessionId,
    libraryRecipeId,
    setWaitingCookNow,
    screen.nav.switchTab,
    refreshNewCookingSessionId,
    paywallStatus,
  ]);

  const onDismissStatusBanner = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setStatusBannerDismissed(true);
    if (libraryRecipe) {
      dispatch(analyticsEvent(reportRecipeStatusBannerDismissed({ recipe: libraryRecipe })));
    }
  }, [setStatusBannerDismissed, libraryRecipe]);

  const addRecipeFromCurrentUrl = useCallback(async () => {
    if (!webViewCurrentUrl) {
      log.error("addRecipeFromCurrentUrl(): webViewCurrentUrl is undefined");
      return;
    }

    let savedRecipeId: UserRecipeId;
    try {
      Haptics.feedback("itemStatusChanged");
      savedRecipeId = await dispatch(
        addRecipeFromWebView({ id: newRecipeId, url: webViewCurrentUrl }, setWaitingSaveRecipeWebView)
      );
      Haptics.feedback("operationSucceeded");
    } catch (err) {
      displayUnexpectedErrorAndLog("Error in addRecipeFromCurrentUrl()", err, { webViewCurrentUrl });
      return;
    }

    refreshNewRecipeId();
    setSavedWebViewRecipes(prev => {
      return { ...prev, [webViewCurrentUrl]: savedRecipeId } satisfies Record<string, UserRecipeId>;
    });
  }, [webViewCurrentUrl, newRecipeId, refreshNewRecipeId, setSavedWebViewRecipes]);

  const navToLibraryRecipe = useCallback(() => {
    const recipeId = webViewCurrentUrl ? savedWebViewRecipes[webViewCurrentUrl] : undefined;

    if (!recipeId) {
      displayUnexpectedErrorAndLog(
        "navToRecipe() called but recipeId is undefined",
        {},
        { webViewCurrentUrl, savedWebViewRecipes, recipeId }
      );
      return;
    }

    // We always open in the library for now to keep things sane.
    screen.nav.switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId });
  }, [screen.nav.switchTab, savedWebViewRecipes]);

  const actions = useMemo<BottomActionBarProps | undefined>(() => {
    if (isNavigated) {
      const alreadyAdded = webViewCurrentUrl && !!savedWebViewRecipes[webViewCurrentUrl];
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return {
        primaryAction: {
          actionIcon: alreadyAdded ? undefined : "library",
          actionText: alreadyAdded ? strings.openRecipe : strings.saveRecipe,
          onPressAction: alreadyAdded ? navToLibraryRecipe : addRecipeFromCurrentUrl,
          waiting: waitingSaveRecipeWebView,
        },
      };
    }

    if (props.onSaveRecipe) {
      return {
        primaryAction: {
          actionIcon: "library",
          actionText: strings.saveRecipe,
          onPressAction: props.onSaveRecipe,
          waiting: props.waitingSaveRecipe,
        },
      };
    }

    if (haveIngredients) {
      const showTapHint =
        !readerModeToggledCheckpoint || recipeActionTaken ? undefined : { delay: config.buttonWobbleDelayMs };

      return {
        primaryAction: {
          actionText: strings.startCooking,
          onPressAction: onPressCookNow,
          waiting: waitingCookNow,
          disabled: !haveIngredients,
          showTapHint,
        },
        secondaryAction: {
          actionText: strings.addToGroceries,
          onPressAction: onAddToGroceries,
          waiting: waitingAddToGrocery,
          disabled: !haveIngredients,
          showTapHint,
        },
      };
    }

    return undefined;
  }, [
    isNavigated,
    addRecipeFromCurrentUrl,
    savedWebViewRecipes,
    navToLibraryRecipe,
    waitingSaveRecipeWebView,
    props.onSaveRecipe,
    libraryRecipe,
    props.waitingSaveRecipe,
    haveIngredients,
    readerModeToggledCheckpoint,
    recipeActionTaken,
    onPressCookNow,
    waitingCookNow,
    onAddToGroceries,
    waitingAddToGrocery,
  ]);

  // If processing times out (5 days), we default to "noRecipe"
  const bannerType: RecipeStatusBannerProps["type"] = recipeProcessingTimedOut
    ? "noRecipe"
    : switchReturn(recipeStatus, {
        processing: "workingOnIt",
        pendingManual: "workingOnIt",
        complete: "noRecipe",
        failed: "workingOnIt",
      });

  // Calculate height dynamically to enable encapsulation of rendering logic in this component and support dismissal
  // of the status banner. RecipeDetail needs to know the height of the action bar to perform its animations and the only other way
  // to know the height is to move all of rendering logic in this component to the parent. We'll want to revisit this once we
  // clean up RecipeDetail.
  useEffect(() => {
    if (actions) {
      props.onChangeHeight?.({ height: bottomActionBarConstants.height });
      return;
    }
    if (!statusBannerDismissed && !haveIngredients && !!libraryRecipe) {
      props.onChangeHeight?.({ height: bottomActionBarConstants.height });
      return;
    }
    props.onChangeHeight?.({ height: 0 });
  }, [!!actions, statusBannerDismissed, haveIngredients, !!libraryRecipe]);

  return (
    <>
      {!!actions && (
        <BottomActionBar
          primaryAction={actions.primaryAction}
          secondaryAction={actions.secondaryAction}
          border={topBorderWidth}
          noBorderRadius
        />
      )}
      {!statusBannerDismissed && !haveIngredients && !!libraryRecipe && (
        <RecipeStatusBanner type={bannerType} recipe={libraryRecipe} onDismiss={onDismissStatusBanner} />
      )}
    </>
  );
});
