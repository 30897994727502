import { getLogger, Logger } from "./Logger";
import { nanoid } from "nanoid";

export type NewIdFunc = <T extends string = string>(prefix?: string) => T;

export const newId: NewIdFunc = <T extends string = string>(prefix = ""): T => {
  if (prefix.length > 7) {
    throw new Error("Use a prefix between 1 and 7 characters for easy human readability");
  }
  return `${prefix}${prefix ? "_" : ""}${nanoid(16)}` as T;
};

export function isNullOrUndefined(x: unknown): x is null | undefined {
  return x === null || x === undefined;
}

export function callIfDefined<T, TReturn>(x: T | undefined, fn: (input: T) => TReturn): TReturn | undefined {
  if (x === undefined) {
    return undefined;
  }

  return fn(x);
}

/**
 * True if x is true, "true" (case-insensitive, trimmed), "1" or 1.
 * False if x is false, "false" (case-insensitive, trimmed) "0" or 0.
 * @param x
 */
export function parseBool(x: string | number | boolean | undefined): boolean {
  const b = tryParseBool(x);

  if (typeof b !== "boolean") {
    throw new Error(`Could not parse bool from ${x}`);
  }

  return b;
}

/**
 * True if x is true, "true" (case-insensitive, trimmed), "1" or 1.
 * False if x is false, "false" (case-insensitive, trimmed) "0" or 0.
 * Returns undefined if the value can not be parsed to a bool
 */
export function tryParseBool(x: string | number | boolean | undefined): boolean | undefined {
  if (typeof x === "boolean") {
    return x;
  }

  if (typeof x === "string") {
    const ci = x.toLowerCase().trim();
    if (ci === "true") return true;
    if (ci === "false") return false;
    if (ci === "1") return true;
    if (ci === "0") return false;
  }

  if (typeof x === "number") {
    if (x === 1) return true;
    if (x === 0) return false;
  }

  return undefined;
}

/**
 * parseInt will return NaN if an integer cannot be parsed. This function will throw instead.
 */
export function parseIntOrThrow(str: string): number {
  const x = parseInt(str);

  if (isNaN(x)) {
    throw new Error(`Could not parse "${str}" to a number}`);
  }

  return x;
}

/**
 * parseInt will return an number for a string that starts with a valid number but has non-number
 * characters afterwards. This function returns a number only if the entire string is a number,
 * otherwise it returns NaN.
 * https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
 */
export function parseInteger(value: string): number {
  // Use type coercion on isNaN to parse the _entirety_ of the string (`parseFloat` alone does not do this)
  // and ensure strings of whitespace fail (parseFloat)
  if (isNaN(value as unknown as number) || isNaN(parseFloat(value))) {
    return Number.NaN;
  }

  return parseInt(value);
}

/**
 * To be used at the end of switch statements in which the default case
 * should never be hit.
 * @param value The switch value
 */
export function bottomThrow(value: never, log: Logger = getLogger()): never {
  log.error("Value not handled in switch. Throwing.", { value: safeJsonStringify(value) });
  throw new Error(`Value not handled in switch: ${safeJsonStringify(value)}`);
}

/**
 * Logs a message, returns a default, and provides a compile-time check, if a switch isn't exhaustive
 * @param value
 * @param opName Operation related to the switch. Simply provides some context when looking at the log.
 */
export function bottomWithDefault<T>(value: never, defaultValue: T, opName: string, log: Logger = getLogger()): T {
  log.error(
    `Value ${safeJsonStringify(value)} not handled in switch for operation '${opName}'. Returning default value.`,
    {
      value: safeJsonStringify(value),
      defaultValue,
    }
  );

  return defaultValue;
}

/**
 * Logs a message, and provides a compile-time check, if a switch isn't exhaustive
 * @param value
 * @param opName Operation related to the switch. Simply provides some context when looking at the log.
 */
export function bottomLog(value: never, opName: string, log: Logger = getLogger()): void {
  log.error(`Value ${safeJsonStringify(value)} not handled in switch for operation '${opName}'.`, {
    value: safeJsonStringify(value),
  });
}

export function bottomNop(_value: never): void {
  // this is simply used to catch compilation errors when a type is expanded
}

export class InitOnce<T> {
  private isSet = false;
  private value: T | undefined;

  get get(): T {
    if (!this.isSet) {
      throw new Error("init has not been called yet");
    }

    return this.value!;
  }

  setAndThrowIfAlreadySet(v: T) {
    this.value = v;
    this.isSet = true;
  }
}

export function initOnce<T>() {
  return new InitOnce<T>();
}

/**
 * Returns true if n is between start and end, inclusive. False otherwise.
 */
export function between(n: number, start: number, end: number): boolean {
  return n >= start && n <= end;
}

export function isPromise<T>(p: unknown): p is Promise<T> {
  return typeof (p as Promise<T>).then === "function";
}

export function tryParseJson<T = unknown>(
  str: string,
  verificationFunc?: (parsed: T) => boolean
): { success: true; parsed: T } | { success: false; error: any } {
  try {
    const parsed = JSON.parse(str) as T;

    if (verificationFunc) {
      const valid = verificationFunc(parsed);
      if (!valid) {
        return { success: false, error: "The json was parsed successfully but failed the verification check" };
      }
    }

    return { success: true, parsed };
  } catch (error) {
    return { success: false, error };
  }
}

export function safeJsonStringify(i: unknown): string {
  try {
    return JSON.stringify(i);
  } catch (err) {
    return `Error stringifying JSON. ${err}`;
  }
}

export function sleep(ms: number): Promise<void> {
  return new Promise(res => {
    setTimeout(res, ms);
  });
}

export function switchReturn<T extends string, R>(val: T, map: { [K in T]: R | (() => R) }): R {
  if (!map.hasOwnProperty(val)) {
    throw new Error(`No switchReturn handler found for val ${val}`);
  }

  const handler = map[val];
  return typeof handler === "function" ? handler() : handler;
}

export function assertNonEmptyArray<T>(val: Array<T>): asserts val is [T, ...[T]] {
  if (val.length === 0) {
    throw new Error("Expected non-empty array. Got empty array");
  }
}
