import { combineReducers } from "@reduxjs/toolkit";
import { systemReducer, SystemState } from "../system/SystemSlice";
import { listsReducer } from "../lists/ListsSlice";
import { recipesReducer } from "../recipes/RecipesSlice";
import { cookingSessionsReducer } from "../cooking/CookingSessionsSlice";
import { socialReducer } from "../social/SocialSlice";
import { debugReducer } from "../debug/DebugSlice";
import { notificationsReducer } from "../notifications/NotificationsSlice";
import { usersSearchReducer } from "../users/UserSearchSlice";
import { log } from "../../Log";
import { searchReducer } from "../search/SearchSlice";
import { webViewReducer } from "../webview/WebViewSlice";

const appReducer = combineReducers({
  system: systemReducer,
  debug: debugReducer,
  groceryLists: listsReducer,
  recipes: recipesReducer,
  cookingSessions: cookingSessionsReducer,
  social: socialReducer,
  notifications: notificationsReducer,
  search: searchReducer,
  users: combineReducers({
    search: usersSearchReducer,
  }),
  webView: webViewReducer,
});

export const rootReducer: typeof appReducer = (state, action) => {
  // NOTE the state can be a partial - hence the checks on system? etc.
  const authStatus = state?.system?.authStatus;

  // the event is defined in SystemSlice.ts, but handled here to do a global reset of state
  if (action.type === "system/userStartedSignOut") {
    const empty = appReducer(undefined, action);
    const system: SystemState = {
      ...empty.system,
      authStatus: "signingOut",
      previouslySignedIn: true,

      // push status is based on the system, not on the user, so keep it around
      pushPermission: state?.system?.pushPermission,

      // keep launch carousel completed. We could clear it and the user would end up back at the carousel
      // if they relaunch, but this gets them to the sign-in screen, assuming it's true
      launchCarouselCompleted: state?.system?.launchCarouselCompleted ?? false,
    };

    const signingOut = {
      ...empty,
      system,
    };

    return signingOut;
  } else if (
    authStatus &&
    (authStatus === "signingOut" || authStatus === "signedOut") &&
    !!state?.system?.previouslySignedIn &&
    !action.type.startsWith("system/") &&
    !action.type.startsWith("global/")
  ) {
    // after we start sign out, we want to ignore dispatches that result from receiving responses to outstanding calls. These could corrupt our state.
    // This is a bit of a big hammer, but it gets the job done for now.
    // We allow system actions for setting auth state
    log.warn(`Action ${action.type} being ignored because user has signed out`);
    return appReducer(state, { type: "nop" });
  }

  return appReducer(state, action);
};

export function getInitialState(): ReturnType<typeof rootReducer> {
  return rootReducer(undefined, { type: "getInitialState" });
}

// I have no idea why the [unknown] is here, but it's annoying, so omitting.
export type RootState = Omit<ReturnType<typeof rootReducer>, "[unknown]"> & {
  _persist: { version: number; rehydrated: boolean };
};
