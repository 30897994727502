import { useCallback, useEffect, useMemo } from "react";
import { ImageStyle, Platform, StyleProp, StyleSheet } from "react-native";
import { getUrlForAsset, PhotoId, PhotoRef, PhotoSize } from "@eatbetter/photos-shared";
import { Image } from "./Image/Image";
import { globalStyleConstants } from "./GlobalStyles";
import { Pressable } from "./Pressable";
import { useScreen } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { log } from "../Log";
import { withErrorBoundary } from "./ErrorBoundary";
import { RemoteSvg } from "./RemoteSvg/RemoteSvg";
import { PhotoPlaceholder } from "./PhotoPlaceholder";
import { UrlString } from "@eatbetter/common-shared";

export interface PhotoProps {
  source?: PhotoRef;
  sourceSize: PhotoSize;
  style: PhotoSizeName;
  placeholderColor?: string;
  border?: { width: number; color: string };
  resizeMode?: "cover" | "contain";
  onPress?: "expand" | (() => void);
  noBorderRadius?: boolean;
}

export function getSuggestedSourceSize(size: PhotoSizeName): PhotoSize {
  const style = styles[size];

  const cutoff = 288 / 3;

  const width = style?.width;
  if (typeof width === "number" && width <= cutoff) {
    return "w288";
  }

  return "w1290";
}

export const Photo = withErrorBoundary(
  "Photo",
  (props: PhotoProps) => {
    const screen = useScreen();

    const photoSource = useMemo(() => {
      if (!props.source) {
        return;
      }

      return { uri: getImageUrl(props.source, props.sourceSize) };
    }, [props.source, props.sourceSize]);

    const onPressPhoto = useCallback(() => {
      if (!props.onPress) {
        return;
      }

      if (props.onPress === "expand") {
        if (props.source) {
          screen.nav.modal(navTree.get.screens.viewPhoto, { photo: props.source });
        }
        return;
      }

      props.onPress();

      // The "?" below is not a typo - this gets called early enough in the app lifecycle that screen.nav can be falsy
    }, [screen.nav?.modal, props.source, props.onPress]);

    useEffect(() => {
      if (props.source?.type === "external" && !props.source.cdn) {
        log.warn("Got external photo ref with no cdn info", {
          ref: props.source,
          sourceSize: props.sourceSize,
          size: props.style,
        });
      }
    }, []);

    const imageStyle = getImageStyles(props);
    const resizeMode = props.resizeMode ?? "cover";

    // @ts-ignore-expected-error the compiler doesn't like styles[props.style]
    const image = <Image source={photoSource} style={imageStyle} resizeMode={resizeMode} />;

    const imageOrRemoteSvg = (
      <>
        {isExternalSvg(props.source) && (
          <>
            {/* SVG is not supported in the native image component, so we use react-native-svg to render on app */}
            {Platform.OS !== "web" && <RemoteSvg uri={photoSource?.uri} style={imageStyle} />}
            {Platform.OS === "web" && image}
          </>
        )}
        {!isExternalSvg(props.source) && image}
      </>
    );

    return (
      <>
        {!!photoSource && (
          <>
            {!!props.onPress && (
              <Pressable style={{ flex: 1 }} noFeedback onPress={onPressPhoto}>
                {imageOrRemoteSvg}
              </Pressable>
            )}
            {!props.onPress && imageOrRemoteSvg}
          </>
        )}
        {!photoSource && <PhotoPlaceholder style={imageStyle} color={props.placeholderColor} />}
      </>
    );
  },
  props => <PhotoPlaceholder style={getImageStyles(props)} color={props.placeholderColor} />
);

function getImageStyles(props: PhotoProps): StyleProp<ImageStyle> {
  const border = props.border ? { borderWidth: props.border.width, borderColor: props.border.color } : {};
  const borderRadius = props.noBorderRadius ? { borderRadius: 0 } : {};
  const size = styles[props.style];

  return [border, size, borderRadius];
}

function isExternalSvg(source?: PhotoRef): boolean {
  if (source?.type === "internal") {
    return false;
  }

  return !!source?.url && source?.url.substring(source?.url.lastIndexOf(".")) === ".svg";
}

export function getImageUrl(ref: PhotoRef, size: PhotoSize): UrlString {
  return getUrlForAsset(CurrentEnvironment.configEnvironment(), ref, size);
}

export function getVideoUrl(id: `videos/${string}`): UrlString {
  return getUrlForAsset(
    CurrentEnvironment.configEnvironment(),
    {
      type: "internal",
      id: id as PhotoId,
    },
    undefined
  );
}

// Avatar sizes
const xxsmall = 20;
const xsmall = 2 * globalStyleConstants.unitSize; // 24
const small = 3 * globalStyleConstants.unitSize; // 36
const medium = 3.5 * globalStyleConstants.unitSize; // 42
const large = 6 * globalStyleConstants.unitSize; // 72
const xlarge = 8 * globalStyleConstants.unitSize; // 96

export type PhotoSizeName =
  | "flexed"
  | "fullWidthLarge"
  | "thumbnailXXsmall"
  | "thumbnailXsmall"
  | "thumbnailSmall"
  | "thumbnailMedium"
  | "thumbnailLarge"
  | "thumbnailXlarge"
  | "avatarXXsmall"
  | "avatarXsmall"
  | "avatarSmall"
  | "avatarMedium"
  | "avatarLarge"
  | "avatarXlarge";

const photoSizes: { [key in PhotoSizeName]: ImageStyle & { width: number | "100%"; height: number | "100%" } } = {
  flexed: {
    width: "100%",
    height: "100%",
  },
  fullWidthLarge: {
    width: "100%",
    height: 256,
    borderRadius: 20,
  },
  thumbnailXXsmall: {
    width: xxsmall,
    height: xxsmall,
    borderRadius: 4,
  },
  thumbnailXsmall: {
    width: xsmall,
    height: xsmall,
    borderRadius: 6,
  },
  thumbnailSmall: {
    width: small,
    height: small,
    borderRadius: small / 8,
  },
  thumbnailMedium: {
    width: medium,
    height: medium,
    borderRadius: medium / 8,
  },
  thumbnailLarge: {
    width: large,
    height: large,
    borderRadius: large / 8,
  },
  thumbnailXlarge: {
    width: xlarge,
    height: xlarge,
    borderRadius: xlarge / 8,
  },
  avatarXXsmall: {
    width: xxsmall,
    height: xxsmall,
    borderRadius: xxsmall / 2,
  },
  avatarXsmall: {
    width: xsmall,
    height: xsmall,
    borderRadius: xsmall / 2,
  },
  avatarSmall: {
    width: small,
    height: small,
    borderRadius: small / 2,
  },
  avatarMedium: {
    width: medium,
    height: medium,
    borderRadius: medium / 2,
  },
  avatarLarge: {
    width: large,
    height: large,
    borderRadius: large / 2,
  },
  avatarXlarge: {
    width: xlarge,
    height: xlarge,
    borderRadius: xlarge / 2,
  },
} as const;

const styles = StyleSheet.create(photoSizes);
