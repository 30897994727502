import React from "react";
import { GradientComponent, GradientProps } from "./GradientInterfaces";
import { View } from "react-native";
import { switchReturn } from "@eatbetter/common-shared";

export const Gradient: GradientComponent = React.memo((props: GradientProps) => {
  const gradient = switchReturn(props.type, {
    vertical: `linear-gradient(to bottom, ${props.fromColor}, ${props.toColor})`,
  });

  const gradientStyle = {
    background: gradient,
    flex: 1,
  };

  return <View style={gradientStyle}>{props.children}</View>;
});
