import React, { createContext, useContext, PropsWithChildren, useMemo, useEffect, useState, useCallback } from "react";
import {
  PixelRatio,
  StyleProp,
  Text as RNText,
  TextProps as RNTextProps,
  TextStyle as RNTextStyle,
} from "react-native";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { globalStyleColors, Opacity } from "./GlobalStyles";
import { loadAsync } from "expo-font";
import { log } from "../Log";

// NOTE: The keys in this map MUST be identical to the file name of the corresponding fonts (minus extension)
// There is an unknown bug/issue on web where expo fonts fail to load properly if these do not match.
// In RN, require() calls must contain static strings.
const customFontSourceMap = {
  "AzeretMono-Regular": require("../assets/fonts/AzeretMono-Regular.ttf"),
  "SchnyderS-Demi": require("../assets/fonts/SchnyderS-Demi.ttf"),
  "Graphik-Regular": require("../assets/fonts/Graphik-Regular.ttf"),
  "Graphik-RegularItalic": require("../assets/fonts/Graphik-RegularItalic.ttf"),
  "Graphik-Medium": require("../assets/fonts/Graphik-Medium.ttf"),
} as const;

type CustomFontSourceName = keyof typeof customFontSourceMap;
type FontFamily = "monospace" | "serif" | "sansSerif" | "sansSerifItalic" | "sansSerifMedium";

const CustomFontsLoadedContext = createContext<{ loadedFonts: Array<CustomFontSourceName> }>({ loadedFonts: [] });

export const LoadCustomFonts = (props: PropsWithChildren<{ loading?: React.ReactNode }>) => {
  const [loadedFonts, setLoadedFonts] = useState<Partial<Record<CustomFontSourceName, "success" | "error">>>({});

  useEffect(() => {
    Object.entries(customFontSourceMap).map(([fontName, fontSource]) => {
      log.info("Loading custom font", { fontName });
      loadAsync(fontName, fontSource)
        .then(() => {
          log.info("Successfully loaded custom font", { fontName });
          setLoadedFonts(prev => ({ ...prev, [fontName]: "success" }));
        })
        .catch(err => {
          log.errorCaught("Error caught loading custom font", err, { fontName });
          setLoadedFonts(prev => ({ ...prev, [fontName]: "error" }));
        });
    });
  }, []);

  const fontsLoadedContext = useMemo(() => {
    return {
      loadedFonts: Object.entries(loadedFonts).flatMap(([fontName, loadOutcome]) =>
        loadOutcome === "success" ? [fontName as CustomFontSourceName] : []
      ),
    };
  }, [loadedFonts]);

  const loading = !Object.keys(customFontSourceMap).every(i => Object.hasOwn(loadedFonts, i));
  const allSucceeded = !loading && Object.values(loadedFonts).every(i => i === "success");

  if (allSucceeded) {
    log.info("All custom fonts loaded successfully!", { loadedFonts });
  }

  return (
    <>
      {loading && props.loading}
      {!loading && (
        <CustomFontsLoadedContext.Provider value={fontsLoadedContext}>
          {props.children}
        </CustomFontsLoadedContext.Provider>
      )}
    </>
  );
};

type FontFamilyMap = Record<FontFamily, string | undefined>;

/**
 * Returns a map of friendly font types/names to values you can pass directly to TextInput props to change the font family
 */
export function useFontFamilyMap(): FontFamilyMap {
  const loadedFonts = useContext(CustomFontsLoadedContext).loadedFonts;

  const setIfLoaded = useCallback(
    (fontName: CustomFontSourceName, fallbackFontName?: string) => {
      if (loadedFonts.some(i => i === fontName)) {
        return fontName;
      }
      return fallbackFontName;
    },
    [loadedFonts]
  );

  const fontFamilies = useMemo<FontFamilyMap>(() => {
    return {
      monospace: setIfLoaded("AzeretMono-Regular", "courier-bold"),
      serif: setIfLoaded("SchnyderS-Demi"),
      sansSerif: setIfLoaded("Graphik-Regular"),
      sansSerifItalic: setIfLoaded("Graphik-RegularItalic"),
      sansSerifMedium: setIfLoaded("Graphik-Medium"),
    };
  }, [setIfLoaded]);

  return fontFamilies;
}

interface CommonTextProps {
  numberOfLines?: number;
  adjustsFontSizeToFit?: boolean;
  align?: "center" | "left" | "right";
  opacity?: keyof typeof Opacity;
  strikethrough?: boolean;
  underline?: boolean;
  italic?: boolean;
  font?: "sansSerif" | "serif" | "monospace";
  fontWeight?: "normal" | "medium" | "heavy";
  onPress?: () => void;
  color?: string;
  suppressHighlighting?: boolean;
  lineHeight?: number;
  enableFontScaling?: "upAndDown" | "upOnly" | "downOnly";
  scale?: number;
  fixEmojiLineHeight?: boolean;
}

interface ActionTextProps {
  actionText?: boolean;
  errorText?: boolean;
}

type TextProps = PropsWithChildren<CommonTextProps & ActionTextProps>;

export enum FontSize {
  h1 = 30,
  h2 = 23,
  body = 17,
  secondary = 15,
  tertiary = 13,
}

export enum FontLineHeight {
  h1 = 1.2 * FontSize.h1,
  h2 = 1.25 * FontSize.h2,
  body = 1.4 * FontSize.body,
  secondary = 1.4 * FontSize.secondary,
  tertiary = 1.4 * FontSize.tertiary,
}

export const THeading1 = (props: TextProps) => {
  const fontSize = FontSize.h1;
  const lineHeight = props.lineHeight ?? FontLineHeight.h1;

  return (
    <TextBase fontSize={fontSize} lineHeight={lineHeight} {...props}>
      {props.children}
    </TextBase>
  );
};

export const THeading2 = (props: TextProps) => {
  const fontSize = FontSize.h2;
  const lineHeight = props.lineHeight ?? FontLineHeight.h2;

  return (
    <TextBase fontSize={fontSize} lineHeight={lineHeight} {...props}>
      {props.children}
    </TextBase>
  );
};

export const TBody = (props: TextProps) => {
  const fontSize = FontSize.body;
  const lineHeight = props.lineHeight ?? FontLineHeight.body;

  return (
    <TextBase fontSize={fontSize} lineHeight={lineHeight} {...props}>
      {props.children}
    </TextBase>
  );
};

export const TSecondary = (props: TextProps) => {
  const fontSize = FontSize.secondary;
  const lineHeight = props.lineHeight ?? FontLineHeight.secondary;

  return (
    <TextBase fontSize={fontSize} lineHeight={lineHeight} {...props}>
      {props.children}
    </TextBase>
  );
};

export const TTertiary = (props: TextProps) => {
  const fontSize = FontSize.tertiary;
  const lineHeight = props.lineHeight ?? FontLineHeight.tertiary;

  return (
    <TextBase fontSize={fontSize} lineHeight={lineHeight} {...props}>
      {props.children}
    </TextBase>
  );
};

export function quotes(str: string): string {
  return `“${str}”`;
}

interface TextBaseProps extends TextProps {
  fontSize: number;
  lineHeight: number;
}

const TextBase = React.memo((props: TextBaseProps) => {
  const commonStyle = useCommonStyle(props);
  const scaledFont = getScaledFont(props.fontSize, props.lineHeight, props.enableFontScaling, props.scale);

  const textStyle = useMemo<StyleProp<RNTextStyle>>(() => {
    return [{ fontSize: scaledFont.fontSize, lineHeight: scaledFont.lineHeight }, commonStyle];
  }, [props.fontSize, props.enableFontScaling, props.lineHeight, commonStyle, scaledFont]);

  // BEGIN Emoji line height bug workaround
  // Emojis with custom fonts in RN render with messed up line heights for the line containing the emoji
  // There have been several bugs open on this in RN but none were fixed. The only workaround that mostly addresses
  // the line height inconsistency is setting the emoji to the "System" font. This is annoying as it requires traversing
  // the graph, but it works and we should call it only call it only when we need to (i.e. paragraphs with emojis).
  const emojiRegex = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;
  const renderTextWithEmojis = (node: React.ReactNode): React.ReactNode => {
    if (typeof node === "string") {
      const parts = node.split(emojiRegex);
      return parts.map((part, index) => {
        if (emojiRegex.test(part)) {
          return (
            <RNText key={index} style={{ fontFamily: "System", fontSize: scaledFont.fontSize }}>
              {part}
            </RNText>
          );
        } else {
          return part;
        }
      });
    } else if (React.isValidElement(node)) {
      // If the child is a React element, clone it and process its children
      return React.cloneElement(node, node.props, renderChildren(node.props.children));
    } else if (Array.isArray(node)) {
      // If it's an array, map through each element
      return node.map(renderTextWithEmojis);
    } else {
      // For other types like numbers or booleans, return them as they are
      return node;
    }
  };

  const renderChildren = (children: React.ReactNode): React.ReactNode => {
    return React.Children.map(children, child => {
      return renderTextWithEmojis(child);
    });
  };

  return (
    <RNText style={textStyle} {...getCommonProps(props)}>
      {props.fixEmojiLineHeight === true ? renderChildren(props.children) : props.children}
    </RNText>
  );
});
// END Emoji line height bug workaround

function useCommonStyle(props: CommonTextProps & ActionTextProps): StyleProp<RNTextStyle> {
  const fontFamilyMap = useFontFamilyMap();

  const fontWeight = props.fontWeight
    ? switchReturn(props.fontWeight, {
        normal: "normal" as const,
        medium: "500" as const,
        heavy: "700" as const,
      })
    : undefined;

  const fontFamily = switchReturn(props.font ?? "sansSerif", {
    sansSerif:
      props.fontWeight === "medium"
        ? fontFamilyMap.sansSerifMedium
        : props.italic
        ? fontFamilyMap.sansSerifItalic
        : fontFamilyMap.sansSerif,
    serif: fontFamilyMap.serif,
    monospace: fontFamilyMap.monospace,
  });

  const color = props.actionText
    ? globalStyleColors.colorAccentCool
    : props.errorText
    ? globalStyleColors.colorAccentWarm
    : props.color;

  const fontStyle = props.italic ? "italic" : undefined;

  const commonStyle = useMemo<StyleProp<RNTextStyle>>(
    () => [
      {
        flexShrink: 1,
        textAlign: props.align,
        opacity: props.opacity ? Opacity[props.opacity] : undefined,
        textDecorationLine: props.strikethrough ? "line-through" : props.underline ? "underline" : undefined,
        fontFamily,
        fontWeight,
        fontStyle,
        color,
      },
    ],
    [props.align, props.opacity, props.strikethrough, props.underline, fontFamily, fontWeight, fontStyle, color]
  );

  return commonStyle;
}

function getCommonProps(
  props: CommonTextProps
): Pick<
  RNTextProps,
  "onPress" | "suppressHighlighting" | "numberOfLines" | "adjustsFontSizeToFit" | "ellipsizeMode" | "allowFontScaling"
> {
  return {
    onPress: props.onPress,
    suppressHighlighting: props.suppressHighlighting,
    numberOfLines: props.numberOfLines,
    adjustsFontSizeToFit: props.adjustsFontSizeToFit,
    ellipsizeMode: "tail",
    // We control font scaling manually for more control + granularity, so we disable the default mechanism
    allowFontScaling: false,
  };
}

export function getScaledFont(
  baseFontSize: number,
  baseLineHeight: number,
  enableFontScaling?: CommonTextProps["enableFontScaling"],
  scale?: number
): { fontSize: number; lineHeight: number } {
  if (!enableFontScaling) {
    return { fontSize: baseFontSize, lineHeight: baseLineHeight };
  }

  const fontScale = scale ?? PixelRatio.getFontScale();
  const minScaleFactor = 0.9;
  const maxScaleFactor = scale ? Number.MAX_SAFE_INTEGER : 1.4;
  const targetFontScale = Math.min(Math.max(fontScale, minScaleFactor), maxScaleFactor);

  const getLineHeightMultiplier = (fontSize: number) => {
    const headingThreshold = FontSize.h2;
    const baseMultiplierForHeadings = FontLineHeight.h2 / FontSize.h2;
    const baseMultiplierForOthers = FontLineHeight.body / FontSize.body;

    if (fontSize === headingThreshold) {
      return baseMultiplierForHeadings;
    } else if (fontSize > headingThreshold) {
      return baseMultiplierForHeadings - 0.025; // Slightly lower for larger headings
    } else {
      return baseMultiplierForOthers; // Uniform multiplier for smaller sizes
    }
  };

  const adjustedFontSize = baseFontSize * targetFontScale;
  const lineHeightMultiplier = getLineHeightMultiplier(adjustedFontSize);
  const adjustedLineHeight = adjustedFontSize * lineHeightMultiplier;

  switch (enableFontScaling) {
    case "upAndDown": {
      return { fontSize: adjustedFontSize, lineHeight: adjustedLineHeight };
    }
    case "upOnly": {
      if (fontScale < 1) {
        return { fontSize: baseFontSize, lineHeight: baseLineHeight };
      }
      return { fontSize: adjustedFontSize, lineHeight: adjustedLineHeight };
    }
    case "downOnly": {
      if (fontScale > 1) {
        return { fontSize: baseFontSize, lineHeight: baseLineHeight };
      }
      return { fontSize: adjustedFontSize, lineHeight: adjustedLineHeight };
    }
    default:
      bottomThrow(enableFontScaling, log);
  }
}
