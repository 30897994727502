import { ActivityIndicator, View } from "react-native";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { globalStyleColors } from "./GlobalStyles";
import { TBody } from "./Typography";

export const Spinner = (props: { color?: "dark" | "light" | "accentCool"; debugText?: string }) => {
  const color =
    props.color === "light" ? "white" : props.color === "accentCool" ? globalStyleColors.colorAccentCool : "black";

  return (
    <>
      {CurrentEnvironment.configEnvironment() !== "prod" && !!props.debugText && <TBody>SD: {props.debugText}</TBody>}
      <ActivityIndicator color={color} />
    </>
  );
};

export const FlexedSpinner = (props: { color?: "dark" | "light"; debugText?: string }) => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Spinner color={props.color} debugText={props.debugText} />
    </View>
  );
};

export const OverlayedSpinner = (props: { color?: "dark" | "light"; debugText?: string }) => (
  <View style={{ position: "absolute", left: "50%", top: "50%", zIndex: 1000 }}>
    <Spinner color={props.color} debugText={props.debugText} />
  </View>
);
