import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import {
  SceneRendererProps as RNSceneRendererProps,
  TabBar as RNTabBar,
  TabBarProps as RNTabBarProps,
  TabView as RNTabView,
} from "react-native-tab-view";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { useResponsiveDimensions } from "./Responsive";
import Animated, { FadeIn, SharedValue, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { useAnimatedScreenHeaderDimensions } from "./ScreenHeaders";
import { bottomThrow } from "@eatbetter/common-shared";
import { TSecondary } from "./Typography";
import { Separator } from "./Separator";
import { Badge } from "./Badge";

export const tabViewConstants = {
  tabBarHeight: 48,
  indicatorHeight: 1,
};

export interface TabViewRoute<T extends string = string> {
  key: T;
  title: string;
}

type TabBarColor = "transparent" | "white";

interface TabBarCommonProps {
  backgroundColor?: TabBarColor;
  tabAlignment?: "left" | "spacedEvenly";
  enteringAnimation?: { type: "fadeIn"; delayMs: number };
  badgeTabKeys?: string[];
}

export type TabViewBarProps =
  | "none"
  | (TabBarCommonProps &
      (
        | { type: "flex" }
        | { type: "snapToScreenHeader"; screenHeaderOffset?: SharedValue<number> }
        | {
            type: "scrollAndClamp";
            top: number;
            clampScrollY: number;
            scrollY: SharedValue<number>;
            backgroundColorClamp?: TabBarColor;
          }
      ));

export interface TabViewProps<T extends TabViewRoute = TabViewRoute> {
  index: number;
  onIndexChange: (index: number) => void;
  routes: T[];
  renderRoute: (props: { route: TabViewRoute }) => React.ReactNode;
  tabBar: TabViewBarProps;
  swipeEnabled?: boolean;
}

export const TabView = React.memo(<T extends TabViewRoute>(props: TabViewProps<T>) => {
  const dimensions = useResponsiveDimensions();
  const { headerHeight: screenHeaderHeight } = useAnimatedScreenHeaderDimensions();
  const { tabBar } = props;

  const snapToScreenHeaderAnimation = useAnimatedStyle(() => {
    if (tabBar === "none" || tabBar.type !== "snapToScreenHeader") {
      return {};
    }

    if (!tabBar.screenHeaderOffset) {
      return {
        top: screenHeaderHeight.value,
      };
    }

    return {
      transform: [
        {
          translateY: interpolate(
            tabBar.screenHeaderOffset.value,
            [0, screenHeaderHeight.value],
            [screenHeaderHeight.value, -(screenHeaderHeight.value + tabViewConstants.tabBarHeight)]
          ),
        },
      ],
    };
  }, [tabBar, screenHeaderHeight]);

  const scrollAndClampAnimation = useAnimatedStyle(() => {
    if (tabBar === "none" || tabBar.type !== "scrollAndClamp") {
      return {};
    }

    return {
      transform: [
        {
          translateY: interpolate(tabBar.scrollY.value, [0, tabBar.clampScrollY], [0, -tabBar.clampScrollY], {
            extrapolateRight: "clamp",
          }),
        },
      ],
      backgroundColor:
        tabBar.scrollY.value < tabBar.clampScrollY ? tabBar.backgroundColor : tabBar.backgroundColorClamp,
    };
  }, [tabBar]);

  const renderTabBar = useCallback(
    (tabBarProps: RenderTabBarPropsInternal) => {
      if (typeof tabBar === "string") {
        return null;
      }

      const tabBarComponent = (
        <TabBar
          {...tabBarProps}
          align={tabBar.tabAlignment}
          backgroundColor={tabBar.backgroundColor}
          badgeTabKeys={tabBar.badgeTabKeys}
        />
      );

      switch (tabBar.type) {
        case "snapToScreenHeader": {
          return (
            <Animated.View style={[styles.tabBarAbsoluteWrap, snapToScreenHeaderAnimation]}>
              {tabBarComponent}
            </Animated.View>
          );
        }
        case "scrollAndClamp": {
          return (
            <Animated.View
              entering={
                tabBar.enteringAnimation?.type === "fadeIn" ? FadeIn.delay(tabBar.enteringAnimation.delayMs) : undefined
              }
              style={[styles.tabBarAbsoluteWrap, { top: tabBar.top }, scrollAndClampAnimation]}
            >
              {tabBarComponent}
            </Animated.View>
          );
        }
        case "flex": {
          return tabBarComponent;
        }
        default:
          bottomThrow(tabBar);
      }
    },
    [tabBar, snapToScreenHeaderAnimation, scrollAndClampAnimation]
  );

  return (
    <RNTabView
      navigationState={{ index: props.index, routes: props.routes }}
      renderScene={renderSceneProps => props.renderRoute({ route: renderSceneProps.route })}
      renderTabBar={renderTabBar}
      initialLayout={{ width: dimensions.width }} // hint to improve initial render performance (per component documentation)
      onIndexChange={props.onIndexChange}
      swipeEnabled={props.swipeEnabled}
    />
  );
});

type TabBarPropsInternal<T extends TabViewRoute = TabViewRoute> = RNTabBarProps<T>;

export interface TabBarProps {
  renderLabel?: TabBarPropsInternal["renderLabel"];
  renderIcon?: TabBarPropsInternal["renderIcon"];
  backgroundColor?: "transparent" | "white";
  align?: "left" | "spacedEvenly";
  badgeTabKeys?: string[];
}

type RenderTabBarPropsInternal = RNSceneRendererProps & Pick<TabBarPropsInternal, "navigationState">;
type RenderTabBarInternal = (propsInternal: RenderTabBarPropsInternal) => JSX.Element;

export type RenderTabBar = RenderTabBarInternal & { _typeCheck: "renderTabBar" };

const TabBar = React.memo((props: Partial<TabBarPropsInternal> & RenderTabBarPropsInternal & TabBarProps) => {
  const dimensions = useResponsiveDimensions();
  const scrollEnabled = dimensions.isSmallScreen && props.navigationState.routes.length > 3;
  const backgroundColor = props.backgroundColor ?? "white";

  return (
    <View style={[styles.tabBarContainer, { backgroundColor }]}>
      <RNTabBar
        {...props}
        indicatorStyle={{
          backgroundColor: globalStyleColors.colorAccentCool,
          height: tabViewConstants.indicatorHeight,
        }}
        style={{
          height: tabViewConstants.tabBarHeight,
          backgroundColor: "transparent",
        }}
        activeColor={globalStyleColors.colorAccentCool}
        inactiveColor={globalStyleColors.black}
        scrollEnabled={scrollEnabled}
        tabStyle={
          scrollEnabled || props.align === "left"
            ? { width: "auto", paddingHorizontal: globalStyleConstants.unitSize }
            : {}
        }
        renderLabel={renderLabelProps => (
          <TabLabel
            title={renderLabelProps.route.title}
            focused={renderLabelProps.focused}
            badged={!!props.badgeTabKeys?.includes(renderLabelProps.route.key)}
          />
        )}
      />
      {scrollEnabled && <View style={styles.tabBarRightBoundary} />}
      <Separator orientation="row" color={globalStyleColors.rgba("colorGreyDark", "light")} />
    </View>
  );
});

const TabLabel = React.memo((props: { title: string; focused: boolean; badged: boolean }) => (
  <View>
    <TSecondary
      numberOfLines={1}
      opacity={props.focused ? "opaque" : "light"}
      fontWeight={"medium"}
      actionText={props.focused}
    >
      {props.title}
    </TSecondary>
    {props.badged && <Badge count={1} />}
  </View>
));

const styles = StyleSheet.create({
  tabBarContainer: {
    width: "100%",
    paddingHorizontal: globalStyleConstants.minPadding,
  },
  tabBarRightBoundary: {
    position: "absolute",
    right: 0,
    width: 6,
    height: tabViewConstants.tabBarHeight,
    backgroundColor: "white",
    zIndex: 1,
    shadowColor: "white",
    shadowRadius: 6,
    shadowOffset: {
      height: 0,
      width: -6,
    },
    shadowOpacity: 1,
  },
  tabBarAbsoluteWrap: {
    left: 0,
    right: 0,
    position: "absolute",
    zIndex: 10,
  },
});
