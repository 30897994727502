import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { ScreenView } from "../components/ScreenView";
import { RecipeContent } from "../components/recipes/RecipeContent";
import { ExternalSharedUserRecipeDetailScreenProps } from "../navigation/NavTree";
import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps, useHeaderScrollAnimation, useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useAuthedUser } from "../lib/system/SystemSelectors";
import Animated from "react-native-reanimated";
import { useShareActions } from "../lib/share/UseShareActions";
import { useEffect, useMemo } from "react";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportSharedUserRecipeDetailsViewedOnWeb } from "../lib/analytics/AnalyticsEvents";
import { useDispatch } from "../lib/redux/Redux";

const strings = {
  screenTitle: "deglaze",
  save: "Save recipe",
  signUp: "Sign up",
  inLibrary: "Saved!",
};

export const ExternalSharedUserRecipeDetailScreen = withNonNavigableScreenContainer(
  "ExternalSharedUserRecipeDetailScreen",
  (props: ExternalSharedUserRecipeDetailScreenProps) => {
    const authedUser = useAuthedUser();
    const { headerHeight } = useScreenHeaderDimensions();
    const dispatch = useDispatch();

    const { recipeSaved, waitingForSave, onPressSave, onPressSignUp } = useShareActions({
      sharingUserId: props.sharingUserId,
      sharedRecipeId: props.recipe.id,
      sharedSourceRecipeID: props.recipe.sourceRecipeId,
      viewingUserHasRecipe: props.viewingUserHasRecipe,
      onRecipeSaved: props.onRecipeSaved,
    });

    const [animationProgress, onScroll] = useHeaderScrollAnimation();

    useEffect(() => {
      dispatch(
        analyticsEvent(
          reportSharedUserRecipeDetailsViewedOnWeb({
            recipe: props.recipe,
          })
        )
      );
    }, []);

    const header: HeaderProps = useMemo(() => {
      return {
        title: strings.screenTitle,
        type: "custom",
        style: "default",
        right: !authedUser ? { type: "signUp", onPress: onPressSignUp } : undefined,
        animationConfig: {
          animationProgress,
          disableRetract: true,
          blurBackgroundThreshold: globalStyleConstants.unitSize,
        },
      };
    }, [authedUser, onPressSignUp, animationProgress]);

    return (
      <ScreenView scrollView={false} paddingHorizontal={false} paddingVertical={false} header={header}>
        <Animated.ScrollView
          contentContainerStyle={{
            paddingTop: headerHeight,
            paddingBottom: bottomActionBarConstants.height + 4 * globalStyleConstants.unitSize,
          }}
          showsVerticalScrollIndicator={false}
          onScroll={onScroll}
          scrollEventThrottle={16}
        >
          <RecipeContent recipe={props.recipe} />
        </Animated.ScrollView>
        <BottomActionBar
          primaryAction={{
            actionText: recipeSaved ? strings.inLibrary : strings.save,
            onPressAction: onPressSave,
            singlePress: true,
            waiting: waitingForSave,
            disabled: recipeSaved,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  }
);
