import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import { TabView, TabViewBarProps, TabViewProps, TabViewRoute, tabViewConstants } from "../TabView";
import { useSyncedTabLists2 } from "../TabViewSyncedLists";
import { ScreenView } from "../ScreenView";
import { SocialFeedImperativeHandle } from "./SocialFeed";
import { HeaderProps, useScreenHeaderDimensions } from "../ScreenHeaders";
import Reanimated, { SharedValue, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { FollowingFeed, FollowingFeedProps } from "./FollowingFeed";
import { useCheckpointCompleted, useHomeScreenOnboardingShouldBeShown } from "../../lib/system/SystemSelectors";
import {
  useHaveFollowingFeedPosts,
  useRecommendedFollows,
  useRecommendedFollowsDismissedThisSession,
} from "../../lib/social/SocialSelectors";
import { ExploreFeed } from "./ExploreFeed";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportSocialFeedsTabChanged } from "../../lib/analytics/AnalyticsEvents";
import { log } from "../../Log";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../SearchBox";
import { useScreen } from "../../navigation/ScreenContainer";
import { useDispatch } from "../../lib/redux/Redux";
import { navToSearchQuery } from "../../lib/search/SearchThunks";
import { homeScreenOnboardingCompleted } from "../../lib/system/SystemSlice";

const strings = {
  searchBoxPlaceholder: "What do you want to eat?",
  tabBar: {
    following: "Following",
    explore: "Explore",
  } as const,
  onboarding: "To help you get started, we've added some tips for you here.",
};

const tabViewRoutes: [TabViewRoute<"following">, TabViewRoute<"explore">] = [
  { key: "following", title: strings.tabBar.following },
  { key: "explore", title: strings.tabBar.explore },
];

export interface SocialFeedsScreenComponentProps {
  context: "homeScreen";
  screenTitle: string;
  renderTopSlot: () => React.ReactNode;
  onPressUsers: () => void;
  onPressNotificationCenter: () => void;
  onPressCreatePost: () => void;
  textPostsEnabled?: boolean;
}

export const SocialFeedsScreenComponent = React.memo((props: SocialFeedsScreenComponentProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const [tabViewIndex, setTabViewIndex] = useState(0);
  const [topSlotComponentHeight, setTopSlotComponentHeight] = useState(0);
  const { bareHeaderHeight, statusBarHeight } = useScreenHeaderDimensions();
  const searchBarHeight = useSearchAndFilterBarHeight();
  const retractClampY = bareHeaderHeight + searchBarHeight;
  const headerHeightScrolled = statusBarHeight + tabViewConstants.tabBarHeight;

  const onChangeTabViewIndex = useCallback(
    (index: number) => {
      const tabKey = tabViewRoutes[index]?.key;
      if (!tabKey) {
        log.error("Unexpected tab index in social feeds screen component", { index, tabViewRoutes });
        return;
      }

      dispatch(analyticsEvent(reportSocialFeedsTabChanged(props.context, strings["tabBar"][tabKey])));
      setTabViewIndex(index);
    },
    [setTabViewIndex, dispatch, props.context]
  );

  // This is an invisible version of the component. We have to do a bit of magic to get the desired behavior for the top
  // slot with a tab view and FlatLists in each tab. We stick the visible one behind the FlatList/TabView so that it persists
  // when the tab index changes. We stick the invisible one in the FlatList header so that it's tappable so that FlatList
  // scrolling behaves as normal.
  const listHeader = useMemo(() => {
    return <View style={{ opacity: 0, paddingBottom: tabViewConstants.tabBarHeight }}>{props.renderTopSlot()}</View>;
  }, [props.renderTopSlot]);

  const recommendedFollowsDismissed = useCheckpointCompleted("recommendedFollowsDismissed");
  const recommendedFollowsDismissedInThisSession = useRecommendedFollowsDismissedThisSession();
  const haveRecommendedFollows = useRecommendedFollows().length > 0;
  const havePosts = useHaveFollowingFeedPosts();

  const followingFeedHeader = useMemo<FollowingFeedProps["feedHeader"]>(() => {
    if (haveRecommendedFollows && (!havePosts || !recommendedFollowsDismissed)) {
      return "recommendedFollows";
    }
    return undefined;
  }, [havePosts, recommendedFollowsDismissed, haveRecommendedFollows]);

  const followingFeedInlinedModules = useMemo<FollowingFeedProps["feedInlinedModules"]>(() => {
    if (!havePosts) {
      return [];
    }

    const isRecommendedFollowersInlined = () => {
      if (recommendedFollowsDismissedInThisSession) {
        // The module was dismissed this session so we don't show anywhere (top or inline) until the next session
        return false;
      }
      if (recommendedFollowsDismissed) {
        // The module was dismissed in a previous session so we show it inline
        return true;
      }
      // The module hasn't been dismissed so it's in the top slot and we don't want to show it inline
      return false;
    };

    return [
      { type: "recommendedFollows", show: isRecommendedFollowersInlined() },
      { type: "inviteFriends", show: havePosts },
    ];
  }, [recommendedFollowsDismissedInThisSession, recommendedFollowsDismissed, havePosts]);

  const followingFeedRef = useRef<SocialFeedImperativeHandle>(null);
  const exploreFeedRef = useRef<SocialFeedImperativeHandle>(null);
  const tabs = useSyncedTabLists2(
    tabViewRoutes,
    followingFeedRef,
    exploreFeedRef,
    tabViewIndex,
    topSlotComponentHeight + retractClampY,
    retractClampY
  );
  const [followingTab, exploreTab] = tabs;
  const currentTabScrollY = tabs[tabViewIndex]?.scrollPosition ?? followingTab.scrollPosition;

  const onHomeHeaderLayout = useCallback(
    (e: LayoutChangeEvent) => {
      setTopSlotComponentHeight(e.nativeEvent.layout.height);
    },
    [setTopSlotComponentHeight]
  );

  const switchToExploreTab = useCallback(() => {
    setTabViewIndex(1);
    exploreFeedRef.current?.scrollToIndex(3);
  }, [setTabViewIndex, exploreFeedRef]);

  const switchToFirstTab = useCallback(() => {
    if (currentTabScrollY.value === 0 && tabViewIndex !== 0) {
      setTabViewIndex(0);
    }
  }, [currentTabScrollY, tabViewIndex, setTabViewIndex]);

  const renderRoute = useCallback(
    ({ route: { key } }: { route: TabViewRoute }) => {
      return (
        <>
          {key === "following" && (
            <FollowingFeed
              ref={followingFeedRef}
              onScroll={followingTab.onScroll}
              onEndDrag={followingTab.onEndDrag}
              onMomentumEnd={followingTab.onMomentumEnd}
              listHeaderComponent={listHeader}
              feedHeader={followingFeedHeader}
              feedInlinedModules={followingFeedInlinedModules}
              goToExploreFeed={switchToExploreTab}
              headerHeightScrolled={headerHeightScrolled}
              feedFooter={havePosts ? "exploreFeedTeaser" : undefined}
            />
          )}
          {key === "explore" && (
            <ExploreFeed
              ref={exploreFeedRef}
              listHeaderComponent={listHeader}
              onScroll={exploreTab.onScroll}
              onEndDrag={exploreTab.onEndDrag}
              onMomentumEnd={exploreTab.onMomentumEnd}
              headerHeightScrolled={headerHeightScrolled}
            />
          )}
        </>
      );
    },
    [
      followingFeedRef,
      followingTab.onScroll,
      followingTab.onEndDrag,
      followingTab.onMomentumEnd,
      followingFeedHeader,
      followingFeedInlinedModules,
      switchToExploreTab,
      listHeader,
      exploreFeedRef,
      exploreTab.onScroll,
      exploreTab.onEndDrag,
      exploreTab.onMomentumEnd,
    ]
  );

  // Delay render the tab bar so that the first render where the top slot height is calculated isn't visible. Otherwise
  // it will render right under the header and then jump to its target position.
  const [renderTabBar, setRenderTabBar] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRenderTabBar(true);
    }, 150);
  }, []);

  const onPressSearch = useCallback(() => {
    dispatch(navToSearchQuery({ nav: screen.nav, from: "home" }));
  }, [screen.nav]);

  const walkthroughShouldBeShown = useHomeScreenOnboardingShouldBeShown();

  const showWalkthrough = walkthroughShouldBeShown && screen.nav.focused;

  const onCloseWalkthrough = useCallback(() => {
    dispatch(homeScreenOnboardingCompleted());
  }, [dispatch]);

  const onPressNotifications = useCallback(() => {
    dispatch(homeScreenOnboardingCompleted());
    props.onPressNotificationCenter();
  }, [dispatch, props.onPressNotificationCenter]);

  const screenHeader: HeaderProps = useMemo(() => {
    const notificationIcon = {
      type: "notificationCenter",
      onPress: onPressNotifications,
      highlight: { isActive: showWalkthrough, message: strings.onboarding, onClose: onCloseWalkthrough },
    } as const;

    return {
      type: "custom",
      style: "tabRoot",
      title: props.screenTitle,
      backgroundColor: "white",
      right: props.textPostsEnabled
        ? {
            type: "threeButtons",
            left: { type: "findUsers", onPress: props.onPressUsers },
            middle: notificationIcon,
            right: { type: "add", onPress: props.onPressCreatePost },
          }
        : {
            type: "twoButtons",
            left: { type: "findUsers", onPress: props.onPressUsers },
            right: notificationIcon,
          },
      subHeaderComponent: {
        render: () => (
          <SearchAndFilterBar
            placeholderText={strings.searchBoxPlaceholder}
            editable={false}
            onPressSearchBox={onPressSearch}
          />
        ),
        getHeight: () => searchBarHeight,
      },
      animationConfig: {
        animationProgress: currentTabScrollY,
        retractClampY,
      },
    };
  }, [
    props.screenTitle,
    props.textPostsEnabled,
    props.onPressUsers,
    onPressNotifications,
    props.onPressCreatePost,
    bareHeaderHeight,
    searchBarHeight,
    currentTabScrollY,
    onPressSearch,
    showWalkthrough,
    onCloseWalkthrough,
  ]);

  return (
    <ScreenView
      header={screenHeader}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      onTabPress={switchToFirstTab}
      backgroundColor="white"
    >
      <TopSlot onLayout={onHomeHeaderLayout} scrollPosition={currentTabScrollY}>
        {props.renderTopSlot()}
      </TopSlot>
      <FeedsTabView
        index={tabViewIndex}
        onIndexChange={onChangeTabViewIndex}
        renderRoute={renderRoute}
        scrollY={currentTabScrollY}
        topSlotHeight={topSlotComponentHeight}
        searchBarHeight={searchBarHeight}
        show={renderTabBar}
      />
    </ScreenView>
  );
});

interface FeedsTabViewProps extends Pick<TabViewProps, "index" | "onIndexChange" | "renderRoute"> {
  show?: boolean;
  topSlotHeight: number;
  searchBarHeight: number;
  scrollY: SharedValue<number>;
}

const FeedsTabView = React.memo((props: FeedsTabViewProps) => {
  const { headerHeight, bareHeaderHeight } = useScreenHeaderDimensions();
  const tabBarTop = headerHeight + props.topSlotHeight;
  const show = props.show ?? true;

  const tabBar = useMemo<TabViewBarProps>(() => {
    if (!show) {
      return "none";
    }

    return {
      type: "scrollAndClamp",
      tabAlignment: "left",
      top: tabBarTop,
      clampScrollY: tabBarTop - headerHeight + bareHeaderHeight + props.searchBarHeight,
      scrollY: props.scrollY,
      backgroundColor: "transparent",
      backgroundColorClamp: "white",
      enteringAnimation: { type: "fadeIn", delayMs: 0 },
    };
  }, [show, tabBarTop, bareHeaderHeight, props.searchBarHeight, props.scrollY]);

  return (
    <TabView
      index={props.index}
      onIndexChange={props.onIndexChange}
      routes={tabViewRoutes}
      renderRoute={props.renderRoute}
      tabBar={tabBar}
    />
  );
});

const TopSlot = React.memo(
  (
    props: PropsWithChildren<{
      onLayout: (e: LayoutChangeEvent) => void;
      scrollPosition: SharedValue<number>;
    }>
  ) => {
    const { headerHeight } = useScreenHeaderDimensions();

    // https://github.com/software-mansion/react-native-reanimated/issues/4942#issuecomment-1695336905
    const { scrollPosition } = props;

    const scrollAnimation = useAnimatedStyle(() => {
      return {
        transform: [
          {
            translateY: interpolate(scrollPosition.value, [0, 1], [0, -1]),
          },
        ],
      };
    }, [scrollPosition]);

    return (
      <Reanimated.View style={[styles.topSlot, { top: headerHeight }, scrollAnimation]}>
        <View onLayout={props.onLayout}>{props.children}</View>
      </Reanimated.View>
    );
  }
);

const styles = StyleSheet.create({
  topSlot: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: "white",
  },
});
