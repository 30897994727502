import {
  GroceryList,
  GroceryListId,
  GroceryListItem,
  GroceryListItemId,
  GroceryListItemStatus,
  GroceryListItemsUpdatedData,
  GroceryListRecipe,
  GroceryLists,
  GroceryListSort,
  GroceryListSuggestion,
  GroceryListSuggestions,
  ManualGroceryListItem,
  PersistedVersions,
  RecipeGroceryListItem,
} from "@eatbetter/lists-shared";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import {
  ReceivedServerData,
  ServerData,
  serverDataErrored,
  serverDataReceived,
  serverDataRequested,
} from "../redux/ServerData";
import { setDiff } from "@eatbetter/common-shared";
import { defaultTimeProvider, EpochMs, newId, switchReturn, UserId } from "@eatbetter/common-shared";
import { UserOrHouseholdId } from "@eatbetter/users-shared";
import {
  addUpdate,
  ItemWithUpdates,
  persistErrored,
  persistStarted,
  persistSucceeded,
  rehydrateItemWithUpdates,
  updateErrored,
  updateStarted,
  updateSucceeded,
} from "../redux/ItemWithUpdates";
import { log } from "../../Log";
import { RecipeId, UserRecipeId } from "@eatbetter/recipes-shared";
import { Draft } from "immer";
import { StandardPrimaryCategory } from "@eatbetter/items-shared";

const recipeColors = [
  "#004C6D", // Dark blue
  "#2A9D8F", // Teal
  "#6EC5D4", // Light blue
  "#B4E1FA", // Pale blue
  "#BFE1D4", // Light green
  "#F0F8FF", // Light cyan
  "#91A6A6", // Gray-blue
  "#B5D3E7", // Blue-gray
  "#D3E3F1", // Pale gray-blue
  "#F8F8FF", // Ghost white
];

interface GroceryListState {
  id: GroceryListId;
  version: EpochMs;
  owner: UserOrHouseholdId;
  itemIds: GroceryListItemId[];
  recipeColors: Record<RecipeId, string>;
  recipes: Record<UserRecipeId, GroceryListRecipe>;
}

export interface GroceryListsState {
  /**
   * No data store here - just used to keep track of fetch status.
   */
  meta: ServerData<{}>;
  selectedListId?: GroceryListId;
  sort: GroceryListSort;
  lists: EntityState<GroceryListState, string>;
  items: EntityState<AppGroceryListItem, string>;
  suggestions: ServerData<GroceryListSuggestions>;
  spotlightIcon?: boolean;
}

const listAdapter = createEntityAdapter<GroceryListState, string>({
  selectId: l => l.id,
});
export const listSelectors = listAdapter.getSelectors();

const itemAdapter = createEntityAdapter<AppGroceryListItem, string>({
  selectId: i => i.item.id,
});

export const itemSelectors = itemAdapter.getSelectors();

const initialState: GroceryListsState = {
  meta: {},
  sort: "category",
  lists: listAdapter.getInitialState(),
  items: itemAdapter.getInitialState(),
  suggestions: {},
};

export function rehydrateListsState(persisted: Draft<GroceryListsState>): void {
  // if the app shuts down while a create/update is pending, we need to reset the state
  // so the reactors restart the operation if necessary.
  Object.values(persisted.items.entities).forEach(i => {
    if (i) {
      rehydrateItemWithUpdates(i);
    }
  });
}

export type AppGroceryListItem = ItemWithUpdates<
  ManualGroceryListItem | RecipeGroceryListItem,
  Partial<Pick<ManualGroceryListItem | RecipeGroceryListItem, "status" | "text" | "manualCategory">>
>;

export interface NewGroceryListItem {
  text: string;
  id: GroceryListItemId;
  addedBy: UserId;
}
// as a general rule, sync actions don't need list ID passed in as it's safe to use the selected list ID
// async actions (i.e. server calls) need to specify list IDs in case the selected list changes before the call returns
const listsSlice = createSlice({
  name: "groceryLists",
  initialState,

  reducers: create => ({
    groceryListsRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.meta, action.payload);
    }),

    groceryListsReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<GroceryLists>>) => {
      serverDataReceived(state.meta, { data: {}, startTime: action.payload.startTime });
      updateListState(state, action.payload.data);
      setRecipeColors(state);
    }),

    groceryListsErrored: create.reducer(state => {
      serverDataErrored(state.meta);
    }),

    groceryListItemAdded: create.preparedReducer(
      (args: { text: string; addedBy: UserId }) => {
        return { payload: { ...args, id: newId<GroceryListItemId>("gli") } };
      },
      (state, action: PayloadAction<NewGroceryListItem>) => {
        const listId = state.selectedListId!;
        // we got a few reports from one person that items were added and then not present on the list
        // The working theory is that the modal is being dismissed with text still present, and it gets
        // cleared on dismissing the modal. Added a log statement on modal dismiss in GroceryListItemAdd
        // and here.
        log.logRemote(`DEBUG: groceryListItemAdded: ${action.payload.text}`);
        addItem(
          state,
          listId,
          {
            id: action.payload.id,
            addedBy: action.payload.addedBy,
            text: action.payload.text,
            version: 0 as EpochMs,
            type: "manual",
            created: defaultTimeProvider(),
            status: { status: "pending" },
          },
          "createNeeded"
        );
      }
    ),

    groceryListItemAddedFromSuggestion: create.reducer(
      (state, action: PayloadAction<{ addedBy: UserId; suggestion: GroceryListSuggestion }>) => {
        if (!state.selectedListId) {
          log.warn("No selected list ID found in groceryListItemAddedFromSuggestion");
          return;
        }

        addItem(
          state,
          state.selectedListId,
          {
            id: newId<GroceryListItemId>("gli"),
            addedBy: action.payload.addedBy,
            text: action.payload.suggestion.text,
            suggestionId: action.payload.suggestion.id,
            category: action.payload.suggestion.category,
            manualCategory: action.payload.suggestion.manualCategory,
            version: 0 as EpochMs,
            type: "manual",
            created: defaultTimeProvider(),
            status: { status: "pending" },
          },
          "createNeeded"
        );
      }
    ),

    groceryListItemStatusUpdated: create.reducer(
      (state, action: PayloadAction<{ id: GroceryListItemId; status: GroceryListItemStatus["status"] }>) => {
        // const status = { status: action.payload.status, ts: action.payload.status === "completed" ? defaultTimeProvider() : undefined }
        const status = switchReturn<GroceryListItemStatus["status"], GroceryListItemStatus>(action.payload.status, {
          completed: { status: "completed", ts: defaultTimeProvider() },
          pending: { status: "pending" },
        });

        const itemAndUpdates = internalItemSelector(state, action.payload.id);

        if (!itemAndUpdates) {
          log.error(`groceryListItemStatusUpdated called for ${action.payload.id} but no item found. Returning`);
          return;
        }

        addUpdate(itemAndUpdates, { status });
      }
    ),

    groceryListItemTextUpdated: create.reducer(
      (state, action: PayloadAction<{ id: GroceryListItemId; text: string }>) => {
        const itemAndUpdates = internalItemSelector(state, action.payload.id);

        if (!itemAndUpdates) {
          log.error(`groceryListItemTextUpdated called for ${action.payload.id} but no item found. Returning`);
          return;
        }

        addUpdate(itemAndUpdates, { text: action.payload.text });
      }
    ),

    groceryListItemManualCategoryUpdated: create.reducer(
      (state, action: PayloadAction<{ id: GroceryListItemId; manualCategory: StandardPrimaryCategory }>) => {
        const itemAndUpdates = internalItemSelector(state, action.payload.id);

        if (!itemAndUpdates) {
          log.error(`groceryListItemTextUpdated called for ${action.payload.id} but no item found. Returning`);
          return;
        }

        addUpdate(itemAndUpdates, { manualCategory: action.payload.manualCategory });
      }
    ),

    groceryListItemPersistStarted: create.reducer((state, action: PayloadAction<{ id: GroceryListItemId }>) => {
      const itemAndUpdates = internalItemSelector(state, action.payload.id);

      if (!itemAndUpdates) {
        log.error(`groceryListItemPersistStarted called for ${action.payload.id} but no item found. Returning`);
        return;
      }

      persistStarted(itemAndUpdates, "groceryListItem");
    }),

    groceryListItemPersistErrored: create.reducer((state, action: PayloadAction<{ id: GroceryListItemId }>) => {
      const itemAndUpdates = internalItemSelector(state, action.payload.id);

      if (!itemAndUpdates) {
        log.error(`groceryListItemPersistErrored called for ${action.payload.id} but no item found. Returning`);
        return;
      }

      persistErrored(itemAndUpdates, "groceryListItem");
    }),

    groceryListItemPersistSuccess: create.reducer((state, action: PayloadAction<{ versions: PersistedVersions }>) => {
      Object.entries(action.payload.versions).forEach(e => {
        const [id, data] = e;
        const itemAndUpdates = internalItemSelector(state, id as GroceryListItemId);

        if (!itemAndUpdates) {
          log.error(`groceryListItemPersistSuccess called for ${id} but no item found. Returning`);
          return;
        }

        itemAndUpdates.item.version = data.version;
        if (itemAndUpdates.item.type === "manual") {
          // this should always be the case
          itemAndUpdates.item.suggestionId = data.suggestionId;
        }
        itemAndUpdates.item.category = data.category;
        itemAndUpdates.item.manualCategory = data.manualCategory;
        itemAndUpdates.item.ingredient = data.ingredient;

        persistSucceeded(itemAndUpdates, "groceryListItem");
      });
    }),

    groceryListItemUpdateStarted: create.reducer((state, action: PayloadAction<{ id: GroceryListItemId }>) => {
      const itemAndUpdates = internalItemSelector(state, action.payload.id);

      if (!itemAndUpdates) {
        log.error(`groceryListItemUpdateStarted called for ${action.payload.id} but no item found. Returning`);
        return;
      }

      updateStarted(itemAndUpdates, "groceryListItem");
    }),

    groceryListItemUpdateErrored: create.reducer((state, action: PayloadAction<{ id: GroceryListItemId }>) => {
      const itemAndUpdates = internalItemSelector(state, action.payload.id);

      if (!itemAndUpdates) {
        log.error(`groceryListItemUpdateErrored called for ${action.payload.id} but no item found. Returning`);
        return;
      }

      updateErrored(itemAndUpdates, "groceryListItem");
    }),

    groceryListItemUpdateSuccess: create.reducer((state, action: PayloadAction<{ updatedItem: GroceryListItem }>) => {
      const itemAndUpdates = internalItemSelector(state, action.payload.updatedItem.id);

      if (!itemAndUpdates) {
        log.error(
          `groceryListItemSUpdateSuccess called for ${action.payload.updatedItem.id} but no item found. Returning`
        );
        return;
      }

      updateSucceeded(itemAndUpdates, action.payload.updatedItem, "groceryListItem");
    }),

    groceryListItemsConflict: create.reducer((state, action: PayloadAction<{ items: GroceryListItem[] }>) => {
      action.payload.items.forEach(i => updateItem(state, i));
    }),

    groceryListItemPushReceived: create.reducer((state, action: PayloadAction<GroceryListItemsUpdatedData>) => {
      const list = internalListSelector(state, action.payload.listId);

      if (list) {
        let itemAdded = false;
        action.payload.items.forEach(i => {
          const alreadyExists = !!internalItemSelector(state, i.id);

          if (alreadyExists) {
            updateItem(state, i);
          } else {
            // if a push is delayed, it's possible that the item has already been deleted. This should be exceptionally
            // rare, but make sure the item was added after the current list was last retrieved.
            if (i.version >= list.version) {
              addItem(state, action.payload.listId, i, "persisted");
              itemAdded = true;
            }
          }
        });

        if (itemAdded) {
          setRecipeColors(state);
        }
      }
    }),

    groceryListSortChanged: create.reducer((state, action: PayloadAction<GroceryListSort>) => {
      state.sort = action.payload;
    }),

    groceryListSuggestionsRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.suggestions, action.payload);
    }),

    groceryListSugggestionsReceived: create.reducer(
      (state, action: PayloadAction<{ data: GroceryListSuggestions; startTime: EpochMs }>) => {
        serverDataReceived(state.suggestions, action.payload);
      }
    ),

    groceryListSuggestionsErrored: create.reducer(state => {
      serverDataErrored(state.suggestions);
    }),

    spotlightGroceryIconChanged: create.reducer((state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.spotlightIcon = true;
      } else {
        delete state.spotlightIcon;
      }
    }),
  }),
});

function setRecipeColors(state: Draft<GroceryListsState>) {
  state.lists.ids.forEach(listId => {
    const list = state.lists.entities[listId!]!;
    const newRecipes = new Set<RecipeId>();
    const usedColors = new Set<string>();
    const updated: Record<string, string> = {};
    const listItemIds = state.lists.entities[listId]!.itemIds;
    listItemIds.forEach(itemId => {
      const item = state.items.entities[itemId];
      if (item && item.item.type === "recipe") {
        const recipeId = item.item.recipeId;
        const color = list.recipeColors[recipeId];
        if (color) {
          updated[recipeId] = color;
          usedColors.add(color);
        } else {
          newRecipes.add(recipeId);
        }
      }
    });

    const availableColors = setDiff(usedColors, recipeColors).added;

    const newRecipeList = [...newRecipes];
    for (let i = 0; i < newRecipeList.length; i++) {
      const recipeId = newRecipeList[i]!;
      updated[recipeId] = availableColors[i] ?? recipeColors[i % recipeColors.length]!;
    }

    list.recipeColors = updated;
  });
}

function internalItemSelector(state: GroceryListsState, id: GroceryListItemId): AppGroceryListItem | undefined {
  const item = state.items.entities[id];
  return item;
}

function internalListSelector(state: GroceryListsState, id: GroceryListId): GroceryListState {
  const item = state.lists.entities[id]!;
  if (!item) {
    log.error(`internalListSelector called with list ID ${id}, but no list found`);
  }

  return item;
}

export const {
  groceryListsRequested,
  groceryListsReceived,
  groceryListsErrored,
  groceryListItemAdded,
  groceryListItemAddedFromSuggestion,
  groceryListItemsConflict,
  groceryListItemStatusUpdated,
  groceryListItemTextUpdated,
  groceryListItemManualCategoryUpdated,
  groceryListItemPersistStarted,
  groceryListItemPersistSuccess,
  groceryListItemPersistErrored,
  groceryListItemPushReceived,
  groceryListItemUpdateStarted,
  groceryListItemUpdateSuccess,
  groceryListItemUpdateErrored,
  groceryListSortChanged,
  groceryListSuggestionsRequested,
  groceryListSugggestionsReceived,
  groceryListSuggestionsErrored,
  spotlightGroceryIconChanged,
} = listsSlice.actions;

export const listsReducer = listsSlice.reducer;

function updateListState(draft: GroceryListsState, resp: GroceryLists) {
  const respIds = resp.lists.map(l => l.id);

  const diff = setDiff(draft.lists.ids, respIds);

  diff.removed.forEach(removedId => removeList(draft, removedId as GroceryListId));
  diff.added.forEach(id => addList(draft, resp.lists.find(l => l.id === id)!));
  diff.intersection.forEach(id => updateList(draft, resp.lists.find(l => l.id === id)!));

  // if we have not yet set an active list, or if it was removed and was previously active,
  // set the first list as active.
  if (draft.selectedListId === undefined || !draft.lists.ids.includes(draft.selectedListId)) {
    draft.selectedListId = draft.lists.ids[0] as GroceryListId;
  }
}

function updateList(draft: GroceryListsState, list: GroceryList) {
  const current = internalListSelector(draft, list.id);

  if (current.version >= list.version) {
    return;
  }

  current.recipes = list.recipes;
  const listMap = Object.fromEntries(list.items.map(i => [i.id, i]));
  const currentIds = current.itemIds;
  const diff = setDiff(currentIds, Object.keys(listMap));

  diff.removed.forEach(id => {
    const item = internalItemSelector(draft, id as GroceryListItemId);

    if (!item) {
      log.error(`updateList diff has ID ${id} but no item found. Skipping`);
      return;
    }

    // The diff here is app state to server state. So a "removed" item here means that we have it in the app
    // but it is not in the server state. This will happen if an item has actually been deleted, but also
    // if an item has not been persisted yet, or there was a race and the item was persisted after the request
    // to the server was made.
    // Only remove if the item has been persisted and the version is <= list version.
    // There is an edge case where the server returns zero items, which will result in a list version of 0.
    // This would only happen if a user goes defaultDaysToReturnCompleted days without opening the app after
    // all items are completed and no new items are added, but handle it anyway.
    if (
      item.itemState === "createNeeded" ||
      item.itemState === "pendingCreate" ||
      (item.item.version > list.version && list.version > 0)
    ) {
      return;
    }

    removeItem(draft, list.id, id as GroceryListItemId);
  });
  diff.added.forEach(id => addItem(draft, list.id, listMap[id]!, "persisted"));
  diff.intersection.forEach(id => updateItem(draft, listMap[id]!));
}

function updateItem(draft: GroceryListsState, item: GroceryListItem) {
  const existing = internalItemSelector(draft, item.id);

  if (!existing) {
    log.error(`updateItem called for item ${item.id} but no existing item found. Returning.`);
    return;
  }

  if (item.version <= existing.item.version) {
    return;
  }

  // This indicates that the item was updated externally. It's an expected condition in rare cases.
  if (existing.itemState === "pendingUpdate") {
    log.warn("Redux updateItem called for a grocery item with a pending update API call. Overwriting updates.", {
      existing,
    });
  }

  // in these cases, it's possible we're blowing away updates, but the update would
  // have failed anyway since we just got a different version from the server
  existing.item = item;
  existing.itemState = "persisted";
  existing.updates = [];
  existing.lastAttempt = undefined;
  existing.errorCount = undefined;
}

function addItem(
  draft: GroceryListsState,
  listId: GroceryListId,
  item: GroceryListItem,
  itemState: AppGroceryListItem["itemState"]
) {
  const list = internalListSelector(draft, listId);
  list.itemIds.push(item.id);
  itemAdapter.addOne(draft.items, {
    item,
    itemState,
    updates: [],
  });
}

function removeItem(draft: GroceryListsState, listId: GroceryListId, id: GroceryListItemId) {
  const list = internalListSelector(draft, listId);
  deleteFromArray(id, list.itemIds);
  itemAdapter.removeOne(draft.items, id);
}

function removeList(draft: GroceryListsState, listId: GroceryListId) {
  const itemIdsToDelete = internalListSelector(draft, listId).itemIds;
  itemAdapter.removeMany(draft.items, itemIdsToDelete);
  listAdapter.removeOne(draft.lists, listId);
}

function addList(draft: GroceryListsState, list: GroceryList) {
  listAdapter.addOne(draft.lists, {
    id: list.id,
    version: list.version,
    owner: list.owner,
    itemIds: [],
    recipeColors: {},
    recipes: list.recipes,
  });

  list.items.forEach(i => addItem(draft, list.id, i, "persisted"));
}

function deleteFromArray(id: string, ids: string[]) {
  const index = ids.findIndex(i => i === id);
  if (index !== -1) ids.splice(index, 1);
}
