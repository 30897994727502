import { Reactor } from "../redux/Reactors";
import {
  loadGroceryLists,
  loadGroceryListSuggestions,
  persistGroceryListItem,
  updateGroceryListItem,
} from "./ListsThunks";
import { selectListItemsAndUpdates } from "./ListsSelectors";
import { GroceryListId } from "@eatbetter/lists-shared";
import { shouldFetch } from "../redux/ShouldFetch";
import { selectFetchIntervalForHouseholdUpdate } from "../system/SystemSelectors";
import { getReactorResponse } from "../redux/ItemWithUpdates";

const fetchGroceryListsReactor: Reactor = state => {
  const fetchResult = shouldFetch("groceryLists.meta", state, s => s.groceryLists.meta, {
    staleThresholdSeconds: selectFetchIntervalForHouseholdUpdate(state, 300),
  });

  if (fetchResult.now) {
    return {
      dispatch: loadGroceryLists(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const fetchGroceryListSuggestions: Reactor = state => {
  const fetchResult = shouldFetch("groceryLists.suggestions", state, s => s.groceryLists.suggestions, {
    // every 12 hours should be sufficient
    staleThresholdSeconds: 3600 * 12,
  });

  if (fetchResult.now) {
    return {
      dispatch: loadGroceryListSuggestions(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const persistGroceryListItemReactor: Reactor = state => {
  let kickInMs: number | undefined;

  for (const listId of state.groceryLists.lists.ids) {
    for (const item of selectListItemsAndUpdates(state, listId as GroceryListId)) {
      const action = getReactorResponse(item, state.system.time, {
        create: () => persistGroceryListItem(listId as GroceryListId, item.item.id),
        update: () => updateGroceryListItem(listId as GroceryListId, item.item.id),
      });

      if (!action) {
        continue;
      }

      if (action.dispatch) {
        return action;
      }

      if (action.kickInMs && (!kickInMs || action.kickInMs < kickInMs)) {
        kickInMs = action.kickInMs;
      }
    }
  }

  if (kickInMs) {
    return { kickInMs };
  }

  return undefined;
};

export const listsReactors = [fetchGroceryListsReactor, fetchGroceryListSuggestions, persistGroceryListItemReactor];
