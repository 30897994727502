import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { OnboardingSurveyResponses } from "@eatbetter/users-shared";
import { onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { Haptics } from "../../components/Haptics";
import { SurveyData, useSurveyOptions } from "../../components/SurveyOptions";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { View, Image, ImageProps } from "react-native";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { TBody } from "../../components/Typography";
import { useResponsiveDimensions } from "../../components/Responsive";
import { Spacer } from "../../components/Spacer";
import { globalStyleConstants } from "../../components/GlobalStyles";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";

const instagramLogo = require("../../assets/images/instagram_logo.png");
const tiktokLogo = require("../../assets/images/tiktok_logo.png");
const facebookLogo = require("../../assets/images/facebook_logo.png");
const pinterestLogo = require("../../assets/images/pinterest_logo.png");
const nytLogo = require("../../assets/images/nyt_logo.png");
const baLogo = require("../../assets/images/ba_logo.png");

const strings = {
  headline: "Where do you typically find recipes?",
  subhead: "We'll show you how to import them and why it's worth your time.",
};

export const OnboardingIngestionScreen = withNonNavigableScreenContainer("OnboardingIngestionScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const { defaultFontScale } = useResponsiveDimensions();

  const surveyData: SurveyData<OnboardingSurveyResponses["ingestionSources"][number]> = [
    {
      key: "web",
      displayText: () => (
        <View style={{ flexDirection: "row" }}>
          <TBody enableFontScaling="upOnly" scale={defaultFontScale}>
            {"Websites"}
          </TBody>
          <Spacer horizontal={1} />
          <LogoImage source={nytLogo} />
          <Spacer horizontal={1} />
          <LogoImage source={baLogo} />
        </View>
      ),
    },
    {
      key: "social",
      displayText: () => (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TBody enableFontScaling="upOnly" scale={defaultFontScale}>
            {"Social Apps"}
          </TBody>
          <Spacer horizontal={1} />
          <LogoImage source={instagramLogo} />
          <Spacer horizontal={1} />
          <LogoImage source={tiktokLogo} />
          <Spacer horizontal={1} />
          <LogoImage source={facebookLogo} />
          <Spacer horizontal={1} />
          <LogoImage source={pinterestLogo} />
        </View>
      ),
    },
  ];

  const surveyOptions = useSurveyOptions("multiSelect", surveyData);

  const onNext = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    const selectedSources = surveyOptions.selected.map(i => i.key);
    dispatch(onboardingQuestionAnswered({ ingestionSources: selectedSources }));
    dispatch(navToNextOnboardingScreen(nav, "replace"));
  }, [surveyOptions.selected, dispatch, nav.goTo]);

  return (
    <ScreenView scrollView={false} header={onboardingScreenHeader}>
      <View
        style={{
          flex: 1,
          justifyContent: "space-between",
          paddingTop: 2 * globalStyleConstants.unitSize,
          paddingBottom: bottomActionBarConstants.height,
        }}
      >
        <View>
          <OnboardingScreenHeadline headline={strings.headline} subHeadline={strings.subhead} paddingBottomUnits={0} />
        </View>
        <View>{surveyOptions.renderSurveyOptions()}</View>
      </View>
      <OnboardingCtaButton
        cta="next"
        onPress={onNext}
        background="transparent"
        disabled={surveyOptions.selected.length === 0}
      />
    </ScreenView>
  );
});

const LogoImage = (props: { source: ImageProps["source"] }) => {
  return (
    <View>
      <Image source={props.source} resizeMode="contain" style={{ width: 24, height: 24 }} />
    </View>
  );
};
