import React from "react";
import { Photo, PhotoProps } from "./Photo";
import { PhotoRef } from "@eatbetter/photos-shared";
import { StyleSheet, View } from "react-native";
import { BlurOverlay } from "./BlurOverlay/BlurOverlay";
import { RecipeInfo } from "@eatbetter/recipes-shared";

interface Props extends Pick<PhotoProps, "sourceSize" | "onPress"> {
  recipe: RecipeInfo;
}

export function recipeHasUsablePhoto(recipe: RecipeInfo): boolean {
  return !!getPhoto(recipe).photo;
}

export const RecipePhoto = React.memo((props: Props) => {
  const { recipe, ...photoProps } = props;
  const { photo, isBookPhoto } = getPhoto(recipe);

  if (!photo) {
    // Return the default photo placeholder
    return <Photo style="flexed" sourceSize={props.sourceSize} />;
  }

  if (!isBookPhoto) {
    return <Photo {...photoProps} source={photo} style="flexed" resizeMode="cover" />;
  }

  // blur it
  return (
    <>
      <View style={StyleSheet.absoluteFill}>
        <Photo {...photoProps} source={photo} style="flexed" resizeMode="cover" sourceSize={props.sourceSize} />
        <BlurOverlay style="photoBlur" noLayoutAnimation />
      </View>
      <View style={{ flex: 1 }}>
        <Photo
          {...photoProps}
          source={photo}
          style="flexed"
          resizeMode="contain"
          noBorderRadius
          sourceSize={props.sourceSize}
        />
      </View>
    </>
  );
});

function getPhoto(recipe: RecipeInfo): { photo?: PhotoRef; isBookPhoto: boolean } {
  const photo = recipe.photo ?? recipe.book?.photo ?? recipe.publisher?.photo;
  return { photo, isBookPhoto: !!recipe.book?.photo && photo === recipe.book.photo };
}
