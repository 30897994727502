import React, { useCallback } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Pressable } from ".././Pressable";
import { TBody, TSecondary } from ".././Typography";
import { globalStyleColors, globalStyleConstants, globalStyles } from ".././GlobalStyles";
import { GroceryListSuggestion } from "@eatbetter/lists-shared";
import { GroceryListSuggestionWithListContext } from "../../lib/lists/ListsSelectors";
import { useResponsiveDimensions } from "../Responsive";
import { IconCircleCheckFilled } from "../Icons";

const strings = {
  emptyState:
    "Personalized suggestions will appear here." +
    "\n\nThe more you use the grocery list, the faster and easier your shopping gets.",
};

const config = {
  suggestionHeight: 3 * globalStyleConstants.unitSize,
  suggestionMarginBottom: globalStyleConstants.unitSize,
  suggestionContainerPaddingTop: 0.5 * globalStyleConstants.unitSize,
  suggestionContainerPaddingBottom: 1.5 * globalStyleConstants.unitSize,
};

interface Props {
  suggestions: Array<GroceryListSuggestion | GroceryListSuggestionWithListContext>;
  onPressSuggestion: (suggestion: GroceryListSuggestion, index: number, type: "Pill" | "Typeahead") => void;
  type: "Pill" | "Typeahead";
}

export const GrocerySmartSuggestions = React.forwardRef<ScrollView, Props>((props, ref) => {
  if (props.type === "Pill" && props.suggestions.length === 0) {
    return <EmptyState />;
  }

  return (
    <ScrollView
      ref={ref}
      keyboardShouldPersistTaps="always"
      style={styles.suggestionsWrap}
      contentContainerStyle={{
        paddingTop: config.suggestionContainerPaddingTop,
        paddingBottom: config.suggestionContainerPaddingBottom,
      }}
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.suggestions}>
        {props.suggestions.map((i, idx) => {
          return (
            <GrocerySmartSuggestion
              key={i.text}
              suggestion={i}
              index={idx}
              onPress={props.onPressSuggestion}
              type={props.type}
            />
          );
        })}
      </View>
    </ScrollView>
  );
});

interface GrocerySmartSuggestionProps {
  suggestion: GroceryListSuggestion | GroceryListSuggestionWithListContext;
  onPress: (suggestion: GroceryListSuggestion, index: number, type: "Pill" | "Typeahead") => void;
  index: number;
  type: "Pill" | "Typeahead";
}

const GrocerySmartSuggestion = React.memo((props: GrocerySmartSuggestionProps) => {
  const screenWidth = useResponsiveDimensions().width;

  const onPress = useCallback(() => {
    props.onPress(props.suggestion, props.index, props.type);
  }, [props.onPress, props.suggestion, props.index, props.type]);

  const onList = "onList" in props.suggestion && props.suggestion.onList;
  const maxWidth = screenWidth - 2 * globalStyleConstants.unitSize;

  const onListBadge = onList && (
    <View style={{ position: "absolute", top: -8, right: -8 }}>
      <IconCircleCheckFilled opacity="opaque" size={20} color={globalStyleColors.colorAccentCool} />
    </View>
  );

  return (
    <Pressable
      disabled={onList}
      style={[styles.suggestion, onList ? styles.pressed : {}, { maxWidth }]}
      onPress={onPress}
    >
      <TSecondary numberOfLines={1} color={globalStyleColors.colorAccentCool}>
        {props.suggestion.text}
      </TSecondary>
      {onListBadge}
    </Pressable>
  );
});

const EmptyState = React.memo(() => {
  return (
    <View style={styles.emptyState}>
      <TBody align="center">{strings.emptyState}</TBody>
    </View>
  );
});

const styles = StyleSheet.create({
  suggestionsWrap: {},
  suggestions: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  suggestion: {
    justifyContent: "center",
    height: config.suggestionHeight,
    borderRadius: config.suggestionHeight / 2,
    marginRight: globalStyleConstants.unitSize,
    marginBottom: config.suggestionMarginBottom,
    paddingHorizontal: globalStyleConstants.unitSize,
    backgroundColor: "white",
    ...globalStyles.shadowItem,
    shadowRadius: 4,
  },
  pressed: {
    shadowOpacity: 0,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("black", "xlight"),
  },
  emptyState: {
    paddingTop: globalStyleConstants.unitSize,
    flex: 1,
  },
});
