import { AutoSaveTextEdit, PersistTextHandler, useAutoSaveTextEdit } from "./UseAutoSaveTextEdit";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { useListItem } from "../lists/ListsSelectors";
import { groceryListItemTextUpdated } from "../lists/ListsSlice";

export const useGroceryItemTextEdit = (id: GroceryListItemId): AutoSaveTextEdit => {
  const persistedText = useListItem(id).text;
  const setPersistedText: PersistTextHandler = (text, dispatch, setPending, callSuccessful) => {
    if (!isValid(text)) {
      callSuccessful(false);
      setPending(false);
      return;
    }

    dispatch(groceryListItemTextUpdated({ id, text }));
    callSuccessful(true);
    setPending(false);
  };

  return useAutoSaveTextEdit(persistedText, setPersistedText, { setPendingOnTextChange: true });
};

function isValid(text: string): boolean {
  return text.trim() !== "";
}
