import React, { useCallback } from "react";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { GroceryEditItemScreenProps } from "../navigation/NavTree";
import { GroceryListItemEdit } from "../components/grocery/GroceryListItemEdit";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { View } from "react-native";

const strings = {
  screenTitle: "Edit item",
};

export const GroceryEditItemScreen = withNonNavigableScreenContainer<GroceryEditItemScreenProps>(
  "GroceryEditItemScreen",
  (props: GroceryEditItemScreenProps) => {
    return React.createElement<Props>(GroceryEditItemScreenComponent, props);
  }
);

interface Props {
  itemId: GroceryListItemId;
}

const GroceryEditItemScreenComponent = React.memo((props: Props) => {
  const screen = useScreen();
  const { modalHeaderHeight } = useScreenHeaderDimensions();

  const goBack = useCallback(() => {
    screen.nav.goBack();
  }, [screen.nav.goBack]);

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      header={{
        type: "native",
        title: strings.screenTitle,
        backgroundColor: "transparent",
        right: { type: "done", onPress: goBack },
      }}
    >
      <View style={{ paddingTop: modalHeaderHeight }}>
        <GroceryListItemEdit itemId={props.itemId} onDismiss={goBack} />
      </View>
    </ScreenView>
  );
});
