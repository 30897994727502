import { bottomThrow, UrlString } from "@eatbetter/common-shared";
import { AdminBook } from "@eatbetter/recipes-shared";
import { AppAddPhotoArgs, ButtonRectangle, ContainerBordered, Spacer, TBody, TextInput } from "@eatbetter/ui-shared";
import { pickedPhotoToRef } from "@eatbetter/ui-shared/src/components/PhotoPicker";
import { PhotoEditControl } from "@eatbetter/ui-shared/src/components/recipes/PhotoEditControl";
import { Divider } from "antd";
import { produce } from "immer";
import React, { Reducer, useCallback, useReducer, useState } from "react";
import { View } from "react-native";
import { TextInputLabel } from "./TextInputLabel";

export interface BookEditOnSaveArgs {
  book: AdminBook;
  newCoverPhoto?: AppAddPhotoArgs;
  newPrimaryAuthorPhoto?: AppAddPhotoArgs;
}

type BookEditDraft = Omit<BookEditOnSaveArgs, "book"> & { book: Partial<AdminBook> };

type BookEditReducer = Reducer<BookEditDraft, BookEditActions>;

type BookEditActions =
  | { type: "editTitle"; title: string }
  | { type: "editPrimaryAuthorName"; name: string }
  | { type: "editPrimaryAuthorUrl"; url: UrlString }
  | { type: "editPrimaryAuthorPhoto"; pickedPhoto: AppAddPhotoArgs }
  | { type: "editPrimaryAuthorPhotoExternalUrl"; url: UrlString }
  | { type: "addSecondaryAuthor" }
  | { type: "editSecondaryAuthorName"; index: number; name: string }
  | { type: "editSecondaryAuthorUrl"; index: number; url: UrlString }
  | { type: "removeSecondaryAuthor"; index: number }
  | { type: "editIsbn"; isbn: string }
  | { type: "editCoverPhoto"; pickedPhoto: AppAddPhotoArgs }
  | { type: "editCoverPhotoExternalUrl"; url: UrlString }
  | { type: "editPurchaseLink"; purchaseLink: UrlString };

interface Props {
  bookToEdit: AdminBook;
  onSave: (args: BookEditOnSaveArgs) => Promise<void>;
}

export const BookEditControl = React.memo((props: Props) => {
  const [waiting, setWaiting] = useState(false);

  const [bookToEdit, dispatch] = useReducer<BookEditReducer>(
    (current, action) => {
      return produce(current, draft => {
        switch (action.type) {
          case "editTitle": {
            const book = verifyDefined(draft.book, action);
            book.title = action.title;
            break;
          }
          case "editPrimaryAuthorName": {
            const primaryAuthor = verifyDefined(draft.book.primaryAuthor, action);
            primaryAuthor.name = action.name;
            break;
          }
          case "editPrimaryAuthorUrl": {
            const primaryAuthor = verifyDefined(draft.book.primaryAuthor, action);
            if (primaryAuthor.type !== "unknownAuthor") {
              break;
            }
            primaryAuthor.url = action.url;
            break;
          }
          case "editPrimaryAuthorPhoto": {
            if (draft.book.primaryAuthor?.photo?.type === "external") {
              draft.book.primaryAuthor.photo.url === "";
            }
            draft.newPrimaryAuthorPhoto = action.pickedPhoto;
            break;
          }
          case "editPrimaryAuthorPhotoExternalUrl": {
            draft.newPrimaryAuthorPhoto = undefined;
            const primaryAuthor = verifyDefined(draft.book.primaryAuthor, action);
            primaryAuthor.photo = {
              type: "external",
              url: action.url as UrlString,
            };
            break;
          }
          case "addSecondaryAuthor": {
            if (!draft.book.otherAuthors) {
              draft.book.otherAuthors = [];
            }
            draft.book.otherAuthors.push({ type: "unknownAuthor", name: "", url: "" as UrlString });
            break;
          }
          case "editSecondaryAuthorName": {
            const author = verifyDefined(draft.book.otherAuthors?.[action.index], action);
            author.name = action.name;
            break;
          }
          case "editSecondaryAuthorUrl": {
            const author = verifyDefined(draft.book.otherAuthors?.[action.index], action);
            if (author.type !== "unknownAuthor") {
              break;
            }
            author.url = action.url;
            break;
          }
          case "removeSecondaryAuthor": {
            const otherAuthors = verifyDefined(draft.book.otherAuthors, action);
            verifyDefined(otherAuthors[action.index], action);
            otherAuthors.splice(action.index, 1);
            break;
          }
          case "editIsbn": {
            const book = verifyDefined(draft.book, action);
            book.isbn = action.isbn;
            break;
          }
          case "editPurchaseLink": {
            const book = verifyDefined(draft.book, action);
            book.purchaseLink = action.purchaseLink;
            break;
          }
          case "editCoverPhoto": {
            if (draft.book?.coverPhoto?.type === "external") {
              draft.book.coverPhoto.url === "";
            }
            draft.newCoverPhoto = action.pickedPhoto;
            break;
          }
          case "editCoverPhotoExternalUrl": {
            draft.newCoverPhoto = undefined;
            draft.book.coverPhoto = {
              type: "external",
              url: action.url as UrlString,
            };
            break;
          }
          default:
            bottomThrow(action);
        }
      });
    },
    { book: props.bookToEdit }
  );

  const onSubmit = useCallback(async () => {
    if (!canSubmit(bookToEdit)) {
      return;
    }

    setWaiting(true);
    await props.onSave({ book: bookToEdit.book, newCoverPhoto: bookToEdit.newCoverPhoto });
    setWaiting(false);
  }, [bookToEdit, setWaiting]);

  return (
    <>
      <ButtonRectangle
        type="submit"
        title="Save Book"
        onPress={onSubmit}
        disabled={!canSubmit(bookToEdit)}
        waiting={waiting}
      />
      <Spacer vertical={2} />

      <TextInputLabel text="Book Title *" />
      <TextInput value={bookToEdit.book.title} onChangeText={title => dispatch({ type: "editTitle", title })} />
      <Spacer vertical={1} />

      <TextInputLabel text="ISBN" />
      <TextInput value={bookToEdit.book.isbn} onChangeText={isbn => dispatch({ type: "editIsbn", isbn })} />
      <Spacer vertical={1} />

      <TextInputLabel text="Purchase Link" />
      <TextInput
        value={bookToEdit.book.purchaseLink}
        onChangeText={link => dispatch({ type: "editPurchaseLink", purchaseLink: link as UrlString })}
      />
      <Divider />

      <PhotoEditControl
        admin
        sectionTitle={"Cover Photo *"}
        photo={{
          photoRef: pickedPhotoToRef(bookToEdit.newCoverPhoto) ?? bookToEdit.book.coverPhoto,
          style: "fullWidthLarge",
        }}
        onChangePhotoExternalUrl={url => dispatch({ type: "editCoverPhotoExternalUrl", url: url as UrlString })}
        onPhotoPicked={args => dispatch({ type: "editCoverPhoto", pickedPhoto: args })}
      />
      <Divider />

      <TBody>Primary Author *</TBody>
      <Spacer vertical={2} />

      <TextInputLabel text="Author Name *" />
      <TextInput
        value={bookToEdit.book.primaryAuthor?.name}
        onChangeText={name => dispatch({ type: "editPrimaryAuthorName", name })}
      />
      <Spacer vertical={1} />

      <TextInputLabel text="Author Url" />
      <TextInput
        value={bookToEdit.book.primaryAuthor?.type === "unknownAuthor" ? bookToEdit.book.primaryAuthor.url : undefined}
        onChangeText={url => dispatch({ type: "editPrimaryAuthorUrl", url: url as UrlString })}
      />
      <Spacer vertical={1} />

      <PhotoEditControl
        admin
        sectionTitle={"Author Photo"}
        photo={{
          photoRef: pickedPhotoToRef(bookToEdit.newPrimaryAuthorPhoto) ?? bookToEdit.book.primaryAuthor?.photo,
          style: "avatarSmall",
        }}
        onChangePhotoExternalUrl={url => dispatch({ type: "editPrimaryAuthorPhotoExternalUrl", url: url as UrlString })}
        onPhotoPicked={args => dispatch({ type: "editPrimaryAuthorPhoto", pickedPhoto: args })}
      />
      <Divider />

      <TBody>Other Authors</TBody>
      <Spacer vertical={2} />
      {bookToEdit.book.otherAuthors?.map((i, idx) => {
        const author = i;

        if (!author || author.type !== "unknownAuthor") {
          return null;
        }

        return (
          <View key={idx}>
            <ContainerBordered>
              <TextInputLabel text="Author Name" />
              <TextInput
                value={author.name}
                onChangeText={name => dispatch({ type: "editSecondaryAuthorName", index: idx, name })}
              />
              <Spacer vertical={1} />

              <TextInputLabel text="Author Url" />
              <TextInput
                value={author.url}
                onChangeText={url => dispatch({ type: "editSecondaryAuthorUrl", index: idx, url: url as UrlString })}
              />
              <Spacer vertical={1} />

              <ButtonRectangle
                type="cancel"
                title="Remove Author"
                onPress={() => dispatch({ type: "removeSecondaryAuthor", index: idx })}
              />
            </ContainerBordered>
            <Spacer vertical={1} />
          </View>
        );
      })}
      <ButtonRectangle type="secondary" title="Add Author" onPress={() => dispatch({ type: "addSecondaryAuthor" })} />
    </>
  );
});

function verifyDefined<T>(value: T | undefined, action: BookEditActions): T {
  if (value === undefined) {
    throw new Error(`Book Edit missing: ${JSON.stringify(action)}`);
  }

  return value;
}

function canSubmit(args: BookEditDraft): args is BookEditOnSaveArgs {
  if (!args.book) {
    return false;
  }

  return (
    !!args.book.id &&
    !!args.book.title &&
    !!args.book.primaryAuthor?.name &&
    !!(args.book.coverPhoto ?? args.newCoverPhoto)
  );
}
