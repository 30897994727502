import React, { useCallback } from "react";
import { SocialPostId } from "@eatbetter/posts-shared";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { usePostExists } from "../lib/social/SocialSelectors";
import { navTree, PostDetailScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import {
  LikeButtonPressedHandler,
  PostInteractionHandler,
  SocialPostComponent,
  useCanEditPost,
  useCommentButtonCallback,
  useLikeButtonCallback,
  useLikesBarCallback,
  usePostOptionsMenu,
  usePostSharedCallback,
} from "../components/social/SocialPost";
import { useAuthedUser } from "../lib/system/SystemSelectors";
import { useDispatch } from "../lib/redux/Redux";
import { loadPost } from "../lib/social/SocialThunks";
import { ScrollView } from "react-native";
import { getPullToRefresh } from "../components/PullToRefresh";
import { usePostForDetailScreen } from "../lib/social/SocialHooks";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { navToCookingSessionIfExists } from "../navigation/NavThunks";

const strings = {
  screenHeader: "Post",
  viewRecipeButton: "View Recipe",
};

export const PostDetailScreen = withScreenContainer(
  "PostDetailScreen",
  (props: PostDetailScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const postExists = usePostExists(props.postId);
    const user = useAuthedUser();

    const onPressShare = usePostSharedCallback();
    const onPressLike = useLikeButtonCallback(screen, user);
    const onPressComment = useCommentButtonCallback(screen);
    const onViewLikes = useLikesBarCallback(screen);

    const onViewRecipe = useCallback(() => {
      if (!postExists) {
        return;
      }

      if (!dispatch(navToCookingSessionIfExists({ type: "post", nav: screen.nav.switchTab, postId: props.postId }))) {
        screen.nav.goTo("push", navTree.get.screens.postViewRecipe, { postId: props.postId });
      }
    }, [postExists, props.postId, screen.nav.goTo, screen.nav.switchTab]);

    const onPullToRefresh = useCallback(async () => {
      return dispatch(loadPost(props.postId));
    }, [props.postId, dispatch]);

    // This 1) loads the post and 2) ensures it is not deleted.
    usePostForDetailScreen(props.postId);

    const canEditPost = useCanEditPost(props.postId);

    return React.createElement<Props>(PostDetailScreenComponent, {
      postId: props.postId,
      postExists,
      onPressShare,
      onPressLike,
      onViewLikes,
      onPressComment,
      onPullToRefresh,
      canEditPost,
      onViewRecipe,
    });
  },
  {
    serializer: {
      postId: s => s,
    },
    parser: {
      postId: s => s as SocialPostId,
    },
  }
);

interface Props {
  postExists: boolean;
  canEditPost: boolean;
  postId: SocialPostId;
  onPressShare: PostInteractionHandler;
  onPressLike: LikeButtonPressedHandler;
  onViewLikes: PostInteractionHandler;
  onPressComment: PostInteractionHandler;
  onPullToRefresh: () => Promise<void>;
  onViewRecipe: () => void;
}

const PostDetailScreenComponent = (props: Props) => {
  const onPressOptionsMenuButton = usePostOptionsMenu();

  const onPressRightHeader = useCallback(() => {
    onPressOptionsMenuButton(props.postId);
  }, [onPressOptionsMenuButton, props.postId]);

  const { bottomTabBarHeight, headerHeight: paddingTop } = useScreenElementDimensions();

  return (
    <ScreenView
      header={{
        type: "default",
        title: strings.screenHeader,
        right: props.canEditPost ? { type: "moreMenu", onPress: onPressRightHeader } : undefined,
      }}
      paddingHorizontal={false}
      paddingVertical={false}
      scrollView={false}
      loading={!props.postExists}
    >
      <ScrollView
        contentContainerStyle={{ paddingTop, paddingBottom: bottomTabBarHeight + bottomActionBarConstants.height }}
        refreshControl={getPullToRefresh(props.onPullToRefresh, paddingTop)}
      >
        <SocialPostComponent
          postId={props.postId}
          onPressShare={props.onPressShare}
          onPressLikeButton={props.onPressLike}
          onPressLikesBar={props.onViewLikes}
          onPressCommentButton={props.onPressComment}
          onPressPost={props.onViewRecipe}
          isDetailView
        />
      </ScrollView>
      <BottomActionBar primaryAction={{ actionText: strings.viewRecipeButton, onPressAction: props.onViewRecipe }} />
    </ScreenView>
  );
};
