import { Envelope, EpochMs, ErrorData } from "@eatbetter/common-shared";

export interface BrowserFetchRequest {
  url: string;
  ts: EpochMs;
}

export type BrowserFetchInternalError = ErrorData<
  "browserFetch/internalError",
  { id: string; url: string; error: string }
>;
export type BrowserFetchUrlError = ErrorData<
  "browserFetch/urlError",
  { id: string; url: string; error: string; navigationErrors: string[] }
>;
export type BrowserFetchTimeoutError = ErrorData<"browserFetch/timeout", { id: string; url: string }>;

export type BrowserFetchError = BrowserFetchInternalError | BrowserFetchUrlError | BrowserFetchTimeoutError;

export interface BrowserFetchData {
  id: string;
  url: string;
  finalUrl: string;
  html: string;
}

export type BrowserFetchResponse = Envelope<BrowserFetchData, BrowserFetchError>;

export function getBrowserFetchHmacArgs(req: BrowserFetchRequest): { hmacKey: string; message: string } {
  return { hmacKey: fetchServerHmacKey, message: `${req.ts}:${req.url}` };
}

// Fetch server can only be accessed with
const fetchServerHmacKey =
  "QQZxNHJWcF2A8J4WgkcVHkiGJE4NZJ3gym2BmVruLDVv7jcxCkkjsocYssAM3uAqKKgZJhnqLhLJqLcxoiyZgqqeLGueQ9tCrTCi";
