import { ApiClientBase, ApiClientConstructorArgs, AppEnvelope, ErrorStrategy } from "@eatbetter/ui-shared";
import {
  AdminAddAndProcessExternalUrlRecipeArgs,
  AdminAddAndProcessExternalUrlRecipeResult,
  AdminBook,
  AdminEditExternalUrlRecipeArgs,
  AdminFindRecipeByUrlArgs,
  AdminFindRecipeByUrlResult,
  AdminGetUserRecipesArgs,
  AdminGetUserRecipesResult,
  AdminReprocessExternalRecipe,
  AdminStoreHtmlArgs,
  AdminStoreHtmlPreviewResult,
  AdminStoreHtmlResult,
  BookId,
  CreateEditBookArgs,
  CreateExternalBookRecipeResult,
  AdminEditExternalBookRecipeArgs,
  EditRecipeArgs,
  ExternalBookRecipe,
  FindBooksArgs,
  FindBooksResult,
  RecipeErrorTypes,
  RecipeId,
  AdminCreateExternalBookRecipeArgs,
  AdminReprocessUserUrlRecipe,
  AdminGetUserRecipeArgs,
  AppUserRecipe,
  AdminDeleteUserRecipeArgs,
  AdminOrphanRecipeArgs,
  AdminSetUserRecipeFullAccessArgs,
  AdminGetRecipeStoreDocumentsArgs,
} from "@eatbetter/recipes-shared";
import {
  AdminGetMetadataResult,
  AdminGetRecipeArgs,
  AdminGetRecipeResult,
  AdminParsePhraseArgs,
  AdminParsePhraseResult,
  AdminUserOverview,
} from "@eatbetter/composite-shared";
import {
  S3UploadAddPhotoArgs,
  DeletePhotoArgs,
  PhotoErrorTypes,
  UploadPhotoRequestInfo,
} from "@eatbetter/photos-shared";
import { SearchUsersArgs, UserErrorTypes } from "@eatbetter/users-shared";
import { UserId } from "@eatbetter/common-shared";
import {
  AdminGetUserArgs,
  AdminGetUserResult,
  AdminSearchUsersResult,
  AdminSetUserFullAccessArgs,
} from "@eatbetter/users-shared/dist/UserAdminTypes";
import { AdminCategoryOverrideArgs, AdminCategoryOverrideResult } from "@eatbetter/lists-shared";
import {
  AdminGetFeedArgs,
  AdminSocialFeed,
  AdminUserSocialOverview,
  AdminUserSocialOverviewArgs,
  GetFollowersArgs,
  GetFollowingArgs,
  GetProfilePostsArgs,
  ModeratedSocialPost,
  ModerateSocialPostArgs,
  SocialErrorTypes,
  SocialPosts,
  EntityFollowers,
  UserFollowing,
} from "@eatbetter/posts-shared";
import { RecipeStoreDocuments } from "@eatbetter/recipes-server/dist/internal/RecipeRawDataStore";
import { AdminKnownAuthorAndPublisherData } from "@eatbetter/recipes-server";

export class AdminApiClient<TErrorHandling extends ErrorStrategy> extends ApiClientBase<TErrorHandling> {
  constructor(opts: ApiClientConstructorArgs) {
    super(opts);
  }

  async parseIngredientPhrase(
    args: AdminParsePhraseArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminParsePhraseResult>> {
    return this.post<AdminParsePhraseArgs, AdminParsePhraseResult>(
      "adminGateway",
      "/items/debug-parse-ingredient",
      args
    );
  }

  async getAdminMetadata(): Promise<AppEnvelope<TErrorHandling, AdminGetMetadataResult>> {
    return this.post<{}, AdminGetMetadataResult, RecipeErrorTypes>("adminGateway", "/composite/get-metadata", {});
  }

  async getRecipe(
    args: AdminGetRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminGetRecipeResult, RecipeErrorTypes>> {
    return this.post<AdminGetRecipeArgs, AdminGetRecipeResult, RecipeErrorTypes>("adminGateway", "/recipes/get", args);
  }

  async getRecipeStoreDocs(
    args: AdminGetRecipeStoreDocumentsArgs
  ): Promise<AppEnvelope<TErrorHandling, RecipeStoreDocuments, RecipeErrorTypes>> {
    return this.post<AdminGetRecipeStoreDocumentsArgs, RecipeStoreDocuments, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/get-docs",
      args
    );
  }

  async getUserRecipe(
    args: AdminGetUserRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post<AdminGetUserRecipeArgs, AppUserRecipe, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/get-user-recipe",
      args
    );
  }

  async findRecipeByUrl(
    args: AdminFindRecipeByUrlArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminFindRecipeByUrlResult, RecipeErrorTypes>> {
    return this.post<AdminFindRecipeByUrlArgs, AdminFindRecipeByUrlResult, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/find-by-url",
      args
    );
  }

  async editUserRecipeOnBehalfOfUser(
    args: EditRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminGetRecipeResult, RecipeErrorTypes>> {
    return this.post<EditRecipeArgs, AdminGetRecipeResult, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/edit-user-recipe",
      args
    );
  }

  async editExternalUrlRecipe(
    args: AdminEditExternalUrlRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, {}, RecipeErrorTypes>> {
    return this.post<AdminEditExternalUrlRecipeArgs, {}, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/edit-external-url",
      args
    );
  }

  async addAndProcessExternalUrlRecipe(
    args: AdminAddAndProcessExternalUrlRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminAddAndProcessExternalUrlRecipeResult, RecipeErrorTypes>> {
    return this.post<
      AdminAddAndProcessExternalUrlRecipeArgs,
      AdminAddAndProcessExternalUrlRecipeResult,
      RecipeErrorTypes
    >("adminGateway", "/recipes/add-and-process-external-url-recipe", args);
  }

  async reprocessExternalUrlRecipe(
    args: AdminReprocessExternalRecipe
  ): Promise<AppEnvelope<TErrorHandling, {}, RecipeErrorTypes>> {
    return this.post<AdminReprocessExternalRecipe, {}, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/reprocess-external",
      args
    );
  }

  async reprocessUserUrlRecipe(
    args: AdminReprocessUserUrlRecipe
  ): Promise<AppEnvelope<TErrorHandling, {}, RecipeErrorTypes>> {
    return this.post<AdminReprocessUserUrlRecipe, {}, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/reprocess-user-url-recipe",
      args
    );
  }

  async editExternalBookRecipe(
    args: AdminEditExternalBookRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, {}, RecipeErrorTypes>> {
    return this.post<AdminEditExternalBookRecipeArgs, {}, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/edit-external-book",
      args
    );
  }

  async createExternalBookRecipe(
    args: AdminCreateExternalBookRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, CreateExternalBookRecipeResult, RecipeErrorTypes>> {
    return this.post<AdminCreateExternalBookRecipeArgs, CreateExternalBookRecipeResult, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/add-external-book",
      args
    );
  }

  async storeHtml(
    args: AdminStoreHtmlArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminStoreHtmlResult, RecipeErrorTypes>> {
    return this.post<AdminStoreHtmlArgs, AdminStoreHtmlResult, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/store-html",
      args
    );
  }

  async storeHtmlPreview(
    args: AdminStoreHtmlArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminStoreHtmlPreviewResult, RecipeErrorTypes>> {
    return this.post<AdminStoreHtmlArgs, AdminStoreHtmlPreviewResult, RecipeErrorTypes>(
      "adminGateway",
      "/recipes/store-html-preview",
      args
    );
  }

  async getUploadPhotoRequestInfo(
    req: S3UploadAddPhotoArgs
  ): Promise<AppEnvelope<TErrorHandling, UploadPhotoRequestInfo, PhotoErrorTypes>> {
    return this.post("photos", "/admin/get-upload-url", req);
  }

  async deletePhoto(req: DeletePhotoArgs): Promise<AppEnvelope<TErrorHandling, void, PhotoErrorTypes>> {
    return this.post("photos", "/admin/delete", req);
  }

  async createBook(req: CreateEditBookArgs): Promise<AppEnvelope<TErrorHandling, AdminBook, RecipeErrorTypes>> {
    return this.post("adminGateway", "/books/create", req);
  }

  async getBook(req: { id: BookId }): Promise<AppEnvelope<TErrorHandling, AdminBook, RecipeErrorTypes>> {
    return this.post("adminGateway", "/books/get", req);
  }

  async editBook(req: AdminBook): Promise<AppEnvelope<TErrorHandling, AdminBook, RecipeErrorTypes>> {
    return this.post("adminGateway", "/books/edit", req);
  }

  async findBooks(req: FindBooksArgs): Promise<AppEnvelope<TErrorHandling, FindBooksResult, RecipeErrorTypes>> {
    return this.post("adminGateway", "/books/find", req);
  }

  async archiveBook(req: { id: BookId }): Promise<AppEnvelope<TErrorHandling, AdminBook, RecipeErrorTypes>> {
    return this.post("adminGateway", "/books/archive", req);
  }

  async unArchiveBook(req: { id: BookId }): Promise<AppEnvelope<TErrorHandling, AdminBook, RecipeErrorTypes>> {
    return this.post("adminGateway", "/books/unarchive", req);
  }

  async getBookRecipes(req: {
    id: BookId;
  }): Promise<AppEnvelope<TErrorHandling, { recipes: ExternalBookRecipe[] }, RecipeErrorTypes>> {
    return this.post("adminGateway", "/books/get-recipes", req);
  }

  async getUser(req: AdminGetUserArgs): Promise<AppEnvelope<TErrorHandling, AdminGetUserResult, UserErrorTypes>> {
    return this.post("adminGateway", "/users/get-user", req);
  }

  async getUserOverview(
    req: AdminGetUserArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminUserOverview, UserErrorTypes>> {
    return this.post("adminGateway", "/composite/user-overview", req);
  }

  async getUserSocialOverview(
    req: AdminUserSocialOverviewArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminUserSocialOverview, SocialErrorTypes>> {
    return this.post("adminGateway", "/social/user-overview", req);
  }

  async getUserRecipes(
    req: AdminGetUserRecipesArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminGetUserRecipesResult, RecipeErrorTypes>> {
    return this.post("adminGateway", "/recipes/get-user-recipes", req);
  }

  async searchUsers(
    req: SearchUsersArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminSearchUsersResult, UserErrorTypes>> {
    return this.post("adminGateway", "/users/search", req);
  }

  async setUserFullAccess(req: AdminSetUserFullAccessArgs): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post("adminGateway", "/users/set-full-access", req);
  }

  async setUserRecipeFullAccess(
    req: AdminSetUserRecipeFullAccessArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, UserErrorTypes>> {
    return this.post("adminGateway", "/recipes/set-user-recipe-full-access", req);
  }

  async saveRecipeCopy(req: {
    userId: UserId;
    sourceRecipeId: RecipeId;
  }): Promise<AppEnvelope<TErrorHandling, RecipeErrorTypes>> {
    return this.post("adminGateway", "/recipes/save-recipe-copy", req);
  }

  async deleteUserRecipe(
    req: AdminDeleteUserRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("adminGateway", "/recipes/delete-user-recipe", req);
  }

  async orphanRecipe(req: AdminOrphanRecipeArgs): Promise<AppEnvelope<TErrorHandling, RecipeErrorTypes>> {
    return this.post("adminGateway", "/recipes/orphan-recipe", req);
  }

  async addCategoryOverride(
    req: AdminCategoryOverrideArgs
  ): Promise<AppEnvelope<TErrorHandling, AdminCategoryOverrideResult, RecipeErrorTypes>> {
    return this.post("adminGateway", "/lists/admin-category-override", req);
  }

  async getSocialFeed(req: AdminGetFeedArgs): Promise<AppEnvelope<TErrorHandling, AdminSocialFeed, SocialErrorTypes>> {
    return this.post("adminGateway", "/social/get-feed", req);
  }

  async moderateSocialPost(
    req: ModerateSocialPostArgs
  ): Promise<AppEnvelope<TErrorHandling, ModeratedSocialPost, SocialErrorTypes>> {
    return this.post("adminGateway", "/social/moderate-post", req);
  }

  async getUserProfilePosts(
    req: GetProfilePostsArgs
  ): Promise<AppEnvelope<TErrorHandling, SocialPosts, SocialErrorTypes>> {
    return this.post("adminGateway", "/social/user-profile-posts", req);
  }

  async getFollowers(req: GetFollowersArgs): Promise<AppEnvelope<TErrorHandling, EntityFollowers, SocialErrorTypes>> {
    return this.post("adminGateway", "/social/followers", req);
  }
  async getFollowing(req: GetFollowingArgs): Promise<AppEnvelope<TErrorHandling, UserFollowing, SocialErrorTypes>> {
    return this.post("adminGateway", "/social/following", req);
  }

  async getKnownData(): Promise<AppEnvelope<TErrorHandling, AdminKnownAuthorAndPublisherData>> {
    return this.post("adminGateway", "/recipes/get-known-data", {});
  }
}
