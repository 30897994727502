import React, { useCallback, useState } from "react";
import { useBottomTabBarDimensions } from "../../navigation/TabBar";
import { StyleSheet, View } from "react-native";
import { TSecondary } from "../Typography";
import { bottomActionBarConstants } from "../BottomActionBar";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { AppUserRecipe } from "@eatbetter/recipes-shared";
import { useDispatch } from "../../lib/redux/Redux";
import { Haptics } from "../Haptics";
import { reportIssue } from "../../lib/system/SystemThunks";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { IconCheck, IconEx } from "../Icons";
import { Spacer } from "../Spacer";
import { Spinner } from "../Spinner";
import { Pressable } from "../Pressable";

const strings = {
  workingOnIt: "We're processing your recipe to enable\nshopping and cooking.",
  noRecipe: "We couldn't find a written recipe on this page.",
  reportIssue: ["Did we miss it? ", "Report an issue"] as const,
  issueReported: "Issue reported, thanks!",
};

export interface RecipeStatusBannerProps {
  recipe: AppUserRecipe;
  type: "workingOnIt" | "noRecipe";
  onDismiss: () => void;
}

export const RecipeStatusBanner = React.memo((props: RecipeStatusBannerProps) => {
  const dispatch = useDispatch();
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  const [issueReported, setIssueReported] = useState(false);
  const [waitingReportIssue, setWaitingReportIssue] = useState(false);

  const onReportIssue = useCallback(async () => {
    try {
      await dispatch(
        reportIssue({ type: "recipeIssue", issue: "missingRecipe", recipeId: props.recipe.id }, setWaitingReportIssue)
      );
      Haptics.feedback("operationSucceeded");
      setIssueReported(true);
    } catch (err) {
      displayUnexpectedErrorAndLog("Error caught in RecipeDetailScreen.onReportMissingRecipeIssue()", err, {
        recipeId: props.recipe.id,
      });
      setIssueReported(false);
    }
  }, [props.recipe.id, setWaitingReportIssue, setIssueReported]);

  return (
    <View style={[styles.banner, { bottom: bottomTabBarHeight }]}>
      <CloseButton onPress={props.onDismiss} />
      {props.type === "workingOnIt" && <WorkingOnItBannerContent />}
      {props.type === "noRecipe" && (
        <NoRecipeBannerContent
          onReportIssue={onReportIssue}
          issueReported={issueReported}
          waitingReportIssue={waitingReportIssue}
        />
      )}
    </View>
  );
});

const WorkingOnItBannerContent = React.memo(() => {
  return (
    <>
      <View style={styles.spinner}>
        <Spinner />
      </View>
      <RecipeStatusMessageText message={strings.workingOnIt} />
    </>
  );
});

const NoRecipeBannerContent = React.memo(
  (props: { onReportIssue: () => void; issueReported: boolean; waitingReportIssue: boolean }) => {
    return (
      <View style={{ alignItems: "center" }}>
        <RecipeStatusMessageText message={strings.noRecipe} />
        <ReportIssueButton
          onPress={props.onReportIssue}
          pressed={props.issueReported}
          waiting={props.waitingReportIssue}
        />
      </View>
    );
  }
);

const RecipeStatusMessageText = React.memo((props: { message: string }) => {
  return (
    <TSecondary align="center" numberOfLines={2} lineHeight={24} adjustsFontSizeToFit suppressHighlighting>
      {props.message}
    </TSecondary>
  );
});

const ReportIssueButton = React.memo((props: { onPress: () => void; pressed: boolean; waiting: boolean }) => {
  return (
    <>
      {props.pressed && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <IconCheck opacity="opaque" color={globalStyleColors.colorAction} size={18} strokeWidth={3} />
          <Spacer horizontal={0.5} />
          <TSecondary color={globalStyleColors.colorAction} fontWeight="medium">
            {strings.issueReported}
          </TSecondary>
        </View>
      )}
      {!props.pressed && (
        <TSecondary
          color={globalStyleColors.colorTextLink}
          fontWeight="medium"
          onPress={props.onPress}
          suppressHighlighting
        >
          {strings.reportIssue[0] + strings.reportIssue[1]}
        </TSecondary>
      )}
    </>
  );
});

const CloseButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable style={styles.closeButton} onPress={props.onPress}>
      <IconEx opacity="opaque" size={18} strokeWidth={2} />
    </Pressable>
  );
});

const styles = StyleSheet.create({
  banner: {
    position: "absolute",
    left: 0,
    right: 0,
    height: bottomActionBarConstants.height,
    paddingVertical: globalStyleConstants.minPadding,
    paddingHorizontal: globalStyleConstants.defaultPadding,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyleColors.colorAccentMid,
    zIndex: 3,
  },
  closeButton: {
    position: "absolute",
    backgroundColor: globalStyleColors.colorAccentMid,
    borderRadius: 20,
    padding: 0.5 * globalStyleConstants.unitSize,
    right: globalStyleConstants.defaultPadding,
    top: -18,
    borderWidth: 2,
    borderColor: globalStyleColors.colorGreyDark,
  },
  spinner: {
    position: "absolute",
    left: globalStyleConstants.defaultPadding,
  },
});
