import React from "react";
import { View } from "react-native";
import { TBody, TSecondary } from "./Typography";

export const SectionHeading = React.memo((props: { text: string; isActionable?: boolean; noPadding?: boolean }) => {
  const padding = props.noPadding ? {} : { paddingHorizontal: 20 };

  return (
    <View style={[{ flexShrink: 1 }, padding]}>
      {props.isActionable !== undefined ? (
        <TBody opacity={props.isActionable ? "dark" : "medium"} strikethrough={props.isActionable === false}>
          {props.text}
        </TBody>
      ) : (
        <TSecondary>{props.text.toUpperCase()}</TSecondary>
      )}
    </View>
  );
});
