import { bottomNop } from "@eatbetter/common-shared";
import { AppRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { usePaywallStatus } from "../../components/PaywallDetector";
import { useUserOrExternalRecipe } from "./RecipesSelectors";

export interface ShowRecipeInstructionsAccess {
  type: "show";
}

export interface HiddenRecipeInstructionsAccess {
  type: "hide";
  reason: "bookRestricted" | "urlPaywall" | "urlRestricted";
}

export type RecipeInstructionsAccess = ShowRecipeInstructionsAccess | HiddenRecipeInstructionsAccess;

// We should move this into a composite selector + useSelector once we get paywall status in redux
export const useRecipeInstructionsAccess = (
  recipeOrUserRecipeId?: AppRecipe | UserRecipeId
): RecipeInstructionsAccess => {
  const { recipeBase } = useUserOrExternalRecipe(recipeOrUserRecipeId);
  const { paywallIsUp } = usePaywallStatus(recipeOrUserRecipeId);

  const sourceType = recipeBase?.source.type;

  if (!sourceType) {
    return { type: "show" };
  }

  switch (sourceType) {
    case "book":
      if (recipeBase.instructionsRestricted) {
        return { type: "hide", reason: "bookRestricted" };
      }
      return { type: "show" };
    case "url": {
      if (recipeBase.instructionsRestricted) {
        return { type: "hide", reason: "urlRestricted" };
      }
      if (paywallIsUp) {
        return { type: "hide", reason: "urlPaywall" };
      }

      return { type: "show" };
    }
    case "user": {
      return { type: "show" };
    }
    default:
      bottomNop(sourceType);
      return { type: "show" };
  }
};
