import { useState, useEffect, useRef, PropsWithChildren, useCallback, useLayoutEffect } from "react";
import { View, StyleSheet, Animated, LayoutAnimation } from "react-native";
import RootSiblings from "react-native-root-siblings";
import { globalStyleColors, globalStyleConstants, globalStyles, Opacity } from "./GlobalStyles";
import { useWobbleAnimation } from "./AttentionGrabbers";
import { ButtonRectangle } from "./Buttons";
import { TBody } from "./Typography";
import { useResponsiveDimensions } from "./Responsive";
import { Spacer } from "./Spacer";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";

const strings = {
  close: "Got it",
};

const constants = {
  arrowSize: 12,
};

export interface HighlightProps {
  isActive: boolean;
  message: string | React.ReactElement;
  onClose: () => void;
  arrowTranslate?: { x: number; y: number };
}

interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const Highlight = (props: PropsWithChildren<HighlightProps>) => {
  const wobble = useWobbleAnimation({ loop: true, wobbleAmount: "large" });

  const [childClone, setChildClone] = useState<RootSiblings | null>(null);
  const [childRect, setChildRect] = useState<Rect>();
  const childRef = useRef<View>(null);

  const onLayout = useCallback(() => {
    if (childRef.current) {
      // Ensures that the layout is fully rendered before the measurements are taken
      requestAnimationFrame(() => {
        childRef.current?.measureInWindow((x, y, width, height) => {
          setChildRect({ x, y, width, height });
        });
      });
    }
  }, [childRef, setChildRect]);

  const { width: screenWidth, height: screenHeight } = useResponsiveDimensions();

  // Forces layout recalculation when screen dimensions change (e.g. iPad rotation)
  useEffect(() => {
    onLayout();
  }, [screenWidth, screenHeight]);

  useLayoutEffect(() => {
    if (!props.isActive || !childRect) {
      return;
    }

    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    const tooltipElement = (
      <View style={styles.overlay}>
        <View
          style={{
            position: "absolute",
            left: childRect.x,
            top: childRect.y,
            width: childRect.width,
            height: childRect.height,
          }}
        >
          <Animated.View style={[StyleSheet.absoluteFill, wobble.animatedStyle]}>{props.children}</Animated.View>
        </View>
        <Tooltip
          arrowDirection="up"
          target={childRect}
          message={props.message}
          onPress={props.onClose}
          arrowTranslate={props.arrowTranslate}
        />
      </View>
    );

    if (!childClone) {
      // Create tooltip
      const sibling = new RootSiblings(tooltipElement);
      setChildClone(sibling);
    } else {
      // Rerender tooltip
      childClone.update(tooltipElement);
    }
  }, [childRect, props.isActive]);

  useEffect(() => {
    if (props.isActive) {
      wobble.startAnimation();
    } else {
      wobble.stopAnimation();
    }
  }, [props.isActive]);

  useEffect(() => {
    if (!props.isActive && childClone) {
      childClone.destroy();
      setChildClone(null);
    }
    return () => {
      if (childClone) {
        childClone.destroy();
      }
    };
  }, [props.isActive, props.children]);

  return (
    <View ref={childRef} onLayout={onLayout} style={{ opacity: props.isActive ? 0 : 1 }}>
      {props.children}
    </View>
  );
};

const Tooltip = (props: {
  arrowDirection: "up";
  arrowTranslate?: { x: number; y: number };
  target: Rect;
  message: string | React.ReactElement;
  onPress: () => void;
}) => {
  const { x, y, width, height } = props.target;
  const { width: screenWidth, height: screenHeight } = useResponsiveDimensions();

  const marginFromTarget = globalStyleConstants.minPadding;
  const screenBoundaryRect = {
    left: globalStyleConstants.minPadding,
    right: screenWidth - globalStyleConstants.minPadding,
    top: globalStyleConstants.minPadding,
    bottom: screenHeight - globalStyleConstants.minPadding,
  };

  const { arrowLeft, arrowTop, messageTop, messageLeft, messageRight, messageMaxWidth } = switchReturn(
    props.arrowDirection,
    {
      up: () => {
        const arrowX = x + width / 2 - constants.arrowSize / 2 + (props.arrowTranslate?.x ?? 0);
        const arrowLeft = Math.min(screenBoundaryRect.right, Math.max(screenBoundaryRect.left, arrowX));

        const arrowY = y + height + marginFromTarget + (props.arrowTranslate?.y ?? 0);
        const arrowTop = Math.min(screenBoundaryRect.bottom, Math.max(screenBoundaryRect.top, arrowY));

        const messageY = y + height + constants.arrowSize + marginFromTarget;
        const messageTop = Math.min(screenBoundaryRect.bottom, Math.max(screenBoundaryRect.top, messageY));

        const messageLeft = x < screenWidth / 2 ? globalStyleConstants.minPadding : undefined;
        const messageRight = x >= screenWidth / 2 ? globalStyleConstants.minPadding : undefined;

        const messageMaxWidth = 0.8 * screenWidth;

        return { arrowLeft, arrowTop, messageTop, messageLeft, messageRight, messageMaxWidth };
      },
    }
  );

  return (
    <>
      <View
        style={[
          styles.message,
          {
            top: messageTop,
            left: messageLeft,
            right: messageRight,
            maxWidth: messageMaxWidth,
          },
        ]}
      >
        {typeof props.message === "string" && (
          <TBody align="center" color={globalStyleColors.blackSoft}>
            {props.message}
          </TBody>
        )}
        {typeof props.message !== "string" && props.message}
        <Spacer vertical={1.5} />
        <CloseButton onPress={props.onPress} />
      </View>
      <View style={{ position: "absolute", left: arrowLeft, top: arrowTop }}>
        <Arrow type="up" />
      </View>
    </>
  );
};

const Arrow = (props: { type: "up" }) => {
  switch (props.type) {
    case "up": {
      return <View style={styles.arrow} />;
    }
    default:
      bottomThrow(props.type);
  }
};

const CloseButton = (props: { onPress: () => void }) => {
  return (
    <View style={{ width: 56 }}>
      <ButtonRectangle type="submit" size="small" title={strings.close} onPress={props.onPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: globalStyleColors.rgba("black", "medium"),
  },
  message: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    padding: globalStyleConstants.defaultPadding,
    minWidth: 96,
    minHeight: 56,
    backgroundColor: globalStyleColors.colorGreyLight,
    borderRadius: 12,
    ...globalStyles.shadowItem,
    shadowOpacity: Opacity.medium,
  },
  arrow: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: constants.arrowSize / 1.5,
    borderRightWidth: constants.arrowSize / 1.5,
    borderBottomWidth: constants.arrowSize,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: globalStyleColors.colorGreyLight,
  },
});
