import React, { useState } from "react";
import { log } from "../Log";
import { Platform, RefreshControl } from "react-native";

export const PullToRefresh = React.memo((props: { onPull: () => Promise<unknown>; topOffset?: number }) => {
  const [refreshing, setRefreshing] = useState(false);
  const onPull = async () => {
    try {
      setRefreshing(true);
      await props.onPull();
    } catch (err) {
      log.errorCaught("Unexpected error in PullToRefresh", err);
    } finally {
      setRefreshing(false);
    }
  };

  // Would be cool to use a custom Lottie animation here
  // https://medium.com/groftware/react-native-how-to-create-custom-pull-to-refresh-animations-aa0d14329dc0
  return (
    <RefreshControl refreshing={refreshing} onRefresh={onPull} tintColor="black" progressViewOffset={props.topOffset} />
  );
});

export const getPullToRefresh = (onPull: () => Promise<unknown>, topOffset?: number): JSX.Element | undefined => {
  if (Platform.OS === "web") {
    return undefined;
  }

  return <PullToRefresh onPull={onPull} topOffset={topOffset} />;
};
