import {
  LayoutAnimation,
  SectionList,
  SectionListData,
  SectionListProps,
  SectionListRenderItem,
  View,
} from "react-native";
import Reanimated, { useAnimatedStyle, withTiming } from "react-native-reanimated";
import { ListItemIdOrMergedListItem, SortedListIds, useListRecipe } from "../../lib/lists/ListsSelectors";
import { GroceryListItemId, RecipeGroceryListItem, RecipeInstanceId } from "@eatbetter/lists-shared";
import React, { SetStateAction, useCallback, useMemo, useState } from "react";
import { useResponsiveDimensions } from "../Responsive";
import { useScreenElementDimensions } from "../ScreenView";
import { Haptics } from "../Haptics";
import { SectionHeading } from "../SectionHeading";
import { Spacer } from "../Spacer";
import { GroceryItemStatusChangeHandler, GroceryListItem, groceryListItemConstants } from "./GroceryListItem";
import { getPullToRefresh } from "../PullToRefresh";
import { StyleSheet } from "react-native";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../GlobalStyles";
import { MergedListItemGroup } from "./MergedListItemGroup";
import { Pressable } from "../Pressable";
import { TSecondary } from "../Typography";
import { AnimatedChevron } from "../AnimatedChevron";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { useRecipe } from "../../lib/recipes/RecipesSelectors";
import { IconMore } from "../Icons";
import { CollapsibleList } from "../CollapsibleList";
import { bottomNop, bottomThrow } from "@eatbetter/common-shared";

const strings = {
  completedButton: "Completed",
  other: "Other",
};

type SortSectionListProps = SectionListProps<SectionItem, Section>;
const AnimatedSectionList = Reanimated.createAnimatedComponent<SortSectionListProps>(SectionList);

type SectionType = "aisle" | "time" | "recipe" | "completed";
interface Section {
  header?: {
    type: SectionType;
    title?: string;
    recipe?: Omit<RecipeSectionHeaderProps, "status">;
    status?: "pending" | "completed";
  };
}

interface SectionItemBase<TType extends SectionType, TData> {
  type: TType;
  data: TData;
  showSwipeHint?: boolean;
}
type CategorySortedListItem = SectionItemBase<"aisle", ListItemIdOrMergedListItem>;
type TimeSortedListItem = SectionItemBase<"time", GroceryListItemId>;
type RecipeSortedListItem = SectionItemBase<"recipe", RecipeGroceryListItem>;
type SectionItem = CategorySortedListItem | TimeSortedListItem | RecipeSortedListItem;

type GrocerySortSectionData = SectionListData<SectionItem, Section>;

type RenderSectionPart = (info: { section: GrocerySortSectionData }) => React.ReactElement;
type RenderSectionPartComponent = (props: {
  highlighted: boolean;
  section: Section;
  leadingSection: Section;
  trailingSection: Section;
  leadingItem: SectionItem;
  trailingItem: SectionItem;
}) => React.ReactElement;
type RenderSectionItem = SectionListRenderItem<SectionItem, Section>;
type KeyExtractor = (item: SectionItem, index: number) => string;

interface GroceryListSortViewProps {
  ids: SortedListIds;
  onPullToRefresh: () => Promise<void>;
  onScroll: SortSectionListProps["onScroll"];
  onItemStatusChange: GroceryItemStatusChangeHandler;
  onEdit: (id: GroceryListItemId) => void;
  onPressRecipeOptions?: (recipeId: UserRecipeId, instanceId: RecipeInstanceId) => void;
  showSwipeHint: boolean;
  hide?: boolean;
}

export const GroceryListSortView = React.memo(
  React.forwardRef<SectionList<any, any>, GroceryListSortViewProps>((props, ref) => {
    const { bottomTabBarHeight, headerHeight, bareHeaderHeight } = useScreenElementDimensions();
    const { height: screenHeight } = useResponsiveDimensions();

    const [isCompletedListOpen, setIsCompletedListOpen] = useState(false);
    const [isOtherPendingExpanded, setIsOtherPendingExpanded] = useState(false);
    const [isOtherCompletedExpanded, setIsOtherCompletedExpanded] = useState(false);
    const [isRecipeExpanded, setIsRecipeExpanded] = useState(new Set<RecipeInstanceId>());

    const onPressCompletedButton = useCallback(() => {
      Haptics.feedback("itemStatusChanged");
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setIsCompletedListOpen(prev => !prev);
    }, [setIsCompletedListOpen]);

    const sections: GrocerySortSectionData[] = useMemo<GrocerySortSectionData[]>(() => {
      switch (props.ids.type) {
        case "category": {
          const pending: GrocerySortSectionData[] = props.ids.pending.map((category, categoryIdx) => {
            return {
              key: category.category,
              header: { type: "aisle", title: category.categoryDisplay },
              data: category.ids.map((itemId, itemIdx) => ({
                type: "aisle",
                data: itemId,
                showSwipeHint: props.showSwipeHint && categoryIdx === 0 && itemIdx === 0,
              })),
            };
          });

          const completed: GrocerySortSectionData = {
            key: "categorySortedCompleted",
            header: { type: "completed" },
            data: isCompletedListOpen ? props.ids.completed.map(i => ({ type: "aisle", data: i })) : [],
          };

          return [...pending, completed];
        }
        case "time": {
          const pending: GrocerySortSectionData[] = props.ids.pending.map((category, categoryIdx) => {
            return {
              key: category.time.toString(),
              header: { type: "time", title: category.timeDisplay },
              data: category.ids.map((item, itemIdx) => ({
                type: "time",
                data: item,
                showSwipeHint: props.showSwipeHint && categoryIdx === 0 && itemIdx === 0,
              })),
            };
          });

          const completed: GrocerySortSectionData = {
            key: "timeSortedCompleted",
            header: { type: "completed" },
            data: isCompletedListOpen ? props.ids.completed.map(i => ({ type: "time", data: i })) : [],
          };

          return [...pending, completed];
        }
        case "recipe": {
          const pendingRecipes: GrocerySortSectionData[] = props.ids.pending.recipeInstancesAndIds.map(
            (i, recipeIdx) => {
              return {
                key: i.instanceId,
                header: {
                  type: "recipe",
                  status: "pending",
                  recipe: {
                    recipeId: i.recipeId,
                    recipeInstanceId: i.instanceId,
                    isExpanded: isRecipeExpanded,
                    setIsExpanded: setIsRecipeExpanded,
                    onPressRecipeOptions: props.onPressRecipeOptions,
                  },
                },
                data: isRecipeExpanded.has(i.instanceId)
                  ? i.ids.map((itemId, itemIdx) => ({
                      type: "recipe",
                      data: itemId,
                      showSwipeHint: props.showSwipeHint && recipeIdx === 0 && itemIdx === 0,
                    }))
                  : [],
              };
            }
          );

          const pendingNonRecipeItems: GrocerySortSectionData[] =
            props.ids.pending.nonRecipeIds.length > 0
              ? [
                  {
                    key: "nonRecipeItemsPending",
                    header: { type: "recipe", status: "pending" },
                    data: isOtherPendingExpanded
                      ? props.ids.pending.nonRecipeIds.map(i => {
                          return {
                            type: "aisle",
                            data: i,
                          };
                        })
                      : [],
                  },
                ]
              : [];

          const pending: GrocerySortSectionData[] = [...pendingRecipes, ...pendingNonRecipeItems];

          const completedButton: GrocerySortSectionData = {
            key: "recipeSortedCompleted",
            header: { type: "completed" },
            data: [],
          };

          const completedRecipes: GrocerySortSectionData[] = props.ids.completed.recipeInstancesAndIds.map(i => {
            return {
              key: i.instanceId,
              header: {
                type: "recipe",
                status: "completed",
                recipe: {
                  recipeId: i.recipeId,
                  recipeInstanceId: i.instanceId,
                  isExpanded: isRecipeExpanded,
                  setIsExpanded: setIsRecipeExpanded,
                  onPressRecipeOptions: props.onPressRecipeOptions,
                },
              },
              data: isRecipeExpanded.has(i.instanceId) ? i.ids.map(i => ({ type: "recipe", data: i })) : [],
            };
          });

          const completedNonRecipeItems: GrocerySortSectionData = {
            key: "nonRecipeItemsCompleted",
            header: { type: "recipe", title: strings.other, status: "completed" },
            data: isOtherCompletedExpanded
              ? props.ids.completed.nonRecipeIds.map(i => {
                  return {
                    type: "time",
                    data: i,
                  };
                })
              : [],
          };

          const completed: GrocerySortSectionData[] = isCompletedListOpen
            ? [...completedRecipes, completedNonRecipeItems]
            : [];

          return [...pending, completedButton, ...completed];
        }
        default:
          bottomThrow(props.ids);
      }
    }, [
      props.ids,
      isCompletedListOpen,
      isRecipeExpanded,
      setIsRecipeExpanded,
      isOtherCompletedExpanded,
      isOtherPendingExpanded,
      setIsOtherPendingExpanded,
      setIsOtherCompletedExpanded,
      props.onPressRecipeOptions,
      props.showSwipeHint,
    ]);

    const renderItem: RenderSectionItem = useCallback(
      ({ item }) => {
        switch (item.type) {
          case "aisle": {
            return (
              <MergedOrIndividualListItem
                idOrMergedItem={item.data}
                onItemStatusChange={props.onItemStatusChange}
                onEdit={props.onEdit}
                showSwipeHint={!!item.showSwipeHint}
              />
            );
          }
          case "time": {
            return (
              <GroceryListItem
                id={item.data}
                onStatusChange={props.onItemStatusChange}
                showSwipeHint={!!item.showSwipeHint}
                onEdit={props.onEdit}
              />
            );
          }
          case "recipe": {
            return (
              <GroceryListItem
                id={item.data.id}
                onStatusChange={props.onItemStatusChange}
                showSwipeHint={!!item.showSwipeHint}
                onEdit={props.onEdit}
              />
            );
          }
          default:
            bottomThrow(item);
        }
      },
      [props.onItemStatusChange, props.onEdit]
    );

    const keyExtractor: KeyExtractor = useCallback((item, index) => {
      switch (item.type) {
        case "aisle": {
          return typeof item.data === "string" ? item.data : item.data.key;
        }
        case "time": {
          return item.data;
        }
        case "recipe": {
          return item.data.id;
        }
        default:
          bottomNop(item);
          return `unknown-item-type-${index}`;
      }
    }, []);

    const renderSectionHeader: RenderSectionPart = useCallback(
      info => {
        if (!info.section.header) {
          return <></>;
        }

        switch (info.section.header.type) {
          case "aisle": {
            return (
              <>
                {!!info.section.header.title && (
                  <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
                    <SectionHeading text={info.section.header.title} noPadding />
                  </View>
                )}
              </>
            );
          }
          case "time": {
            return (
              <>
                {!!info.section.header.title && (
                  <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
                    <SectionHeading text={info.section.header.title} noPadding />
                  </View>
                )}
              </>
            );
          }
          case "recipe": {
            const recipe = info.section.header.recipe;
            return (
              <>
                {!!recipe && (
                  <RecipeSectionHeader
                    recipeId={recipe.recipeId}
                    recipeInstanceId={recipe.recipeInstanceId}
                    status={info.section.header.status ?? "pending"}
                    isExpanded={recipe.isExpanded}
                    setIsExpanded={recipe.setIsExpanded}
                    onPressRecipeOptions={recipe.onPressRecipeOptions}
                  />
                )}
                {!recipe && (
                  <>
                    {info.section.header.status === "pending" && (
                      <RecipeOtherItemsSectionHeader
                        isOpen={isOtherPendingExpanded}
                        setIsOpen={setIsOtherPendingExpanded}
                        status="pending"
                      />
                    )}
                    {info.section.header.status === "completed" && (
                      <RecipeOtherItemsSectionHeader
                        isOpen={isOtherCompletedExpanded}
                        setIsOpen={setIsOtherCompletedExpanded}
                        status="completed"
                      />
                    )}
                  </>
                )}
              </>
            );
          }
          case "completed": {
            return (
              <View key={"completedItems_header"}>
                <Spacer vertical={1} />
                <CompletedItemsButton isExpanded={isCompletedListOpen} onPress={onPressCompletedButton} />
                <Spacer vertical={1} />
              </View>
            );
          }
          default:
            bottomThrow(info.section.header.type);
        }
      },
      [
        isCompletedListOpen,
        onPressCompletedButton,
        isOtherPendingExpanded,
        isOtherCompletedExpanded,
        setIsOtherPendingExpanded,
        setIsOtherCompletedExpanded,
      ]
    );

    const renderItemSeparator: RenderSectionPartComponent = useCallback(() => {
      return <Spacer vertical={groceryListItemConstants.marginTop} unit="pixels" />;
    }, []);

    const renderSectionSeparator: RenderSectionPartComponent = useCallback(() => {
      return <Spacer vertical={1.5} />;
    }, []);

    const renderSectionFooter: RenderSectionPart = useCallback(() => {
      return <Spacer vertical={groceryListItemConstants.marginTop} unit="pixels" />;
    }, []);

    const renderListHeader: RenderSectionPartComponent = useCallback(() => {
      return <Spacer vertical={groceryListItemConstants.marginTop} unit="pixels" />;
    }, []);

    const renderListFooter: RenderSectionPartComponent = useCallback(() => {
      return <Spacer vertical={8} />;
    }, []);

    const refreshControl = useMemo(() => {
      return getPullToRefresh(props.onPullToRefresh, headerHeight);
    }, [props.onPullToRefresh, headerHeight]);

    const showHideAnimation = useAnimatedStyle(() => {
      if (props.hide) {
        return {
          zIndex: 0,
          opacity: 0,
        };
      }

      return {
        zIndex: 1,
        opacity: withTiming(1),
      };
    });

    const contentContainerStyle = useMemo(
      () => [
        styles.content,
        { paddingTop: headerHeight, paddingBottom: bottomTabBarHeight, minHeight: screenHeight + bareHeaderHeight },
      ],
      [headerHeight, bottomTabBarHeight, screenHeight, bareHeaderHeight]
    );

    return (
      <Reanimated.View style={[StyleSheet.absoluteFill, showHideAnimation]}>
        <AnimatedSectionList
          ref={ref}
          sections={sections}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          renderSectionHeader={renderSectionHeader}
          ItemSeparatorComponent={renderItemSeparator}
          refreshControl={refreshControl}
          SectionSeparatorComponent={renderSectionSeparator}
          renderSectionFooter={renderSectionFooter}
          ListHeaderComponent={renderListHeader}
          ListFooterComponent={renderListFooter}
          contentContainerStyle={contentContainerStyle}
          showsVerticalScrollIndicator={false}
          onScroll={props.onScroll}
          scrollEventThrottle={16}
        />
      </Reanimated.View>
    );
  })
);

const MergedOrIndividualListItem = React.memo(
  (props: {
    idOrMergedItem: ListItemIdOrMergedListItem;
    showSwipeHint: boolean;
    onItemStatusChange: GroceryItemStatusChangeHandler;
    onEdit: (id: GroceryListItemId) => void;
  }) => {
    const id = props.idOrMergedItem;
    const showSwipeHint = props.showSwipeHint;

    if (typeof id === "object") {
      return (
        <MergedListItemGroup
          groupName={id.groupName}
          mergedItemIds={id.ids}
          showSwipeHint={showSwipeHint}
          onItemStatusChange={props.onItemStatusChange}
          onEdit={props.onEdit}
        />
      );
    }

    return (
      <GroceryListItem
        id={id}
        showSwipeHint={showSwipeHint}
        onEdit={props.onEdit}
        onStatusChange={props.onItemStatusChange}
      />
    );
  }
);

const CompletedItemsButton = React.memo((props: { isExpanded: boolean; onPress: () => void }) => {
  return (
    <View style={{ flexWrap: "wrap" }}>
      <Pressable onPress={props.onPress} style={styles.completedItemsButton}>
        <TSecondary color={globalStyleColors.colorAccentCool}>{strings.completedButton}</TSecondary>
        <Spacer horizontal={0.25} />
        <AnimatedChevron
          color={globalStyleColors.colorAccentCool}
          size={20}
          isRotated={props.isExpanded}
          startPosition="down"
        />
      </Pressable>
    </View>
  );
});

interface RecipeSectionHeaderProps {
  recipeId: UserRecipeId;
  recipeInstanceId: RecipeInstanceId;
  status: "pending" | "completed";
  onPressRecipeOptions?: (recipeId: UserRecipeId, recipeInstanceId: RecipeInstanceId) => void;
  isExpanded: Set<RecipeInstanceId>;
  setIsExpanded: React.Dispatch<SetStateAction<Set<RecipeInstanceId>>>;
}

const RecipeSectionHeader = React.memo((props: RecipeSectionHeaderProps) => {
  // if the recipe is deleted, it should still be present in the lists data
  const recipe = useRecipe(props.recipeId);
  const glRecipeTitle = useListRecipe(props.recipeId)?.title.trim();
  const recipeTitle = recipe?.title ?? glRecipeTitle ?? "Deleted Recipe";

  const toggleRecipe = useCallback(() => {
    props.setIsExpanded(curr => {
      const result = new Set(curr);
      if (curr.has(props.recipeInstanceId)) {
        result.delete(props.recipeInstanceId);
        return result;
      }
      // expand
      result.add(props.recipeInstanceId);
      return result;
    });
  }, [props.recipeId]);

  const onPressMore = useCallback(() => {
    props.onPressRecipeOptions?.(props.recipeId, props.recipeInstanceId);
  }, [props.onPressRecipeOptions, props.recipeId, props.recipeInstanceId]);

  const isOpen = props.isExpanded.has(props.recipeInstanceId);

  return (
    <CollapsibleSectionHeader
      headerText={recipeTitle}
      onPressMoreMenu={onPressMore}
      isActionable={props.status === "pending"}
      isOpen={isOpen}
      onPress={toggleRecipe}
    />
  );
});

interface RecipeOtherItemsSectionHeaderProps {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  status: "pending" | "completed";
}

const RecipeOtherItemsSectionHeader = React.memo((props: RecipeOtherItemsSectionHeaderProps) => {
  const onPress = useCallback(() => {
    props.setIsOpen(!props.isOpen);
  }, [props.isOpen, props.setIsOpen]);

  return (
    <CollapsibleSectionHeader
      headerText={strings.other}
      isActionable={props.status === "pending" ? true : false}
      isOpen={props.isOpen}
      onPress={onPress}
    />
  );
});

const CollapsibleSectionHeader = React.memo(
  (props: {
    headerText: string;
    isOpen: boolean;
    onPress: () => void;
    onPressMoreMenu?: () => void;
    isActionable: boolean;
  }) => {
    const headerComponent = useMemo(
      () => (
        <View style={{ flexDirection: "row", justifyContent: "space-between", flexGrow: 1, flexShrink: 1 }}>
          <View style={{ flexShrink: 1, marginLeft: globalStyleConstants.unitSize }}>
            <SectionHeading text={props.headerText} isActionable={props.isActionable} noPadding />
          </View>
          {!!props.onPressMoreMenu && <MoreButton onPress={props.onPressMoreMenu} />}
        </View>
      ),
      [props.headerText, props.isActionable, props.onPressMoreMenu]
    );

    return (
      <>
        <CollapsibleList
          disableLazyRender
          headerComponent={headerComponent}
          isOpen={props.isOpen}
          onPress={props.onPress}
          chevronStyle={{ align: "flex-end" }}
        />
        {!props.isOpen && <Spacer vertical={1} />}
      </>
    );
  }
);

const MoreButton = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable
      onPress={props.onPress}
      style={{ justifyContent: "flex-end", paddingHorizontal: globalStyleConstants.unitSize }}
    >
      <IconMore opacity="opaque" />
    </Pressable>
  );
});

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: globalStyleConstants.minPadding,
  },
  completedItemsButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 0.5 * globalStyleConstants.unitSize,
    paddingLeft: globalStyleConstants.unitSize,
    paddingRight: 0.5 * globalStyleConstants.unitSize,
    backgroundColor: globalStyleColors.white,
    borderColor: globalStyleColors.colorAccentCool,
    borderWidth: 1,
    borderRadius: globalStyleConstants.defaultBorderRadius,
    ...globalStyles.shadowItem,
  },
});
