import { StyleSheet, View } from "react-native";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { TextInput, TextInputHandle } from "../TextInput";
import React from "react";

export const groceryItemTextInputConstants = {
  minHeight: 48, // 1 line
  maxHeight: 90, // 3 lines
};

interface GroceryItemTextInputProps {
  inputText: string;
  onChangeText: (v: string) => void;
  placeholderText?: string;
  showClearButton?: boolean;
  multiline?: boolean;
  autofocus?: boolean;
  blurOnSubmit?: boolean;
  onSubmit?: () => void;
  renderSavedIcon?: () => React.ReactNode;
  isErrorState?: boolean;
}

export const GroceryItemTextInput = React.memo(
  React.forwardRef<TextInputHandle, GroceryItemTextInputProps>((props, ref) => {
    return (
      <View style={[styles.textInputWrap, props.isErrorState ? styles.errorState : {}]}>
        <View style={styles.textInput}>
          <TextInput
            ref={ref}
            value={props.inputText}
            onChangeText={props.onChangeText}
            returnKeyType={"done"}
            blurOnSubmit={props.blurOnSubmit}
            onSubmit={props.onSubmit}
            placeholderText={props.placeholderText}
            noBorder
            noPadding
            autoFocus={props.autofocus}
            showClearButton={props.showClearButton}
            multiline={props.multiline}
            cursorColor={props.isErrorState ? globalStyleColors.colorAccentWarm : undefined}
            backgroundColor="transparent"
          />
        </View>
        {props.renderSavedIcon?.()}
      </View>
    );
  })
);

const styles = StyleSheet.create({
  textInputWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: groceryItemTextInputConstants.maxHeight,
    minHeight: groceryItemTextInputConstants.minHeight,
    paddingHorizontal: globalStyleConstants.unitSize,
    borderRadius: groceryItemTextInputConstants.minHeight / 2,
    borderColor: globalStyleColors.rgba("black", "xlight"),
    borderWidth: 1,
    backgroundColor: "white",
    overflow: "hidden",
  },
  textInput: {
    flex: 1,
    paddingLeft: 0.5 * globalStyleConstants.unitSize,
  },
  errorState: {
    borderColor: globalStyleColors.colorAccentWarm,
  },
});
