import { useEffect, useRef } from "react";
import { OnboardingEventType, reportOnboardingEvent } from "../lib/analytics/AnalyticsEvents";
import RootSibling from "react-native-root-siblings";
import { Animated, Platform } from "react-native";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { StyleSheet } from "react-native";
import Svg, { Defs, Mask, Rect } from "react-native-svg";

/**
 * Spotlights a section of the screen for the specified duration. Greys out the entire screen with the exception of the area specified.
 */
export function spotlight(args: {
  screenX: number;
  screenY: number;
  width: number;
  height: number;
  durationMs: number;
  delayMs?: number;
  analyticsEvent: OnboardingEventType;
}) {
  const fadeDurationMs = 300;
  const delayMs = args.delayMs ?? 0;
  const spotlightDuration = args.durationMs - 2 * fadeDurationMs;

  const spotlight = new RootSibling(
    (
      <SpotlightMask
        delayMs={delayMs}
        durationMs={spotlightDuration}
        fadeMs={fadeDurationMs}
        screenX={args.screenX}
        screenY={args.screenY}
        width={args.width}
        height={args.height}
        analyticsEvent={args.analyticsEvent}
      />
    )
  );

  // Unmount the spotlight component after it's done animating
  setTimeout(() => {
    spotlight.destroy();
  }, delayMs + args.durationMs);
}

const SpotlightMask = (props: {
  delayMs: number;
  fadeMs: number;
  durationMs: number;
  screenX: number;
  screenY: number;
  width: number;
  height: number;
  analyticsEvent: OnboardingEventType;
}) => {
  const opacity = useRef(new Animated.Value(0)).current;
  const dispatch = useDispatch();

  // Fade in and out
  useEffect(() => {
    Animated.sequence([
      Animated.timing(opacity, {
        toValue: 1,
        delay: props.delayMs,
        duration: props.fadeMs,
        useNativeDriver: Platform.OS !== "web",
      }),
      Animated.timing(opacity, {
        toValue: 0,
        duration: props.fadeMs,
        delay: props.durationMs,
        useNativeDriver: Platform.OS !== "web",
      }),
    ]).start();

    dispatch(analyticsEvent(reportOnboardingEvent(props.analyticsEvent)));
  }, []);

  return (
    <Animated.View style={[StyleSheet.absoluteFill, { opacity, zIndex: 1000 }]}>
      <Svg height={"100%"} width={"100%"}>
        <Defs>
          <Mask id="mask" x="0" y="0" height="100%" width="100%">
            <Rect height="100%" width="100%" fill="#fff" />
            <Rect
              x={props.screenX}
              y={props.screenY}
              width={props.width}
              height={props.height}
              rx={props.height / 2}
              ry={props.height / 2}
            />
          </Mask>
        </Defs>
        <Rect height="100%" width="100%" fill="rgba(0,0,0,0.8)" mask="url(#mask)" />
      </Svg>
    </Animated.View>
  );
};
