import { View } from "react-native";
import { SegmentedControl, getSegmentedControlHeight } from "./SegmentedControl";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCheckpointCompleted } from "../lib/system/SystemSelectors";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { EpochMs, daysBetween, defaultTimeProvider } from "@eatbetter/common-shared";
import { useScreen } from "../navigation/ScreenContainer";
import { spotlight } from "./Spotlight";
import { globalStyleConstants } from "./GlobalStyles";
import { useScreenHeaderDimensions } from "./ScreenHeaders";
import { useResponsiveDimensions } from "./Responsive";
import { OnboardingEventType } from "../lib/analytics/AnalyticsEvents";
import { useDispatch } from "../lib/redux/Redux";

const strings = {
  web: "Web",
  readerMode: "X-ray",
};

export const readerModeToggleConstants = {
  width: 128,
  height: getSegmentedControlHeight("small"),
};

const config = {
  delayMs: 1000,
  durationMs: 1600,
};

export type ReaderModeSpotlightContext =
  | "spotlightLibraryReaderMode"
  | "spotlightPostViewRecipeReaderMode"
  | "spotlightShareViewRecipeReaderMode"
  | "spotlightSearchViewRecipeReaderMode";

// keep track of the last spotlight and only do it once per day (or app lifecycle)
let readerModeSpotlighted = 0 as EpochMs;

export function useReaderModeToggle(
  context: Extract<OnboardingEventType, ReaderModeSpotlightContext>,
  isReaderMode: boolean,
  setIsReaderMode: (v: boolean) => void,
  disabled: boolean,
  showSpotlight: boolean
) {
  const dispatch = useDispatch();
  const recipeReaderModeToggled = useCheckpointCompleted("recipeReaderModeToggled");
  const focused = useScreen().nav.focused;

  const { headerHeight } = useScreenHeaderDimensions();
  const { width: screenWidth } = useResponsiveDimensions();

  const onPress = useCallback(() => {
    setIsReaderMode(!isReaderMode);
    if (!recipeReaderModeToggled) {
      dispatch(checkpointsCompleted(["recipeReaderModeToggled"]));
    }
  }, [dispatch, isReaderMode, setIsReaderMode, recipeReaderModeToggled]);

  const [delaySpotlight, setDelaySpotlight] = useState(true);

  // Delay spotlight
  useEffect(() => {
    setTimeout(() => {
      setDelaySpotlight(false);
    }, config.delayMs);
  }, []);

  // Onboarding: spotlight reader mode toggle if user hasn't tried it yet
  useEffect(() => {
    const daysSinceLast = daysBetween(readerModeSpotlighted, defaultTimeProvider());
    if (delaySpotlight || !focused || !showSpotlight || daysSinceLast < 1) {
      return;
    }

    const spotlightPadding = 0.5 * globalStyleConstants.unitSize;
    const spotlightHeight = readerModeToggleConstants.height + 2 * spotlightPadding;
    const spotlightWidth = readerModeToggleConstants.width + 2 * spotlightPadding;

    spotlight({
      durationMs: config.durationMs,
      screenY: headerHeight - spotlightHeight + (spotlightPadding - 0.5 * globalStyleConstants.unitSize),
      screenX: screenWidth / 2 - 0.5 * readerModeToggleConstants.width - spotlightPadding,
      width: spotlightWidth,
      height: spotlightHeight,
      analyticsEvent: context,
    });

    readerModeSpotlighted = defaultTimeProvider();
  }, [focused, delaySpotlight]);

  return useMemo(
    () => <ReaderModeToggle value={isReaderMode} onPress={onPress} disabled={disabled} />,
    [isReaderMode, onPress, disabled]
  );
}

interface ReaderModeToggleProps {
  value: boolean;
  onPress: () => void;
  disabled: boolean;
}

export const ReaderModeToggle = React.memo((props: ReaderModeToggleProps) => {
  return (
    <View style={{ backgroundColor: "transparent" }}>
      <SegmentedControl
        height="small"
        width={readerModeToggleConstants.width}
        disabled={props.disabled}
        segments={[
          {
            label: strings.web,
            onPress: props.onPress,
            isSelected: !props.value,
          },
          {
            label: strings.readerMode,
            onPress: props.onPress,
            isSelected: props.value,
          },
        ]}
      />
    </View>
  );
});
