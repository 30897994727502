import { PhotoRef } from "@eatbetter/photos-shared";
import React, { useCallback } from "react";
import { Photo } from "../components/Photo";
import { Pressable } from "../components/Pressable";
import { ScreenView } from "../components/ScreenView";
import { ViewPhotoScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";

export const ViewPhotoScreen = withNonNavigableScreenContainer("ViewPhotoScreen", (props: ViewPhotoScreenProps) => {
  return React.createElement<Props>(ViewPhotoScreenComponent, {
    photo: props.photo,
  });
});

interface Props {
  photo: PhotoRef;
}

const ViewPhotoScreenComponent = React.memo((props: Props) => {
  const screen = useScreen();

  const onPressDone = useCallback(() => {
    screen.nav.goBack();
  }, [screen]);

  return (
    <ScreenView
      header={{
        type: "native",
        title: "",
        backgroundColor: "black",
        tintColor: "white",
        right: { type: "done", onPress: onPressDone },
      }}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      <Pressable
        onPress={onPressDone}
        singlePress
        noFeedback
        style={{ flex: 1, backgroundColor: "black", justifyContent: "center" }}
      >
        <Photo resizeMode="contain" style={"flexed"} source={props.photo} sourceSize="w1290" noBorderRadius />
      </Pressable>
    </ScreenView>
  );
});
