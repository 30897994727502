import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { GroceryListItemAdd, GroceryListItemAddImperativeHandle } from "../components/grocery/GroceryListItemAdd";
import { Keyboard, LayoutAnimation, StyleSheet, View } from "react-native";
import { HeaderProps, useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { globalStyleColors } from "../components/GlobalStyles";
import { useDispatch } from "../lib/redux/Redux";
import { useAuthedUserId } from "../lib/system/SystemSelectors";
import { usePillSuggestions, useTypeaheadSuggestions } from "../lib/lists/ListsSelectors";
import { GroceryListSuggestion } from "@eatbetter/lists-shared";
import { log } from "../Log";
import { Haptics } from "../components/Haptics";
import { groceryListItemAddedClient, groceryListSuggestionTapped } from "../lib/lists/ListsThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { UserId } from "@eatbetter/common-shared";

const strings = {
  screenHeader: "Add item",
};

export const GroceryAddItemScreen = withNonNavigableScreenContainer("GroceryAddItemScreen", () => {
  return React.createElement(GroceryAddItemScreenComponent);
});

const GroceryAddItemScreenComponent = React.memo(() => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { modalHeaderHeight } = useScreenHeaderDimensions();

  const userId = useAuthedUserId();
  const suggestions = usePillSuggestions();
  const addItemRef = useRef<GroceryListItemAddImperativeHandle>(null);

  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [inputText, setInputTextDirect] = useState("");

  const typeAheadSuggestions = useTypeaheadSuggestions(inputText);

  const inputTextRef = useRef(inputText);
  const setInputText = useCallback(
    (s: string) => {
      setInputTextDirect(s);
      inputTextRef.current = s;
    },
    [setInputTextDirect, inputTextRef]
  );

  const onChangeText = useCallback(
    (text: string) => {
      addItemRef.current?.scrollSuggestionsToTop();
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setInputText(text);
    },
    [addItemRef.current, setInputText]
  );

  const goBack = useCallback(() => {
    screen.nav.goBack();
  }, [screen]);

  const submitTextItem = useCallback(
    (item: string, userId: UserId) => {
      if (!item) {
        return;
      }

      try {
        dispatch(groceryListItemAddedClient({ text: item, addedBy: userId }));
        Haptics.feedback("itemAdded");
        onChangeText("");
      } catch (error) {
        displayUnexpectedErrorAndLog("Error in GroceryAddItemScreen.submit", { item, userId });
      }
    },
    [inputText, onChangeText, addItemRef.current, goBack, userId, dispatch]
  );

  const onPressSuggestion = useCallback(
    (suggestion: GroceryListSuggestion, index: number, type: "Pill" | "Typeahead") => {
      if (!userId) {
        log.error("Grocery smart suggestion onPress: Could not get authed user ID");
        return;
      }

      Haptics.feedback("itemAdded");
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      dispatch(groceryListSuggestionTapped({ addedBy: userId, suggestion, index, type }));
      addItemRef.current?.triggerItemAddedToast();
      onChangeText("");
    },
    [dispatch, addItemRef.current, onChangeText, userId]
  );

  const onKeyboardSubmit = useCallback(() => {
    const item = inputText.trim();

    if (!item) {
      goBack();
      return;
    }

    if (!userId) {
      log.error("Grocery List: userId not set, cannot add item to list.");
      return;
    }

    submitTextItem(item, userId);
    addItemRef.current?.triggerItemAddedToast();
  }, [inputText, userId, submitTextItem, addItemRef.current]);

  const onPressDone = useCallback(() => {
    goBack();
  }, [goBack]);

  // If the user dismisses the sheet (swipe or 'Done') and the text input field is populated, we'll submit the item.
  const onBeforeCloseModal = useCallback(() => {
    const item = inputTextRef.current.trim();

    if (item) {
      if (userId) {
        submitTextItem(item, userId);
      } else {
        log.error("Grocery List: userId not set, cannot add item to list.");
      }
    }
  }, [userId, submitTextItem]);

  useEffect(() => {
    return screen.nav.addListener("beforeRemove", onBeforeCloseModal);
  }, [onBeforeCloseModal]);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", e => {
      setKeyboardHeight(e.endCoordinates.height);
    });

    return () => keyboardDidShowListener.remove();
  }, []);

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "native",
      backgroundColor: globalStyleColors.colorGreyLight,
      title: strings.screenHeader,
      right: { type: "done", onPress: onPressDone },
    };
  }, [onPressDone]);

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      header={header}
      backgroundColor={globalStyleColors.colorGreyLight}
    >
      <View style={[StyleSheet.absoluteFill, { bottom: keyboardHeight, paddingTop: modalHeaderHeight }]}>
        <GroceryListItemAdd
          ref={addItemRef}
          inputText={inputText}
          onChangeText={onChangeText}
          suggestions={suggestions}
          typeAheadSuggestions={typeAheadSuggestions}
          onPressSuggestion={onPressSuggestion}
          onKeyboardSubmit={onKeyboardSubmit}
          onDismiss={goBack}
          autofocusTextInput
        />
      </View>
    </ScreenView>
  );
});
