import { SyncThunkAction, ThunkAction } from "../redux/Redux";
import { log } from "../../Log";
import { filterOutFalsy, StructuredError, UserId, DistributiveOmit, emptyToUndefined } from "@eatbetter/common-shared";
import { isLoading } from "../redux/ServerData";
import {
  cookingSessionAdded,
  cookingSessionConflict,
  cookingSessionRemoved,
  cookingSessionsErrored,
  cookingSessionsReceived,
  cookingSessionsRequested,
  ingredientUpdateErrored,
  ingredientUpdateStarted,
  ingredientUpdateSuccess,
  selectedInstructionUpdateErrored,
  selectedInstructionUpdateStarted,
  selectedInstructionUpdateSuccess,
  internalIngredientStatusSelector,
  internalSelectedInstructionSelector,
  activeCookingSessionChanged,
  audioEnabledChanged,
  selectedInstructionUpdated,
} from "./CookingSessionsSlice";
import {
  CookingSessionId,
  CookingSessionIngredientIndex,
  CookingSessionInstructionIndex,
} from "@eatbetter/cooking-shared";
import { SetWaitingHandler } from "../Types";
import { mergeItemWithUpdates, mergeUpdates } from "../redux/ItemWithUpdates";
import { UserRecipeId, RecipeSectionId, RecipeInstructionId } from "@eatbetter/recipes-shared";
import { getAndStoreUserRecipe } from "../recipes/RecipesThunks";
import { recipeAdapterSelectors } from "../recipes/RecipesSlice";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import {
  reportAudioSessionEnded,
  reportAudioSessionStarted,
  reportCookingSessionEnded,
  reportCookingSessionOpened,
  reportCookingSessionStarted,
} from "../analytics/AnalyticsEvents";
import { deleteStaleTimers } from "./CookingTimerThunks";
import { EndCookingSessionArgsV2 } from "@eatbetter/composite-shared";
import { PaywallDetectionOutcome } from "@eatbetter/composite-shared";
import { selectCookingSessionById } from "./CookingSessionsSelectors";
import { getImageUrl } from "../../components/Photo";
import { loadNewProfilePosts } from "../social/SocialThunks";

export const loadCookingSessions = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: loadCookingSessions");
    try {
      if (isLoading("cookingSessions.meta", getState(), s => s.cookingSessions.meta)) {
        log.info("loadCookingSessions called, but status is already 'loading'");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(cookingSessionsRequested(startTime));

      const resp = await deps.api.withThrow().getActiveCookingSessions();
      dispatch(cookingSessionsReceived({ startTime, data: resp.data }));

      // clean up any timers that are for cooking sessions that no longer exist
      const state = getState();
      const sessionIds = resp.data.map(s => s.id);
      const timers = filterOutFalsy(Object.values(state.cookingSessions.timers.entities));
      const timersToDelete = timers.filter(t => !sessionIds.includes(t.cookingSessionId)).map(t => t.id);
      log.info("Deleting stale timers in loadCookingSessions", { timersToDelete });
      dispatch(deleteStaleTimers(timersToDelete));
    } catch (err) {
      log.errorCaught("Unexpected error fetching active cooking sessions", err);
      dispatch(cookingSessionsErrored());
    }
  };
};

export const createCookingSession = (
  args: { id: CookingSessionId; sourceRecipeId: UserRecipeId; paywallDetectionOutcome: PaywallDetectionOutcome },
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: createCookingSession", { args });
    try {
      const state = getState();
      const recipe = recipeAdapterSelectors.selectById(state.recipes, args.sourceRecipeId);

      if (!recipe) {
        log.error("Error creating cooking session: could not find source recipe.", { args });
        return;
      }

      const resp = await deps.api.withThrow(setWaiting).createNewCookingSession({ id: args.id, sourceRecipe: recipe });
      dispatch(cookingSessionAdded(resp.data));
      dispatch(activeCookingSessionChanged(resp.data.id));

      const activeSessionsIncludingNew = state.cookingSessions.cookingSessionIds.length;

      const event = reportCookingSessionStarted({
        cookingSession: resp.data,
        activeSessionsIncludingNew,
        paywallStatus: args.paywallDetectionOutcome,
      });
      dispatch(analyticsEvent(event));
    } catch (err) {
      log.errorCaught("Unexpected error creating new cooking session.", err, { args });
    }
  };
};

export const changeActiveCookingSession = (args: { cookingSessionId: CookingSessionId }): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const cookingSession = state.cookingSessions.cookingSessions[args.cookingSessionId];

    dispatch(activeCookingSessionChanged(args.cookingSessionId));

    if (cookingSession) {
      const event = reportCookingSessionOpened({ cookingSession });
      dispatch(analyticsEvent(event));
    }
  };
};

export const endCookingSession = (args: {
  endArgs: DistributiveOmit<EndCookingSessionArgsV2, "cookingSessionVersion">;
  setWaiting?: SetWaitingHandler;
}): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    try {
      const { endArgs, setWaiting } = args;
      const { type, cookingSessionId: id } = endArgs;

      log.info("Thunk: endCookingSession", { id, type });
      const state = getState();

      // clean up any timers for this recipe
      const timers = filterOutFalsy(Object.values(state.cookingSessions.timers.entities));
      const timersForSession = timers.filter(t => t.cookingSessionId === id).map(t => t.id);

      log.info("Ending cooking session, cleaning up timers", { timersForSession });
      dispatch(deleteStaleTimers(timersForSession));

      await dispatch(completeCookingSessionWithRetry({ endArgs, setWaiting }));

      if (endArgs.type === "save") {
        // refresh profile to update cook count, but don't wait on it
        dispatch(loadNewProfilePosts()).catch(err => {
          log.errorCaught("Error dispatching loadNewProfilePosts from endCookingSession", err);
        });
      }

      const cookingSession = state.cookingSessions.cookingSessions[id];
      if (!cookingSession) {
        log.error(`No cooking session found for ID ${id}. This shouldn't happen`);
        return;
      }

      const event = reportCookingSessionEnded({
        cookingSession,
        saveActivity: endArgs.type === "save",
        createPost: endArgs.type === "save" && endArgs.createPost,
        postComment: endArgs.type === "save" ? emptyToUndefined(endArgs.comment) : undefined,
        rating: endArgs.type === "save" ? endArgs.rating : undefined,
      });
      dispatch(analyticsEvent(event));
    } catch (err) {
      log.errorCaught("Unexpected error in endCookingSession", err);
      throw err;
    }
  };
};

const completeCookingSessionWithRetry = (args: {
  endArgs: DistributiveOmit<EndCookingSessionArgsV2, "cookingSessionVersion">;
  setWaiting?: SetWaitingHandler;
  retry?: boolean;
}): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    const { endArgs, setWaiting } = args;
    const { type, cookingSessionId } = endArgs;
    const retry = args.retry ?? true;

    log.info("Thunk: completeCookingSessionWithRetry", { cookingSessionId, type, retry });

    try {
      setWaiting?.(true);

      const state = getState();
      const cookingSession = state.cookingSessions.cookingSessions[cookingSessionId];

      const resp = await deps.api
        .withReturn(setWaiting)
        .endCookingSession({ ...endArgs, cookingSessionVersion: cookingSession!.version });

      if (resp.data) {
        dispatch(cookingSessionRemoved({ id: cookingSessionId }));

        // refresh the recipe to get the updated cook time to make sure it doesn't show up in the
        // recently shopped section.
        if (cookingSession) {
          await dispatch(getAndStoreUserRecipe({ recipeId: cookingSession.sourceRecipe.id })).catch(err => {
            log.errorCaught("Error refreshing recipe after cooking session completed", err);
          });
        }

        return;
      }

      if (resp.error && resp.error.code === "cooking/cookingSessionConflict") {
        // Currently either user can complete the cooking session, regardless of who started it.
        log.info("completeCookingSessionWithRetry conflict error");
        dispatch(cookingSessionConflict(resp.error.payload));
        if (retry) {
          log.info("Retrying completeCookingSessionWithRetry due to conflict");
          await dispatch(completeCookingSessionWithRetry({ endArgs, setWaiting, retry: false }));
          return;
        } else {
          log.error("Failed to complete cooking session: version conflict after update and retry...giving up.");
          throw new StructuredError(resp.error);
        }
      }

      if (resp.error) {
        throw new StructuredError(resp.error);
      }
    } finally {
      setWaiting?.(false);
    }
  };
};

export const updateIngredientStatus = (
  cookingSessionId: CookingSessionId,
  ingredientSectionId: RecipeSectionId,
  index: CookingSessionIngredientIndex
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: updateIngredientStatus", { cookingSessionId, ingredientSectionId, index });
    let started = false;

    try {
      const state = getState();
      const cookingSession = state.cookingSessions.cookingSessions[cookingSessionId];

      if (!cookingSession) {
        log.error("Could not find cooking session.", { cookingSessionId });
        return;
      }

      const current = internalIngredientStatusSelector(cookingSession, ingredientSectionId, index);

      if (!current) {
        log.error("updateIngredientStatus called but could not find target instruction.");
        return;
      }

      if (current.updates.filter(u => u.pending).length > 0) {
        log.info("updateCookingSessionIngredient called when update is already in progress", { current });
        return;
      }

      if (current.updates.length > 0) {
        const update = mergeUpdates(current.updates);
        started = true;
        dispatch(ingredientUpdateStarted({ cookingSessionId, sectionId: ingredientSectionId, ingredientId: index }));
        const resp = await deps.api.withReturn().updateCookingSessionIngredientStatus({
          cookingSessionId,
          ingredientSectionId,
          ingredientIndex: index,
          ingredientStatus: update.status,
          version: current.item.version,
        });

        if (resp.data) {
          dispatch(
            ingredientUpdateSuccess({ cookingSessionId, sectionId: ingredientSectionId, index, updatedItem: resp.data })
          );
          return;
        }

        if (resp.error && resp.error.code === "cooking/cookingSessionConflict") {
          dispatch(cookingSessionConflict({ item: resp.error.payload.item }));
          return;
        }

        throw new StructuredError(resp.error);
      }
    } catch (err) {
      log.errorCaught(`Unexpected error persisting cooking session ingredient ${index}`, err);

      if (started) {
        dispatch(ingredientUpdateErrored({ cookingSessionId, sectionId: ingredientSectionId, ingredientId: index }));
      }
    }
  };
};

export const instructionTapped = (args: {
  cookingSessionId: CookingSessionId;
  userId: UserId;
  instructionSectionId: RecipeSectionId;
  instructionIndex?: CookingSessionInstructionIndex;
  instructionId?: RecipeInstructionId;
}): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    dispatch(selectedInstructionUpdated(args));

    const state = getState();
    if (state.cookingSessions.audioEnabled) {
      const session = selectCookingSessionById(state, args.cookingSessionId);
      if (!session) {
        log.error("updateSelectedInstructionClient: No session for id", { args });
        return;
      }

      const recipe = session.sourceRecipe;
      const instructions = recipe.instructions;
      const sectionIndex = instructions.sections.findIndex(s => s.id === args.instructionSectionId);
      if (sectionIndex < 0) {
        log.error(`updateSelectedInstructionClient: Invalid section index ${sectionIndex}`, { args });
        return;
      }
      const instructionIndex =
        args.instructionIndex ?? instructions.sections[sectionIndex]?.items.findIndex(i => i.id === args.instructionId);
      if (instructionIndex === undefined || instructionIndex < 0) {
        log.error(`updateSelectedInstructionClient: Invalid instruction index ${instructionIndex}`, { args });
        return;
      }

      deps.audioCookingSessionManager?.playInstruction({
        title: recipe.title,
        instructions,
        sectionIndex,
        instructionIndex,
        sessionId: args.cookingSessionId,
        photo: recipe.photo ? getImageUrl(recipe.photo, "w1290") : undefined,
        publisher: recipe.publisher?.name,
        author: recipe.author?.name,
        book: recipe.book?.name,
      });
    }
  };
};

export const updateSelectedInstructionServer = (
  cookingSessionId: CookingSessionId,
  userId: UserId
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: updateSelectedInstruction", { cookingSessionId, userId });
    let started = false;

    try {
      const state = getState();
      const cookingSession = state.cookingSessions.cookingSessions[cookingSessionId];

      if (!cookingSession) {
        log.error("Could not find cooking session.", { cookingSessionId });
        return;
      }

      const current = internalSelectedInstructionSelector(cookingSession, userId);

      if (!current) {
        log.error("updateSelectedInstruction called but could not find target instruction.");
        return;
      }

      if (current.itemState === "createNeeded") {
        log.error("updateSelectedInstruction called and invalid createNeeded state found", { current });
        return;
      }

      if (current.itemState !== "updateNeeded") {
        log.error(`updateSelectedInstruction called while item state has state ${current.itemState}`, { current });
        return;
      }

      if (current.updates.filter(u => u.pending).length > 0) {
        log.error("updateSelectedInstruction called when update is already in progress and state is incorrect", {
          current,
        });
        return;
      }

      if (current.updates.length > 0) {
        const update = mergeUpdates(current.updates);
        started = true;
        dispatch(selectedInstructionUpdateStarted({ cookingSessionId, userId }));

        const resp = await deps.api.withThrow().updateCookingSessionSelectedInstruction({
          cookingSessionId,
          instructionSectionId: update.sectionId,
          instructionIndex: update.index,
          instructionState: update.state,
          clientTs: update.clientTs,
        });

        dispatch(selectedInstructionUpdateSuccess({ cookingSessionId, userId, updatedItem: resp.data }));
      }
    } catch (err) {
      log.errorCaught(`Unexpected error persisting selected instruction for cooking session for user ${userId}`, err);

      if (started) {
        dispatch(selectedInstructionUpdateErrored({ cookingSessionId, userId: userId }));
      }
    }
  };
};

/**
 * Activate the audio session and return either the user's selected instruction, if there is 1, or the first instruction so the
 * UI can focus it to start playing
 * @param activeSessionId
 */
export const setAudioCookingSessionEnabled = (
  activeSessionId?: CookingSessionId
): ThunkAction<{ selectedInstruction?: { sectionId: RecipeSectionId; instructionId: RecipeInstructionId } }> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: setAudioCookingSessionEnabled");

    if (!deps.audioCookingSessionManager) {
      return {};
    }

    const state = getState();
    const currentState = state.cookingSessions.audioEnabled;

    if (currentState) {
      return {};
    }

    dispatch(audioEnabledChanged({ enabled: true }));

    const event = reportAudioSessionStarted();
    dispatch(analyticsEvent(event));

    await deps.audioCookingSessionManager.startSession({
      nextPreviousHandler: args => dispatch(instructionSelectedByAudioNextPrevious(args)),
    });

    if (!activeSessionId) {
      return {};
    }

    const userId = state.system.authedUser.data?.userId;
    const session = state.cookingSessions.cookingSessions[activeSessionId];

    if (!session || !userId) {
      return {};
    }

    const instructions = session.sourceRecipe.instructions;
    const selectedInstructionRaw = session.selectedInstructions[userId];
    const selectedInstruction = selectedInstructionRaw ? mergeItemWithUpdates(selectedInstructionRaw) : undefined;

    if (selectedInstruction) {
      const instructionId = instructions.sections.find(s => s.id === selectedInstruction.sectionId)?.items[
        selectedInstruction.index
      ]?.id;
      if (instructionId) {
        return {
          selectedInstruction: { sectionId: selectedInstruction.sectionId, instructionId },
        };
      }
    } else if (instructions.sections[0] && instructions.sections[0].items.length > 0) {
      const section = instructions.sections[0];
      return {
        selectedInstruction: { sectionId: section.id, instructionId: section.items[0]!.id },
      };
    }

    return {};
  };
};

export const setAudioCookingSessionDisabled = (durationInSeconds?: number): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: setAudioCookingSessionDisabled");
    const currentState = getState().cookingSessions.audioEnabled;

    if (!currentState) {
      return;
    }

    dispatch(audioEnabledChanged({ enabled: false }));
    const event = reportAudioSessionEnded({ durationInSeconds });
    dispatch(analyticsEvent(event));

    await deps?.audioCookingSessionManager?.endSession();
  };
};

/**
 * Called when a user taps next/previous on the now playing audio controls
 * @param args
 */
export const instructionSelectedByAudioNextPrevious = (args: {
  cookingSessionId: CookingSessionId;
  sectionIndex: number;
  instructionIndex: number;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    const state = getState();
    const userId = state.system.authedUser.data?.userId;
    const section =
      state.cookingSessions.cookingSessions[args.cookingSessionId]?.sourceRecipe.instructions.sections[
        args.sectionIndex
      ];

    if (!userId || !section) {
      return;
    }

    dispatch(
      selectedInstructionUpdated({
        userId,
        cookingSessionId: args.cookingSessionId,
        instructionSectionId: section.id,
        instructionIndex: args.instructionIndex as CookingSessionInstructionIndex,
      })
    );
  };
};

export const cookingSessionScreenUnmountedOrNavedAway = (): SyncThunkAction<void> => {
  return (dispatch, _getState, _deps) => {
    dispatch(setAudioCookingSessionDisabled()).catch(err => {
      log.errorCaught("Error dispatching setAudioCookingSessionDisabled cookingSessionScreenUnmountedOrNavedAway", err);
    });
  };
};
