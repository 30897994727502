import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  getHackModalScreenName,
  getScreenNameFromReactNavigationRouteName,
  isHackModalStack,
  ModalNavScreen,
  ModalNonNavigableScreen,
  NavAction,
  NavigableScreenType,
  NavScreen,
  NonNavigableScreen,
  NonNavigableScreenType,
  OptionalParamParser,
  OptionalParamSerializer,
  ParamParser,
  ParamSerializer,
  Parser,
  PropsUrlConverter,
  RouteInfo,
  ScreenType,
  ScreenWithUrlPropsConverter,
  Serializer,
} from "./NavigationTypes";
import {
  getPathFromState,
  getStateFromPath,
  NavigationContainerRefWithCurrent,
  NavigationState,
  useIsFocused,
} from "@react-navigation/native";
import {
  ScreenTypeRouteName,
  NavTree,
  navTree,
  WithName,
  isNonNavigableScreen,
  TabScreenRouteName,
  isNavigableScreen,
  tabNames,
} from "./NavTree";
import { log } from "../Log";
import { bottomThrow, deepEquals, FilterKeys, getPathAndQuery, UrlString } from "@eatbetter/common-shared";
import { AppErrorBoundary } from "../components/ErrorBoundary";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  useAppFocused,
  useAuthedUserId,
  useAuthStatus,
  useHasFullWebAccess,
  useRequestedNavAction,
  useTappedNotification,
} from "../lib/system/SystemSelectors";
import { navActionHandled, tappedNotificationHandled } from "../lib/system/SystemSlice";
import { useDispatch } from "../lib/redux/Redux";
import { FlexedSpinner } from "../components/Spinner";
import { ScreenView } from "../components/ScreenView";
import { getLinkingConfig } from "./NavigationRoot";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { Platform } from "react-native";
import { navHome } from "./NavThunks";
import { handlePushNotificationTapped } from "../lib/NotificationHandlers";
import { areTabsMounted } from "../lib/system/SystemThunks";

let rootNavRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList> | undefined;

export function setRootNavRef(ref: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>) {
  rootNavRef = ref;
}

/**
 * ScreenUtil provide screen-specific functionality (nav, etc.)
 */
// a bit of type magic that makes the 2nd parameter (the props) optional only in the case that props are {}
// overloads don't work since the implementation function must allow for all the other signatures, meaning the second
// param would have to be optional.
type NavigateProps<T> = {} extends Required<T>
  ? [props?: undefined]
  : {} extends T
  ? [props?: T | undefined]
  : [props: T];

export interface NavApi {
  focused: boolean;
  getCurrentTab: () => TabScreenRouteName | undefined;
  goTo<TProps>(action: NavAction, screen: ScreenType<TProps>, ...props: NavigateProps<TProps>): void;
  switchTab<TProps>(tab: TabScreenRouteName, screen: ScreenType<TProps>, ...props: NavigateProps<TProps>): void;
  modal<TProps>(
    screen: ModalNavScreen<TProps> | ModalNonNavigableScreen<TProps>,
    ...props: NavigateProps<TProps>
  ): void;
  goBack(): void;
  pop(count?: number): void;
  canGoBack(): boolean;
  addListener(type: "beforeRemove", callback: () => void): void;
}

export interface ScreenUtil {
  nav: NavApi;
  navScreen: WithName<ScreenType<unknown>>;
}

/***
 * ScreenContainer component
 */
const ScreenContext = React.createContext<ScreenUtil>({} as ScreenUtil);

// I failed at typing this, and ScreenContainer is local to this file

interface Props {
  /**
   * If true, props are serialized/deserialized to and from strings. This makes screens work with linking, meaning that
   * if a screen uses this, it can be directly accessed with a URl on web, or via a deep link.
   * If a screen does not need to be directly accessed, this can be false and anything can be passed via props (functions, etc.)
   * since it is not serialized.
   */
  serializeProps: boolean;
  component: FunctionComponent<any>;
  reactNavigation: NativeStackScreenProps<ReactNavigationPropType>;
  screenName: NavigableScreenName | NonNavigableScreenName;
}

type ReactNavigationPropType = Record<string, object | undefined>;

function getNavScreenFromRouteName(routeName: string): WithName<ScreenType<unknown>> {
  const s = navTree.get.screens[routeName as ScreenTypeRouteName] as WithName<ScreenType<unknown>>;
  if (!s) {
    throw new Error(`No NavScreen found for route name ${routeName}`);
  }
  return s;
}

// it seems like there should be an easier way to do this, but I couldn't find the relevant key in the state returned from getParent().getState()
// so we store a ref to the navigation container and traverse the root state.
function getTabFromNavigation(key: string): TabScreenRouteName | undefined {
  if (!rootNavRef) {
    log.error("Expecting rootNavRef to be set");
    return undefined;
  }
  const root = rootNavRef?.current?.getRootState();

  if (!root) {
    log.error("Navigation getRootState returned undefined.");
    return undefined;
  }

  for (const route of root.routes) {
    const r = getTabFromNavigationRoute(undefined, key, route);
    if (r) {
      return r;
    }
  }

  return undefined;
}

function getTabFromNavigationRoute(
  currentTab: TabScreenRouteName | undefined,
  key: string,
  route: NavigationState["routes"][number]
): TabScreenRouteName | undefined {
  const ct =
    currentTab ?? tabNames.includes(route.name as TabScreenRouteName) ? (route.name as TabScreenRouteName) : undefined;
  const childRoutes = (route.state?.routes ?? []) as NavigationState["routes"];
  if (childRoutes.find(r => r.key === key)) {
    return ct;
  }

  for (const cr of childRoutes) {
    const t = getTabFromNavigationRoute(ct, key, cr);
    if (t) {
      return t;
    }
  }

  return undefined;
}

function ScreenContainer(props: Props) {
  const navScreen = useRef(getNavScreenFromRouteName(props.reactNavigation.route.name)).current;
  const focused = useIsFocused();

  const dispatch = useDispatch();

  const getParams = <TProps,>(s: ScreenType<TProps>, props: TProps): any => {
    if (!s.component.serializeProps) {
      return props;
    }

    const reactNavigationParams = screenPropsToReactNavigationParams(
      props,
      s.component.serializer,
      s.component.nameRemapping
    );

    return reactNavigationParams;
  };

  const getCurrentTab = useCallback(() => {
    return getTabFromNavigation(props.reactNavigation.route.key);
  }, [props.reactNavigation.route.key]);

  const goTo = useRef(
    <TProps,>(
      action: NavAction,
      screen: WithName<NavScreen<TProps>> | WithName<NonNavigableScreen<TProps>>,
      ...screenProps: NavigateProps<TProps>
    ): void => {
      log.info(`Navigating to ${screen.name}`);

      const reactNavigationScreenParams = screenProps[0] ? getParams(screen, screenProps[0]) : {};

      const navTypeKey: keyof RouteInfo = "navAction";

      // add the nav type. This can be consumed in screens.ts to change the animation or other screen config
      // based on which navigation method is used.
      const reactNavigationParams = {
        [navTypeKey]: action,
        ...reactNavigationScreenParams,
      };

      switch (action) {
        case "push":
          props.reactNavigation.navigation.push(screen.name, reactNavigationParams);
          break;
        case "replace":
          props.reactNavigation.navigation.replace(screen.name, reactNavigationParams);
          break;
        case "reset":
          props.reactNavigation.navigation.reset({
            index: 0,
            routes: [{ name: screen.name, params: reactNavigationParams }],
          });
          break;
        default:
          bottomThrow(action, log);
      }
    }
  ).current;

  const modal = useRef(
    <TProps,>(
      screen: WithName<ModalNavScreen<TProps>> | WithName<ModalNonNavigableScreen<TProps>>,
      ...screenProps: NavigateProps<TProps>
    ): void => {
      const reactNavigationParams = screenProps[0] ? getParams(screen, screenProps[0]) : {};
      const route = getHackModalScreenName(screen.name);
      props.reactNavigation.navigation.navigate(route, {
        screen: screen.name,
        params: reactNavigationParams,
      });
    }
  ).current;

  const switchTab = useRef(
    <TProps,>(
      tab: TabScreenRouteName,
      screen: WithName<NavScreen<TProps>>,
      ...screenProps: NavigateProps<TProps>
    ): void => {
      log.info(`Navigating to tab "${tab}" and screen "${screen.name}"`);
      if (!dispatch(areTabsMounted())) {
        log.error(
          `Attepting to navigate to tab ${tab} and screen ${screen.name} from ${navScreen.name} while tabs aren't yet mounted`
        );
      }

      const reactNavigationParams = screenProps[0] ? getParams(screen, screenProps[0]) : {};

      props.reactNavigation.navigation.navigate(tab, { screen: screen.name, params: reactNavigationParams });
    }
  ).current;

  const addListener = useRef((type: "beforeRemove", callback: () => void): void => {
    log.info(`Adding navigation listener of type ${type}`);
    props.reactNavigation.navigation.addListener(type, callback);
  }).current;

  const screenProps = useMemo(() => {
    return getScreenPropsFromReactNavigationParams(navScreen, props.reactNavigation.route.params);
  }, [props.serializeProps, props.reactNavigation.route.params, navScreen]);

  const nav: NavApi = {
    focused,
    getCurrentTab,
    goTo,
    goBack: props.reactNavigation.navigation.goBack,
    canGoBack: props.reactNavigation.navigation.canGoBack,
    pop: (count?: number) => props.reactNavigation.navigation.pop(count),
    switchTab,
    modal,
    addListener,
  };

  const tappedNotification = useTappedNotification();
  const requestedNavAction = useRequestedNavAction();
  const authStatus = useAuthStatus();
  const appFocused = useAppFocused();
  const hasFullWebAccess = useHasFullWebAccess();
  const userId = useAuthedUserId();

  // HANDLE Redirects and REQUESTED NAV ACTION
  useEffect(() => {
    if (!appFocused) {
      // we were seeing strange behavior when the modal was opened while the app is in the background. Namely,
      // when a notification was tapped, the modal useEffect cleanup function was being called, which set timerScreenFocused=false
      // even though the timer screen was visible when the app focused. Another modal would then open on top. This avoids that problem.
      return;
    }

    if (!focused) {
      return;
    }

    // HANDLE NO WEB ACCESS
    if (Platform.OS === "web" && isNavigableScreen(navScreen) && !navScreen.web && !hasFullWebAccess) {
      dispatch(navHome(nav, "ScreenContainer web access check"));
      return;
    }

    // HANDLE AUTH REQUIRED
    if (isNavigableScreen(navScreen) && navScreen.authRequired && authStatus !== "signedIn") {
      const url = getLinkForNavigableScreen<any>(navScreen, screenProps as any);
      const path = getPathAndQuery(url);
      nav.goTo("reset", navTree.get.screens.signIn, { redirectPath: path, showBackButton: false });
      return;
    }

    // HANDLE TAPPED NOTIFICATION
    if (tappedNotification) {
      if (!userId || tappedNotification.notificationTargetUserId !== userId) {
        log.warn("Got notification for a user other than the target user ID. Clearing notification", {
          userId,
          targetUserId: tappedNotification.notificationTargetUserId,
        });
        dispatch(tappedNotificationHandled());
        return;
      }

      // close the modal, unless the notification is for timers and we're already on the timers screen.
      // This is a hack - we need to refactor the way this works to more closely align with requestedNavAction,
      // but our current logic only supports root screens and it's not worth changing this at the moment.
      if (navScreen.isModal && !(navScreen.name === "timers" && tappedNotification.type === "timers/timerComplete")) {
        // close the current modal and let the next screen handle it. This is here because on ios, when you nav
        // while a modal is up, the nav happens "underneath", and it messes with the current nav state, so things like back
        // don't work as expected from the modal. In the event that a user taps a notification and a modal is up, and the modal
        // has state (like an in-progress text post, etc.), then that state might be lost. I think this is okay for now.
        nav.goBack();
        return;
      }

      // clear it immediately to make sure that gets done and we don't handle it twice.
      dispatch(tappedNotificationHandled());
      handlePushNotificationTapped(tappedNotification, dispatch, switchTab, modal);
      return;
    }

    // HANDLE REQUESTED NAV ACTION
    if (!requestedNavAction) {
      return;
    }

    const nameMatch = navScreen.name === requestedNavAction.screenName;
    const isCurrentScreen =
      nameMatch &&
      (!navScreen.getScreenId ||
        navScreen.getScreenId(screenProps) === navScreen.getScreenId(requestedNavAction.props));

    // check isCurrentScreen here because we don't want to close the modal only to reopen the same one.
    if (navScreen.isModal && !isCurrentScreen) {
      // close the current modal and let the next screen handle it. This is here because on ios, when you nav
      // while a modal is up, the nav happens "underneath", and it messes with the current nav state, so things like back
      // don't work as expected from the modal. In the event that a user taps a notification and a modal is up, and the modal
      // has state (like an in-progress text post, etc.), then that state might be lost. I think this is okay for now.
      nav.goBack();
      return;
    }

    dispatch(navActionHandled());

    // if we're already on the screen, replace the props with the incoming props.
    // This was added to handle the case of the CheckYourEmailScreen to set the
    // redirectPath that is part of the incoming deeplink that results in the requested
    // screen. I *think* this should be fine as the default case, but we can always move it
    // to an option on ScreenType.
    if (isCurrentScreen) {
      let propsReplaced = false;
      const propsMatch = deepEquals(screenProps, requestedNavAction.props);
      if (!propsMatch) {
        const updatedReactNavigationParams = screenPropsToReactNavigationParams(
          requestedNavAction.props,
          navScreen.component.serializer,
          navScreen.component.nameRemapping
        );
        props.reactNavigation.navigation.setParams(updatedReactNavigationParams);
        propsReplaced = true;
      }
      log.info(
        `Requested nav action is same as current screen. Clearing requested action. Props replaced: ${propsReplaced}`
      );
      return;
    }

    const requestedScreen = navTree.get.screens[requestedNavAction.screenName];
    if (!requestedScreen) {
      log.warn(`Requested screen ${requestedNavAction.screenName} not found. Returning.`);
      return;
    }

    // if the requested screen needs auth, we ideally don't want to load it at all until the user is authed
    // becasue the screen might have useEffects, etc. that assume auth.
    if (isNavigableScreen(requestedScreen) && requestedScreen.authRequired && authStatus === "signedOut") {
      log.info("Requested screen is auth required. Nav'ing to sign-in");
      // the sign-in screen works with a redirect path to make it easy for web. Convert our eventual destination to a path
      const signInRedirectLink = getLinkForNavigableScreen(requestedScreen as any, requestedNavAction.props as any);
      const signInRedirectPath = getPathAndQuery(signInRedirectLink);
      goTo("reset", navTree.get.screens.signIn, { redirectPath: signInRedirectPath });
      return;
    }

    if (requestedScreen.isModal) {
      // no idea how to type this well, so taking the easy way out.
      log.info(`Requested Nav Action. Opening modal ${requestedScreen.name}`);
      modal(requestedScreen as any, requestedNavAction.props as any);
    } else {
      // default to push here
      log.info(`Requested Nav Action. Pushing ${requestedScreen.name}`);
      goTo("push", requestedScreen as any, requestedNavAction.props as any);
    }
  }, [
    appFocused,
    focused,
    requestedNavAction,
    modal,
    goTo,
    switchTab,
    tappedNotification,
    navScreen,
    nav,
    screenProps,
    authStatus,
    userId,
    hasFullWebAccess,
  ]);

  const util: ScreenUtil = useMemo(() => {
    return {
      nav,
      navScreen: navScreen,
    };
  }, [focused]);

  // Return a spinner if the auth status is pending
  // This should only be the case on the launch screen in the app (and only on a fresh start, I think,
  // since the status will be persisted otherwise.
  // We use a ScreenView to render so that the default react-navigation header doesn't get displayed.
  // We also use a FlexedSpinner instead of loading=true on the ScreenView so we get a better
  // debug message on the spinner.
  return (
    <AppErrorBoundary componentName={`${navScreen.name}Screen`}>
      <ScreenContext.Provider value={util}>
        {authStatus === "pending" && (
          <ScreenView>
            <FlexedSpinner debugText="ScreenContainer (authStatus=pending)" />
          </ScreenView>
        )}
        {authStatus !== "pending" && <props.component {...screenProps} />}
      </ScreenContext.Provider>
    </AppErrorBoundary>
  );
}

// Note, if we want to add options, just add another named value to these tuples
// [converter:?: undefined, options?: ScreenContainerOptions
type WithScreenContainerParams<TProps> = {} extends Required<TProps>
  ? [converter?: undefined]
  : [converter: PropsUrlConverter<TProps>];

type NavigableScreenName = `${Capitalize<
  FilterKeys<NavTree["screens"], Omit<NavigableScreenType<unknown>, "getScreenId">>
>}Screen`;
type NonNavigableScreenName = `${Capitalize<
  FilterKeys<NavTree["screens"], Omit<NonNavigableScreenType<unknown>, "getScreenId">>
>}Screen`;

/**
 *
 * @param screenName Purely for debugging purposes. This sets the function name so it makes sense in stack traces
 * @param component
 * @param converter
 */
export function withScreenContainer<TProps>(
  screenName: NavigableScreenName,
  component: FunctionComponent<TProps>,
  ...otherParams: WithScreenContainerParams<TProps>
): ScreenWithUrlPropsConverter<TProps> {
  const [converter] = otherParams;
  return withScreenContainerInternal(screenName, component, true, converter);
}

export function withNonNavigableScreenContainer<TProps>(
  screenName: NonNavigableScreenName,
  component: FunctionComponent<TProps>
): ScreenWithUrlPropsConverter<TProps> {
  return withScreenContainerInternal(screenName, component, false, undefined);
}

function withScreenContainerInternal<TProps>(
  screenName: NavigableScreenName | NonNavigableScreenName,
  component: FunctionComponent<TProps>,
  serializeProps: boolean,
  converter?: PropsUrlConverter<TProps> | undefined
): ScreenWithUrlPropsConverter<TProps> {
  const WithScreenContainer: ScreenWithUrlPropsConverter<TProps> = (
    props: PropsWithChildren<NativeStackScreenProps<ReactNavigationPropType>>
  ) => {
    return (
      <ScreenContainer
        serializeProps={serializeProps}
        component={component}
        reactNavigation={props}
        screenName={screenName}
      />
    );
  };

  WithScreenContainer.serializer = converter?.serializer;
  WithScreenContainer.parser = converter?.parser;
  WithScreenContainer.nameRemapping = converter?.nameRemapping;
  WithScreenContainer.serializeProps = serializeProps;
  Object.defineProperty(WithScreenContainer, "name", { value: screenName });
  return WithScreenContainer;
}

/**
 * Hook that exposes the ScreenUtil
 */
export const useScreen = () => {
  return useContext(ScreenContext);
};

export function getLinkForNavigableScreen<TProps>(
  screen: WithName<NavScreen<TProps>> | WithName<ModalNavScreen<TProps>>,
  ...screenProps: NavigateProps<TProps>
): UrlString {
  if (isNonNavigableScreen(screen)) {
    throw new Error("Can't build link for non-navigable screen");
  }

  const reactNavigationParams = screenProps[0]
    ? screenPropsToReactNavigationParams(screenProps[0], screen.component.serializer, screen.component.nameRemapping)
    : {};

  const path = getPathFromState(
    {
      routes: [
        {
          name: screen.name,
          params: reactNavigationParams,
        },
      ],
    },
    {
      screens: {
        [screen.name]: {
          path: screen.path,
        },
      },
    }
  );

  const getLink = (pathAndQuery: string) => {
    return `${CurrentEnvironment.linkBaseUrl()}${pathAndQuery}` as UrlString;
  };

  if (screen.pathType === "absolute") {
    return getLink(path);
  }

  const stack = screen.isModal ? "root" : screen.stacks[0];

  if (!stack) {
    throw new Error(`Screen ${screen.name} doesn't have a stack specified`);
  }

  switch (stack) {
    case "root": {
      return getLink(path);
    }
    case "allTabs":
    case "home": {
      return getLink(navTree.get.tabs.homeTab.path + path);
    }
    case "lists": {
      return getLink(navTree.get.tabs.groceriesTab.path + path);
    }
    case "recipes": {
      return getLink(navTree.get.tabs.recipesTab.path + path);
    }
    case "profile": {
      return getLink(navTree.get.tabs.profileTab.path + path);
    }
    default: {
      bottomThrow(stack);
    }
  }
}

export function getScreenAndPropsFromPath(
  pathAndQs: string
): { screen: WithName<NavigableScreenType<any>>; props: any } | undefined {
  const state = getStateFromPath(pathAndQs, { screens: getLinkingConfig().config!.screens });
  if (!state) {
    return undefined;
  }

  const activeRoute = state.index ? state.routes[state.index] : state.routes[state.routes.length - 1];
  // We currently only support non-nested routes here (see example states below).
  // So return if we don't have an active route or if the active route is a navigator and not a screen
  // *unless* the stack is a modal hack stack (see NavigationRoot for details)
  if (!activeRoute || (activeRoute.state && !isHackModalStack(activeRoute.name))) {
    return undefined;
  }

  const name = getScreenNameFromReactNavigationRouteName(activeRoute.name) as ScreenTypeRouteName;
  const screen = navTree.get.screens[name] as WithName<NavigableScreenType<any>>;

  if (!screen) {
    return undefined;
  }

  // in the event of the active screen being a modal, we need to pull params from deeper because of the hack (see NavigatinRoot)
  // this logic is a bit messy - the getScreenNameFromREactNaviationRouteName already does this check, but
  // doing it explicitly here to grab the correct route. Should probably refactor to add a function to get the name and parms from the
  // state.
  let params;
  if (isHackModalStack(activeRoute.name) && activeRoute.state?.routes.length === 1) {
    //  {"name":"timers-ModalStack","state":{"routes":[{"name":"timers","path":"/timers?ack=1","params":{"ack":"1"}}]}}
    params = activeRoute.state.routes[0]?.params;
  } else {
    params = activeRoute.params;
  }

  const props = getScreenPropsFromReactNavigationParams(screen, params);

  return { screen, props };

  // example simple state:
  /*
  "routes": [
    {
      "name": "externalSharedRecipe",
      "params": {
        "userId": "k98Sb0GDL2QCXHIwKsR327Nv0Zi1",
        "recipeId": "k98Sb0GDL2QCXHIwKsR327Nv0Zi1:SRZZ06vD0ncpXIJu"
      },
      "path": "/share/k98Sb0GDL2QCXHIwKsR327Nv0Zi1/k98Sb0GDL2QCXHIwKsR327Nv0Zi1:SRZZ06vD0ncpXIJu"
    }
  ]
  */

  // example nested state
  /*
        "routes": [
        {
          "name": "home",
          "state": {
            "routes": [
              {
                "name": "recipesTab",
                "state": {
                  "index": 1,
                  "routes": [
                    {
                      "name": "recipesHome"
                    },
                    {
                      "name": "recipeDetail",
                      "params": {
                        "recipeId": "foobar"
                      },
                      "path": "/recipes/foobar"
                    }
      <closing brackets omitted>
   */
}

export function getReactNavigationGetIdFunction<TProps extends object>(
  s: ScreenType<TProps>
): ((routeProps: { params: object | undefined }) => string) | undefined {
  if (!s.getScreenId) {
    return undefined;
  }

  return rp => {
    if (!rp.params) {
      return "";
    }

    const props = s.component.serializeProps
      ? reactNavigationParamsToScreenProps<TProps>(rp.params, s.component.parser, s.component.nameRemapping)
      : (rp.params as TProps);
    const deps = s.getScreenId!(props);
    return deps.map(d => `${d}`).join("_");
  };
}

function getScreenPropsFromReactNavigationParams<TProps extends object>(
  screen: ScreenType<TProps>,
  params: object | undefined
): TProps {
  return screen.component.serializeProps
    ? reactNavigationParamsToScreenProps<TProps>(params, screen.component.parser, screen.component.nameRemapping)
    : (params as TProps);
}

function reactNavigationParamsToScreenProps<TProps extends object>(
  params: object | undefined,
  parser?: Parser<TProps>,
  nameMap?: Partial<Record<keyof TProps, string>>
): TProps {
  const propsEntries = !parser
    ? []
    : Object.entries(parser).map(e => {
        const [paramName, parseFnOrObject] = e as [keyof TProps, ParamParser<unknown> | OptionalParamParser<unknown>];
        const nameToLookFor = nameMap?.[paramName] ?? paramName;
        const value = (params as Record<string, string>)?.[nameToLookFor as string];
        const isOptional = typeof parseFnOrObject === "object";
        if (value === undefined && !isOptional) {
          throw new Error(`Could not parse screen props. Param '${String(nameToLookFor)}' not found.`);
        }

        const parseFn = typeof parseFnOrObject === "object" ? parseFnOrObject.fn : parseFnOrObject;

        return [paramName, value ? parseFn(value) : undefined];
      });

  const props = Object.fromEntries(propsEntries) as TProps;
  if (Object.keys(props).length > 0) {
    log.info("Parsed props", { props });
  }

  return props;
}

function screenPropsToReactNavigationParams<TProps>(
  props: TProps | undefined,
  serializer?: Serializer<TProps>,
  nameMap?: Partial<Record<keyof TProps, string>>
) {
  if (props === undefined) {
    if (Object.keys(serializer ?? {}).length > 0) {
      throw new Error("Got empty props but expected value");
    }

    return {};
  }

  const params = Object.entries(serializer ?? {}).flatMap(e => {
    const [paramName, serializeFnOrObject] = e as [
      keyof TProps,
      ParamSerializer<unknown> | OptionalParamSerializer<unknown>
    ];
    const nameToUse = nameMap?.[paramName] ?? paramName;
    const fn = typeof serializeFnOrObject === "object" ? serializeFnOrObject.fn : serializeFnOrObject;
    const value = props?.[paramName];

    // We check undefined and null explicitly here to allow for valid falsy values such as 0 to be serialized
    if (value === undefined || value === null) {
      return [];
    }

    return [[nameToUse, fn(value)]];
  });

  const serialized = Object.fromEntries(params);
  log.info("Serialized props", { serialized });
  return serialized;
}
