import { useCallback, useEffect, useMemo, useRef } from "react";
import { AppRecipe, haveRecipeIngredients } from "@eatbetter/recipes-shared";
import { useDispatch } from "../redux/Redux";
import { ReaderModeEnabledInstanceId, setReaderMode } from "./RecipesSlice";
import {
  useIsReaderModeEnabled,
  useGetSocialMediaDomainType,
  useRecipeSourceType,
  useRecipeSourceUrl,
} from "./RecipesSelectors";
import { useCheckpointCompleted } from "../system/SystemSelectors";
import { ReaderModeSpotlightContext, useReaderModeToggle } from "../../components/ReaderModeToggle";
import { reportOpenInSocialAppButtonPressed, reportRecipeTextViewToggled } from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { log } from "../../Log";
import { usePaywallStatus } from "../../components/PaywallDetector";
import { newId, SocialMediaDomainKey, switchReturn } from "@eatbetter/common-shared";
import { ButtonRectangle } from "../../components/Buttons";
import { View } from "react-native";
import { TTertiary } from "../../components/Typography";
import { Spacer } from "../../components/Spacer";
import { SocialMediaLogo } from "../../components/Logos";
import { openWebpage } from "../util/WebUtil";

export type ReaderModeContext = "library" | "search" | "post" | "share";

export function useReaderMode(
  recipe: AppRecipe | undefined,
  context: ReaderModeContext
): {
  readerModeEnabled: boolean;
  setReaderModeEnabled: (value: boolean) => void;
  toggleReaderModeEnabled: () => void;
  renderReaderModeToggle: () => React.ReactElement;
} {
  const dispatch = useDispatch();
  const readerModeToggledCheckpoint = useCheckpointCompleted("recipeReaderModeToggled");

  const instanceId = useRef<ReaderModeEnabledInstanceId>(newId()).current;
  const readerModeEnabled = useIsReaderModeEnabled(instanceId);
  const lastReaderModeEnabled = useRef(readerModeEnabled);

  const recipeId = recipe?.id;
  const haveIngredients = !!recipe && haveRecipeIngredients(recipe.ingredients);
  const isWebRecipe = useRecipeSourceType(recipe) === "url";
  const socialMediaRecipeType = useGetSocialMediaDomainType(recipe);
  const recipeUrl = useRecipeSourceUrl(recipe);
  const { paywallStatus, paywallIsUp } = usePaywallStatus(recipe);

  const setReaderModeEnabled = useCallback(
    (value: boolean) => {
      if (!recipeId) {
        log.error("useReaderMode.setReaderModeEnabled called but recipeId is falsy", {
          recipeId: recipe?.id,
          setReaderModeToValue: value,
          instanceId,
        });
        return;
      }
      dispatch(setReaderMode({ instanceId, recipeId, isEnabled: value }));
    },
    [dispatch, instanceId, recipeId]
  );

  const toggleReaderModeEnabled = useCallback(() => {
    setReaderModeEnabled(!readerModeEnabled);
  }, [setReaderModeEnabled, readerModeEnabled]);

  const onPressOpenInSocialApp = useCallback(() => {
    if (!recipeUrl) {
      log.error("onPressOpenInSocialApp called but recipeUrl is falsy");
      return;
    }
    dispatch(analyticsEvent(reportOpenInSocialAppButtonPressed({ recipe, socialApp: socialMediaRecipeType })));
    openWebpage(recipeUrl);
  }, [recipeUrl, recipe, socialMediaRecipeType]);

  useEffect(() => {
    if (!recipeId) {
      return;
    }

    // Default to reader mode enabled for social media recipes. If recipe type changes to a non social media recipe,
    // (e.g. when a recipe is orphaned), disable reader mode.
    setReaderModeEnabled(!!socialMediaRecipeType);
  }, [socialMediaRecipeType, recipeId]);

  // Disable reader mode on unmount
  useEffect(() => {
    if (!recipeId) {
      return;
    }
    return () => setReaderModeEnabled(false);
  }, [recipeId]);

  // Analytics: report reader mode toggle
  useEffect(() => {
    if (!recipe) {
      return;
    }

    if (readerModeEnabled !== lastReaderModeEnabled.current) {
      const event = reportRecipeTextViewToggled({
        recipe,
        textView: readerModeEnabled,
        paywallStatus,
      });
      dispatch(analyticsEvent(event));
      lastReaderModeEnabled.current = readerModeEnabled;
    }
  }, [readerModeEnabled, recipe]);

  const disableReaderModeToggle = !haveIngredients;
  const showReaderModeToggleSpotlight =
    !readerModeToggledCheckpoint && isWebRecipe && !paywallIsUp && haveIngredients && !socialMediaRecipeType;

  const spotlightContext = switchReturn<ReaderModeContext, ReaderModeSpotlightContext>(context, {
    library: "spotlightLibraryReaderMode",
    post: "spotlightPostViewRecipeReaderMode",
    share: "spotlightShareViewRecipeReaderMode",
    search: "spotlightSearchViewRecipeReaderMode",
  });

  const readerModeToggleElement = useReaderModeToggle(
    spotlightContext,
    readerModeEnabled,
    toggleReaderModeEnabled,
    disableReaderModeToggle,
    showReaderModeToggleSpotlight
  );

  const renderReaderModeToggle = useCallback(() => {
    if (!isWebRecipe) {
      return <></>;
    }

    return (
      <>
        {!socialMediaRecipeType && readerModeToggleElement}
        {!!socialMediaRecipeType && <OpenInAppButton type={socialMediaRecipeType} onPress={onPressOpenInSocialApp} />}
      </>
    );
  }, [isWebRecipe, socialMediaRecipeType, readerModeToggleElement, onPressOpenInSocialApp]);

  return useMemo(
    () => ({
      readerModeEnabled,
      setReaderModeEnabled,
      toggleReaderModeEnabled,
      readerModeToggledCheckpoint,
      renderReaderModeToggle,
    }),
    [
      readerModeEnabled,
      setReaderModeEnabled,
      toggleReaderModeEnabled,
      readerModeToggledCheckpoint,
      renderReaderModeToggle,
      socialMediaRecipeType,
    ]
  );
}

const OpenInAppButton = (props: { type: SocialMediaDomainKey; onPress: () => void }) => {
  const title = (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <TTertiary>{"Open in"}</TTertiary>
      <Spacer horizontal={0.5} />
      <SocialMediaLogo type={props.type} size="small" />
    </View>
  );

  return (
    <View>
      <ButtonRectangle type="secondary" title={title} onPress={props.onPress} size="small" />
    </View>
  );
};
