import { EpochMs } from "@eatbetter/common-shared";
import { AdminBook, BookId, CreateEditBookArgs } from "@eatbetter/recipes-shared";
import { useCallback } from "react";
import { View } from "react-native";
import { useDispatch } from "../lib/AdminRedux";
import { createBookOrThrow } from "../lib/books/AdminBooksThunks";
import { addPhoto } from "../lib/photos/AdminPhotoThunks";
import { BookEditControl, BookEditOnSaveArgs } from "./BookEditControl";

interface Props {
  args: CreateEditBookArgs;
  onDone: (id: BookId) => void;
}

export const BookAdd = (props: Props) => {
  const dispatch = useDispatch();

  const onSave = useCallback(
    async (args: BookEditOnSaveArgs) => {
      const { newCoverPhoto, book } = args;

      if (!newCoverPhoto) {
        throw new Error("Add Book: Cover photo is required.");
      }

      const newPhoto = await dispatch(addPhoto(newCoverPhoto));
      const addArgs: CreateEditBookArgs = { ...book, coverPhoto: newPhoto };

      const adminBook = await dispatch(createBookOrThrow(addArgs));
      props.onDone(adminBook.id);
    },
    [dispatch]
  );

  return (
    <View>
      <BookEditControl bookToEdit={newBook(props.args)} onSave={onSave} />
    </View>
  );
};

function newBook(args: CreateEditBookArgs): AdminBook {
  return {
    ...args,
    version: 0 as EpochMs,
  };
}
