import { UrlString } from "@eatbetter/common-shared";
import { BookId, CreateEditBookArgs } from "@eatbetter/recipes-shared";
import { Spacer, THeading1, useIdempotentId } from "@eatbetter/ui-shared";
import { useNavigate } from "react-router-dom";
import { AdminScreenView } from "../components/AdminScreenView";
import { BookAdd } from "../components/BookAdd";
import { AdminBooksScreenEmptyNav, AdminBooksScreenIdNav } from "./AdminBooksScreen";

export const AdminBookCreateScreenNav = {
  getPath: () => {
    return `${AdminBooksScreenEmptyNav.getPath()}/create`;
  },
};

export const AdminBookCreateScreen = () => {
  const [token, refreshToken] = useIdempotentId("b");
  const nav = useNavigate();

  const onDone = (bookId: BookId) => {
    refreshToken();
    nav(AdminBooksScreenIdNav.getPath(bookId));
  };

  return (
    <AdminScreenView>
      <THeading1 fontWeight="medium">Create New Book</THeading1>
      <Spacer vertical={2} />
      <BookAdd args={newBookArgs(token as BookId)} onDone={onDone} key={token} />
    </AdminScreenView>
  );
};

function newBookArgs(id: BookId): CreateEditBookArgs {
  return {
    id,
    title: "",
    coverPhoto: undefined as any,
    primaryAuthor: {
      name: "",
      type: "unknownAuthor",
      url: "" as UrlString,
    },
    otherAuthors: [],
    isbn: "",
    purchaseLink: "" as UrlString,
  };
}
