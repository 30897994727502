import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { View } from "react-native";
import { TextInput } from "../TextInput";
import {
  AddRecipeArgs,
  RecipeIngredientId,
  RecipeIngredients,
  RecipeIngredientSection,
  RecipeInstructionId,
  RecipeSectionId,
  ShoppableRecipeIngredient,
  RecipeInstructions,
  Publisher,
  Author,
  UserAuthor,
  RecipeInstructionSection,
  Book,
  RecipeInstruction,
  RecipeIngredient,
  // This file is the only place that we should deal with Recipe/Externaul*Recipe in ui/shared, and only because
  // we share the edit control with admin. Otherwise, it should only be UserRecipe and RecipeBase so we don't leak
  // any data not intended for user clients.
  // eslint-disable-next-line no-restricted-imports
  ExternalBookRecipe,
  // eslint-disable-next-line no-restricted-imports
  ExternalUrlRecipe,
  // This component is used by admin for editing user recipes on behalf of a user
  // eslint-disable-next-line no-restricted-imports
  UserRecipe,
  RecipeTime,
  SystemRecipeTag,
  RecipeTagManifest,
  AppUserRecipe,
  KnownAuthor,
  KnownPublisher,
} from "@eatbetter/recipes-shared";
import { TBody, TSecondary } from "../Typography";
import { Spacer } from "../Spacer";
import { displayExpectedError, displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { Haptics } from "../Haptics";
import { pickedPhotoToRef } from "../PhotoPicker";
import { AppAddPhotoArgs } from "../../lib/Types";
import { produce } from "immer";
import { bottomThrow, DurationMs, emptyToUndefined, isUrl, newId, UrlString } from "@eatbetter/common-shared";
import { log } from "../../Log";
import { AuthorEditControl, getAuthor, PartialUnknownAuthor } from "./AuthorEditControl";
import { globalStyleConstants, Opacity } from "../GlobalStyles";
import { getPublisher, PartialUnknownPublisher, PublisherEditControl } from "./PublisherEditControl";
import { RecipeSectionEdit } from "./RecipeSectionEdit";
import { Photo } from "../Photo";
import { ParsedIngredientSummary } from "@eatbetter/items-shared/src/ItemsTypes";
import { PhotoEditControl } from "./PhotoEditControl";
import { PhotoRef } from "@eatbetter/photos-shared";
import { SectionHeading } from "../SectionHeading";
import { ButtonRectangle } from "../Buttons";
import { RecipeTimePicker } from "./RecipeTimePicker/RecipeTimePicker";
import { AdminRecipeTagsEdit } from "./RecipeTagsEdit";

const strings = {
  title: "Title",
  titlePlaceholder: "Recipe title",
  photo: "Photo",
  photoAdd: "Add",
  photoEdit: "Edit",
  description: "Description",
  descriptionPlaceholder: "Recipe description",
  ingredients: "Ingredients",
  instructions: "Instructions",
  time: "Time",
  recipeYield: "Yield",
  recipeYieldPlaceholder: "Recipe yield",
  totalTime: "Total",
  onePerLine: "1 per line",
  save: "Save",
  required: "We're minimalists too, but you've got to have at least a name and some ingredients.",
  requiredAdmin: "Title is required",
  invalidValue: (fieldName: string) => `Invalid value for "${fieldName}"`,
};

type RecipeSectionEditActions =
  | { type: "addSection" }
  | { type: "removeSection"; index: number }
  | { type: "updateSectionTitle"; index: number; title: string }
  | { type: "addItem"; sectionIndex: number; atItemIndex?: number }
  | { type: "removeItem"; sectionIndex: number; itemIndex: number }
  | { type: "convertToSection"; sectionIndex: number; itemIndex: number };

export type RecipeIngredientEditActions =
  | RecipeSectionEditActions
  | { type: "updateIngredientText"; sectionIndex: number; index: number; text: string }
  | { type: "addShoppable"; sectionIndex: number; ingredientIndex: number; text?: string }
  | { type: "removeShoppable"; sectionIndex: number; ingredientIndex: number; shoppableIndex: number }
  | {
      type: "updateShoppableText";
      sectionIndex: number;
      ingredientIndex: number;
      shoppableIndex: number;
      text: string;
    };

export type RecipeInstructionEditActions =
  | RecipeSectionEditActions
  | { type: "updateInstructionText"; sectionIndex: number; index: number; text: string };

type RecipePhotoEditActions =
  | { type: "updatePhotoExternalUrl"; url: string }
  | { type: "updatePickedPhoto"; pickedPhoto: AppAddPhotoArgs };

type RecipeTagsEditActions = { type: "toggleTag"; tag: SystemRecipeTag };

type RecipeIngredientsReducer = Reducer<RecipeIngredients, RecipeIngredientEditActions>;
type RecipeInstructionsReducer = Reducer<RecipeInstructions, RecipeInstructionEditActions>;
type RecipePhotoReducer = Reducer<{ photo?: PhotoRef }, RecipePhotoEditActions>;
type RecipeTagsReducer = Reducer<SystemRecipeTag[], RecipeTagsEditActions>;

export interface EditRecipeOnSaveArgsBase extends Omit<AddRecipeArgs, "id"> {
  newRecipePhoto?: AppAddPhotoArgs;
}

export type EditUserRecipeOnSaveArgs = EditRecipeOnSaveArgsBase;

export interface EditExternalUrlRecipeOnSaveArgs extends EditRecipeOnSaveArgsBase {
  canonicalUrl: UrlString;
  author?: Author;
  newAuthorPhoto?: AppAddPhotoArgs;
  publisher?: Publisher;
  newPublisherPhoto?: AppAddPhotoArgs;
  tags?: SystemRecipeTag[];
}

export interface EditExternalBookRecipeOnSaveArgs extends EditRecipeOnSaveArgsBase {
  author?: Author;
  newAuthorPhoto?: AppAddPhotoArgs;
  book: Book;
  tags?: SystemRecipeTag[];
}

interface EditRecipeTypeMap {
  user: [AppUserRecipe, EditUserRecipeOnSaveArgs];
  adminUser: [UserRecipe, EditUserRecipeOnSaveArgs];
  adminExternalUrl: [ExternalUrlRecipe, EditExternalUrlRecipeOnSaveArgs];
  adminExternalBook: [ExternalBookRecipe, EditExternalBookRecipeOnSaveArgs];
}

interface Props<T extends keyof EditRecipeTypeMap> {
  /**
   * Will be called when the user taps save. Wrapped in a try/catch and an error will be displayed
   * if it throws.
   */
  onSave: (args: EditRecipeTypeMap[T][1]) => Promise<void>;
  // allows a consumer to save a draft of the recipe as it is edited
  onChange?: (args: EditRecipeTypeMap[T][1]) => void;
  initialRecipe?: EditRecipeTypeMap[T][0];
  // we save draft recipes periodically
  initialDraftArgs?: EditRecipeTypeMap[T][1];
  type: T;
  parsedIngredients?: ParsedIngredientSummary[];
  tagManifest?: RecipeTagManifest;
}

export const RecipeEditControl = <T extends keyof EditRecipeTypeMap>(props: Props<T>) => {
  if (props.initialRecipe && props.initialDraftArgs) {
    throw new Error("Not expecting both initialRecipe and initialDraftArgs");
  }

  const [waiting, setWaiting] = useState(false);
  const [title, setTitle] = useState(props.initialRecipe?.title ?? props.initialDraftArgs?.title ?? "");
  const [description, setDescription] = useState(
    props.initialRecipe?.description ?? props.initialDraftArgs?.description ?? ""
  );
  const [recipeYield, setRecipeYield] = useState(
    props.initialRecipe?.recipeYield?.text ?? props.initialDraftArgs?.recipeYield?.text
  );
  const [totalTime, setTotalTime] = useState<DurationMs | undefined>(
    props.initialRecipe?.time?.total[0] ?? props.initialDraftArgs?.time?.total[0]
  );

  const [pickedRecipePhoto, setPickedRecipePhoto] = useState<AppAddPhotoArgs>();
  const [pickedAuthorPhoto, setPickedAuthorPhoto] = useState<AppAddPhotoArgs | undefined>();
  const [pickedPublisherPhoto, setPickedPublisherPhoto] = useState<AppAddPhotoArgs | undefined>();

  // admin fields
  const [canonicalUrl, setCanonicalUrl] = useState<string | undefined>(
    props.initialRecipe?.type === "externalUrlRecipe" ? props.initialRecipe.canonicalUrl : undefined
  );
  const [author, setAuthor] = useState<PartialUnknownAuthor | UserAuthor | KnownAuthor | undefined>(
    props.initialRecipe?.author
  );
  const [publisher, setPublisher] = useState<PartialUnknownPublisher | KnownPublisher | undefined>(
    props.initialRecipe?.publisher
  );

  const [ingredients, ingredientsDispatch] = useReducer<RecipeIngredientsReducer>((current, action) => {
    return produce(current, draft => {
      switch (action.type) {
        case "addItem": {
          const section = verifyDefined(draft.sections[action.sectionIndex], action);
          const newItem: RecipeIngredient = {
            text: "",
            id: newId() as RecipeIngredientId,
          };

          if (action.atItemIndex !== undefined) {
            section.items.splice(action.atItemIndex, 0, newItem);
          } else {
            section.items.push(newItem);
          }

          break;
        }
        case "removeItem": {
          const section = verifyDefined(draft.sections[action.sectionIndex], action);
          section.items.splice(action.itemIndex, 1);
          break;
        }
        case "updateIngredientText": {
          const input = verifyDefined(draft.sections[action.sectionIndex]?.items[action.index], action);

          // handle pasting multiple ingredients into an input
          const lines = action.text.split("\n").filter(s => s !== "");

          // if there are no lines left after the filter, the user cleared an input, so default to ""
          if (lines.length <= 1) {
            input.text = lines[0] ?? "";
          }

          if (lines.length > 1) {
            const section = verifyDefined(draft.sections[action.sectionIndex], action);
            const itemsToAdd = lines.map(line => {
              return { text: line, id: newId() as RecipeIngredientId };
            });
            // splice here to remove the input that the user pasted into. The resizing logic for multiline inputs
            // on web doesn't seem to work to *shrink* the input, only grow it. If we reuse the input, we end up with
            // empty lines in the size of the pasted input.
            section.items.splice(action.index, 1, ...itemsToAdd);
          }

          break;
        }
        case "convertToSection": {
          const section = verifyDefined(draft.sections[action.sectionIndex], action);
          if (section.items.length < action.itemIndex) {
            throw new Error(`Not enough items: ${JSON.stringify(action)}`);
          }

          const allItems = section.items;
          section.items = allItems.slice(0, action.itemIndex);
          const newSectionItems = allItems.slice(action.itemIndex + 1);
          const newSection: RecipeIngredientSection = {
            id: newId<RecipeSectionId>(),
            title: allItems[action.itemIndex]!.text,
            items: newSectionItems,
          };

          // insert the new section after the modified section
          draft.sections.splice(action.sectionIndex + 1, 0, newSection);
          break;
        }
        case "addShoppable": {
          const ingr = verifyDefined(draft.sections[action.sectionIndex]?.items[action.ingredientIndex], action);
          if (!ingr.shoppable) {
            ingr.shoppable = [];
          }
          ingr.shoppable.push(newShoppable(action.text));
          break;
        }
        case "removeShoppable": {
          const shoppableList = verifyDefined(
            draft.sections[action.sectionIndex]?.items[action.ingredientIndex]?.shoppable,
            action
          );
          verifyDefined(shoppableList[action.shoppableIndex], action);
          shoppableList.splice(action.shoppableIndex, 1);
          break;
        }
        case "updateShoppableText": {
          verifyDefined(
            draft.sections[action.sectionIndex]?.items[action.ingredientIndex]?.shoppable?.[action.shoppableIndex],
            action
          ).text = action.text;
          break;
        }
        case "addSection": {
          verifyDefined(draft.sections, action);
          draft.sections.push({ id: newId() as RecipeSectionId, items: [] });
          break;
        }
        case "removeSection": {
          verifyDefined(draft.sections[action.index], action);
          draft.sections.splice(action.index, 1);
          break;
        }
        case "updateSectionTitle": {
          const section = verifyDefined(draft.sections[action.index], action);
          section.title = action.title;
          break;
        }
        default:
          bottomThrow(action, log);
      }
    });
  }, props.initialRecipe?.ingredients ?? props.initialDraftArgs?.ingredients ?? newRecipeIngredients());

  const [instructions, instructionsDispatch] = useReducer<RecipeInstructionsReducer>((current, action) => {
    return produce(current, draft => {
      switch (action.type) {
        case "addItem": {
          const section = verifyDefined(draft.sections[action.sectionIndex], action);
          const newItem: RecipeInstruction = {
            text: "",
            id: newId() as RecipeInstructionId,
          };

          if (action.atItemIndex !== undefined) {
            section.items.splice(action.atItemIndex, 0, newItem);
          } else {
            section.items.push(newItem);
          }

          break;
        }
        case "removeItem": {
          const section = verifyDefined(draft.sections[action.sectionIndex], action);
          section.items.splice(action.itemIndex, 1);
          break;
        }
        case "updateInstructionText": {
          const input = verifyDefined(draft.sections[action.sectionIndex]?.items[action.index], action);

          // handle pasting multiple instructions into an input
          const lines = action.text.split(/\n+/);

          // if there are no lines left after the split, the user cleared an input, so default to ""
          if (lines.length <= 1) {
            input.text = lines[0] ?? "";
          }

          if (lines.length > 1) {
            const section = verifyDefined(draft.sections[action.sectionIndex], action);
            const itemsToAdd = lines.map(line => {
              return { text: line, id: newId() as RecipeInstructionId };
            });
            // splice here to remove the input that the user pasted into. The resizing logic for multiline inputs
            // on web doesn't seem to work to *shrink* the input, only grow it. If we reuse the input, we end up with
            // empty lines in the size of the pasted input.
            section.items.splice(action.index, 1, ...itemsToAdd);
          }

          break;
        }
        case "convertToSection": {
          const section = verifyDefined(draft.sections[action.sectionIndex], action);
          if (section.items.length < action.itemIndex) {
            throw new Error(`Not enough items: ${JSON.stringify(action)}`);
          }

          const allItems = section.items;
          section.items = allItems.slice(0, action.itemIndex);
          const newSectionItems = allItems.slice(action.itemIndex + 1);
          const newSection: RecipeInstructionSection = {
            id: newId<RecipeSectionId>(),
            title: allItems[action.itemIndex]!.text,
            items: newSectionItems,
          };

          // insert the new section after the modified section
          draft.sections.splice(action.sectionIndex + 1, 0, newSection);
          break;
        }
        case "addSection": {
          verifyDefined(draft.sections, action);
          draft.sections.push({ id: newId() as RecipeSectionId, items: [] });
          break;
        }
        case "removeSection": {
          verifyDefined(draft.sections[action.index], action);
          draft.sections.splice(action.index, 1);
          break;
        }
        case "updateSectionTitle": {
          const section = verifyDefined(draft.sections[action.index], action);
          section.title = action.title;
          break;
        }
        default:
          bottomThrow(action, log);
      }
    });
  }, props.initialRecipe?.instructions ?? props.initialDraftArgs?.instructions ?? newRecipeInstructions());

  const [recipePhoto, recipePhotoDispatch] = useReducer<RecipePhotoReducer>(
    (current, action) => {
      return produce(current, draft => {
        switch (action.type) {
          case "updatePhotoExternalUrl": {
            // Admin only action
            setPickedRecipePhoto(undefined);
            draft.photo = {
              type: "external",
              url: action.url as UrlString,
            };
            break;
          }
          case "updatePickedPhoto": {
            draft.photo = undefined;
            setPickedRecipePhoto(action.pickedPhoto);
            break;
          }
          default:
            bottomThrow(action, log);
        }
      });
    },
    { photo: props.initialRecipe?.photo ?? props.initialDraftArgs?.photo }
  );

  const [recipeTags, recipeTagsDispatch] = useReducer<RecipeTagsReducer>((current, action) => {
    return produce(current, draft => {
      switch (action.type) {
        case "toggleTag": {
          const current = draft.find(i => i.type === action.tag.type && i.tag === action.tag.tag);
          if (current) {
            // Remove
            const removeIdx = draft.findIndex(i => i.type === action.tag.type && i.tag === action.tag.tag);
            if (removeIdx < 0) {
              return;
            }
            draft.splice(removeIdx, 1);
            return;
          }
          // Add
          if (draft.some(i => i.type === action.tag.type && i.tag === action.tag.tag)) {
            return;
          }
          draft.push(action.tag);
          return;
        }
        default:
          bottomThrow(action.type, log);
      }
    });
    // strip the added/deleted info - we want to deal only with the tag and not the metadata
  }, props.initialRecipe?.tags.flatMap<SystemRecipeTag>(i => (i.type === "system" && !("deleted" in i) ? [{ type: i.type, tag: i.tag }] : [])) ?? []);

  const getArgs = (): EditRecipeTypeMap[T][1] => {
    const cleanedIngredients = removeEmptyIngredients(ingredients);
    const cleanedInstructions = removeEmptyInstructions(instructions);

    const time: RecipeTime | undefined =
      totalTime !== undefined && totalTime > 0
        ? {
            total: [totalTime, totalTime],
          }
        : undefined;

    const baseArgs: EditRecipeOnSaveArgsBase = {
      title,
      description,
      ingredients: cleanedIngredients,
      instructions: cleanedInstructions,
      photo: recipePhoto.photo,
      newRecipePhoto: pickedRecipePhoto,
      time,
      recipeYield: recipeYield ? { text: recipeYield } : undefined,
    };

    let args: EditRecipeTypeMap[T][1];
    switch (props.type) {
      case "adminUser":
      case "user": {
        const userArgs: EditUserRecipeOnSaveArgs = baseArgs;
        args = userArgs;
        break;
      }
      case "adminExternalUrl": {
        // edit currently only supports unknown authors, so if it's not unknown, just pass through what we already have
        const updatedAuthor = author?.type === "unknownAuthor" ? getAuthor(author) : props.initialRecipe?.author;
        const updatedPublisher =
          publisher?.type === "unknownPublisher" ? getPublisher(publisher) : props.initialRecipe?.publisher;
        const externalUrlArgs: EditExternalUrlRecipeOnSaveArgs = {
          ...baseArgs,
          canonicalUrl: canonicalUrl as UrlString,
          author: updatedAuthor,
          newAuthorPhoto: pickedAuthorPhoto,
          publisher: updatedPublisher,
          newPublisherPhoto: pickedPublisherPhoto,
          tags: recipeTags,
        };
        args = externalUrlArgs;
        break;
      }
      case "adminExternalBook": {
        if (props.initialRecipe?.type !== "externalBookRecipe") {
          throw new Error(`Expected initial recipe of type "externalBookRecipe, got: ${props.initialRecipe?.type}`);
        }
        const externalBookArgs: EditExternalBookRecipeOnSaveArgs = {
          ...baseArgs,
          book: props.initialRecipe.book,
          author: props.initialRecipe.author,
          tags: recipeTags,
        };
        args = externalBookArgs;
        break;
      }
      default:
        bottomThrow(props.type);
    }

    return args;
  };

  const onSave = async () => {
    const args = getArgs();

    if (props.type === "user" && (title === "" || args.ingredients.sections[0].items.length === 0)) {
      displayExpectedError(strings.required);
      return;
    } else if (props.type !== "user" && title === "") {
      displayExpectedError(strings.requiredAdmin);
      return;
    }

    if (args.time?.total && isNaN(args.time.total[0])) {
      displayExpectedError(strings.invalidValue(strings.totalTime));
      return;
    }

    if (props.type === "adminExternalUrl") {
      const adminArgs = args as EditRecipeTypeMap["adminExternalUrl"][1];
      if (!emptyToUndefined(adminArgs.canonicalUrl)) {
        displayExpectedError("Canonical URL is required");
        return;
      }

      if (adminArgs.author?.photo?.type === "external" && !isUrl(adminArgs.author.photo.url)) {
        displayExpectedError("Author photo URL is not a valid URL");
        return;
      }

      if (adminArgs.publisher?.photo?.type === "external" && !isUrl(adminArgs.publisher.photo.url)) {
        displayExpectedError("Publisher photo URL is not a valid URL");
        return;
      }
    }

    try {
      setWaiting(true);
      await props.onSave(args);
      Haptics.feedback("operationSucceeded");
    } catch (err) {
      displayUnexpectedErrorAndLog("Error caught in onSave handler in RecipeEditControl", err, { args });
    } finally {
      setWaiting(false);
    }
  };

  const onRecipePhotoPicked = useCallback(
    (pickedPhoto: AppAddPhotoArgs) => {
      recipePhotoDispatch({ type: "updatePickedPhoto", pickedPhoto });
    },
    [recipePhotoDispatch]
  );

  const onChangePhotoExternalUrl = useCallback(
    (url: string) => {
      recipePhotoDispatch({ type: "updatePhotoExternalUrl", url });
    },
    [recipePhotoDispatch]
  );

  const onToggleTag = useCallback(
    (tag: SystemRecipeTag) => recipeTagsDispatch({ type: "toggleTag", tag }),
    [recipeTagsDispatch]
  );

  useEffect(() => {
    // note that we don't currently handle the photo here because there is not parity between the photo picker input type (PhotoRef)
    // and the type that is passed to the onSelected handler.
    if (!props.onChange) {
      return;
    }

    const args = getArgs();
    props.onChange(args);
  }, [props.onChange, title, description, ingredients, instructions]);

  const admin = props.type === "adminExternalUrl" || props.type === "adminExternalBook" || props.type === "adminUser";

  return (
    <View style={{ flex: 1 }}>
      <>
        {props.type === "adminExternalUrl" && (
          <>
            {/* Hard coded strings in admin fields are okay*/}
            <TBody fontWeight="heavy">Canonical URL</TBody>
            <TextInput value={canonicalUrl} onChangeText={setCanonicalUrl} />
            <Spacer vertical={2} />
          </>
        )}
        {props.type === "adminExternalBook" && (
          <View style={{ opacity: Opacity.medium }}>
            <TBody fontWeight="heavy">Book</TBody>
            <Spacer vertical={1} />
            <TextInput editable={false} value={props.initialRecipe?.book?.name} />
            <Spacer vertical={1} />
            <Photo
              style="thumbnailXlarge"
              resizeMode="contain"
              source={props.initialRecipe?.book?.photo}
              sourceSize="w1290"
            />
            <Spacer vertical={2} />
          </View>
        )}
        {(props.type === "adminExternalUrl" || props.type === "adminExternalBook") && (
          <View style={props.type === "adminExternalBook" ? { opacity: Opacity.medium } : {}}>
            <TBody fontWeight="heavy">Author</TBody>
            <Spacer vertical={1} />
            <View style={{ paddingLeft: globalStyleConstants.unitSize * 2 }}>
              <AuthorEditControl
                disabled={props.type === "adminExternalBook"}
                author={author}
                onChange={setAuthor}
                pickedAuthorPhoto={pickedAuthorPhoto}
                setPickedAuthorPhoto={setPickedAuthorPhoto}
              />
            </View>
            <Spacer vertical={2} />
          </View>
        )}
        {props.type === "adminExternalUrl" && (
          <>
            <TBody fontWeight="heavy">Publisher</TBody>
            <Spacer vertical={1} />
            <View style={{ paddingLeft: globalStyleConstants.unitSize * 2 }}>
              <PublisherEditControl
                disabled={props.type === "adminExternalBook"}
                publisher={publisher}
                onChange={setPublisher}
                pickedPublisherPhoto={pickedPublisherPhoto}
                setPickedPublisherPhoto={setPickedPublisherPhoto}
              />
            </View>
            <Spacer vertical={2} />
          </>
        )}
        <>
          {/* Title */}
          <SectionHeading text={strings.title} noPadding />
          <Spacer vertical={1} />
          <TextInput
            value={title}
            onChangeText={setTitle}
            placeholderText={strings.titlePlaceholder}
            // autoFocus={title === ""}
          />
        </>

        {/* Photo */}
        <Spacer vertical={2} />
        <View>
          <PhotoEditControl
            admin={admin}
            sectionTitle={strings.photo}
            photo={{ photoRef: pickedPhotoToRef(pickedRecipePhoto) ?? recipePhoto.photo, style: "fullWidthLarge" }}
            onPhotoPicked={onRecipePhotoPicked}
            onChangePhotoExternalUrl={onChangePhotoExternalUrl}
          />
        </View>

        {/* Description */}
        <Spacer vertical={2} />
        <View>
          <SectionHeading text={strings.description} noPadding />
          <Spacer vertical={1} />
          <TextInput
            value={description}
            onChangeText={setDescription}
            placeholderText={strings.descriptionPlaceholder}
            multiline
          />
        </View>

        {/* Time */}
        <Spacer vertical={2} />
        <View>
          <SectionHeading text={strings.time} noPadding />
          <Spacer vertical={1} />
          <RecipeTimePicker
            label={strings.totalTime}
            durationMs={totalTime ?? (0 as DurationMs)}
            onChangeTime={setTotalTime}
          />
          {props.initialRecipe?.type === "externalUrlRecipe" && (
            <>
              <Spacer vertical={1} />
              <TSecondary>
                <TSecondary opacity="medium">{"Raw Time"}</TSecondary>
                <TSecondary opacity="medium">{"       "}</TSecondary>
                <TSecondary opacity="medium">{`Total: ${props.initialRecipe.rawTime?.total}`}</TSecondary>
                <TSecondary opacity="medium">{"   |   "}</TSecondary>
                <TSecondary opacity="medium">{`Active: ${props.initialRecipe.rawTime?.prepTime}`}</TSecondary>
                <TSecondary opacity="medium">{"   |   "}</TSecondary>
                <TSecondary opacity="medium">{`Inactive: ${props.initialRecipe.rawTime?.cookTime}`}</TSecondary>
              </TSecondary>
            </>
          )}
        </View>

        {/* Yield */}
        <Spacer vertical={2} />
        <View>
          <SectionHeading text={strings.recipeYield} noPadding />
          <Spacer vertical={1} />
          <TextInput
            value={recipeYield}
            onChangeText={setRecipeYield}
            placeholderText={strings.recipeYieldPlaceholder}
          />
          {props.initialRecipe?.type === "externalUrlRecipe" && (
            <>
              <Spacer vertical={1} />
              <TSecondary>
                <TSecondary opacity="medium">{"Raw Yield"}</TSecondary>
                <TSecondary opacity="medium">{"       "}</TSecondary>
                <TSecondary opacity="medium">{props.initialRecipe.rawRecipeYield?.values.toString()}</TSecondary>
              </TSecondary>
            </>
          )}
        </View>

        {/* Tags */}
        {admin && props.type !== "adminUser" && (
          <>
            <Spacer vertical={2} />
            <SectionHeading text="Tags" noPadding />
            <Spacer vertical={1} />
            <AdminRecipeTagsEdit
              renderTagLabel={tag =>
                tag.type === "system" ? props.tagManifest?.tagDisplay[tag.tag] ?? tag.tag : tag.tag
              }
              selectedTags={recipeTags}
              systemTags={props.tagManifest?.categoryList ?? []}
              onPressTag={onToggleTag}
            />
          </>
        )}

        {/* Ingredients  */}
        <Spacer vertical={2} />
        <View>
          <SectionHeading text={strings.ingredients} noPadding />
          <Spacer vertical={1} />
          <RecipeSectionEdit
            type="ingredients"
            recipeSection={ingredients}
            dispatch={ingredientsDispatch}
            admin={admin}
            parsedIngredients={props.parsedIngredients}
          />
        </View>

        {/* Instructions */}
        <Spacer vertical={2} />
        <View>
          <SectionHeading text={strings.instructions} noPadding />
          <Spacer vertical={1} />
          <RecipeSectionEdit
            type="instructions"
            recipeSection={instructions}
            dispatch={instructionsDispatch}
            admin={admin}
          />
        </View>

        {/* Save Button */}
        <Spacer vertical={2} />
        <View>
          <ButtonRectangle waiting={waiting} onPress={onSave} type="submit" title={strings.save} />
        </View>
      </>
    </View>
  );
};

export function newRecipeIngredients(): RecipeIngredients {
  return {
    sections: [
      {
        id: newId() as RecipeSectionId,
        items: [{ text: "", id: newId() as RecipeIngredientId }],
      },
    ],
  };
}

function newShoppable(text?: string): ShoppableRecipeIngredient {
  return { text: text ?? "", type: "simple", source: "manualEntry", id: newId() };
}

function verifyDefined<T>(value: T | undefined, action: RecipeIngredientEditActions | RecipeInstructionEditActions): T {
  if (value === undefined) {
    const message = `RecipeEditControl: RecipeSection/Ingredient/Shoppable missing ${JSON.stringify(action)}`;
    log.error(message);
    throw new Error(message);
  }

  return value;
}

function removeEmptyIngredients(ingredients: RecipeIngredients): RecipeIngredients {
  const sections = ingredients.sections.map(s => {
    const items = s.items.flatMap(i => {
      const text = i.text.trim();
      const shoppable = i.shoppable?.filter(s => s.text.trim() !== "");
      if ((shoppable?.length ?? 0) > 0 || text !== "") {
        return [{ ...i, text, shoppable }];
      } else {
        return [];
      }
    });

    return {
      ...s,
      items,
    };
  });

  return { sections: sections as [RecipeIngredientSection, ...[RecipeIngredientSection]] };
}

export function newRecipeInstructions(): RecipeInstructions {
  return {
    sections: [
      {
        id: newId() as RecipeSectionId,
        items: [{ text: "", id: newId() as RecipeInstructionId }],
      },
    ],
  };
}

function removeEmptyInstructions(instructions: RecipeInstructions): RecipeInstructions {
  const sections = instructions.sections.map(s => {
    const items = s.items.filter(i => !!i.text.trim());
    return {
      ...s,
      items,
    };
  });

  return { sections: sections as [RecipeInstructionSection, ...[RecipeInstructionSection]] };
}
