import { StyleSheet, View } from "react-native";
import { Spacer } from "../Spacer";
import { TSecondary } from "../Typography";
import { RecipeCard, UserRecipePressedHandler, recipeCardConstants } from "../recipes/RecipeCards";
import { globalStyleConstants } from "../GlobalStyles";
import React, { useCallback } from "react";
import { RecipeGroceryListItem } from "@eatbetter/lists-shared";
import { useRecipe } from "../../lib/recipes/RecipesSelectors";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { SectionHeading } from "../SectionHeading";

const strings = {
  sectionHeader: "Source Recipe",
};

const config = {
  sectionHeadingHeight: 21 + globalStyleConstants.unitSize,
  ingredientTextMaxHeight: 88, // 2 lines
  recipeCardHeight: recipeCardConstants.verticalScrollCardHeight + 0.5 * globalStyleConstants.unitSize,
};

export const groceryItemRecipeSourceConstants = {
  height: config.sectionHeadingHeight + config.ingredientTextMaxHeight + config.recipeCardHeight,
};

export const GroceryItemRecipeSource = React.memo((props: { item: RecipeGroceryListItem }) => {
  const recipe = useRecipe(props.item.recipeId);
  const screen = useScreen();

  const onPressCallback = useCallback<UserRecipePressedHandler>(
    recipeId => {
      screen.nav.switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId });
    },
    [screen.nav.switchTab]
  );

  const ingredientText = (
    <View style={styles.ingredientText}>
      <Spacer vertical={1} />
      <TSecondary opacity="medium" italic numberOfLines={2}>
        {props.item.recipeText}
      </TSecondary>
      <Spacer vertical={1} />
    </View>
  );

  if (recipe) {
    return (
      <View style={styles.container}>
        <SectionHeading text={strings.sectionHeader} noPadding />
        <Spacer vertical={1} />
        <RecipeCard
          {...recipe}
          index={0}
          onPress={onPressCallback}
          searchSessionId={undefined}
          showTimeFilter={false}
        />
        {ingredientText}
      </View>
    );
  }

  return null;
});

const styles = StyleSheet.create({
  container: {},
  ingredientText: {
    paddingHorizontal: 8,
    maxHeight: config.ingredientTextMaxHeight,
  },
});
