import { isStructuredError, UrlString } from "@eatbetter/common-shared";
import { PartialRecipeId } from "@eatbetter/recipes-shared";
import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { ButtonRectangle } from "../components/Buttons";
import { globalStyleColors, globalStyleConstants, Opacity } from "../components/GlobalStyles";
import { Haptics } from "../components/Haptics";
import { ScreenView } from "../components/ScreenView";
import { Spacer } from "../components/Spacer";
import { TextInput, TextInputHandle } from "../components/TextInput";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { addRecipeFromUrl } from "../lib/recipes/RecipesThunks";
import { useIdempotentId } from "../lib/util/UseIdempotentId";
import { log } from "../Log";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import Clipboard from "@react-native-clipboard/clipboard";
import { ShareExtensionDemoVideo } from "./OnboardShareExtensionScreen";
import { useDispatch } from "../lib/redux/Redux";
import { TBody, THeading2 } from "../components/Typography";
import { Alert } from "../components/Alert/Alert";

const strings = {
  title: "Add recipe",
  addRecipe: {
    textInputPlaceholder: "Paste a recipe URL here",
    saveButton: "Save",
  },
  or: "or",
  shareExtensionHeadline: "Add any recipe right from\nyour browser",
  dontSeeDeglaze: "Don't see Deglaze?",
};

export const RecipeAddFromUrlScreen = withScreenContainer("RecipeAddFromUrlScreen", () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const inputRef = useRef<TextInputHandle>(null);
  const [url, setUrl] = useState<UrlString>("" as UrlString);
  const [id, refreshId] = useIdempotentId<PartialRecipeId>();
  const [addRecipePending, setAddRecipePending] = useState(false);

  const onAddFromUrl = useCallback(
    async (urlToAdd: UrlString) => {
      try {
        log.info(`Attempting to add recipe from url ${urlToAdd}`);
        Haptics.feedback("itemStatusChanged");

        await dispatch(addRecipeFromUrl({ url: urlToAdd, id }, setAddRecipePending));
        setUrl("" as UrlString);
        refreshId();

        Haptics.feedback("operationSucceeded");
        setImmediate(screen.nav.goBack);
      } catch (err) {
        if (isStructuredError(err) && err.data.userMessage) {
          Alert.alert(err.data.userMessage, "", [{ type: "save", text: "Okay", onPress: () => {} }]);
        } else {
          displayUnexpectedErrorAndLog("Add from url call failed", err, { url: urlToAdd });
        }
      }
    },
    [dispatch, refreshId, id, setUrl, setAddRecipePending, screen.nav.goBack]
  );

  const goToShareExtensionSetup = useCallback(() => {
    screen.nav.modal(navTree.get.screens.onboardShareExtension);
  }, [screen.nav.modal]);

  return React.createElement(RecipeAddFromUrlScreenComponent, {
    inputRef,
    url,
    setUrl,
    onAddFromUrl,
    addRecipePending,
    goToShareExtensionSetup,
  });
});

interface Props {
  inputRef: React.Ref<TextInputHandle>;
  url: UrlString;
  setUrl: (s: UrlString) => void;
  onAddFromUrl: (s: UrlString) => void;
  addRecipePending: boolean;
  goToShareExtensionSetup: () => void;
}

export const RecipeAddFromUrlScreenComponent = (props: Props) => {
  const flexedDivider = <View style={{ height: 1, flex: 1, backgroundColor: "black", opacity: Opacity.xlight }} />;

  return (
    <ScreenView header={{ type: "default", title: strings.title }} backgroundColor={globalStyleColors.colorGreyLight}>
      <RecipeUrlAdd
        inputRef={props.inputRef}
        url={props.url}
        setUrl={props.setUrl}
        onAddFromUrl={props.onAddFromUrl}
        addRecipePending={props.addRecipePending}
      />
      <Spacer vertical={2} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {flexedDivider}
        <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
          <TBody align="center">{strings.or}</TBody>
        </View>
        {flexedDivider}
      </View>
      <Spacer vertical={1} />
      <ShareExtensionMessage />
    </ScreenView>
  );
};

const RecipeUrlAdd = (props: Pick<Props, "inputRef" | "url" | "setUrl" | "onAddFromUrl" | "addRecipePending">) => {
  const onFocusTextInput = useCallback(async () => {
    const hasUrl = await Clipboard.hasURL();
    if (!hasUrl) {
      return;
    }
    const url = (await Clipboard.getString()) as UrlString;
    props.setUrl(url);
  }, [props.setUrl]);

  return (
    <View>
      <View style={styles.textInput}>
        <TextInput
          ref={props.inputRef}
          onChangeText={v => props.setUrl(v as UrlString)}
          returnKeyType="done"
          keyboardType="url"
          value={props.url}
          placeholderText={strings.addRecipe.textInputPlaceholder}
          autoCapitalize="none"
          autoCorrect={false}
          noBorder
          noPadding
          onFocus={onFocusTextInput}
        />
      </View>
      <Spacer vertical={1} />
      <SaveButton
        onPress={() => props.onAddFromUrl(props.url)}
        addRecipePending={props.addRecipePending}
        paddingHorizontal={globalStyleConstants.unitSize}
        disabled={!props.url}
      />
    </View>
  );
};

const SaveButton = (props: {
  onPress: () => void;
  addRecipePending: boolean;
  paddingHorizontal: number;
  disabled: boolean;
}) => (
  <ButtonRectangle
    onPress={props.onPress}
    title={strings.addRecipe.saveButton}
    waiting={props.addRecipePending}
    type="submit"
    disabled={props.disabled}
    singlePress
  />
);

const ShareExtensionMessage = React.memo(() => {
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const onPressDontSeeDeglaze = useCallback(() => {
    nav.modal(navTree.get.screens.onboardShareExtensionFirstTime);
  }, [dispatch]);

  return (
    <View style={{ flex: 1 }}>
      <THeading2 align="center" numberOfLines={2} adjustsFontSizeToFit>
        {strings.shareExtensionHeadline}
      </THeading2>
      <View style={{ height: "75%" }}>
        <ShareExtensionDemoVideo />
      </View>
      <Spacer vertical={0.5} />
      <TBody
        align="center"
        color={globalStyleColors.colorTextLink}
        fontWeight="medium"
        suppressHighlighting
        onPress={onPressDontSeeDeglaze}
      >
        {strings.dontSeeDeglaze}
      </TBody>
    </View>
  );
});

const styles = StyleSheet.create({
  textInput: {
    height: 48,
    backgroundColor: "white",
    justifyContent: "center",
    borderRadius: 24,
    paddingHorizontal: 1.5 * globalStyleConstants.unitSize,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("blackSoft", "light"),
  },
});
