import { CookingSessionId } from "@eatbetter/cooking-shared";
import {
  RecipeId,
  AppUserRecipe,
  UserRecipeId,
  RecipeInfo,
  Book,
  Publisher,
  Author,
  UserRecipeStatus,
} from "@eatbetter/recipes-shared";
import React, { useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { globalStyleColors, Opacity, globalStyleConstants, globalStyles } from "../GlobalStyles";
import { Pressable } from "../Pressable";
import { FlexedSpinner, Spinner } from "../Spinner";
import { TTertiary } from "../Typography";
import { SelectedCircle } from "../SelectedCircle";
import { useFilterTags } from "../../lib/recipes/RecipeTagSelectors";
import { discriminate, DurationMs, getFormattedDuration, UserId } from "@eatbetter/common-shared";
import { Spacer } from "../Spacer";
import { RecipeAuthor, RecipePublisherOrBook, RecipeTitle } from "./RecipeTitleAndSource";
import { ShareViewRecipeScreenProps } from "../../navigation/NavTree";
import { RecipePhoto } from "../RecipePhoto";
import { RecipeCookCount } from "./RecipeCookCount";
import { Separator } from "../Separator";
import { useCookingSessionId } from "../../lib/cooking/CookingSessionsSelectors";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { SearchSessionId } from "../../lib/search/SearchSlice";

const constants = {
  verticalScrollCardPhotoSize: 96,
  horizontalScrollPhotoHeight: 112,
  inlinePhotoSize: 84,
};

export const recipeCardConstants = {
  verticalScrollCardHeight: 112,
  horizontalScrollCardWidth: 176,
  horizontalScrollCardHeight: 224,
};

export type RecipePressedHandler = (recipeId: RecipeId, index: number) => void;
export type UserRecipePressedHandler = (
  recipeId: UserRecipeId,
  index: number,
  cookingSessionId?: CookingSessionId
) => void;

interface ListItemProps {
  scrollDirection?: "vertical" | "horizontal";
  index: number;
}

interface FilterProps {
  showTimeFilter?: boolean;
  searchSessionId: SearchSessionId | undefined;
}

interface PressableUserRecipeProps {
  onPress?: UserRecipePressedHandler;
}

interface PressableRecipeBaseProps {
  onPress?: RecipePressedHandler;
}

interface MultiSelectCardProps {
  isSelected?: boolean;
}

export type UserRecipeCardProps = AppUserRecipe &
  ListItemProps &
  PressableUserRecipeProps &
  MultiSelectCardProps &
  FilterProps;
export type RecipeBaseCardProps = RecipeInfo & ListItemProps & PressableRecipeBaseProps & FilterProps;
export type RecipeCardProps = UserRecipeCardProps | RecipeBaseCardProps;

export const RecipeCard = React.memo((props: RecipeCardProps) => {
  // RecipeInfo props
  const title = props.title;
  const author = props.author;
  const book = props.book;
  const publisher = props.publisher;

  const { status, time, stats } = props as AppUserRecipe;
  const hasTypeProp = "type" in props;
  const isUserRecipe = hasTypeProp && props.type;
  const totalTime = time?.total?.[0];
  const cookCount = stats?.cooked;

  const cookingSessionId = useCookingSessionId(props.id);
  const isCooking = !!cookingSessionId;
  const cookingStyle = isCooking ? styles.activeItem : {};

  const { isSelected } = props as MultiSelectCardProps;

  const scrollDirection = props.scrollDirection ?? "vertical";

  const onPressCallback = useCallback(() => {
    if (isUserRecipe) {
      props.onPress?.(props.id, props.index, cookingSessionId);
      return;
    }

    if (!hasTypeProp) {
      props.onPress?.(props.id as RecipeId, props.index);
      return;
    }

    displayUnexpectedErrorAndLog("RecipeLibraryCard: Unexpected type in onPressCallback", {}, { type: props.type });
  }, [hasTypeProp, isUserRecipe, props.id, props.index, props.onPress, cookingSessionId]);

  const activeFilters = useFilterTags(props.searchSessionId);
  const hasTotalTimeFilter = useMemo(() => {
    return activeFilters.some(discriminate("type", "totalTime"));
  }, [activeFilters]);

  return (
    <Pressable onPress={onPressCallback} singlePress noFeedback>
      {scrollDirection === "vertical" && (
        <View style={[styles.verticalScrollItem, cookingStyle]}>
          <RecipeCardPhoto
            scrollDirection="vertical"
            recipe={props}
            isCooking={isCooking}
            showTotalTime={props.showTimeFilter !== false && hasTotalTimeFilter}
            totalTime={totalTime}
          />
          <RecipeCardDetails
            scrollDirection="vertical"
            title={title}
            book={book}
            publisher={publisher}
            author={author}
            status={status}
            cookCount={cookCount}
            isSelected={isSelected}
          />
        </View>
      )}
      {scrollDirection === "horizontal" && (
        <View style={[styles.horizontalScrollItem, cookingStyle]}>
          <RecipeCardDetails
            scrollDirection="horizontal"
            title={title}
            book={book}
            publisher={publisher}
            author={author}
            status={status}
            cookCount={cookCount}
            isSelected={isSelected}
          />
          <RecipeCardPhoto
            scrollDirection="horizontal"
            recipe={props}
            isCooking={isCooking}
            showTotalTime={hasTotalTimeFilter}
            totalTime={totalTime}
          />
        </View>
      )}
    </Pressable>
  );
});

interface RecipeCardDetailsProps {
  scrollDirection: "vertical" | "horizontal";
  title: string;
  author?: Author;
  book?: Book;
  publisher?: Publisher;
  cookCount?: number;
  status?: UserRecipeStatus;
  isSelected?: boolean;
}

const RecipeCardDetails = React.memo((props: RecipeCardDetailsProps) => {
  const publisherOrBook = props.book ?? props.publisher;

  return (
    <View
      style={
        props.scrollDirection === "vertical" ? styles.itemContentVerticalScroll : styles.itemContentHorizontalScroll
      }
    >
      <View style={styles.libraryTitleAndSource}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          {!!publisherOrBook && (
            <View style={{ flexShrink: 1 }}>
              <RecipePublisherOrBook
                author={props.author}
                book={props.book}
                publisher={props.publisher}
                photoStyle="square"
                fontSize="tertiary"
                numberOfLines={1}
              />
            </View>
          )}
          {!!props.cookCount && (
            <>
              <Spacer horizontal={1} />
              <RecipeCookCount cookCount={props.cookCount} />
            </>
          )}
        </View>
        <Spacer vertical={0.5} />
        <View style={{ flexGrow: 1, flexShrink: 1, justifyContent: "center" }}>
          <RecipeTitle title={props.title} fontSize="secondary" numberOfLines={2} />
          <Spacer vertical={0.1} />
          {!!props.author && <RecipeAuthor author={props.author} numberOfLines={1} fontSize="tertiary" noPhoto />}
        </View>
      </View>
      {props.status === "processing" && (
        <View style={{ padding: globalStyleConstants.unitSize }}>
          <Spinner />
        </View>
      )}
      {props.isSelected !== undefined && (
        <>
          <View style={{ paddingVertical: 1.25 * globalStyleConstants.unitSize }}>
            <Separator orientation="column" />
          </View>
          <View style={{ padding: globalStyleConstants.unitSize }}>
            <SelectedCircle isSelected={props.isSelected} />
          </View>
        </>
      )}
    </View>
  );
});

interface RecipeCardPhoto {
  scrollDirection: "vertical" | "horizontal";
  isCooking: boolean;
  showTotalTime: boolean;
  totalTime?: DurationMs;
  recipe: RecipeInfo;
}

const RecipeCardPhoto = React.memo((props: RecipeCardPhoto) => {
  const cookingStyle = props.isCooking ? styles.activeRecipePhoto : {};

  return (
    <View
      style={[
        props.scrollDirection === "vertical"
          ? styles.cardPhotoWrapVerticalScroll
          : styles.cardPhotoWrapHorizontalScroll,
        cookingStyle,
      ]}
    >
      {props.showTotalTime && <RecipeTimeBadge showTotalTime={props.showTotalTime} totalTime={props.totalTime} />}
      <View
        style={props.scrollDirection === "vertical" ? styles.cardPhotoVerticalScroll : styles.cardPhotoHorizontalScroll}
      >
        <RecipePhoto recipe={props.recipe} sourceSize="w1290" />
      </View>
    </View>
  );
});

const RecipeTimeBadge = React.memo(
  (props: { showTotalTime: boolean; totalTime?: DurationMs; showActiveTime?: boolean; activeTime?: DurationMs }) => {
    if (!props.totalTime) {
      return null;
    }

    const totalTimeDisplay = getFormattedDuration({ milliseconds: props.totalTime }, false, "XXh:XXm");
    const activeTimeDisplay = props.activeTime
      ? getFormattedDuration({ milliseconds: props.activeTime }, false, "XXh:XXm")
      : undefined;

    return (
      <View style={styles.timeOverlay}>
        {props.showTotalTime && (
          <View style={styles.timeOverlayPill}>
            <TTertiary>{totalTimeDisplay}</TTertiary>
          </View>
        )}
        {props.showActiveTime && props.showTotalTime && !!activeTimeDisplay && <Spacer vertical={0.25} />}
        {props.showActiveTime && !!activeTimeDisplay && (
          <View style={styles.timeOverlayPill}>{<TTertiary>{activeTimeDisplay}</TTertiary>}</View>
        )}
      </View>
    );
  }
);

export const RecipeLibraryCardLoading = React.memo(() => {
  return (
    <View style={[styles.verticalScrollItem, { paddingHorizontal: globalStyleConstants.unitSize }]}>
      <View style={{ flex: 1, borderBottomWidth: 1, borderBottomColor: globalStyleColors.colorGreyLight }}>
        <FlexedSpinner />
      </View>
    </View>
  );
});

type CommentRecipeCardProps = RecipeBaseCardProps & {
  sharedByUserId: UserId;
  sourceRecipeId?: RecipeId;
  userRecipeId: UserRecipeId;
  onPressRecipe: (args: ShareViewRecipeScreenProps) => void;
};

export const CommentRecipeCard = React.memo((props: CommentRecipeCardProps) => {
  const title = props.title;
  const author = props.author;
  const book = props.book;
  const publisher = props.publisher;
  const publisherOrBook = book ?? publisher;

  const onPress = useCallback(() => {
    props.onPressRecipe({
      sharedByUserId: props.sharedByUserId,
      sourceRecipeId: props.sourceRecipeId ?? props.userRecipeId,
      sharedByRecipeId: props.userRecipeId,
      context: "socialPostComment",
    });
  }, [props.onPressRecipe, props.sharedByUserId, props.sourceRecipeId, props.userRecipeId]);

  return (
    <Pressable onPress={onPress} singlePress noFeedback>
      <View style={[styles.verticalScrollItem, { height: "auto", borderRadius: 20 }]}>
        <View style={styles.inlinePhoto}>
          <RecipePhoto recipe={props} sourceSize="w1290" />
        </View>
        <View style={styles.itemContentVerticalScroll}>
          <View style={styles.inlineTitleAndSource}>
            {!!publisherOrBook && (
              <RecipePublisherOrBook
                author={props.author}
                book={book}
                publisher={publisher}
                fontSize="tertiary"
                numberOfLines={1}
                photoStyle="square"
              />
            )}
            <Spacer vertical={0.5} />
            <RecipeTitle title={title} fontSize="secondary" numberOfLines={1} />
            {!!author && <RecipeAuthor author={author} fontSize="tertiary" numberOfLines={1} noPhoto />}
          </View>
        </View>
      </View>
    </Pressable>
  );
});

const styles = StyleSheet.create({
  verticalScrollItem: {
    height: recipeCardConstants.verticalScrollCardHeight,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: globalStyleConstants.defaultBorderRadius,
    ...globalStyles.shadowItem,
  },
  horizontalScrollItem: {
    height: recipeCardConstants.horizontalScrollCardHeight,
    width: recipeCardConstants.horizontalScrollCardWidth,
    backgroundColor: "white",
    borderRadius: globalStyleConstants.defaultBorderRadius,
    ...globalStyles.shadowItem,
    shadowOffset: { ...globalStyles.shadowItem.shadowOffset, width: globalStyles.shadow.shadowRadius },
  },
  activeItem: {
    backgroundColor: globalStyleColors.rgba("colorAccentMid"),
  },
  itemContentVerticalScroll: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  },
  itemContentHorizontalScroll: {
    flex: 1,
    flexDirection: "row",
  },
  libraryTitleAndSource: {
    flexGrow: 1,
    flexShrink: 1,
    height: "100%",
    paddingVertical: 1.25 * globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.unitSize,
  },
  cardPhotoWrapVerticalScroll: {
    marginVertical: globalStyleConstants.minPadding,
    marginLeft: globalStyleConstants.minPadding,
  },
  cardPhotoWrapHorizontalScroll: {},
  cardPhotoVerticalScroll: {
    height: constants.verticalScrollCardPhotoSize,
    width: constants.verticalScrollCardPhotoSize,
    overflow: "hidden",
    borderRadius: globalStyleConstants.defaultBorderRadius,
  },
  cardPhotoHorizontalScroll: {
    height: constants.horizontalScrollPhotoHeight,
    width: "100%",
    overflow: "hidden",
    borderBottomLeftRadius: globalStyleConstants.defaultBorderRadius,
    borderBottomRightRadius: globalStyleConstants.defaultBorderRadius,
  },
  cardMetadata: {
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    paddingVertical: 1.25 * globalStyleConstants.unitSize,
    paddingRight: globalStyleConstants.unitSize,
  },
  activeRecipePhoto: {
    opacity: Opacity.medium,
    backgroundColor: globalStyleColors.rgba("colorAccentMid"),
  },
  feedbackControl: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 2 * globalStyleConstants.unitSize,
    paddingLeft: globalStyleConstants.unitSize,
  },
  inlinePhoto: {
    height: constants.inlinePhotoSize,
    width: constants.inlinePhotoSize,
    borderTopLeftRadius: globalStyleConstants.defaultBorderRadius,
    borderBottomLeftRadius: globalStyleConstants.defaultBorderRadius,
    overflow: "hidden",
  },
  inlineTitleAndSource: {
    flexShrink: 1,
    paddingHorizontal: globalStyleConstants.unitSize,
  },
  border: {
    borderRadius: globalStyleConstants.unitSize,
    borderColor: globalStyleColors.rgba("black", "light"),
    borderWidth: 1,
  },
  timeOverlay: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    left: 0,
    padding: 8,
    width: "auto",
    zIndex: 1,
  },
  timeOverlayPill: {
    justifyContent: "center",
    alignItems: "center",
    height: 18,
    opacity: 0.8,
    backgroundColor: "white",
    paddingHorizontal: 4,
    borderRadius: 9,
  },
});
