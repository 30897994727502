import {
  EpochMs,
  TypedPrimitive,
  UrlString,
  UserId,
  DataAndType,
  DurationMs,
  Base64Data,
  UrlNoProtocolString,
  bottomWithDefault,
  UrlHost,
} from "@eatbetter/common-shared";
import { EntityDisplay, PhotoRef } from "@eatbetter/photos-shared";
import { DeglazeUser, HouseholdId, UserOrHouseholdId, UserProfileLink } from "@eatbetter/users-shared";
import { RecipeTag, RecipeTagUpdate, SystemRecipeTagAndMetadata } from "./RecipeTagTypes";

// a recipe ID is a compound ID of `userId:partialRecipeId`
// the client specifies the partial recipe ID for idempotency
export type PartialRecipeId = TypedPrimitive<string, "PartialRecipeId">;
export type RecipeId = UserRecipeId | ExternalRecipeId;
export type ExternalRecipeId = TypedPrimitive<string, "ExternalRecipeId">;
export type UserRecipeId = TypedPrimitive<string, "UserRecipeId">;
export type RecipeSectionId = TypedPrimitive<string, "RecipeSectionId">;
export type RecipeIngredientId = TypedPrimitive<string, "RecipeIngredientId">;
export type ShoppableRecipeIngredientId = TypedPrimitive<string, "ShoppableRecipeIngredientId">;
export type RecipeInstructionId = TypedPrimitive<string, "RecipeInstructionId">;
export type RecipeActivityId = TypedPrimitive<string, "RecipeActivityId">;
export type BookId = TypedPrimitive<string, "BookId">;

export type ExternalRecipe = ExternalUrlRecipe | ExternalBookRecipe;
export type ServerRecipe = UserRecipe | ExternalRecipe;
export type AppRecipe = AppUserRecipe | AppRecipeBase;

export function isExternalRecipeId(id: RecipeId): id is ExternalRecipeId {
  return id.startsWith("e:");
}

export function isUserRecipeId(id: RecipeId): id is UserRecipeId {
  return !isExternalRecipeId(id);
}

export function isUserRecipe(r: AppRecipe): r is AppUserRecipe {
  const typeKey: keyof AppUserRecipe = "type";
  return typeKey in r && r.type === "userRecipe";
}

/**
 * Parts of the recipe that can be edited.
 */
export interface EditableRecipeFields {
  // IMPORTANT - The RecipeDynamo.editRecipe update statement must be explicitly
  // updated when this list changes
  title: string;
  description: string;
  ingredients: RecipeIngredients;
  instructions: RecipeInstructions;
  photo?: PhotoRef;
  time?: RecipeTime;
  recipeYield?: RecipeYield;
  // IMPORTANT - SEE NOTE ABOVE
}

interface NonEditableRecipeFields {
  author?: Author;
  publisher?: Publisher;
  book?: Book;
  source: RecipeSource;
  version: EpochMs;
  created: EpochMs;
}

export type Author = UnknownAuthor | UserAuthor | KnownAuthor;

/**
 * An author for which we have no internal representation.
 */
export interface UnknownAuthor extends EntityDisplay {
  type: "unknownAuthor";
  url?: UrlString;

  // These are here for back compat reasons and should be removed once we can
  // get all users upgaded to >= v3.
  // See RecipeAccess for more details
  id?: KnownAuthorId;
  redirectToPublisherId?: KnownPublisherId;
}

export const knownAuthorIdPrefix = "ka:";
export type KnownAuthorId = `${typeof knownAuthorIdPrefix}${string}`;
export function isKnownAuthorId(id: string): id is KnownAuthorId {
  return id.startsWith(knownAuthorIdPrefix);
}

export interface KnownAuthor extends EntityDisplay {
  type: "knownAuthor";
  id: KnownAuthorId;

  // set if a known author is also a deglaze user
  userId?: UserId;

  /**
   * There are some "authors" from the metadata sense, like "Woks of Life", that are
   * actually publishers. If this is set, it means that users, when tapping this author,
   * should be direct to the publisher page instead of the author page.
   */
  redirectToPublisherId?: KnownPublisherId;
}

/**
 * A Deglaze user
 */
export interface UserAuthor extends DeglazeUser {
  type: "userAuthor";
}

export type Publisher = UnknownPublisher | KnownPublisher;

export interface UnknownPublisher extends EntityDisplay {
  type: "unknownPublisher";
  url?: UrlString;

  // These are here for back compat reasons and should be removed once we can
  // get all users upgaded to >= v3.
  // See RecipeAccess for more details
  id?: KnownPublisherId;
  redirectToAuthorId?: KnownAuthorId;
}

export const knownPublisherIdPrefix = "kp:";
export type KnownPublisherId = `${typeof knownPublisherIdPrefix}${string}`;
export function isKnownPublisherId(id: string): id is KnownPublisherId {
  return id.startsWith(knownPublisherIdPrefix);
}
export interface KnownPublisher extends EntityDisplay {
  type: "knownPublisher";
  id: KnownPublisherId;
  url: UrlString;
  domain: UrlHost;

  // set if a known publisher is also a deglaze user
  userId?: UserId;

  /**
   * There are some publishers, like ixta.world, that are
   * actually sites solely dedicated to their namesake author (usually in the domain name).
   * If this is set, it means that users, when tapping this publisher,
   * should be navigated to the relevant author page.
   */
  redirectToAuthorId?: KnownAuthorId;
}

export interface EntityLinks {
  website?: string;
  instagramHandle?: string;
  substack?: string;
  otherLinks?: UserProfileLink[];
}

export interface KnownProfileInfo {
  bannerPhoto?: PhotoRef;
  profileBio?: string;
  books?: Array<Omit<Book, "id">>;
  links?: EntityLinks;
}

export interface UserRecipeStats {
  lastViewed: Record<UserId, EpochMs>;
  lastAction: EpochMs;
  lastCooked?: EpochMs;
  cooked?: number;
  addedToList?: number;
  edited?: number;
}

export type UserRecipeStatus =
  // initial state while waiting to process the external recipe
  | "processing"

  // external recipe has been processed, but manual intervention is needed
  | "pendingManual"

  // complete via automatic parsing or manual intervention
  | "complete"

  // things hvae gone horribly wrong
  | "failed";

export type RecipeInfo = Pick<
  RecipeBase<any>,
  "id" | "title" | "source" | "photo" | "author" | "publisher" | "book" | "time"
> & {
  /**
   * RecipeInfo Full Access. This is set if the recipe the RecipeInfo is generated from an external recipe and it has faInfo set
   */
  rifa?: true;
};

export interface IndexLastAction {
  action: "indexed" | "deleted" | "skipped";
  note?: string;
  ts: EpochMs;
}

export interface IndexOverride {
  override: "index" | "doNotIndex";
  ts: EpochMs;
  note: string;
}

export interface IndexableRecipe {
  indexLastAction?: IndexLastAction;
  indexOverride?: IndexOverride;
}

export interface ExternalRecipeFullAccessInfo {
  note?: string;
  ts: EpochMs;
}

export interface RestrictedRecipe {
  faInfo?: ExternalRecipeFullAccessInfo;
}

export interface RecipeBase<TAccessChecked extends boolean> extends EditableRecipeFields, NonEditableRecipeFields {
  id: RecipeId;

  // these are here because they are editable, but not handled in the editRecipe call path.
  tags: RecipeTag[];

  /**
   * ABOUT AccessChecking
   * We keep full recipe details in the backend. These should not always be returned to a user in the frontend
   * For example, a book recipe photo should never be returned by default.
   * We use the ac (access checked) property to differentiate types.
   * The converting functions in the server that convert the internal representations to these types
   * always return ac: false. The functions that return recipes to the app always expect types with ac: true.
   * We have a series of access checking functions in RecipeAccessChecker.ts that handles these conversions.
   * We also use eslint rule sin ui-shared to prohibit the use of server-only types.
   */
  ac: TAccessChecked;
}

type RestrictableInstructions = { instructionsRestricted?: boolean };

/**
 * Type returned to the app when we need the base representation of a recipe
 */
export type AppRecipeBase = RecipeBase<true> & RestrictableInstructions;

export interface RecipeNotes {
  text: string;
}

export interface RecipeRating {
  type: "1to5Star";
  rating: number;
}

export function getRatingString(rating: RecipeRating): string | undefined {
  switch (rating?.type) {
    case "1to5Star":
      return `${rating?.rating}/5`;
    default:
      // old version of the app could get a new rating format.
      return bottomWithDefault(rating.type, undefined, "getRatingString");
  }
}

export interface RecipeTime {
  total: [DurationMs, DurationMs];
}

export interface RawRecipeTime {
  prepTime?: string;
  cookTime?: string;
  total?: string;
}

export interface RecipeYield {
  text: string;
}

export interface RawRecipeYield {
  values: string[];
}

interface UserRecipeBase<TAccessChecked extends boolean> extends RecipeBase<TAccessChecked> {
  type: "userRecipe";
  id: UserRecipeId;
  userId: UserId;
  householdId?: HouseholdId;
  sharedBy?: UserId;

  rating?: RecipeRating;

  /**
   * Notes
   */
  notes?: RecipeNotes;

  /**
   * If the user got the recipe from another user's version of the recipe (post, share, etc.)
   * keep track of that recipe ID. If this is an external recipe ID, it implies that the external
   * recipe was shared directly (From search results, etc.) and the reciep was not in the user's library.
   */
  sharedByRecipeId?: RecipeId;

  source: RecipeSource;

  /**
   * The deglaze recipe ID that corresponds to the source.
   */
  sourceRecipeId?: RecipeId;

  sourceRecipeVersion?: EpochMs;

  /**
   * Means that a new version of the source recipe with *meaningful* changes
   * (ingredients/instructions), is available.
   */
  newVersionAvailable?: EpochMs;

  /**
   * If a user has edited/cooked a recipe, we won't automatically update the
   * ingredients/instruction when the source recipe changes.
   * However, we *will* update the metadata. This field tracks the latest
   * version of the source recipe metadata and is primarily useful for
   * debugging.
   */
  sourceRecipeMetaVersion?: EpochMs;

  stats: UserRecipeStats;

  /**
   * Archived recipes can optionally be shown and can be un-archived.
   */
  archived?: boolean;

  /**
   * Deleted recipes are never shown in the UI and are not recoverable.
   * This should only be used for cases where the system is taking action such as replacing
   * a recipe with one with a different ID.
   */
  deleted?: boolean;

  /**
   * For recipes requiring processing, such as URL recipes, this will be set to indicate if processing
   * is still ongoing, if it has completed successfully, or if it has failed.
   */
  status?: UserRecipeStatus;
}

/**
 * Since user recipes are user-speific, access checked is the "default".
 */
export type AppUserRecipe = UserRecipeBase<true> &
  RestrictableInstructions & {
    /**
     * Full-access
     */
    fa?: boolean;

    /**
     * Whether or not this is a quality recipe. This is only set when the recipe is initally added
     * (meaning it's set on the AppRecipe object that is returned from the save call), but
     * only if it meets our subjective quality definition. If it's true, we send an analytics
     * event so we can optimize on it.
     */
    qr?: boolean;
  };

/**
 * This is used on the server in cases where we want full details.
 */
export interface UserRecipe extends UserRecipeBase<false>, IndexableRecipe {
  /**
   * If the source recipe of a recipe changes because the previous source recipe got orphaned, then this will be
   * set so we have a record of what happened. An external URL recipe is orphaned when we determine that it's URL
   * should be associated with a different recipe. The most common example of this is a recipe associated with an instagram
   * link and we determine the recipe associated with the instagram link has a better URL (like the link in the bio)
   * and we associate the instagram URL with the recipe associated with the link in bio.
   */
  previousSourceRecipe?: {
    sourceRecipeId: RecipeId;
    sourceRecipeVersion?: EpochMs;
    sourceRecipeMetaVersion?: EpochMs;
    canonicalUrl?: UrlString;
    userRecipeVersion: EpochMs;
  };

  faInfo?: UserRecipeFullAccessInfo;
}

/**
 * See UserRecipeFullAccess for logic involved in setting this
 */
export type UserRecipeFullAccessInfo =
  | UserRecipeLegacyFullAccessInfo
  | UserRecipeAdminRecipeFullAccessInfo
  | UserRecipeAdminUserFullAccessInfo
  | UserRecipeSourceRecipeFullAccessInfo;

export interface UserRecipeLegacyFullAccessInfo {
  // note: this is set in RecipeConverters for any user recipe created before a cuttoff date
  // and which does not already have persisted faInfo.
  type: "legacy";
}

export interface UserRecipeSourceRecipeFullAccessInfo {
  // derived from the source recipe
  type: "sourceRecipe";
}

export interface UserRecipeAdminRecipeFullAccessInfo {
  // set on the recipe level. This overrides everythign else since it can't be derived.
  type: "adminRecipe";
  admin: string;
  grantedAt: EpochMs;
}

export interface UserRecipeAdminUserFullAccessInfo {
  // set on the user level and subsequently set on each user recipe
  type: "adminUser";
}

export interface UserRecipes {
  version: EpochMs;
  recipesFor: UserOrHouseholdId;
  recipes: AppUserRecipe[];
}

export interface RecipeVersion {
  recipeId: UserRecipeId;
  version: EpochMs;
  editedBy?: UserId;
  note?: string;
  recipe: EditableRecipeFields;
  adminEdit?: boolean;
  created: EpochMs;
}

export type AddEditRecipeArgs = Pick<
  AppUserRecipe,
  "id" | "description" | "ingredients" | "instructions" | "photo" | "time" | "title" | "recipeYield"
>;
export type AddRecipeArgs = Omit<AddEditRecipeArgs, "id"> & { id: PartialRecipeId };
export type EditRecipeArgs = AddEditRecipeArgs & { version: EpochMs };

export interface EditUserRecipeNoteArgs {
  recipeId: UserRecipeId;
  text: string;
  tsClient: EpochMs;
}

export interface EditUserRecipeTimeArgs {
  recipeId: UserRecipeId;
  time?: RecipeTime;
  version: EpochMs;
}

export interface EditUserRecipeRatingArgs {
  recipeId: UserRecipeId;
  version: EpochMs;
  rating: RecipeRating | null;
}

export interface AddRecipeFromUrlArgs {
  id: PartialRecipeId;
  url: UrlString;
  htmlSourceUpload?: "pending";
  via?: "shareExtension";
}

export interface StoreSourceHtmlArgs {
  url: UrlString;
  base64GzippedHtml: Base64Data;
  partialRecipeId: PartialRecipeId;
}

export interface RecipeViewedArgs {
  recipeId: UserRecipeId;
}

export interface RecipeCookedArgs {
  recipeId: UserRecipeId;
  cookingSessionId: string;
  startTime: EpochMs;
}

export interface GetUserRecipesByLastUpdatedArgs {
  /**
   * If specified, only updates since this version will be included
   */
  sinceVersion?: EpochMs;
}

export interface GetRecipeArgs {
  recipeId: RecipeId;
}

export interface GetUserRecipeArgs {
  recipeId: UserRecipeId;
}

export interface ArchiveRecipeArgs {
  recipeId: UserRecipeId;
}

export interface RecipesThatNeedFeedback {
  recipes: RecipeThatNeedsFeedback[];
}

export type RecipeThatNeedsFeedback = FirstCookFeedback;

export interface FirstCookFeedback {
  type: "firstCookFeedback";
  recipeId: UserRecipeId;
  activityId: RecipeActivityId;
}

export type PostCookSurvey = FirstCookSurvey;

export interface FirstCookSurvey {
  type: "firstCookSurvey";
  ts: EpochMs;
  wouldYouCookAgain: boolean;
}

export interface SubmitPostCookSurveyArgs {
  recipeId: UserRecipeId;
  activityId: RecipeActivityId;
  survey: PostCookSurvey;
}

export interface ShareRecipeArgs {
  recipeId: UserRecipeId;
  recipientIds: UserId[];
}

export interface SaveSharedRecipeArgs {
  /**
   * The recipe to copy. This can be the sourceRecipeId of a user recipe, or the user recipe ID.
   * In the latter case, the source recipe ID is retrieved from the user recipe, if there is one.
   */
  sourceRecipeId: RecipeId;
  sharedByUserId: UserId;
  sharedByRecipeId: RecipeId;
  partialRecipeId: PartialRecipeId;
}

export type RecipeSource = UrlSource | UserSource | BookSource;

export interface UrlSource {
  type: "url";
  /**
   * If the user added the recipe via a URL, this is the exact URL that was added.
   * If they saved the recipe from within the platform, this will be set to the canonical url
   * for the corresponding external recipe.
   */
  url: UrlString;

  /**
   * The canonical URL for the external recipe in sourceRecipeId. Note that this might *not*
   * be the canonical version of the url property above. For example, if a user added an instagram link
   * that we resovle to a blog, the url could be instagram.com/... and this could be https://myblog.com/...
   */
  canonicalUrl?: UrlString;
}

export interface UserSource {
  type: "user";
  userId: UserId;
  recipeId: UserRecipeId;
}

export interface BookSource {
  type: "book";
  bookId: BookId;
  recipeId: ExternalRecipeId;
}

export interface RecipeIngredients {
  sections: [RecipeIngredientSection, ...RecipeIngredientSection[]];
}

/**
 * Returns true if there is at least one section with one or more ingredients
 */
export function haveRecipeIngredients(ingredients: RecipeIngredients) {
  return ingredients.sections.reduce((a, b) => a + b.items.length, 0) > 0;
}

export interface RecipeIngredientSection {
  title?: string;
  id: RecipeSectionId;
  items: RecipeIngredient[];
}

export interface RecipeIngredient {
  id: RecipeIngredientId;
  text: string;
  shoppable?: ShoppableRecipeIngredient[];
}

export interface ShoppableRecipeIngredient {
  text: string;
  id: ShoppableRecipeIngredientId;

  // leave room for expansion here. Other possible types:
  // Omit - no sri for a recipe ingredient
  // OneOf - Sometimes recipes call for soemthing like A bunch of cilantro *or* parsley
  // etc.
  type: "simple";
  // explicitly calling out things that are entered manually so we know what
  // not to muck with when we start auto-generating things
  source: "manualEntry";
}

export function shouldOmitRecipeIngredientFromList(ri: RecipeIngredient) {
  return ri.shoppable?.[0]?.text.toLowerCase().trim() === "omit";
}

export interface RecipeInstructions {
  sections: RecipeInstructionSection[];
}

/**
 * Returns true if there is at least one section with one or more instructions
 */
export function haveRecipeInstructions(instructions: RecipeInstructions) {
  return instructions.sections.reduce((a, b) => a + b.items.length, 0) > 0;
}

export interface RecipeInstructionSection {
  id: RecipeSectionId;
  title?: string;
  items: RecipeInstruction[];
}

export interface RecipeInstruction {
  id: RecipeInstructionId;
  text: string;
}

export type RecipesNotificationPushTypes = PushRecipeShared | PushRecipeUpdated;
export type RecipesWebsocketPushTypes = PushRecipesUpdated;
export type PushRecipesUpdated = DataAndType<"recipes/recipesUpdated", RecipesUpdatedData>;
export type PushRecipeUpdated = DataAndType<"recipes/recipeUpdated", RecipeUpdatedData>;
export type PushRecipeShared = DataAndType<"recipes/recipeShared", RecipeSharedData>;

export interface RecipesUpdatedData {
  updatedBy: UserId;
  recipes: AppUserRecipe[];
}

export interface RecipeUpdatedData {
  userRecipeId: UserRecipeId;
}

export interface RecipeSharedData {
  sharedBy: UserId;
  sourceRecipeId: RecipeId;
  sharedByRecipeId: UserRecipeId;
}

export const deletableExternalRecipeFields = ["time"] as const satisfies Readonly<Array<keyof RecipeBase<any>>>;
export type DeletableExternalRecipeField = (typeof deletableExternalRecipeFields)[number];

export const updateableExternalUrlRecipeFields = [
  "author",
  "canonicalUrl",
  "description",
  "ingredients",
  "instructions",
  "photo",
  "publisher",
  "time",
  "title",
  "recipeYield",

  // These aren't expected to be updated by a human, but need to be updateable
  "parsedAuthor",
  "rawTime",
  "rawRecipeYield",
  "datePublished",
  "dateModified",
] as const satisfies Readonly<Array<keyof ExternalUrlRecipe>>;

export type UpdateableExternalUrlRecipeField = (typeof updateableExternalUrlRecipeFields)[number];

export type ExternalUrlRecipeUpdate = {
  [key in UpdateableExternalUrlRecipeField]?: ExternalUrlRecipe[key];
};

export type ExternalRecipeUpdateType =
  | { type: "manual"; updatedBy: string }
  | { type: "parsed"; parsedFromS3?: string }
  | { type: "ai"; parsedFromS3?: string };

export interface EditExternalUrlRecipeArgs {
  id: ExternalRecipeId;
  updateType: ExternalRecipeUpdateType;
  status?: ExternalUrlRecipeStatus;
  version: EpochMs;
  tagUpdate?: RecipeTagUpdate;
  fieldsToDelete?: DeletableExternalRecipeField[];
  updates: ExternalUrlRecipeUpdate;
  /**
   * If true, any ingestion messages propagated will be on the low pri queue. Currently
   * only used for crawling recipes to make sure we don't add significant delays to user recipe
   * processing.
   */
  lowPriority?: boolean;

  /**
   * If true, parsed/ai changes can overwrite manual changes. This should not be used in normal operations and is intended
   * for admin scripts to update data (this was specifically added to allow known authors to be set if an admin set the name manually).
   */
  allowOverwriteOfManuallyUpdatedFields?: boolean;
}

export interface EditExternalBookRecipeArgs {
  id: ExternalRecipeId;
  updateType: ExternalRecipeUpdateType;
  version: EpochMs;
  tagUpdate?: RecipeTagUpdate;
  fieldsToDelete?: DeletableExternalRecipeField[];
  updates: Partial<
    Pick<
      ExternalBookRecipe,
      "author" | "book" | "description" | "ingredients" | "instructions" | "photo" | "title" | "time" | "recipeYield"
    >
  >;
}

/**
 * A representation of a URL recipe external to Deglaze. This allows us to
 * update the recipe and propagate updates.
 */
export interface ExternalUrlRecipe extends RecipeBase<false>, IndexableRecipe, RestrictedRecipe {
  type: "externalUrlRecipe";
  id: ExternalRecipeId;
  canonicalUrl: UrlString;
  otherUrls?: UrlNoProtocolString[];
  manuallyUpdatedFields: UpdateableExternalUrlRecipeField[];
  manuallyUpdatedBy?: string;
  aiUpdatedFields: UpdateableExternalUrlRecipeField[];
  parsedFromS3?: string;
  source: UrlSource;
  status: ExternalUrlRecipeStatus;
  book?: never;
  tags: SystemRecipeTagAndMetadata[];

  /**
   * The date the recipe was published, based on html metadata
   */
  datePublished?: EpochMs;

  /**
   * The date the recipe was modified on the publisher site, based on html metadata
   */
  dateModified?: EpochMs;

  ingestionSource?: "user" | "admin" | "crawl";

  rawTime?: RawRecipeTime;
  rawRecipeYield?: RawRecipeYield;

  /**
   * The author data we parse. This should be set when we add a known author if we
   * have parsed author info.
   */
  parsedAuthor?: { name: string; url?: UrlString };

  // By adding this from the start, we can easily add numeric stats and incrementing them without a migration
  stats: {};

  /**
   * An external URL recipe is orphaned if we find a "better" URL to describe a recipe. The best example of this
   * is a recipe posted to Instagram that has the full recipe as a link in the comments. If the user ingests
   * the instagram recipe, we create an external url recipe for the canonical instagram URL. If an admin (or eventually code)
   * then determines that there is a better link, we set this field to the new external URL and also update the URL lookup
   * for this recipe's canonical URL to point to the orphanedBy recipeId.
   */
  orphanedBy?: ExternalRecipeId;
}

export interface ExternalBookRecipe extends RecipeBase<false>, IndexableRecipe, RestrictedRecipe {
  type: "externalBookRecipe";
  id: ExternalRecipeId;
  book: Book;
  publisher?: never;
  source: BookSource;
  tags: SystemRecipeTagAndMetadata[];
  stats: {};
}

export interface CreateExternalBookRecipeArgs
  extends Omit<
    ExternalBookRecipe,
    "id" | "version" | "created" | "type" | "source" | "publisher" | "stats" | "tags" | "ac"
  > {
  id: PartialRecipeId;
  tags?: SystemRecipeTagAndMetadata[];
}

export interface CreateExternalBookRecipeResult {
  id: ExternalRecipeId;
}

/**
 * adminEditedBeforeProcessing means an admin has edited the recipe before it was processed (created || queuedForProcessing).
 * If the recipe is subsequently processed, it should end up in recipeParsed or recipeNotParsed
 * This status was added so that we continue with processing even if it's been admin edited so that
 * we get any fields that come from parsing that the admin did not set (author, publisher, yield, etc.)
 */
export type ExternalUrlRecipeStatus =
  | "created" // recipes are initially created with this status
  | "queuedForProcessing" // used to ensure that a message is dropped
  | "recipeNotParsed" // this means initial processing did not find a recipe
  // All recipes should end up in one of these states
  | "recipeParsed" // we have at least ingredients
  | "noRecipe"; // we have no recipe after all automatic processing

// deprecated. There should be zero recipes with this status in prod, but any switch statements
// dealing with this have been left in place with ts-ignore
//| "adminEditedBeforeProcessing"

export interface Book extends EntityDisplay {
  id: BookId;
  purchaseLink?: UrlString;
}

export interface RecipesScript {
  script: string;
}

export interface KnownEntities {
  entities: Array<KnownAuthor | KnownPublisher>;
}
