import {
  bottomLog,
  bottomThrow,
  bottomWithDefault,
  daysBetween,
  defaultTimeProvider,
  DurationMs,
  Email,
  emptyToUndefined,
  getDurationInMinutes,
  secondsBetween,
  SocialMediaDomainKey,
  switchReturn,
  UrlString,
  UserId,
  Username,
} from "@eatbetter/common-shared";
import {
  AnalyticsEventAndProperties,
  AnalyticsEventPropertyMap,
  AnalyticsSuperPropertyMap,
  AnalyticsUserPropertyMap,
  CtaLocation,
  getMixpanelTimestamp,
  PaywallDetectionOutcome,
  PaywallLocation,
  ReportIssueData,
  ReviewPromptThresholds,
} from "@eatbetter/composite-shared";
import {
  AuthedUserAndSettings,
  DeglazeUser,
  NotificationId,
  SignInMethod,
  UserCheckpoint,
} from "@eatbetter/users-shared";
import {
  EditUserRecipeTagArgs,
  AppRecipeBase,
  RecipeId,
  RecipeTime,
  AppUserRecipe,
  AppRecipe,
  RecipeRating,
  getRatingString,
  RecipeInfo,
  KnownAuthorId,
  KnownPublisherId,
  KnownAuthor,
  KnownPublisher,
} from "@eatbetter/recipes-shared";
import { log } from "../../Log";
import {
  GroceryListId,
  GroceryListItem,
  GroceryListItemId,
  GroceryListSort,
  GroceryListSuggestion,
} from "@eatbetter/lists-shared";
import { StandardCategory, StandardPrimaryCategory } from "@eatbetter/items-shared";
import { CookingSession } from "@eatbetter/cooking-shared";
import { CookingSessionState } from "../cooking/CookingSessionsSlice";
import { getSocialEntityId, isDeglazeUser, SocialEntity, SocialEntityId, SocialPost } from "@eatbetter/posts-shared";
import { SocialFeedType } from "../social/SocialSlice";
import { AppRecipeTag, RecipeFilters } from "../recipes/RecipesSlice";
import { RecipeTagManifest } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { AnonymousRegistrationScreenProps, RecipeEditFieldLocation } from "../../navigation/NavTree";
import { NotificationContext } from "../NotificationHandlers";
import { AppOnboardingQuestions, UserAuthStatus } from "../system/SystemSlice";
import { PhotoRef } from "@eatbetter/photos-shared";
import { Platform } from "react-native";
import { NotificationPermissionProps } from "../../components/recipes/NotificationPermission";
import { InternalWebPage } from "../util/WebUtil";
import { SearchSessionId, SearchSessionState } from "../search/SearchSlice";

// NOTE - these functions implementations are wrapped in try/catch to ensure
// the analytics code does not cause a user-visible error.
/* TEMPLATE
export function TODO(event: {}): AnalyticsEventAndProperties {
  try {

    return {};
  } catch(err) {
    log.errorCaught("Error in TODO", err, { event });
    return {};
  }
}
 */

export function reportUnexpectedErrorDisplayed(args: {
  err: any;
  appBoundaryErrorStack?: string | null;
  errorId: string;
  context: "errorBoundary" | "alert";
  description: string;
  displayMessage?: string;
}): AnalyticsEventAndProperties {
  try {
    const stack = args.appBoundaryErrorStack
      ? args.appBoundaryErrorStack
      : args.err instanceof Error
      ? args.err.stack
      : undefined;
    return {
      events: [
        {
          name: "Unexpected Error Displayed",
          properties: {
            "Error Display Context": args.context,
            "Error ID": args.errorId,
            "Error Stack": truncateStack(stack),
            "Error Message": args.err instanceof Error ? args.err.message : undefined,
            "Error Description": args.description,
            "Error Display Message": args.displayMessage,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportUnexpectedErrorDisplayed", err, { args });
    return {};
  }
}

export function reportLaunchCarouselStarted(): AnalyticsEventAndProperties {
  return simpleEvent("Launch Carousel Displayed");
}

export function reportLaunchCarouselCompleted(args: {
  durations?: DurationMs[];
  backActions?: number;
}): AnalyticsEventAndProperties {
  try {
    const properties: Partial<AnalyticsEventPropertyMap> = {};
    properties["Launch Carousel Back Actions"] = args.backActions;

    if (args.durations) {
      const sum = args.durations.reduce((a, b) => (a + b) as DurationMs);
      properties["Launch Carousel Sum Duration Seconds"] = sum / 1000;
      const key: keyof AnalyticsEventPropertyMap = "Launch Carousel Slide <N> Duration Seconds";
      args.durations.forEach((d, idx) => {
        const keyWithIndex = key.replace("<N>", (idx + 1).toString());
        //@ts-ignore-next-line
        properties[keyWithIndex] = d / 1000;
      });
    }

    return {
      events: [
        {
          name: "Launch Carousel Completed",
          properties,
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportLaunchCarouselCompleted", err, { args });
    return {};
  }
}

export function reportScreenView(args: {
  screenName: string;
  instanceActiveTime?: DurationMs;
  totalActiveTime?: DurationMs;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: `Screen Viewed: ${args.screenName}`,
          properties: {
            "Screen Duration Instance Active Seconds": args.instanceActiveTime
              ? args.instanceActiveTime / 1000
              : undefined,
            "Screen Duration Total Active Seconds": args.totalActiveTime ? args.totalActiveTime / 1000 : undefined,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportScreenView", err, { args });
    return {};
  }
}

export function reportOnboardingCompleted(args: {
  responses: AppOnboardingQuestions;
  entitiesFollowed: number;
  followingPostCount: number;
  notificationPermissionsGranted: boolean;
}): AnalyticsEventAndProperties {
  try {
    const r = args.responses;
    return {
      events: [
        {
          name: "Onboarding Completed",
          properties: {
            "Onboarding Overall Duration Seconds": r.startTime
              ? (defaultTimeProvider() - r.startTime) / 1000
              : undefined,
            "Onboarding Welcome Screen Duration Seconds": r.welcomeActiveTime ? r.welcomeActiveTime / 1000 : undefined,
            "Onboarding Ingestion Sources": r.ingestionSources,
            "Onboarding Want Organization": r.organize,
            "Onboarding Entities Followed": args.entitiesFollowed,
            "Onboarding Try Groceries": r.tryGroceries,
            "Onboarding Have Household": r.household,
            "Onboarding Want Notifications": r.notificationsPromptResponse,
            "Onboarding Notification Permissions Granted": args.notificationPermissionsGranted,
            "Onboarding Initial Following Post Count": args.followingPostCount,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportOnboardingCompleted", err, { args });
    return {};
  }
}

export function reportUrlRecipeOpenedExternally(args: {
  url: UrlString;
  paywallStatus: PaywallDetectionOutcome;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Recipe Opened in External Browser",
          properties: {
            "Recipe URL": args.url,
            "Recipe Paywall Status": args.paywallStatus,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportUrlRecipeOpenedExternally", err, { args });
    return {};
  }
}

export function reportIssueReported(args: ReportIssueData): AnalyticsEventAndProperties {
  try {
    switch (args.type) {
      case "recipeIssue": {
        return {
          events: [
            {
              name: "User Reported Issue",
              properties: {
                "Reported Issue Type": args.type,
                "Reported Issue ID": args.recipeId,
                "Reported Issue Description": `${args.issue} ${args.otherText}`,
              },
            },
          ],
        };
      }
      case "requestedEntityPage": {
        return {
          events: [
            {
              name: "User Requested Entity Page",
              properties: {
                "Reported Issue Type": args.type,
                "Reported Issue Description": `${args.name}`,
              },
            },
          ],
        };
      }
      case "userFeedbackSubmitted": {
        return {
          events: [
            {
              name: "User Feedback Submitted",
              properties: {
                "Reported Issue Type": args.type,
                "Reported Issue Description": `${args.userFeedbackText}`,
              },
            },
          ],
        };
      }
      default:
        bottomThrow(args);
    }
  } catch (err) {
    log.errorCaught("Error in reportIssueReported", err, { args });
    return {};
  }
}

export function reportGetAppButtonPressed(args: { platform: "ios" | "android" }): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "Get App Button Pressed", properties: { "Get App Platform": args.platform } }],
    };
  } catch (err) {
    log.errorCaught("Error in reportGetAppButtonPressed", err, { args });
    return {};
  }
}

export function reportAppLaunched(): AnalyticsEventAndProperties {
  try {
    const prefix = Platform.OS === "web" ? "Web " : "";
    return { events: [{ name: `${prefix}App Launched` }] };
  } catch (err) {
    log.errorCaught("Error in reportAppLaunched", err, {});
    return {};
  }
}

export function reportAuthStatusChanged(authStatus: UserAuthStatus): AnalyticsEventAndProperties {
  try {
    let status: AnalyticsSuperPropertyMap["User Auth"] | undefined;
    switch (authStatus) {
      case "pending":
        break;
      case "signedIn":
        status = "Signed in";
        break;
      case "signedInNoAccount":
        status = "Signed in (no account)";
        break;
      case "signedOut":
      case "signingOut":
        status = "Signed out";
        break;
      default:
        bottomThrow(authStatus);
    }

    if (status) {
      return {
        superProperties: {
          "User Auth": status,
        },
      };
    }
  } catch (err) {
    log.errorCaught("Error in reportAuthStatusChanged", err, {});
  }

  return {};
}

export function reportUserSignedIn(event: {
  signInMethod: SignInMethod;
  email?: Email;
  anonymousAccountLinked?: boolean;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "User Signed In",
          properties: {
            "Sign-in Method": event.signInMethod,
            "Sign-in Email": event.email,
            "Anonymous Account Linked": event.anonymousAccountLinked,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportUserSignedIn", err, { event });
    return {};
  }
}

export function reportUserSignedOut(): AnalyticsEventAndProperties {
  return simpleEvent("User signed out");
}

export function reportAnonymousAccountCreated(): AnalyticsEventAndProperties {
  return simpleEvent("Anonymous Account Created");
}

export function reportAnonymousRegistrationScreenDisplayed(
  context: AnonymousRegistrationScreenProps["mode"],
  previousCount: number | undefined
): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Registration Screen Displayed",
          properties: {
            "Anonymous Prompt Count": previousCount !== undefined ? (previousCount ?? 0) + 1 : undefined,
            "Anonymous Prompt Context": context,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportAnonymousRegistrationScreenDisplayed", err);
    return {};
  }
}

export function reportUserAccountCreated(
  wasAnonymous: boolean,
  nameFromAuthProvider?: string
): AnalyticsEventAndProperties {
  try {
    const name = "User Created Account";
    return {
      events: [{ name, properties: { "User was Anonymous": wasAnonymous } }],
      // https://support.appsflyer.com/hc/en-us/articles/4410481112081#predefined-event-names
      // Also using a non-standard event to give us options with partners. For example, Meta says optimization
      // is better for app promotion with custom events.
      attributionEvents: [{ name }],
      setProperties: {
        "User was Anonymous": wasAnonymous,
        "Name from Auth Provider": nameFromAuthProvider,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportUserAccountCreated", err, { nameFromAuthProvider });
    return {};
  }
}

export function reportUserAccountDeleted(): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "User Deleted Account" }],
      setProperties: { "User Deleted": true, "User Deleted Reason": "Deleted by User" },
    };
  } catch (err) {
    log.errorCaught("Error in reportUserAccountDeleted", err);
    return {};
  }
}

export function reportDeletedUserAttemptedSignIn(): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "Deleted User Attempted Sign-In" }],
    };
  } catch (err) {
    log.errorCaught("Error in reportDeletedUserAttemptedSignIn", err);
    return {};
  }
}

export function reportUserCanceledCreatingAccount(): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "User Canceled Creating Account" }],
    };
  } catch (err) {
    log.errorCaught("Error in reportUserCanceledCreatingAccount", err);
    return {};
  }
}

export function reportUsernameUpdated(args: { old: string; updated: string }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Profile Username Updated",
          properties: {
            "Profile Username New": args.updated,
            "Profile Username Original": args.old,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportUsernameUpdated", err, { args });
    return {};
  }
}

export function reportNameUpdated(args: { old: string; updated: string }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Profile Name Updated",
          properties: {
            "Profile Name New": args.updated,
            "Profile Name Original": args.old,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportNameUpdated", err, { args });
    return {};
  }
}

export function reportBioUpdated(args: { old: string; updated: string }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Profile Bio Updated",
          properties: {
            "Profile Bio New": args.updated,
            "Profile Bio Original": args.old,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportBioUpdated", err, { args });
    return {};
  }
}

export function reportProfileLinkUpdated(args: { old: string; updated: string }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Profile Link Updated",
          properties: {
            "Profile Link New": args.updated,
            "Profile Link Original": args.old,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportProfileLinkUpdated", err, { args });
    return {};
  }
}

export function reportProfilePhotoUpdated(args: {
  old: PhotoRef | undefined;
  updated: PhotoRef | null;
}): AnalyticsEventAndProperties {
  try {
    const getValue = (photo: PhotoRef | null | undefined) => {
      if (!photo) return "<none>";
      if (photo.type === "internal") return photo.id;
      if (photo.type === "external") return photo.url;
      throw new Error(`Unexpected value for profile photo ref: ${photo}`);
    };

    return {
      events: [
        {
          name: "Profile Photo Updated",
          properties: {
            "Profile Photo New": getValue(args.updated),
            "Profile Photo Original": getValue(args.old),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportProfilePhotoUpdated", err, { args });
    return {};
  }
}

export type ProfileLinkTappedContext =
  | "ownProfile"
  | "otherUserProfile"
  | "knownUserAuthorProfile"
  | "knownAuthorPage"
  | "knownPublisherPage";
export function reportProfileLinkTapped(args: {
  link: UrlString;
  index: number;
  context: ProfileLinkTappedContext;
  userId?: UserId;
  entityId?: KnownAuthorId | KnownPublisherId;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Profile Link Tapped",
          properties: {
            "Profile ID": args.userId,
            "Known Entity ID": args.entityId,
            "Profile Link": args.link,
            "Profile Link Index": args.index,
            "Profile Viewed Context": switchReturn(args.context, {
              ownProfile: "Own Profile",
              otherUserProfile: "Other User Profile",
              knownUserAuthorProfile: "Known User Author Profile",
              knownAuthorPage: "Known Author Page",
              knownPublisherPage: "Known Publisher Page",
            }),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportProfileLinkTapped", err, { args });
    return {};
  }
}

export function reportOtherUserProfileViewed(args: { user: DeglazeUser }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Other User Profile Viewed",
          properties: {
            "Profile ID": args.user.userId,
            "Profile Username": args.user.username,
            "Profile Name": args.user.name,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportOtherUserProfileViewed", err, { args });
    return {};
  }
}

// We can get more granular here as we learn what we need, but this should be a good starting point
export type KnownEntityPageViewedFrom =
  | "search"
  | "recipeDetail"
  | "recommendedFollows"
  | "profileFollowersFollowing"
  | "postLikes"
  | "postComments"
  | "socialPostHeading"
  | "recipeTitleAndSource"
  | "knownUserAuthorRedirect"
  | "userSearch"
  | "browseAllEntities";

export function reportKnownEntityPageViewed(args: {
  entity: KnownAuthor | KnownPublisher;
  from: KnownEntityPageViewedFrom;
}): AnalyticsEventAndProperties {
  try {
    const authorOrPublisher = switchReturn(args.entity.type, {
      knownAuthor: "Author",
      knownPublisher: "Publisher",
    });

    return {
      events: [
        {
          name: `Known ${authorOrPublisher} Page Viewed`,
          properties: {
            ...getKnownEntityProperties(args.entity),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportKnownEntityPageViewed", err, { args });
    return {};
  }
}

export type ReportUsersSearchedContext = "postComment" | "shareRecipe" | "searchUsers" | "newPost";

export function reportUsersSearched(args: {
  query: string;
  context: ReportUsersSearchedContext;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Users Searched",
          properties: {
            "User Search Context": args.context,
            "User Search Query": args.query,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportUsersSearched", err, { args });
    return {};
  }
}

export type BrowseAllEntitiesViewedFrom =
  | "searchQuerySuggestions"
  | "searchEntitiesScreen"
  | "onboardingCollections"
  | "followingFeedEmptyState"
  | "followingFeedInlined";

export function reportBrowseAllEntitiesViewed(args: {
  initialSearchQuery?: string;
  from: BrowseAllEntitiesViewedFrom;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Browse Known Entities Screen Viewed",
          properties: {
            "Browse Known Entities Viewed From": args.from,
            "Browse Known Entities Initial Search Query": args.initialSearchQuery,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportBrowseAllEntitiesViewed", err, { args });
    return {};
  }
}

export function reportKnownEntitiesSearched(args: { searchQuery: string }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Known Entities Searched",
          properties: {
            "Known Entities Search Query": args.searchQuery,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportKnownEntitiesSearched", err, { args });
    return {};
  }
}

function truncateStack(stack?: string): string[] {
  let size = 0;
  const truncatedStack: string[] = [];
  for (const line of stack?.split("\n") ?? []) {
    const truncated = line.substring(0, 255);
    truncatedStack.push(truncated);
    size += truncated.length;
    // leave an extra kb buffer
    if (size >= 7 * 1024) {
      break;
    }
  }

  return truncatedStack;
}

export function reportFatalJavascriptError(event: { message: string; stack?: string }): AnalyticsEventAndProperties {
  try {
    // https://help.mixpanel.com/hc/en-us/articles/115004547063-Properties-Supported-Data-Types
    // string properties are limited to 255 bytes
    // arrays are limited to 8kb total, including the json characters, I think
    const { message, stack } = event;

    return {
      events: [
        {
          name: "Fatal Javacript Error",
          properties: {
            "Error Message": message.substring(0, 254),
            "Error Stack": truncateStack(stack),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportFatalJavascriptError", err, {});
    return {};
  }
}

export type ExternalLinkLocation = "postComment" | "postText";
export function reportExternalLinkOpened(args: {
  url: string;
  location: ExternalLinkLocation;
}): AnalyticsEventAndProperties {
  try {
    const location = switchReturn<ExternalLinkLocation, AnalyticsEventPropertyMap["External Link Location"]>(
      args.location,
      {
        postComment: "Social Post Comment",
        postText: "Social Post Text",
      }
    );

    return {
      events: [
        {
          name: "External Link Opened",
          properties: {
            "External Link URL": args.url,
            "External Link Location": location,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportExternalLinkOpened", err, { args });
    return {};
  }
}

export function reportNotificationsViewed(event: { unreadCount: number }): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "Notifications Viewed", properties: { "Notification Unread Count": event.unreadCount } }],
    };
  } catch (err) {
    log.errorCaught("Error in notificationsViewed", err, { event });
    return {};
  }
}

export function reportFeedScrolled(event: { indexReached: number; feed: SocialFeedType }): AnalyticsEventAndProperties {
  try {
    const feedType = switchReturn(event.feed.type, {
      followingFeed: "Following Feed",
      exploreFeed: "Explore Feed",
      profileFeed: "Profile Feed (self)",
      otherUserProfileFeed: "Profile Feed (other)",
    });

    return {
      events: [
        {
          name: `${feedType} Scrolled to Index ${event.indexReached}`,
          properties: {
            "Social Feed Scrolled Event": true,
            "Social Feed Scrolled Index": event.indexReached,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportFeedScrolled", err, { event });
    return {};
  }
}

export function reportJoinHouseholdLinkCreated(): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Join Household Link Created",
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportJoinHouseholdLinkCreated", err);
    return {};
  }
}

export function reportAppShareLinkCreated(
  from: AnalyticsEventPropertyMap["App Share Link Created From"]
): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "App Share Link Created",
          properties: {
            "App Share Link Created From": from,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportAppShareLinkCreated", err);
    return {};
  }
}

export function reportFindUsersButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Find Users Button Pressed");
}

export type SearchQueryStartedFrom = "home" | "knownAuthorProfile" | "knownPublisherProfile";

function getSearchQueryStartedFrom(from: SearchQueryStartedFrom) {
  switch (from) {
    case "home": {
      return "Home";
    }
    case "knownAuthorProfile": {
      return "Known Author Profile";
    }
    case "knownPublisherProfile": {
      return "Known Publisher Profile";
    }
    default:
      bottomThrow(from);
  }
}

export function reportSearchQueryStarted(args: {
  from: SearchQueryStartedFrom;
  sessionId: SearchSessionId;
  knownEntity?: KnownAuthor | KnownPublisher;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Query Screen Opened",
          properties: {
            ...getKnownEntityProperties(args.knownEntity),
            "Search Query Started From": getSearchQueryStartedFrom(args.from),
            "Search Session ID": args.sessionId,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchQueryStarted", err, { args });
    return {};
  }
}

export function reportSearchQuerySubmitted(args: {
  sessionId: SearchSessionId;
  searchSession: SearchSessionState;
  manifest: RecipeTagManifest;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Query Submitted",
          properties: getSearchSessionProperties({
            sessionId: args.sessionId,
            searchSession: args.searchSession,
            manifest: args.manifest,
          }),
        },
      ],
      incrementProperties: {
        "Searches Performed": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchQuerySubmitted", err, { args });
    return {};
  }
}

export function reportSearchQuerySuggestionTapped(args: {
  sessionId: SearchSessionId;
  suggestionValue: string;
  suggestionIndex: number;
  suggestionType: string;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Query Suggestion Tapped",
          properties: {
            "Search Session ID": args.sessionId,
            "Search Query Suggestion Value": args.suggestionValue,
            "Search Query Suggestion Index": args.suggestionIndex,
            "Search Query Suggestion Type": args.suggestionType,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchQuerySuggestionTapped", err, { args });
    return {};
  }
}

export function reportSearchResultsViewed(event: {
  searchSessionId: SearchSessionId;
  searchSession: SearchSessionState;
  entityResultCount?: number;
  libraryRecipeResultCount?: number;
  serverRecipeResultCount?: number;
  manifest: RecipeTagManifest;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Results Viewed",
          properties: {
            ...getSearchSessionProperties({
              sessionId: event.searchSessionId,
              searchSession: event.searchSession,
              manifest: event.manifest,
            }),
            "Search Entity Results Count": event.entityResultCount,
            "Search Library Results Count": event.libraryRecipeResultCount,
            "Search Server Recipe Results Count": event.serverRecipeResultCount,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchResultsViewed", err, { event });
    return {};
  }
}

export function reportSearchResultsScrolled(event: {
  indexReached: number;
  searchSessionId: SearchSessionId;
  searchSession: SearchSessionState;
  manifest: RecipeTagManifest;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: `Search Results Scrolled to Index ${event.indexReached}`,
          properties: {
            ...getSearchSessionProperties({
              sessionId: event.searchSessionId,
              searchSession: event.searchSession,
              manifest: event.manifest,
            }),
            "Search Results Scrolled Event": true,
            "Search Results Scrolled Index": event.indexReached,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchResultsScrolled", err, { event });
    return {};
  }
}

export function reportSearchCancelButtonTapped(args: { sessionId: SearchSessionId }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Cancel Button Tapped",
          properties: {
            "Search Session ID": args.sessionId,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchCancelButtonTapped", err, { args });
    return {};
  }
}

export function reportSearchTagFilterUpdated(args: {
  type: "added" | "removed";
  sessionId: SearchSessionId;
  tag: AppRecipeTag;
  tagManifest: RecipeTagManifest;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: `Search Tag Filter ${switchReturn(args.type, {
            added: "Added",
            removed: "Removed",
          })}`,
          properties: {
            ...getRecipeFilterProperties(
              {
                tags: args.tag.type !== "totalTime" ? [args.tag] : undefined,
                time: args.tag.type === "totalTime" ? [args.tag] : undefined,
              },
              args.tagManifest,
              "search"
            ),
            "Search Session ID": args.sessionId,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchTagFilterUpdated", err, { args });
    return {};
  }
}

export function reportSearchRecentQueryDeleted(args: {
  suggestionText: string;
  suggestionIndex: number;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Recent Query Deleted",
          properties: {
            "Search Query Suggestion Value": args.suggestionText,
            "Search Query Suggestion Index": args.suggestionIndex,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchRecentQueryDeleted", err, { args });
    return {};
  }
}

export function reportSearchSuggestionUpLeftArrowTapped(args: {
  suggestionText: string;
  suggestionIndex: number;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Suggestion Up Left Arrow Tapped",
          properties: {
            "Search Query Suggestion Value": args.suggestionText,
            "Search Query Suggestion Index": args.suggestionIndex,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchSuggestionUpLeftArrowTapped", err, { args });
    return {};
  }
}

export function reportDeleteAllRecentQueriesTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Search Recent Queries Clear All Tapped");
}

export function reportSeeMoreRecentQueriesTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Search Recent Queries See More Tapped");
}

export function reportSearchAddFilterButtonPressed(args: {
  sessionId: SearchSessionId;
  searchSession: SearchSessionState;
  manifest: RecipeTagManifest;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Search Add Filter Button Pressed",
          properties: getSearchSessionProperties({
            sessionId: args.sessionId,
            searchSession: args.searchSession,
            manifest: args.manifest,
          }),
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSearchAddFilterButtonPressed", err, { args });
    return {};
  }
}

export function reportLibraryAddFilterButtonPressed() {
  return simpleEvent("Library Add Filter Button Pressed");
}

export function reportPostShareLinkCreated(event: {
  post: SocialPost;
  recipe?: RecipeInfo;
}): AnalyticsEventAndProperties {
  try {
    const recipeProperties = event.recipe ? getRecipeInfoProperties(event.recipe) : {};
    const postProperties = getSocialPostProperties(event.post);
    return {
      events: [
        {
          name: "Post Share Link Created",
          properties: {
            ...postProperties,
            ...recipeProperties,
          },
        },
      ],
      incrementProperties: {
        "Social Posts Shared": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeShareLinkCreated", err, { event });
    return {};
  }
}

export function reportRecipeShareLinkCreated(event: {
  recipe?: AppRecipe;
  webViewUrl?: UrlString;
}): AnalyticsEventAndProperties {
  try {
    const recipeProperties = event.recipe ? getRecipeProperties(event.recipe) : {};
    return {
      events: [
        {
          name: "Recipe Share Link Created",
          properties: {
            ...recipeProperties,
            "Shared Recipe Web View URL": event.webViewUrl,
          },
        },
      ],
      incrementProperties: {
        "Recipes Shared": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeShareLinkCreated", err, { event });
    return {};
  }
}

export function reportRecipeShared(event: {
  recipe?: AppUserRecipe;
  recipients: number;
  recipientUsernames: string[];
}): AnalyticsEventAndProperties {
  try {
    const recipeProperties = event.recipe ? getRecipeProperties(event.recipe) : {};
    return {
      events: [
        {
          name: "Recipe Shared",
          properties: {
            ...recipeProperties,
            "Share Recipients": event.recipients,
            "Share Recipient Usernames": event.recipientUsernames,
          },
        },
      ],
      incrementProperties: {
        "Recipes Shared": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeShared", err, { event });
    return {};
  }
}

export function reportOpenInSocialAppButtonPressed(event: {
  recipe?: AppRecipe;
  socialApp?: SocialMediaDomainKey;
}): AnalyticsEventAndProperties {
  try {
    const recipeProperties = event.recipe ? getRecipeProperties(event.recipe) : {};
    return {
      events: [
        {
          name: "Open in Social App Button Pressed",
          properties: {
            "Recipe Social Media Source": event.socialApp,
            ...recipeProperties,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportOpenInSocialAppButtonPressed", err, { event });
    return {};
  }
}

export function reportPostCreated(event: { post: SocialPost }): AnalyticsEventAndProperties {
  try {
    const properties = getSocialPostProperties(event.post);
    return {
      events: [{ name: "Social Post Created", properties }],
      incrementProperties: {
        "Social Posts Created": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportPostCreated", err, { event });
    return {};
  }
}

export function reportPostLiked(event: { post?: SocialPost }): AnalyticsEventAndProperties {
  try {
    const properties = event.post ? getSocialPostProperties(event.post) : {};
    const name = "Social Post Liked";
    return {
      events: [{ name, properties }],
      incrementProperties: {
        "Social Post Likes": 1,
      },
      attributionEvents: [{ name }],
    };
  } catch (err) {
    log.errorCaught("Error in reportPostLiked", err, { event });
    return {};
  }
}

export function reportPostLikesBarTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Social Post Likes Bar Tapped");
}

export function reportPostComment(event: { post?: SocialPost; comment: string }): AnalyticsEventAndProperties {
  try {
    const postProperties = event.post ? getSocialPostProperties(event.post) : {};
    const name = "Social Post Comment";
    return {
      events: [
        {
          name,
          properties: {
            ...postProperties,
            "Social Post Comment": event.comment,
          },
        },
      ],
      incrementProperties: {
        "Social Post Comments": 1,
      },
      attributionEvents: [{ name }],
    };
  } catch (err) {
    log.errorCaught("Error in reportPostComment", err, { event });
    return {};
  }
}

export function reportPostDeleted(event: { post?: SocialPost }): AnalyticsEventAndProperties {
  try {
    const properties = event.post ? getSocialPostProperties(event.post) : {};
    return {
      events: [{ name: "Social Post Deleted", properties }],
    };
  } catch (err) {
    log.errorCaught("Error in reportPostDeleted", err, { event });
    return {};
  }
}

export type AnalyticsFollowUnfollowContext =
  | "otherUserProfile"
  | "ownFollowing"
  | "ownFollowers"
  | "otherUserFollowing"
  | "otherUserFollowers"
  | "postLikes"
  | "newFollowerNotification"
  | "recommendedFollowsFeedTopSlot"
  | "recommendedFollowsFeedInlineSlot"
  | "searchResults"
  | "entitySearch";
export function reportFollowUnfollow(event: {
  followedEntityId: SocialEntityId;
  action: "follow" | "unfollow";
  context: AnalyticsFollowUnfollowContext;
  otherUser?: DeglazeUser;
}): AnalyticsEventAndProperties {
  try {
    const attributionEvents: AnalyticsEventAndProperties["attributionEvents"] =
      event.action === "follow" ? [{ name: "User Followed Entity" }] : [];
    return {
      events: [
        {
          name: event.action === "follow" ? "User Followed User" : "User Unfollowed User",
          properties: {
            "Followed User ID": event.followedEntityId,
            "Followed User Context": switchReturn(event.context, {
              ownFollowing: "Following (Own)",
              ownFollowers: "Followers (Own)",
              otherUserFollowing: "Following (Other User)",
              otherUserFollowers: "Followers (Other User)",
              otherUserProfile: "Profile (Other User)",
              postLikes: "Social Post Likes",
              newFollowerNotification: "Notification (New Follower)",
              recommendedFollowsFeedTopSlot: "Recommended Follows (Feed Top Slot)",
              recommendedFollowsFeedInlineSlot: "Recommended Follows (Feed Inline Slot)",
              searchResults: "Search Results",
              entitySearch: "Entity Search",
            }),
          },
        },
      ],
      incrementProperties:
        event.action === "follow"
          ? {
              "Followed Users": 1,
            }
          : undefined,
      attributionEvents,
    };
  } catch (err) {
    log.errorCaught("Error in reportFollowUnfollow", err, { event });
    return {};
  }
}

export function reportRecommendedPostsViewMoreButtonPressed(): AnalyticsEventAndProperties {
  return simpleEvent("Recommended Posts View More Button Pressed");
}

export function recommendedFollowsReceived(count: number): AnalyticsEventAndProperties {
  return {
    setProperties: {
      "Follow Recommendations": count,
    },
  };
}

export function reportFollowerCountsReceived(counts: {
  following: number;
  followers?: number;
}): AnalyticsEventAndProperties {
  // followers *should* always be set, but allowing for it not to be since it's optional on the data type, which is
  // used for both a user's own profile and other user's profiles, where follower count is not returned.
  const followers: Partial<AnalyticsUserPropertyMap> =
    counts.followers !== undefined ? { Followers: counts.followers } : {};

  return {
    setProperties: {
      // Renamed to Following in Mixpanel lexicon
      "Followed Users": counts.following,
      ...followers,
    },
  };
}

export function reportFollowCollectionsFeedModuleButtonPressed(
  ctaLocation: AnalyticsEventPropertyMap["CTA Location"]
): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Follow Collections Feed Module Button Pressed",
          properties: {
            "CTA Location": ctaLocation,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportFollowCollectionsFeedModuleButtonPressed", err, { event });
    return {};
  }
}

export function reportFindOrInviteFriendsButtonPressed(
  ctaLocation: AnalyticsEventPropertyMap["CTA Location"]
): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Find or Invite Friends Button Tapped",
          properties: {
            "CTA Location": ctaLocation,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportFindOrInviteFriendsButtonPressed", err, { event });
    return {};
  }
}

export function reportRecommendedFollowsDisplayed(event: {
  ctaLocation: CtaLocation;
  recommendationCount: number;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Recommended Follows Displayed",
          properties: {
            "Recommended Follows Count": event.recommendationCount,
            "CTA Location": event.ctaLocation,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecommendedFollowsDisplayed", err, { event });
    return {};
  }
}

export function reportRecommendedFollowTapped(args: { entity: SocialEntity }): AnalyticsEventAndProperties {
  try {
    const entityProperties: Partial<AnalyticsEventPropertyMap> = isDeglazeUser(args.entity)
      ? {
          "Recommended Follow Entity ID": args.entity.userId,
          "Recommended Follow Username": args.entity.username,
          "Recommended Follow Entity Name": args.entity.username,
        }
      : getKnownEntityProperties(args.entity);

    return {
      events: [
        {
          name: "Recommended Follow Entity Tapped",
          properties: entityProperties,
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecommendedFollowTapped", err, { event });
    return {};
  }
}

export function reportRecommendedFollowDismissed(event: {
  recommendedEntity: SocialEntity;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Recommended Follow Entity Dismissed",
          properties: {
            "Recommended Follow Entity ID": getSocialEntityId(event.recommendedEntity),
            "Recommended Follow Username": isDeglazeUser(event.recommendedEntity)
              ? event.recommendedEntity.username
              : undefined,
            "Recommended Follow Entity Name": event.recommendedEntity.name,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecommendedFollowDismissed", err, { event });
    return {};
  }
}

export function reportRecommendedFollowsDismissed(event: { recommendationCount: number }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Recommended Follows Dismissed",
          properties: {
            "Recommended Follows Count": event.recommendationCount,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecommendedFollowsDismissed", err, { event });
    return {};
  }
}

export function reportProfileFollowersButtonPressed(): AnalyticsEventAndProperties {
  return simpleEvent("Profile Followers Button Pressed");
}

export function reportProfileInviteFriendsButtonPressed(): AnalyticsEventAndProperties {
  return simpleEvent("Profile Invite Friends Button Pressed");
}

export function reportProfileRecipeCountPressed(
  context: "ownProfile" | "otherUserProfile"
): AnalyticsEventAndProperties {
  return simpleEvent("Profile Recipe Count Pressed" + (context === "ownProfile" ? "" : " (Other User)"));
}

export function reportProfileCookedCountPressed(
  context: "ownProfile" | "otherUserProfile"
): AnalyticsEventAndProperties {
  return simpleEvent("Profile Cooked Count Pressed" + (context === "ownProfile" ? "" : " (Other User)"));
}

export function reportProfileFollowingCountPressed(
  context: "ownProfile" | "otherUserProfile"
): AnalyticsEventAndProperties {
  return simpleEvent("Profile Following Count Pressed" + (context === "ownProfile" ? "" : " (Other User)"));
}

export function reportCookingSessionStarted(event: {
  cookingSession: CookingSession;
  activeSessionsIncludingNew: number;
  paywallStatus: PaywallDetectionOutcome;
}): AnalyticsEventAndProperties {
  try {
    const name = "Cooking Session Started";
    const properties = getRecipeProperties(event.cookingSession.sourceRecipe);
    return {
      events: [
        {
          name,
          properties: {
            ...properties,
            "Sessions Active": event.activeSessionsIncludingNew,
            "Recipe Paywall Status": event.paywallStatus,
          },
        },
      ],
      attributionEvents: [{ name }],
      incrementProperties: {
        "Cooking Sessions Started": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportCookingSessionStarted", err, { event });
    return {};
  }
}

export function reportCookingSessionEnded(event: {
  cookingSession: CookingSessionState;
  saveActivity: boolean;
  createPost: boolean;
  postComment?: string;
  rating?: RecipeRating;
}): AnalyticsEventAndProperties {
  try {
    // get user count from updatedBy and instructions
    // get time
    const recipeProperties = getRecipeProperties(event.cookingSession.sourceRecipe);
    const duration = Math.floor(secondsBetween(defaultTimeProvider(), event.cookingSession.timeStarted));
    const endType: AnalyticsEventPropertyMap["Session End Type"] = event.saveActivity ? "Save" : "Discard";

    const participants = new Set<string>();
    Object.values(event.cookingSession.ingredientStatuses).forEach(section => {
      section.forEach(i => {
        if (i.item.updatedBy) {
          participants.add(i.item.updatedBy);
        }
      });
    });

    Object.keys(event.cookingSession.selectedInstructions).forEach(userId => {
      participants.add(userId);
    });

    const participantCount = Math.max(participants.size, 1);

    return {
      events: [
        {
          name: "Cooking Session Ended",
          properties: {
            "Session Duration Seconds": duration,
            "Session End Type": endType,
            "Session End Create Post": event.createPost,
            "Session End Post Comment": event.postComment,
            "Session End Rating": event.rating ? getRatingString(event.rating) : "-",
            "Session Participants": participantCount,
            ...recipeProperties,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportCookingSessionEnded", err, { event });
    return {};
  }
}

export function reportCookingSessionOpened(args: { cookingSession: CookingSessionState }): AnalyticsEventAndProperties {
  try {
    const properties = getRecipeProperties(args.cookingSession.sourceRecipe);
    return {
      events: [
        {
          name: "Cooking Session Opened",
          properties: {
            ...properties,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportCookingSessionOpened", err, { args });
    return {};
  }
}

export function reportCookingSessionMenuOpened(): AnalyticsEventAndProperties {
  return simpleEvent("Cooking Session Menu Opened");
}

export function reportCookingSessionTextSizeOpened(): AnalyticsEventAndProperties {
  return simpleEvent("Cooking Session Menu Opened");
}

export function reportCookingSessionTextSizeChanged(args: { fontScale: number }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Cooking Session Text Size Changed",
          properties: {
            "Cooking Session Font Scale": args.fontScale,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportCookingSessionTextSizeChanged", err, { args });
    return {};
  }
}

export function reportRecipesReceived(args: { recipes: AppUserRecipe[] }): AnalyticsEventAndProperties {
  try {
    return {
      setProperties: {
        "Recipe Count": args.recipes.length,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipesReceived", err);
    return {};
  }
}

export function reportUserReceived(user: AuthedUserAndSettings): AnalyticsEventAndProperties {
  try {
    const username = user.isRegistered ? user.username : ("<Anonymous>" as Username);
    // add the current user to the household member count
    const householdMembers = user.household.length + 1;
    return {
      superProperties: {
        "Deglaze User ID": user.userId,
        "Household Members": householdMembers,
        Username: username,
        "User Access": user.access,
        "User Registered": user.isRegistered,
        "User is Anonymous": !user.isRegistered,
      },
      setProperties: {
        "Deglaze User ID": user.userId,
        $name: user.isRegistered ? user.name : `Anon ${user.userId}`,
        "Household ID": user.householdId,
        "Household Members": householdMembers,
        Username: username,
        "User Access": user.access,
        "User Referred By": user.referredBy,
        "User Created": getMixpanelTimestamp(user.created),
        "User Registered": user.isRegistered,
        "User is Anonymous": !user.isRegistered,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportUserRetrieved", err, { args: user });
    return {};
  }
}

// the args here are getting complex. Next time we add to this, we should break it up into
// different functions and have an internal function that munges it all together
export function reportRecipeAdded(args: {
  addedVia:
    | "appUrl"
    | "appManual"
    | "shareExtensionUrl"
    | "socialPost"
    | "socialPostComment"
    | "userShared"
    | "search"
    | "webViewBrowse";
  recipe: AppUserRecipe;
  htmlSourceAvailable?: boolean;
  searchProps?: { filters: RecipeFilters; tagManifest: RecipeTagManifest };
}): AnalyticsEventAndProperties {
  try {
    const addedVia = switchReturn<typeof args.addedVia, AnalyticsEventPropertyMap["Recipe Added Via"]>(args.addedVia, {
      appManual: "App (Manual Recipe)",
      appUrl: "App (URL)",
      shareExtensionUrl: "Share Extension (URL)",
      socialPost: "Social Post",
      socialPostComment: "Social Post Comment",
      userShared: "User Shared Recipe",
      search: "Search Results",
      webViewBrowse: "Web View Browse",
    });

    const filterProps = args.searchProps
      ? getRecipeFilterProperties(args.searchProps.filters, args.searchProps.tagManifest, "search")
      : {};

    const properties: Partial<AnalyticsEventPropertyMap> = {
      ...getRecipeProperties(args.recipe),
      "Recipe Added Via": addedVia,
      "Recipe HTML Source Available": args.htmlSourceAvailable,
      "Recipe is Quality": !!args.recipe.qr,
      ...filterProps,
    };

    const name = "Recipe Added";
    const attributionEventProperties = { "Recipe Added Via": addedVia };

    const attributionEvents: AnalyticsEventAndProperties["attributionEvents"] = [
      { name, properties: attributionEventProperties },
    ];
    if (args.recipe.qr) {
      attributionEvents.push({ name: "Quality Recipe Added", properties: attributionEventProperties });
    }

    return {
      events: [
        {
          name,
          properties,
        },
      ],
      attributionEvents,
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeAdded", err, { args });
    return {};
  }
}

export function reportUnsupportedUrlRecipeAdded(url: UrlString): AnalyticsEventAndProperties {
  return {
    events: [
      {
        name: "Attempted to Add Recipe with Unsupported Url",
        properties: {
          "Recipe URL": url,
        },
      },
    ],
  };
}

export function reportRecipeAddedToGroceryList(args: {
  listId: GroceryListId;
  itemId: GroceryListItemId;
  recipe?: AppUserRecipe;
}): AnalyticsEventAndProperties {
  try {
    const recipeProperties = args.recipe ? getRecipeProperties(args.recipe) : {};
    const name = "Recipe Added to Grocery List";
    return {
      events: [
        {
          name,
          properties: {
            ...recipeProperties,
            "List ID": args.listId,
            "List Item ID": args.itemId,
          },
        },
      ],
      attributionEvents: [{ name }],
      incrementProperties: {
        "Recipes Added to Grocery List": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeAddedToGroceryList", err, { args });
    return {};
  }
}

export function reportRecipeEdited(args: { recipe: AppUserRecipe }) {
  try {
    const properties = getRecipeProperties(args.recipe);
    return {
      events: [{ name: "Recipe Edited", properties }],
      incrementProperties: { "Recipes Edited": 1 },
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeEdited", err, { args });
    return {};
  }
}

export function reportRecipeDeleted(args: { recipe: AppUserRecipe }) {
  try {
    const properties = getRecipeProperties(args.recipe);
    return {
      events: [{ name: "Recipe Deleted", properties }],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeDeleted", err, { args });
    return {};
  }
}

export function reportGroceryListItemAdded(args: {
  text: string;
  listId: GroceryListId;
  itemId: GroceryListItemId;
  fromSuggestion: boolean;
  manualCategory?: StandardPrimaryCategory;
  category?: StandardCategory;
}): AnalyticsEventAndProperties {
  try {
    const name = "Grocery List Item Added";
    return {
      events: [
        {
          name,
          properties: {
            "List ID": args.listId,
            "List Item ID": args.itemId,
            "List Item Category": args.category?.primary ?? "uncategorized",
            "List Item From Suggestion": args.fromSuggestion,
            "List Item Manual Category": args.manualCategory,
            "List Item Text": args.text,
          },
        },
      ],
      attributionEvents: [{ name }],
      incrementProperties: {
        "Grocery List Items Created": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportGroceryListItemAdded", err, { args });
    return {};
  }
}

export function reportGroceryListItemEdited(args: {
  oldText: string;
  newText: string;
  listId: GroceryListId;
  itemId: GroceryListItemId;
  category?: StandardCategory;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Grocery List Item Edited",
          properties: {
            "List ID": args.listId,
            "List Item ID": args.itemId,
            "List Item Category": args.category?.primary ?? "uncategorized",
            "List Item Text Previous": args.oldText,
            "List Item Text": args.newText,
          },
        },
      ],
      incrementProperties: {
        "Grocery List Items Edited": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportGroceryListItemAdded", err, { args });
    return {};
  }
}

export function reportGroceryListSuggestionTapped(event: {
  type: "Pill" | "Typeahead";
  suggestion: GroceryListSuggestion;
  index: number;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Grocery List Suggestion Tapped",
          properties: {
            "List ID": event.suggestion.listId,
            "List Item Suggestion ID": event.suggestion.id,
            "List Item Suggestion Index": event.index,
            "List Item Suggestion Text": event.suggestion.text,
            "List Item Suggestion Type": event.type,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportGroceryListSuggestionTapped", err, { event });
    return {};
  }
}

export function reportGroceryListCategoryChanged(event: {
  item: GroceryListItem;
  listId?: GroceryListId;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Grocery List Item Category Changed",
          properties: {
            "List ID": event.listId,
            "List Item ID": event.item.id,
            "List Item Category": event.item.category?.primary ?? "uncategorized",
            "List Item Text": event.item.text,
            "List Item Manual Category": event.item.manualCategory,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportGroceryListCategoryChanged", err, { event });
    return {};
  }
}

export function reportGroceryListItemStatusChange(event: {
  item: GroceryListItem;
  view: GroceryListSort;
  groupCount?: number;
  groupSwipe?: boolean;
}): AnalyticsEventAndProperties {
  try {
    const name = switchReturn(event.item.status.status, {
      pending: "Grocery List Item Uncompleted",
      completed: "Grocery List Item Completed",
    });
    return {
      events: [
        {
          name,
          properties: {
            "List Item ID": event.item.id,
            "List Item Category": event.item.category?.primary ?? "uncategorized",
            "List Item Text": event.item.text,
            "List Item Group Count": event.groupCount,
            "List Item Group Swipe": event.groupSwipe,
            "List View": event.view,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportGroceryListItemCompleted", err, { event });
    return {};
  }
}

export function reportNotificationTapped(event: {
  id: NotificationId;
  context: NotificationContext;
  from: "OS" | "App Notification Center";
}): AnalyticsEventAndProperties {
  try {
    const externalUrlProperties: Partial<AnalyticsEventPropertyMap> =
      // report these here since we don't go to a screen
      event.context.type === "users/externalLink"
        ? {
            "Notification External URL": event.context.data.url,
            "Notification External URL Key": event.context.data.analyticsAndIdempotencyDescription,
          }
        : {};

    const onboardingProp =
      event.context.type === "users/onboardingPrompt"
        ? { "Notification Onboarding Prompt": event.context.data.prompt }
        : {};

    return {
      events: [
        {
          name: "Notification Tapped",
          properties: {
            "Notification ID": event.id,
            "Notification Type": event.context.type,
            "Notification Tapped From": event.from,
            ...onboardingProp,
            ...externalUrlProperties,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportNotificationTapped", err, { event });
    return {};
  }
}

export function reportVideoWatched(event: {
  analyticsId: string;
  url?: UrlString;
  videoLoaded: boolean;
  videoCompleted: boolean;
  lengthInSeconds: number;
  maxSecondReached: number;
}): AnalyticsEventAndProperties {
  try {
    const percentage = event.videoCompleted
      ? 100
      : event.lengthInSeconds
      ? Math.floor((event.maxSecondReached / event.lengthInSeconds) * 100)
      : undefined;
    return {
      events: [
        {
          name: "Video Watched",
          properties: {
            "Video Key": event.analyticsId,
            "Video URL": event.url,
            "Video Length": event.lengthInSeconds ? event.lengthInSeconds : undefined,
            "Video Loaded": event.videoLoaded,
            "Video Percentage Watched": percentage,
            "Video Max Second Reached": event.maxSecondReached,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportVideoWatched", err, { event });
    return {};
  }
}

export function reportRecipeTextViewToggled(event: {
  recipe: AppRecipeBase;
  textView: boolean;
  paywallStatus: PaywallDetectionOutcome;
}): AnalyticsEventAndProperties {
  try {
    const recipeProperties = getRecipeProperties(event.recipe);
    return {
      events: [
        {
          name: "Recipe Detail Text View Toggled",
          properties: {
            ...recipeProperties,
            "Recipe Text View": event.textView,
            "Recipe Paywall Status": event.paywallStatus,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeTextViewToggled", err, { event });
    return {};
  }
}

export function reportRecipeStatusBannerDismissed(event: { recipe: AppUserRecipe }): AnalyticsEventAndProperties {
  try {
    const recipeProperties = getRecipeProperties(event.recipe);
    return {
      events: [
        {
          name: "Recipe Status Banner Dismissed",
          properties: {
            ...recipeProperties,
            "Recipe Processing Status": event.recipe.status,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeStatusBannerDismissed", err, { event });
    return {};
  }
}

export function reportBookPurchaseLinkClicked(event: { recipe: AppRecipeBase }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Book Purchase Link Clicked",
          properties: getRecipeProperties(event.recipe),
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportBookPurchaseLinkClicked", err, { event });
    return {};
  }
}

export function reportPaywallHit(event: {
  recipe: AppRecipeBase;
  context: PaywallLocation;
}): AnalyticsEventAndProperties {
  try {
    const recipeProperties = getRecipeProperties(event.recipe);

    return {
      events: [
        {
          name: "Recipe Paywall Hit",
          properties: {
            ...recipeProperties,
            "Paywall Location": event.context,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportPaywallHit", err, { event });
    return {};
  }
}

export function reportPaywallSigninDetected(event: { recipeUrl: UrlString }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Recipe Paywall Signin Detected",
          properties: {
            "Recipe URL": event.recipeUrl,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportPaywallSigninDetected", err, { event });
    return {};
  }
}

export function reportRecipeLibrarySearched(event: {
  filters: RecipeFilters;
  tagManifest: RecipeTagManifest;
}): AnalyticsEventAndProperties {
  try {
    if (emptyToUndefined(event.filters.search) === undefined) {
      return {};
    }

    return {
      events: [
        {
          name: "Recipe Library Searched",
          properties: getRecipeFilterProperties(event.filters, event.tagManifest, "library"),
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in recipeLibrarySearched", err, { event });
    return {};
  }
}

export function reportRecipeLibraryFiltered(event: {
  filters: RecipeFilters;
  tagManifest: RecipeTagManifest;
}): AnalyticsEventAndProperties {
  try {
    if ((event.filters.tags ?? []).length === 0 && (event.filters.time ?? []).length === 0) {
      return {};
    }

    return {
      events: [
        {
          name: "Recipe Library Filtered",
          properties: getRecipeFilterProperties(event.filters, event.tagManifest, "library"),
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in recipeLibraryFiltered", err, { event });
    return {};
  }
}

export function reportRecipeTimeEdited(event: {
  recipe: AppUserRecipe;
  originalTime?: RecipeTime;
  newTime?: RecipeTime;
  location: RecipeEditFieldLocation;
}): AnalyticsEventAndProperties {
  try {
    const newTime = timeToStr(event.newTime);
    const origTime = timeToStr(event.originalTime);

    return {
      events: [
        {
          name: "Recipe Time Edited",
          properties: {
            "Recipe Time Original": origTime,
            "Recipe Time New": newTime,
            ...getRecipeProperties(event.recipe),
            ...getRecipeEditFieldLocation(event.location),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in recipeLibraryFiltered", err, { event });
    return {};
  }
}

export function reportRecipeRatingEdited(event: {
  recipe: AppUserRecipe;
  originalRating?: RecipeRating;
  newRating: RecipeRating | null;
  location: RecipeEditFieldLocation;
}): AnalyticsEventAndProperties {
  try {
    const noRating = "-";
    const originalRating = event.originalRating ? getRatingString(event.originalRating) : noRating;
    const newRating = event.newRating ? getRatingString(event.newRating) ?? JSON.stringify(event.newRating) : noRating;
    return {
      events: [
        {
          name: "Recipe Rating Edited",
          properties: {
            "Recipe Rating Original": originalRating,
            "Recipe Rating New": newRating,
            ...getRecipeProperties(event.recipe),
            ...getRecipeEditFieldLocation(event.location),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in recipeLibraryFiltered", err, { event });
    return {};
  }
}

export function reportRecipeNoteEdited(event: {
  recipe: AppUserRecipe;
  newNote: string;
  location: RecipeEditFieldLocation;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Recipe Note Edited",
          properties: {
            "Recipe Note": event.newNote.substring(0, 255),
            ...getRecipeProperties(event.recipe),
            ...getRecipeEditFieldLocation(event.location),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeNoteEdited", err, { event });
    return {};
  }
}

function timeToStr(time?: RecipeTime) {
  const totalMsMin = time?.total[0];
  const totalMsMax = time?.total[1];
  const totalMin = totalMsMin ? getDurationInMinutes({ milliseconds: totalMsMin }).toFixed(0) : "<none>";
  const totalMax = totalMsMax ? getDurationInMinutes({ milliseconds: totalMsMax }).toFixed(0) : "<none>";
  return totalMin === totalMax ? `Total: ${totalMin}` : `Total: ${totalMin}-${totalMax}`;
}

export function reportRecipeTagAddedRemoved(event: {
  update: EditUserRecipeTagArgs;
  recipe: AppUserRecipe;
  location: RecipeEditFieldLocation;
}): AnalyticsEventAndProperties {
  try {
    let action: string;
    switch (event.update.action) {
      case "add":
        action = "Added";
        break;
      case "remove":
        action = "Removed";
        break;
      default:
        action = bottomWithDefault(event.update.action, "UNKNOWN ACTION", "reportRecipeTagAddedRemoved action name");
    }

    return {
      events: [
        {
          name: `Recipe Tag ${action}`,
          properties: {
            "Recipe Tag Type": event.update.tag.type,
            "Recipe Tag Name": event.update.tag.tag,
            ...getRecipeProperties(event.recipe),
            ...getRecipeEditFieldLocation(event.location),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeTagAddedRemoved", err, { event });
    return {};
  }
}

export type AnalyticsTabName = "Home" | "Recipes" | "Groceries" | "Profile";
export function reportBottomTabChanged(event: { tab: AnalyticsTabName }): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: `Bottom Tab Changed to ${event.tab}` }],
    };
  } catch (err) {
    log.errorCaught("Error in reportBottomTabChanged", err);
    return {};
  }
}

export function reportRecipeDetailTabChanged(event: {
  recipe: AppRecipeBase;
  context: "recipeDetail" | "cookingSession";
  tab: string;
}) {
  try {
    let contextStr;
    switch (event.context) {
      case "recipeDetail":
        contextStr = "Recipe Detail";
        break;
      case "cookingSession":
        contextStr = "Cooking Session";
        break;
      default:
        bottomLog(event.context, "Unknown context in reportRecipeDetailTabChanged");
    }

    if (!contextStr) {
      return {};
    }

    return {
      events: [
        {
          name: `${contextStr} Tab Changed to ${event.tab}`,
          properties: {
            ...getRecipeProperties(event.recipe),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeDetailTabChanged", err, { event });
    return {};
  }
}

export function reportRecipeLibraryContextMenuOpened(): AnalyticsEventAndProperties {
  return simpleEvent("Library Recipe Context Menu Opened");
}

export function reportRecipeInKitchenMenuOpened(): AnalyticsEventAndProperties {
  return simpleEvent("Recipe In Kitchen Context Menu Opened");
}

export type RecipeContextMenuAction = "tags" | "notes" | "rating" | "editRecipe" | "reportIssue";

export function reportLibraryRecipeContextMenuItemTapped(event: {
  action: RecipeContextMenuAction;
}): AnalyticsEventAndProperties {
  try {
    const description = switchReturn(event.action, {
      tags: "Tags",
      notes: "Notes",
      editRecipe: "Edit",
      reportIssue: "Report Issue",
      rating: "Rating",
    });

    return { events: [{ name: `Library Recipe Context Menu Item Tapped: ${description}` }] };
  } catch (err) {
    log.errorCaught("Error in reportLibraryRecipeContextMenuItemTapped", err);
    return {};
  }
}

export function reportHouseholdInviteLinkViewed(event: {
  sharedByUserId: UserId;
  sharedByUsername: Username;
}): AnalyticsEventAndProperties {
  try {
    const location = Platform.OS === "web" ? "on Web" : "in App";
    return {
      events: [
        {
          name: `Household Invite Link Viewed ${location}`,
          properties: {
            "Shared by User ID": event.sharedByUserId,
            "Shared by Username": event.sharedByUsername,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportHouseholdInviteLinkViewed", err);
    return {};
  }
}

export function reportHouseholdInviteLinkAccepted(event: {
  sharedByUserId: UserId;
  sharedByUsername: Username;
}): AnalyticsEventAndProperties {
  try {
    const location = Platform.OS === "web" ? "on Web" : "in App";
    return {
      events: [
        {
          name: `Household Invite Link Accepted ${location}`,
          properties: {
            "Shared by User ID": event.sharedByUserId,
            "Shared by Username": event.sharedByUsername,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportHouseholdInviteLinkAccepted", err);
    return {};
  }
}

export function reportSharedRecipeOrUserViewed(event: {
  sharedRecipeId?: RecipeId;
  sourceRecipeId?: RecipeId;
  recipe?: AppUserRecipe | RecipeInfo;
  sharingUserId: UserId;
  sharingUserUsername?: Username;
}): AnalyticsEventAndProperties {
  try {
    const recipeProps: Partial<AnalyticsEventPropertyMap> = event.recipe ? getRecipeInfoProperties(event.recipe) : {};
    const linkType = event.sharedRecipeId || event.sourceRecipeId ? "Recipe" : "User";
    const name =
      Platform.OS === "web" ? `Shared ${linkType} Link Viewed on Web` : `Shared ${linkType} Link Opened in App`;
    const sharedByUsername = event.sharingUserUsername ? { "Shared by Username": event.sharingUserUsername } : {};

    return {
      events: [
        {
          name,
          properties: {
            ...recipeProps,
            "Shared by User ID": event.sharingUserId,
            ...sharedByUsername,
            "Shared Recipe ID": event.sharedRecipeId,
            "Shared Source Recipe ID": event.sourceRecipeId,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSharedRecipeViewedOnWeb", err, { event });
    return {};
  }
}

export function reportSharedUserRecipeDetailsViewedOnWeb(event: {
  recipe: AppUserRecipe;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Shared User Recipe Details Viewed on Web",
          properties: {
            ...getRecipeProperties(event.recipe),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSharedUserRecipeDetailsViewedOnWeb", err, { event });
    return {};
  }
}

export function reportSharedBookRecipeDetailsViewedOnWeb(event: {
  recipeId?: RecipeId;
  recipeInfo: RecipeInfo;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Shared Book Recipe Details Viewed on Web",
          properties: {
            ...getRecipeInfoProperties(event.recipeInfo),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSharedUserRecipeDetailsViewedOnWeb", err, { event });
    return {};
  }
}

export function reportSignUpTappedOnShareScreen(): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "User Tapped Sign Up on Share Screen" }],
    };
  } catch (err) {
    log.errorCaught("Error in reportJoinTappedOnShareScreen", err);
    return {};
  }
}

export function reportSaveRecipeTappedOnShareScreen(): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "User Tapped Save Recipe on Share Screen" }],
    };
  } catch (err) {
    log.errorCaught("Error in reportSaveRecipeTappedOnShareScreen", err);
    return {};
  }
}

export function reportViewRecipeTappedOnShareScreen(): AnalyticsEventAndProperties {
  try {
    return {
      events: [{ name: "User Tapped View Recipe on Share Screen" }],
    };
  } catch (err) {
    log.errorCaught("Error in reportViewRecipeTappedOnShareScreen", err);
    return {};
  }
}

export function reportRecipeDetailViewed(event: {
  recipe: AppRecipeBase;
  location: "Library" | "Social Post" | "User Shared Recipe" | "Social Post Comment" | "Search Results";
  recipeInLibrary: boolean;
  filters?: { filters: RecipeFilters; context: "search" | "library" };
  tagManifest?: RecipeTagManifest;
  knownEntityId?: KnownAuthorId | KnownPublisherId;
  searchResultIndex?: number;
}): AnalyticsEventAndProperties {
  try {
    const filters =
      event.filters && event.tagManifest
        ? getRecipeFilterProperties(event.filters.filters, event.tagManifest, event.filters.context)
        : {};
    const recipeProperties = getRecipeProperties(event.recipe);
    return {
      events: [
        {
          name: "Recipe Detail Viewed",
          properties: {
            ...recipeProperties,
            ...filters,
            "Recipe Viewed From": event.location,
            "Recipe in Library": event.recipeInLibrary,
            "Known Entity ID": event.knownEntityId,
            "Search Result Index": event.searchResultIndex,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportRecipeDetailViewed", err, { event });
    return {};
  }
}

export function reportCookingTimerCreated(event: {
  minDuration: number;
  maxDuration: number;
  timerCountInclusive: number;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Cooking Timer Created",
          properties: {
            "Timer Min Duration": event.minDuration,
            "Timer Max Duration": event.maxDuration,
            "Timer Count": event.timerCountInclusive,
          },
        },
      ],
      incrementProperties: {
        "Cooking Timers Created": 1,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportCookingTimerCreated", err, { event });
    return {};
  }
}

export function reportAudioSessionStarted(): Partial<AnalyticsEventAndProperties> {
  try {
    return { events: [{ name: "Audio Session Started" }] };
  } catch (err) {
    log.errorCaught("Error in reportAudioSessionStarted", err);
    return {};
  }
}

export function reportAudioSessionEnded(props: { durationInSeconds?: number }): Partial<AnalyticsEventAndProperties> {
  try {
    const properties: Partial<AnalyticsEventPropertyMap> = props.durationInSeconds
      ? { "Session Duration Seconds": props.durationInSeconds }
      : {};
    return { events: [{ name: "Audio Session Ended", properties }] };
  } catch (err) {
    log.errorCaught("Error in reportAudioSessionEnded", err);
    return {};
  }
}

export function reportAppUpdateScreenDisplayed(): Partial<AnalyticsEventAndProperties> {
  try {
    return { events: [{ name: "App Update Screen Displayed" }] };
  } catch (err) {
    log.errorCaught("Error in reportAppUpdateScreenDisplayed", err);
    return {};
  }
}

export function reportFontScale(event: { fontScale: number }): AnalyticsEventAndProperties {
  try {
    return { setProperties: { "Font Scale": event.fontScale } };
  } catch (err) {
    log.errorCaught("Error in reportFontScale", err);
    return {};
  }
}

export function reportPushPermissionScreenDisplayed(args: {
  context: NotificationPermissionProps["context"];
}): AnalyticsEventAndProperties {
  try {
    let context: AnalyticsEventPropertyMap["Notification Permission Screen Context"];
    switch (args.context) {
      case "firstTime":
        context = "Setup";
        break;
      case "timers":
        context = "Timers";
        break;
      default:
        context = "Setup";
        bottomLog(args.context, "reportPushPermissionScreenDisplayed.context");
    }
    return {
      events: [
        { name: "Push Permission Screen Displayed", properties: { "Notification Permission Screen Context": context } },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportPushPermissionScreenDisplayed", err);
    return {};
  }
}

export function reportPushPermissionChanged(args: {
  havePermission: boolean;
  changedVia: AnalyticsEventPropertyMap["Notification Permission Changed Via"];
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: `Push Permissions ${args.havePermission ? "Granted" : "Denied"}`,
          properties: { "Notification Permission Changed Via": args.changedVia },
        },
      ],
      setProperties: {
        "Push Notifications Enabled": args.havePermission,
      },
    };
  } catch (err) {
    log.errorCaught("Error in reportPushPermissionChanged", err);
    return {};
  }
}

export function reportReviewPromptMaybeOpened(args: {
  trigger: AnalyticsEventPropertyMap["Review Prompt Action"];
  thresholds: ReviewPromptThresholds;
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Review Prompt Maybe Opened",
          properties: {
            "Review Prompt Action": args.trigger,
            "Review Prompt Thresholds": JSON.stringify(args.thresholds),
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportReviewPromptMaybeOpened", err);
    return {};
  }
}

export type OnboardingEventType =
  | "shareExtensionExampleScreen"
  | "shareExtensionSetupScreen"
  | "howToPostScreen"
  | "spotlightAddRecipeButton"
  | "spotlightLibraryRecipe"
  | "spotlightLibraryRecipeActions"
  | "spotlightLibraryReaderMode"
  | "spotlightPostViewRecipeReaderMode"
  | "spotlightShareViewRecipeReaderMode"
  | "spotlightSearchViewRecipeReaderMode"
  | "spotlightGroceryTab"
  | "howToVideoDismissed";

export function reportOnboardingEvent(event: OnboardingEventType): AnalyticsEventAndProperties {
  try {
    const desc = switchReturn(event, {
      shareExtensionExampleScreen: "Share Extension Example Screen Viewed",
      shareExtensionSetupScreen: "Share Extension Setup Screen Viewed",
      howToPostScreen: "How to Post Screen Viewed",
      spotlightAddRecipeButton: "Add Recipe Button Spotlighted",
      spotlightGroceryTab: "Grocery Tab Icon Spotlighted",
      spotlightLibraryRecipe: "Library Recipe Spotlighted",
      spotlightLibraryRecipeActions: "Library Recipe Actions Spotlighted",
      spotlightLibraryReaderMode: "Library Reader Mode Spotlighted",
      spotlightPostViewRecipeReaderMode: "Post View Recipe Reader Mode Spotlighted",
      spotlightShareViewRecipeReaderMode: "Share View Recipe Reader Mode Spotlighted",
      spotlightSearchViewRecipeReaderMode: "Search View Recipe Reader Mode Spotlighted",
      howToVideoDismissed: "How-to Video Dismissed",
    });
    return {
      events: [{ name: `Onboarding: ${desc}` }],
    };
  } catch (err) {
    log.errorCaught("Error in reportOnboardingEvent", err);
    return {};
  }
}

export function reportSurveyDisplayed(args: { surveyName: string }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Survey Displayed",
          properties: {
            "Survey Name": args.surveyName,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSurveyDisplayed", err);
    return {};
  }
}

export function reportSurveyCompleted(args: {
  surveyName: string;
  surveyOptions: string[];
  surveySelection: string;
  surveySelectionIndex: number;
  surveyOtherText?: string;
}): AnalyticsEventAndProperties {
  log.logRemote(`Survey completed: ${args.surveyName}`, { survey: args });

  try {
    return {
      events: [
        {
          name: "Survey Completed",
          properties: {
            "Survey Name": args.surveyName,
            "Survey Options": args.surveyOptions,
            "Survey Selection": args.surveySelection,
            "Survey Selection Index": args.surveySelectionIndex,
            "Survey Other Text": args.surveyOtherText,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSurveyCompleted", err);
    return {};
  }
}

export function reportSurveyDismissed(args: {
  surveyName: string;
  surveyOptions: string[];
}): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Survey Dismissed",
          properties: {
            "Survey Name": args.surveyName,
            "Survey Options": args.surveyOptions,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportSurveyDismissed", err);
    return {};
  }
}

export function reportExplainerSheetDisplayed(args: { checkpoint: UserCheckpoint }): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Explainer Sheet Displayed",
          properties: {
            "Explainer Sheet Name": args.checkpoint,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportExplainerSheetDisplayed", err);
    return {};
  }
}

export function reportSocialFeedsTabChanged(
  context: "homeScreen",
  tab: "Following" | "Explore"
): AnalyticsEventAndProperties {
  switch (context) {
    case "homeScreen": {
      return simpleEvent(`Home Tab Changed to ${tab}`);
    }
    default:
      {
        bottomLog(context, "reportSocialFeedsTabChanged context");
      }

      return {};
  }
}

export function reportExplainerSheetDismissed(): AnalyticsEventAndProperties {
  return simpleEvent("Explainer Sheet Dismissed");
}

export function reportAddRecipeButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Add Recipe Button Tapped");
}

export function reportGroceryLinkYourHouseholdButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Grocery Link Your Household Button Tapped");
}

export function reportAlreadyHaveAcountButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Already Have Account Button Tapped");
}

export function reportLeaveRatingTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Leave Rating Button Tapped");
}

export function reportSendFeedbackTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Send Feedback Button Tapped");
}

export function reportSettingsScreenOpened(): AnalyticsEventAndProperties {
  return simpleEvent("Settings Screen Opened");
}

export function reportFollowOnInstagramButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Follow on Instragram Button Tapped");
}

export function reportHowToDeglazeVideoInSettingsTapped(): AnalyticsEventAndProperties {
  return simpleEvent("How to Deglaze Video in Settings Tapped");
}

export function reportAboutButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("About Button Tapped");
}

export function reportSupportButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Support Button Tapped");
}

export function reportHowToButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("How To Button Tapped");
}

export function reportHowToAddRecipesButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("How To Add Recipes Button Tapped");
}

export function reportHowToOrganizeRecipesButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("How To Organize Recipes Button Tapped");
}

export function reportHowToGrocerylistListButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("How To Grocery List Button Tapped");
}

export function reportDeleteAccountButtonTapped(): AnalyticsEventAndProperties {
  return simpleEvent("Delete Account Button Tapped");
}

export function reportInternalSiteOpened(site: InternalWebPage): AnalyticsEventAndProperties {
  try {
    const siteName = switchReturn(site, {
      privacyPolicy: "Privacy Policy",
      termsOfService: "Terms of Service",
    });

    return {
      events: [{ name: `${siteName} Page Opened` }],
    };
  } catch (err) {
    log.errorCaught("Error in reportInternalSiteOpened", err);
    return {};
  }
}

export function reportEmailSignInLinkSent(email?: string): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "Sign-in Email Link Sent",
          properties: {
            "Sign-in Email": email,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportInternalSiteOpened", err);
    return {};
  }
}

export function reportAppSignInLinkOnWeb(email?: string): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "App Sign-In Link Opened on Web",
          properties: {
            "Sign-in Email": email,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportInternalSiteOpened", err);
    return {};
  }
}

export function reportNonHttpSignInLink(protocol: string, email?: string): AnalyticsEventAndProperties {
  try {
    return {
      events: [
        {
          name: "App Sign-In Link with Non-HTTP Protocol",
          properties: {
            "Sign-in Link Protocol": protocol,
            "Sign-in Email": email,
          },
        },
      ],
    };
  } catch (err) {
    log.errorCaught("Error in reportInternalSiteOpened", err);
    return {};
  }
}

function simpleEvent(event: string): AnalyticsEventAndProperties {
  return { events: [{ name: event }] };
}

function getRecipeFilterProperties(
  f: RecipeFilters,
  manifest: RecipeTagManifest,
  context: "library" | "search"
): Partial<AnalyticsEventPropertyMap> {
  const tags: string[] = (f.tags ?? []).map(t => {
    if (t.type === "user") {
      return `${t.type}:${t.tag}`;
    }

    const category = manifest.categoryList.find(c => c.tags.includes(t.tag))?.category ?? "unknown";
    return `${t.type}:${category.toLowerCase()}:${t.tag}`;
  });
  const time: string[] = (f.time ?? []).map(t => {
    const minutes = getDurationInMinutes({ milliseconds: t.totalTime });
    let timeType: string;
    switch (t.type) {
      case "totalTime":
        timeType = "total";
        break;
      default:
        timeType = bottomWithDefault(t.type, "unknown", "getTimeTagType");
    }
    return `time:${timeType}:${minutes}`;
  });

  const query = emptyToUndefined(f.search) ?? "";
  const filterTags = [...tags, ...time];

  switch (context) {
    case "library":
      return {
        "Recipe Library Search Query": query,
        "Recipe Library Filter Tags": filterTags,
      };
    case "search":
      return {
        "Search Query": query,
        "Search Filter Tags": filterTags,
      };
    default:
      bottomLog(context, "getRecipeFilterProperties");
      return {};
  }
}

function getRecipeProperties(r: AppRecipe): Partial<AnalyticsEventPropertyMap> {
  const baseProps = getRecipeInfoProperties(r);

  const typeKey: keyof AppUserRecipe = "type";
  // Recipe age is really only interesting for analytics for user recipes
  // for when they are cooked and shopped relative to the date the user saved them
  if (typeKey in r && r.type === "userRecipe") {
    return {
      ...baseProps,
      // this was null for a cooking session recipe (likely an old one), but handling just in case
      "Recipe Age in Days": r.created ? daysBetween(r.created, defaultTimeProvider()) : undefined,
      "Recipe Count Cooked": r.stats.cooked ?? 0,
      "Recipe Count Shopped": r.stats.addedToList ?? 0,
    };
  }

  return baseProps;
}

function getRecipeEditFieldLocation(location: RecipeEditFieldLocation): Partial<AnalyticsEventPropertyMap> {
  let val: AnalyticsEventPropertyMap["Recipe Update Location"] | undefined;
  switch (location) {
    case "recipeDetail":
      val = "Recipe Detail";
      break;
    case "endCookingSession":
      val = "End Cooking Session";
      break;
    case "cookingSession":
      val = "Cooking Session";
      break;
    default:
      bottomLog(location, "getRecipeEditFieldLocation");
  }

  if (val) {
    return { "Recipe Update Location": val };
  }

  return {};
}

function getRecipeInfoProperties(r: RecipeInfo): Partial<AnalyticsEventPropertyMap> {
  return {
    "Recipe Title": r.title,
    "Recipe Source Type": r.source.type,
    "Recipe Book Name": r.book?.name,
    "Recipe Book Purchase Link": r.book?.purchaseLink,
    "Recipe Publisher Name": r.publisher?.name,
    "Recipe Author Name": r.author?.name,
    "Recipe URL": r.source.type === "url" ? r.source.url : undefined,
    "Recipe ID": r.id,
  };
}

function getSocialPostProperties(s: SocialPost): Partial<AnalyticsEventPropertyMap> {
  const recipeProperties = s.type === "userRecipeActionPost" ? getRecipeInfoProperties(s.recipeInfo) : {};
  const textProperties = s.type === "textPost" ? { "Social Post Body": s.body } : {};
  return {
    ...recipeProperties,
    ...textProperties,
    "Social Post ID": s.id,
    "Social Post Type": s.type,
  };
}

function getKnownEntityProperties(entity?: KnownAuthor | KnownPublisher): Partial<AnalyticsEventPropertyMap> {
  if (!entity) {
    return {};
  }

  return {
    "Known Entity ID": entity.id,
    "Known Entity Name": entity.name,
    "Known Entity Type": entity.type,
    "Known Entity User ID": entity.userId,
  };
}

function getSearchSessionProperties(args: {
  sessionId: SearchSessionId;
  searchSession: SearchSessionState;
  manifest: RecipeTagManifest;
}): Partial<AnalyticsEventPropertyMap> {
  args.searchSession;
  return {
    ...(args.searchSession.filters
      ? getRecipeFilterProperties(args.searchSession.filters, args.manifest, "search")
      : {}),
    "Search Session ID": args.sessionId,
    "Known Entity ID": args.searchSession.authorId ?? args.searchSession.publisherId,
  };
}
