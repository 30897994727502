import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthedUser,
  AuthedUserAndSettings,
  NullableUserCheckpoints,
  NumericUserCheckpoint,
  OnboardingSurveyResponses,
  ReceivedNotificationData,
  SignInMethod,
  SystemSettings,
  UserCheckpoint,
  UserCheckpoints,
  UserCreateData,
  WithVersion,
} from "@eatbetter/users-shared";
import {
  ServerData,
  ReceivedServerData,
  serverDataReceived,
  serverDataRequested,
  serverDataErrored,
} from "../redux/ServerData";
import { defaultTimeProvider, DurationMs, EpochMs, UrlString, UserId } from "@eatbetter/common-shared";
import { NotificationContext } from "../NotificationHandlers";
import { AppMetadata } from "@eatbetter/composite-shared";
import { RecipeId } from "@eatbetter/recipes-shared";
import { ScreenTypeRouteName } from "../../navigation/NavTree";
import { Platform } from "react-native";
import { Draft } from "immer";
import {
  addUpdate,
  ItemWithUpdates,
  rehydrateItemWithUpdates,
  updateErrored,
  updateStarted,
  updateSucceeded,
} from "../redux/ItemWithUpdates";
import { AppleFullName } from "../Deps";
import { log } from "../../Log";

export type UserAuthStatus = "pending" | "signedIn" | "signedInNoAccount" | "signingOut" | "signedOut";

/**
 * Tracks the *native* permission state.
 * userHasBeenPrompted will be true if the user has explicitly granted or denied permissions from the prompt in the app
 * or the settings menu.
 */
export interface PushPermission {
  havePermission: boolean;
  userHasBeenPrompted: boolean;
}

export type AuthErrorType = "general" | "userDeleted";

export interface DeviceSettings {
  cookingSessionFontScale?: number;
}

export type AppOnboardingQuestions = Partial<OnboardingSurveyResponses> & {
  startTime?: EpochMs;
  welcomeViewed?: boolean;
  welcomeActiveTime?: DurationMs;
  notificationsPromptResponse?: boolean;
  collectionSelectionDone?: boolean;
};

export interface SystemState {
  time: EpochMs;
  // time used to drive ticks on timers
  appFocused?: boolean;
  pushPermission?: PushPermission;
  userLastPromptedForPush?: EpochMs;
  navigation: {
    tabsMounted: boolean;
  };
  previouslySignedIn?: boolean;
  launchCarouselCompleted: boolean;
  showHomeScreenOnboarding?: boolean;
  authStatus: UserAuthStatus;
  authedUser: ServerData<AuthedUser>;

  // if the user was created anonymously, we track this to toggle
  // some UI
  anonymousUserCreated?: boolean;

  // Set when we detect a change in household members. We fetch recipes/lists/cooking session
  // more frequently for a couple minutes when this happens
  householdMembersChangedTime?: EpochMs;
  appMetadata: ServerData<AppMetadata>;
  requestedNavAction?: { screenName: ScreenTypeRouteName; props: object };
  session: {
    start: EpochMs;
    lastBlur?: EpochMs;
  };
  systemSettings: SystemSettings;
  deviceSettings: DeviceSettings;
  userCheckpoints: ItemWithUpdates<WithVersion<NullableUserCheckpoints>>;
  tappedNotification?: ReceivedNotificationData<NotificationContext>;
  diagnosticModeEndTime?: EpochMs;
  impersonateUser?: UserId;
  preAuthData: {
    /**
     * True if we encounter an error while authing
     */
    authError?: AuthErrorType;

    // for email sign-in, we need to pass the entered email address to firebase along with the
    // link. By storing this in redux, everything will work if the user 1) has the email sent
    // and then 2) closes the app and then 3) taps the email.
    email?: string;
    // this gets set to true when the app is opened with an email sign-in link. In the
    // "normal" flow of a user being on the "check your email" screen, we will use this
    // to display a spinner - otherwise the user is just staring at a static screen for a couple
    // seconds
    emailSigninLinkReceived?: UrlString;
    // this isn't really pre-auth, but it's pre-user account.
    createData?: UserCreateData;

    // For apple auth, we only get the name the first time the user signs in *and* it's not currently plumbed through firebase correctly
    // at least not in the version we're using. So, store it locally for use in the create account screen.
    appleName?: AppleFullName;

    signInMethod?: SignInMethod;

    referredBy?: UserId;
    sharedRecipeId?: RecipeId;
    sharedSourceRecipeId?: RecipeId;
  };
  onboardingQuestions: AppOnboardingQuestions;

  /**
   * If universal links don't work, app sign-in links can open the browser. We detect this and set this
   * var. We then nav to a recourse screen and provide the same link, but with a custom url scheme
   * so the app is guaranteed to open, assuming the user is on a device that has the app installed.
   */
  WEB_ONLY_appSignInLink?: { original: string; app: string };
}

const defaultSystemSettings: SystemSettings = {
  signInMethod: "google",
  debugFeatures: false,
  showFullRecipe: true,
  textPosts: false,
  cookingTimers: false,
  householdInvites: false,
};

const defaultDeviceSettings: DeviceSettings = {};

const initialState: SystemState = {
  time: defaultTimeProvider(),
  authStatus: "pending",
  authedUser: {},
  appMetadata: {},
  session: {
    start: 0 as EpochMs,
    lastBlur: undefined,
  },
  systemSettings: defaultSystemSettings,
  deviceSettings: defaultDeviceSettings,
  userCheckpoints: {
    itemState: "persisted",
    item: {
      version: 0 as EpochMs,
    },
    updates: [],
  },
  preAuthData: {},
  navigation: { tabsMounted: false },
  launchCarouselCompleted: false,
  onboardingQuestions: {},
};

export function migrateSystemState(existingState: unknown, newState: Draft<SystemState>): void {
  log.info("Migrating system state");

  if (!existingState || typeof existingState !== "object") {
    return;
  }

  const deviceSettingsKey: keyof SystemState = "deviceSettings";
  if (deviceSettingsKey in existingState) {
    log.info("Migrating deviceSettings");
    newState.deviceSettings = existingState[deviceSettingsKey] as SystemState["deviceSettings"];
  }

  const carouselKey: keyof SystemState = "launchCarouselCompleted";
  if (carouselKey in existingState && typeof existingState[carouselKey] === "boolean") {
    newState.launchCarouselCompleted = existingState[carouselKey];
  } else {
    // we should only hit this if a user is upgrading from a previous version when the carousel
    // didn't exist. In this case, just set it to completed.
    newState.launchCarouselCompleted = true;
  }
}

export function rehydrateSystemState(persisted: Draft<SystemState>): void {
  // we clear authStatus for web because we need the auth callback from firebase to complete before we render the screen and if
  // have a persisted status, we don't know when that happens.
  // We are currently *not* clearing for the app since we want the app to work in offline mode with the data it has persisted and I believe
  // that opening the app with no connection after the current token has expired woudl result in the user being taken to the sign-in screen.
  // I haven't tested this, however, and it's possible doing this in app would have no adverse consequences.
  if (Platform.OS === "web") {
    persisted.authStatus = "pending";
  }

  // reset tabs mounted to false
  persisted.navigation.tabsMounted = false;

  if (persisted.preAuthData.authError) {
    delete persisted.preAuthData.authError;
  }

  // clear any nav actions that were never handled.
  persisted.requestedNavAction = undefined;
  persisted.tappedNotification = undefined;

  // the app state event was not firing on app launch (or at least didn't fire by the time we subscribed).
  // reset this to undefined and we set it in appLaunched
  persisted.appFocused = undefined;

  rehydrateItemWithUpdates(persisted.userCheckpoints);
}

/**
 * 3rd party here means that a 3rd party is involved in sign-in by opening the app with a link
 * that the app then handles to complete authentication. These are significant because
 * we want to display a spinner in the sign-in component until the link is received or the user cancels
 * @param sim
 */
export function is3rdPartySignInMethod(sim?: SignInMethod): boolean {
  return sim === "apple" || sim === "google";
}

const systemSlice = createSlice({
  name: "system",
  initialState,

  reducers: create => ({
    userStartedSignIn: create.reducer((state, action: PayloadAction<SignInMethod>) => {
      delete state.preAuthData.authError;
      // display a spinner on the sign-in screen for 3rd-party sign-in
      state.preAuthData.signInMethod = action.payload;
    }),

    userCanceledSignIn: create.reducer(state => {
      delete state.preAuthData.signInMethod;
      // in the case of an anonymous user, we don't want to change the signedIn state
      if (state.authStatus !== "signedIn") {
        state.authStatus = "signedOut";
      }
    }),

    userStartedSignOut: create.reducer(() => {
      // this is handled in RootReducer.ts and
      // resets the state to the default
    }),

    newUserAuthed: create.reducer(state => {
      // this drives navigation from sign-in screen and other "end" auth screens
      state.authStatus = "signedInNoAccount";
    }),

    userSignedInEvent: create.reducer((state, _action: PayloadAction<{ userId: UserId }>) => {
      state.authStatus = "signedIn";
      state.preAuthData = {};
    }),

    userSignedOutEvent: create.reducer(state => {
      state.authStatus = "signedOut";
      state.authedUser = {};

      // We initially had this, but it's unnecessary and was causing problems on web
      // because the auth callback was firing after we had set some properties on the
      // share external screen. When a user explicitly signs out, the state will be cleared
      // by the root reducer. We also clear it once the user auths.
      //state.preAuthData = {};
    }),

    userSignInErrored: create.reducer((state, action: PayloadAction<AuthErrorType>) => {
      delete state.preAuthData.signInMethod;
      state.preAuthData.authError = action.payload;
      state.authStatus = "signedOut";
    }),

    anonymousUserCreated: create.reducer(state => {
      if (!state.anonymousUserCreated) {
        state.anonymousUserCreated = true;
      }
    }),

    appMetadataRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.appMetadata, action.payload);
    }),

    appMetadataReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<AppMetadata>>) => {
      serverDataReceived(state.appMetadata, action.payload);
    }),

    appMetadataErrored: create.reducer(state => {
      serverDataErrored(state.appMetadata);
    }),

    appMetadataAvailable: create.reducer((_state, _action: PayloadAction<AppMetadata>) => {
      // NOP - this event exists for other slices to consume
    }),

    authedUserRequested: create.reducer((state, action: PayloadAction<EpochMs>) => {
      serverDataRequested(state.authedUser, action.payload);
    }),

    authedUserReceived: create.reducer((state, action: PayloadAction<ReceivedServerData<AuthedUserAndSettings>>) => {
      const { settings, checkpoints, appExpired, ...user } = action.payload.data;
      const receivedData = {
        ...action.payload,
        data: user,
      };

      serverDataReceived(
        state.authedUser,
        receivedData,
        ({ current, previous }) => current.version >= previous.version
      );
      state.systemSettings = {
        ...defaultSystemSettings,
        ...settings,
      };

      updateCheckpoints(state, checkpoints);
    }),

    authedUserErrored: create.reducer(state => {
      serverDataErrored(state.authedUser);
    }),

    navActionRequested: create.reducer(
      (state, action: PayloadAction<{ screenName: ScreenTypeRouteName; props: object }>) => {
        state.requestedNavAction = action.payload;
      }
    ),

    navActionHandled: create.reducer(state => {
      delete state.requestedNavAction;
    }),

    appFocused: create.reducer(state => {
      state.appFocused = true;
    }),

    appBlurred: create.reducer((state, action: PayloadAction<EpochMs>) => {
      state.appFocused = false;
      state.session.lastBlur = action.payload;
    }),

    newAppSession: create.reducer((state, action: PayloadAction<{ startTime: EpochMs; firstSession: boolean }>) => {
      state.session.start = action.payload.startTime;
      state.session.lastBlur = undefined;
    }),

    notificationTapped: create.reducer(
      (state, action: PayloadAction<ReceivedNotificationData<NotificationContext>>) => {
        state.tappedNotification = action.payload;
      }
    ),

    tappedNotificationHandled: create.reducer(state => {
      state.tappedNotification = undefined;
    }),

    diagnosticModeEnabled: create.reducer((state, action: PayloadAction<{ endTime: EpochMs }>) => {
      state.diagnosticModeEndTime = action.payload.endTime;
    }),

    diagnosticModeDisabled: create.reducer(state => {
      state.diagnosticModeEndTime = undefined;
    }),

    impersonateUserSelected: create.reducer((state, action: PayloadAction<{ userId?: UserId }>) => {
      state.impersonateUser = action.payload.userId;
    }),

    emailAuthStarted: create.reducer((state, action: PayloadAction<{ email: string }>) => {
      state.preAuthData.email = action.payload.email;
    }),

    emailSigninLinkReceived: create.reducer((state, action: PayloadAction<UrlString>) => {
      state.preAuthData.emailSigninLinkReceived = action.payload;
    }),

    clearEmailSignInLink: create.reducer(state => {
      state.preAuthData.emailSigninLinkReceived = undefined;
    }),

    userCreateDataReceived: create.reducer((state, action: PayloadAction<UserCreateData>) => {
      state.preAuthData.createData = action.payload;
    }),

    referralDataAvailable: create.reducer(
      (state, action: PayloadAction<{ referredBy: UserId; sharedRecipeId?: RecipeId; sourceRecipeId?: RecipeId }>) => {
        state.preAuthData.referredBy = action.payload.referredBy;
        state.preAuthData.sharedRecipeId = action.payload.sharedRecipeId;
        state.preAuthData.sharedSourceRecipeId = action.payload.sourceRecipeId;
      }
    ),

    tabsMounted: create.reducer(state => {
      state.navigation.tabsMounted = true;
    }),

    tabsUnmounted: create.reducer(state => {
      state.navigation.tabsMounted = false;
    }),

    householdMembersChanged: create.reducer((state, action: PayloadAction<EpochMs>) => {
      state.householdMembersChangedTime = action.payload;
    }),

    clearHouseholdMembersChanged: create.reducer(state => {
      delete state.householdMembersChangedTime;
    }),

    checkpointsCompleted: create.reducer((state, action: PayloadAction<UserCheckpoint[]>) => {
      const updates: Partial<NullableUserCheckpoints> = {};
      action.payload.forEach(c => (updates[c] = true));
      addUpdate(state.userCheckpoints, updates);
    }),

    checkpointsCleared: create.reducer((state, action: PayloadAction<UserCheckpoint[]>) => {
      const updates: Partial<NullableUserCheckpoints> = {};
      action.payload.forEach(c => (updates[c] = null));
      addUpdate(state.userCheckpoints, updates);
    }),

    numericCheckpointsUpdated: create.reducer(
      (state, action: PayloadAction<{ [key in NumericUserCheckpoint]?: number | null }>) => {
        addUpdate(state.userCheckpoints, action.payload);
      }
    ),

    checkpointsUpdateStarted: create.reducer(state => {
      updateStarted(state.userCheckpoints, "userCheckpoints");
    }),

    checkpointsUpdateErrored: create.reducer(state => {
      updateErrored(state.userCheckpoints, "userCheckpoints");
    }),

    checkpointsUpdateSucceeded: create.reducer((state, action: PayloadAction<WithVersion<UserCheckpoints>>) => {
      updateSucceeded(state.userCheckpoints, action.payload, "userCheckpoints");
    }),

    pushPermissionUpdated: create.reducer((state, action: PayloadAction<PushPermission>) => {
      state.pushPermission = action.payload;
    }),

    userPromptedForPush: create.reducer(state => {
      state.userLastPromptedForPush = defaultTimeProvider();
    }),

    DEBUG_clearUserPromptedForPush: create.reducer(state => {
      state.userLastPromptedForPush = undefined;
    }),

    appleFullNameReceived: create.reducer((state, action: PayloadAction<AppleFullName>) => {
      state.preAuthData.appleName = action.payload;
    }),

    cookingSessionFontScaleUpdated: create.reducer((state, action: PayloadAction<number>) => {
      state.deviceSettings.cookingSessionFontScale = action.payload;
    }),
    launchCarouselCompleted: create.reducer(state => {
      state.launchCarouselCompleted = true;
    }),
    DEBUG_clearLaunchCarouselCompleted: create.reducer(state => {
      state.launchCarouselCompleted = false;
    }),
    onboardingQuestionAnswered: create.reducer((state, action: PayloadAction<AppOnboardingQuestions>) => {
      state.onboardingQuestions = {
        ...state.onboardingQuestions,
        ...action.payload,
      };
    }),
    onboardingComplete: create.reducer(state => {
      state.onboardingQuestions = {};
    }),
    homeScreenOnboardingShouldBeShown: create.reducer(state => {
      state.showHomeScreenOnboarding = true;
    }),
    homeScreenOnboardingCompleted: create.reducer(state => {
      delete state.showHomeScreenOnboarding;
    }),
    appSignInLinkReceivedInWeb: create.reducer(
      (state, action: PayloadAction<SystemState["WEB_ONLY_appSignInLink"]>) => {
        if (action.payload && Platform.OS === "web") {
          state.WEB_ONLY_appSignInLink = action.payload;
        } else {
          delete state.WEB_ONLY_appSignInLink;
        }
      }
    ),
  }),

  extraReducers: builder => {
    // https://read.reduxbook.com/markdown/part2/10-reactive-state.html for background
    // Set time on every dispatch. In this way, the reducers can use it for time
    // calculations, making time based calculations easily testable
    builder.addDefaultCase(state => {
      state.time = defaultTimeProvider();
    });
  },
});

function updateCheckpoints(state: Draft<SystemState>, checkpoints: WithVersion<UserCheckpoints>): void {
  if (state.userCheckpoints.item.version <= checkpoints.version) {
    state.userCheckpoints.item = checkpoints;
    // We don't touch the updates here. I'm not sure this is 100% correct logic, but it works almost all the time
    // given the nature of checkpoints (they are set and never unset in the normal course of operation)
  }
}

export const {
  anonymousUserCreated,
  appBlurred,
  appFocused,
  appleFullNameReceived,
  appMetadataAvailable,
  appMetadataRequested,
  appMetadataReceived,
  appMetadataErrored,
  authedUserRequested,
  authedUserReceived,
  authedUserErrored,
  impersonateUserSelected,
  newAppSession,
  navActionHandled,
  navActionRequested,
  notificationTapped,
  numericCheckpointsUpdated,
  tappedNotificationHandled,
  userCanceledSignIn,
  userSignInErrored,
  userSignedInEvent,
  newUserAuthed,
  userSignedOutEvent,
  userStartedSignIn,
  userStartedSignOut,
  diagnosticModeEnabled,
  diagnosticModeDisabled,
  emailAuthStarted,
  emailSigninLinkReceived,
  clearEmailSignInLink,
  userCreateDataReceived,
  referralDataAvailable,
  tabsMounted,
  tabsUnmounted,
  householdMembersChanged,
  clearHouseholdMembersChanged,
  checkpointsCompleted,
  checkpointsCleared,
  checkpointsUpdateStarted,
  checkpointsUpdateErrored,
  checkpointsUpdateSucceeded,
  pushPermissionUpdated,
  userPromptedForPush,
  cookingSessionFontScaleUpdated,
  DEBUG_clearUserPromptedForPush,
  launchCarouselCompleted,
  DEBUG_clearLaunchCarouselCompleted,
  onboardingQuestionAnswered,
  onboardingComplete,
  homeScreenOnboardingShouldBeShown,
  homeScreenOnboardingCompleted,
  appSignInLinkReceivedInWeb,
} = systemSlice.actions;

export const systemReducer = systemSlice.reducer;
