import React from "react";
import { StyleSheet, View } from "react-native";
import { TTertiary } from "./Typography";

interface BadgeProps {
  count?: number;
  scale?: number;
  offset?: number;
}

export const Badge = React.memo((props: BadgeProps) => {
  if (!(props.count && props.count > 0)) {
    return null;
  }

  const scale = props.scale ?? 1;
  const offset = props.offset ?? 0;

  return (
    <View
      style={[
        styles.badge,
        {
          transform: [{ scaleX: scale }, { scaleY: scale }, { translateX: -offset }, { translateY: offset }],
        },
      ]}
    >
      <TTertiary color="white" fontWeight="medium">
        {props.count}
      </TTertiary>
    </View>
  );
});

const styles = StyleSheet.create({
  badge: {
    backgroundColor: "red",
    minWidth: 18,
    height: 18,
    borderRadius: 18,
    paddingHorizontal: 4,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -8,
    right: -8,
    zIndex: 1,
  },
});
