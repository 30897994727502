import { bottomThrow } from "./Util";
import { DurationMs, EpochMs, EpochSeconds } from "./UtilityTypes";

const msInMinute = 60 * 1000;
const msInHour = 60 * msInMinute;
const msInDay = 24 * msInHour;

export const defaultTimeProvider = () => Date.now() as EpochMs;

export function tryParseDate(str: string): EpochMs | undefined {
  try {
    const parsed = Date.parse(str);
    return Number.isNaN(parsed) ? undefined : (parsed as EpochMs);
  } catch (err) {
    // we don't expect to hit this - Date.parse shoudl just return NaN if it fails.
    return undefined;
  }
}

export interface Duration {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export function epochMsToSeconds(ms: EpochMs): EpochSeconds {
  return (ms / 1000) as EpochSeconds;
}

/**
 * Returns the number of days (1 day = 86,400 seconds) between 2 timestamps.
 * Order doesn't matter
 */
export function daysBetween(time1: EpochMs, time2: EpochMs): number {
  return Math.abs((time1 - time2) / msInDay);
}

/**
 * Returns the number of hours between 2 timestamps.
 * Order doesn't matter
 */
export function hoursBetween(time1: EpochMs, time2: EpochMs): number {
  return Math.abs((time1 - time2) / msInHour);
}

/**
 * Returns the number of minutes between 2 timestamps.
 * Order doesn't matter
 */
export function minutesBetween(time1: EpochMs, time2: EpochMs): number {
  return Math.abs((time1 - time2) / msInMinute);
}

/**
 * Returns the number of seconds between 2 timestamps.
 * Order doesn't matter
 */
export function secondsBetween(time1: EpochMs, time2: EpochMs): number {
  return Math.abs((time1 - time2) / 1000);
}

/**
 * Get an epoch for now +/- N days, where a day is simply 86400 * 1000 ms.
 */
export function addDays(start: EpochMs, n: number): EpochMs {
  return (start + n * msInDay) as EpochMs;
}

/**
 * Get an epoch for now +/- n hours
 */
export function addHours(start: EpochMs, n: number): EpochMs {
  return (start + n * msInHour) as EpochMs;
}

/**
 * Get an epoch for now +/- n minutes
 */
export function addMinutes(start: EpochMs, n: number): EpochMs {
  return (start + n * msInMinute) as EpochMs;
}

export function addSeconds(start: EpochMs, n: number): EpochMs {
  return (start + n * 1000) as EpochMs;
}

export function addMilliseconds(start: EpochMs, n: number): EpochMs {
  return (start + n) as EpochMs;
}

export function getDurationInMilliseconds(args: Duration): DurationMs {
  return ((args.days ?? 0) * 86400 * 1000 +
    (args.hours ?? 0) * 3600 * 1000 +
    (args.minutes ?? 0) * 60 * 1000 +
    (args.seconds ?? 0) * 1000 +
    (args.milliseconds ?? 0)) as DurationMs;
}

export function addDurations(d0: Duration, d1: Duration): Duration {
  const add = (n0: number | undefined, n1: number | undefined) => {
    const sum = (n0 ?? 0) + (n1 ?? 0);
    return sum ? sum : undefined;
  };
  return {
    days: add(d0.days, d1.days),
    hours: add(d0.hours, d1.hours),
    minutes: add(d0.minutes, d1.minutes),
    seconds: add(d0.seconds, d1.seconds),
    milliseconds: add(d0.milliseconds, d1.milliseconds),
  };
}

/**
 * Return duration in seconds. Milliseconds will be dropped.
 */
export function getDurationInSeconds(args: Duration): number {
  return Math.floor(getDurationInMilliseconds(args) / 1000);
}

export function getDurationInMinutes(args: Duration): number {
  return Math.floor(getDurationInSeconds(args) / 60);
}

export function getDurationInHours(args: Duration): number {
  return Math.floor(getDurationInMinutes(args) / 60);
}

/**
 * Given a duration, return a string in hh:mm:ss format if duration is > 1 hour
 * or in mm:ss format if duration < 1 hour
 * @param ms
 */
export function getFormattedDuration(
  duration: Duration,
  leadingZeros = true,
  format: "hh:mm:ss" | "XXh:XXm" = "hh:mm:ss"
): string {
  const ms = getDurationInMilliseconds(duration);
  const hours = Math.floor(ms / msInHour);
  const minutes = Math.floor((ms % msInHour) / msInMinute);
  const seconds = Math.floor((ms % msInMinute) / 1000);

  switch (format) {
    case "hh:mm:ss": {
      const minutesSeconds = `${minutes.toString().padStart(leadingZeros ? 2 : 1, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      if (hours) {
        return `${hours}:${minutesSeconds}`;
      }

      return minutesSeconds;
    }
    case "XXh:XXm": {
      return `${hours}h ${minutes}m`;
    }
    default:
      bottomThrow(format);
  }
}

export function getFormattedTime(epoch: EpochMs): string {
  const d = new Date(epoch);

  // just go with h:mm for now, and with 12-hour time
  const h = d.getHours() % 12;
  const hour = h === 0 ? 12 : h;
  return `${hour}:${d.getMinutes().toString().padStart(2, "0")}`;
  // hermes doesn't appear to implement the options that would make this only hours and minutes
  //return (new Date(epoch)).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit"});
}

export function getTimeForFilename(ts: EpochMs): string {
  const d = new Date(ts);
  return d.toISOString().replaceAll(":", ".");
}

export function isToday(ts: EpochMs): boolean {
  const now = new Date();
  const t = new Date(ts);

  return now.getDate() === t.getDate() && now.getMonth() === t.getMonth() && now.getFullYear() === t.getFullYear();
}
