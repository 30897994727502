import { switchReturn } from "@eatbetter/common-shared";
import React from "react";
import { useRef } from "react";
import { Button, StyleSheet, View } from "react-native";
import ToastInternal, { ToastOptions as ToastOptionsInternal } from "react-native-root-toast";
import { globalStyleColors, globalStyleConstants, Opacity } from "./GlobalStyles";
import { useScreenElementDimensions } from "./ScreenView";
import { TBody } from "./Typography";

interface ToastConfigBase<TType extends string> {
  type: TType;
  message: string;
}

interface UndoToastConfig extends ToastConfigBase<"undoToast"> {
  onPressUndo: () => void;
}

type InfoToastConfig = ToastConfigBase<"infoToast">;

type ToastConfig = UndoToastConfig | InfoToastConfig;

export function useToast() {
  const toastRef = useRef();
  const { bottomTabBarHeight } = useScreenElementDimensions();

  const toast = {
    show: (config: ToastConfig) => {
      const toastOptionsInternal: ToastOptionsInternal = {
        animation: true,
        duration: 3500,
        containerStyle: [styles.toastContainer, { marginBottom: bottomTabBarHeight }],
        hideOnPress: false,
        position: ToastInternal.positions.BOTTOM,
        opacity: Opacity.opaque,
        shadow: true,
        shadowColor: globalStyleColors.rgba("black", "light"),
      };

      const toastComponent: React.ReactNode = switchReturn(config.type, {
        undoToast: <UndoToast message={config.message} onPressUndo={(config as UndoToastConfig).onPressUndo} />,
        infoToast: <InfoToast message={config.message} />,
      });

      toastRef.current = ToastInternal.show(toastComponent, toastOptionsInternal);
    },
    hide: () => {
      ToastInternal.hide(toastRef.current);
    },
  };

  return toast;
}

const UndoToast = React.memo((props: Omit<UndoToastConfig, "type">) => {
  return (
    <View style={styles.toastContent}>
      <View>
        <TBody>{props.message}</TBody>
      </View>
      <View>
        <Button title="Undo" color={globalStyleColors.colorAccentCool} onPress={props.onPressUndo} />
      </View>
    </View>
  );
});

const InfoToast = React.memo((props: Omit<InfoToastConfig, "type">) => {
  return (
    <View style={[styles.toastContent, { justifyContent: "center" }]}>
      <View>
        <TBody>{props.message}</TBody>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  toastContainer: {
    width: "95%",
    paddingLeft: 1.5 * globalStyleConstants.unitSize,
    paddingRight: globalStyleConstants.unitSize,
    backgroundColor: globalStyleColors.colorAccentWarmLight,
    borderRadius: 20,
    zIndex: 10,
  },
  toastContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});
