import React, { useCallback, useEffect, useState } from "react";
import { Opacity, globalStyleColors, globalStyleConstants, globalStyles } from "./GlobalStyles";
import { Insets, LayoutAnimation, Platform, StyleSheet, View, ViewProps } from "react-native";
import { Pressable } from "./Pressable";
import { Spinner } from "./Spinner";
import {
  IconAddUser,
  IconArrowUpLeft,
  IconBook,
  IconCheck,
  IconChevronLeft,
  IconComment,
  IconEditSquare,
  IconEx,
  IconPlayFilled,
  IconPlus,
  IconSearchUsers,
  IconShare,
} from "./Icons";
import { TBody, THeading2, TSecondary, TTertiary } from "./Typography";
import { ContainerPadded } from "./Containers";
import { UrlString, switchReturn } from "@eatbetter/common-shared";
import { Spacer } from "./Spacer";
import { useScreenElementDimensions } from "./ScreenView";
import { AppRecipe, isKnownAuthorId, isKnownPublisherId } from "@eatbetter/recipes-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { openWebpage } from "../lib/util/WebUtil";
import { useDispatch } from "../lib/redux/Redux";
import { AnalyticsFollowUnfollowContext, reportBookPurchaseLinkClicked } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import {
  useAuthedUserId,
  useCheckpointCompleted,
  useIsAnonymousUser,
  useSystemSetting,
} from "../lib/system/SystemSelectors";
import { useIsFollowingEntity } from "../lib/social/SocialSelectors";
import { Haptics } from "./Haptics";
import { followUnfollow } from "../lib/social/SocialThunks";
import { NavApi, useScreen } from "../navigation/ScreenContainer";
import { navToAnonymousSignin } from "../lib/util/AnonymousSignIn";
import { Alert } from "./Alert/Alert";
import { SocialEntityId } from "@eatbetter/posts-shared";
import { navTree } from "../navigation/NavTree";
import { useBottomSheet } from "../screens/BottomSheetScreen";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { smallScreenBreakpoint } from "./Responsive";

const strings = {
  save: "Save",
  cancel: "Cancel",
  buyBook: "Buy this book",
  follow: "Follow",
  following: "Following",
  followAction: "follow",
  close: "Close",
  viewAll: "View all",
  unfollowAlert: {
    title: "Unfollow",
    body: "Unfollow this account?",
    confirm: "Unfollow",
    cancel: "Cancel",
  },
  knownFollowSheet: {
    headline: "Got it!",
    subheadline:
      "We'll let you know when new recipes are published and personalize your search results based on this collection.",
    cta: "Close",
  },
};

interface CommonButtonProps {
  onPress: () => void;
  waiting?: boolean;
  disabled?: boolean;
  singlePress?: boolean;
}

export const buttonCirclePlusSize = 64;

export const ButtonCirclePlus = React.memo((props: CommonButtonProps & { shadow?: boolean }) => {
  const halfSize = buttonCirclePlusSize / 2;
  const backgroundColor = globalStyleColors.colorAccentCool;
  const shadowStyle = props.shadow ? globalStyles.shadow : {};
  const inner = props.waiting ? <Spinner color="light" /> : <IconPlus size={32} color="white" opacity="opaque" />;
  return (
    <Pressable onPress={props.onPress} disabled={props.disabled}>
      <View
        style={[
          {
            width: buttonCirclePlusSize,
            height: buttonCirclePlusSize,
            borderRadius: halfSize,
            backgroundColor,
            justifyContent: "center",
            alignItems: "center",
          },
          shadowStyle,
        ]}
      >
        {inner}
      </View>
    </Pressable>
  );
});

export const AddItemButton = React.memo((props: { onPress: () => void; waiting?: boolean }) => {
  const { bottomTabBarHeight: bottom } = useScreenElementDimensions();

  return (
    <View style={{ position: "absolute", bottom, right: 0, zIndex: 10 }}>
      <ContainerPadded right={2} bottom={2}>
        <ButtonCirclePlus waiting={props.waiting} onPress={props.onPress} shadow />
      </ContainerPadded>
    </View>
  );
});

export interface ButtonRectangleProps extends CommonButtonProps {
  type: "submit" | "cancel" | "secondary" | "acknowledge";
  title: string | React.ReactElement;
  icon?: "addFriends" | "searchUsers" | "check" | "library" | "edit";
  size?: ButtonHeight;
  shadow?: boolean;
  noFeedback?: boolean;
}

export const ButtonRectangle = React.memo((props: ButtonRectangleProps) => {
  const buttonStyle = switchReturn(props.type, {
    submit: styles.submit,
    cancel: styles.cancel,
    secondary: styles.secondary,
    acknowledge: styles.acknowledge,
  });

  const contentStyle = switchReturn(props.type, {
    submit: { textColor: "white", spinnerColor: "light" },
    cancel: { textColor: globalStyleColors.colorAccentCool, spinnerColor: "accentCool" },
    secondary: { textColor: globalStyleColors.colorAccentCool, spinnerColor: "accentCool" },
    acknowledge: { textColor: globalStyleColors.white, spinnerColor: "light" },
  });

  const shadowStyle: ViewProps["style"] = props.shadow === true ? styles.shadow : {};

  const minHeight = getHeight(props.size ?? "medium");
  const borderRadius = minHeight / 2;
  const minWidth = getWidth(props.size ?? "medium");

  const iconSize = switchReturn(props.size ?? "medium", {
    small: 18,
    medium: 22,
    large: 24,
  });

  const icon = props.icon
    ? switchReturn(props.icon, {
        addFriends: <IconAddUser strokeWidth={2} opacity="opaque" color={contentStyle.textColor} size={iconSize} />,
        searchUsers: (
          <IconSearchUsers strokeWidth={2} opacity="opaque" color={contentStyle.textColor} size={iconSize} />
        ),
        check: <IconCheck strokeWidth={2} opacity="opaque" color={contentStyle.textColor} size={iconSize} />,
        library: <IconBook strokeWidth={1.75} opacity="opaque" color={contentStyle.textColor} size={iconSize} />,
        edit: <IconEditSquare strokeWidth={2} opacity="opaque" color={contentStyle.textColor} size={iconSize} />,
      })
    : undefined;

  const content = (
    <>
      {!!props.waiting && <Spinner color={contentStyle.spinnerColor as "accentCool" | "light"} />}
      {!props.waiting && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {!!props.icon && (
            <>
              {icon}
              <Spacer horizontal={iconSize / 2} unit="pixels" />
            </>
          )}
          {typeof props.title === "string" && (
            <>
              {props.size !== "large" && (
                <TSecondary color={contentStyle.textColor} fontWeight="medium" adjustsFontSizeToFit numberOfLines={1}>
                  {props.title}
                </TSecondary>
              )}
              {props.size === "large" && (
                <TBody color={contentStyle.textColor} fontWeight="medium" adjustsFontSizeToFit numberOfLines={1}>
                  {props.title}
                </TBody>
              )}
            </>
          )}
          {typeof props.title !== "string" && props.title}
        </View>
      )}
    </>
  );

  return (
    <>
      {!(Platform.OS === "web" && typeof props.onPress === "string") && (
        <Pressable
          style={[styles.rectangle, buttonStyle, shadowStyle, { minHeight, borderRadius, minWidth }]}
          onPress={props.onPress}
          disabled={props.disabled || props.waiting}
          singlePress={props.singlePress}
          noFeedback={props.noFeedback}
        >
          {content}
        </Pressable>
      )}
      {Platform.OS === "web" && typeof props.onPress === "string" && (
        <a href={props.onPress} style={{ flex: 1, textDecoration: "none" }}>
          <View style={[styles.rectangle, buttonStyle, shadowStyle, { minHeight, borderRadius, minWidth }]}>
            {content}
          </View>
        </a>
      )}
    </>
  );
});

export const IconButton = React.memo(
  (props: {
    type: "close" | "arrowUpLeft" | "share" | "comment" | "back";
    onPress: () => void;
    size?: "small" | "medium" | "large";
    opacity?: keyof typeof Opacity;
    hitSlop?: Insets | number;
    singlePress?: boolean;
    color?: keyof typeof globalStyleColors;
  }) => {
    const size = switchReturn(props.size ?? "medium", {
      small: 18,
      medium: 24,
      large: 36,
    });

    const opacity = props.opacity ?? "opaque";

    const icon = switchReturn(props.type, {
      close: <IconEx opacity={opacity} size={size} color={props.color} />,
      arrowUpLeft: <IconArrowUpLeft opacity={opacity} size={size} color={props.color} />,
      share: <IconShare opacity={opacity} size={size} color={props.color} />,
      comment: <IconComment opacity={opacity} size={size} color={props.color} />,
      back: <IconChevronLeft opacity={opacity} size={size} color={props.color} />,
    });

    return (
      <Pressable onPress={props.onPress} hitSlop={props.hitSlop} singlePress={props.singlePress}>
        {icon}
      </Pressable>
    );
  }
);

interface SubmitAndCancelButtonsProps {
  onSubmit: () => void | Promise<void>;
  onCancel: () => void | Promise<void>;

  submitDisabled?: boolean;

  /**
   * By default, the spinner is shown while the onSubmit action is pending. This prop can be used
   * if it needs to be manually controlled.
   */
  waitingForSubmit?: boolean;

  /**
   * Defautls to "Save"
   */
  submitText?: string;

  /**
   * Defaults to "Cancel"
   */
  cancelText?: string;

  /**
   * Default to "large"
   */
  buttonSize?: ButtonHeight;
}

export const SubmitAndCancelButtons = React.memo((props: SubmitAndCancelButtonsProps) => {
  return (
    <LeftAndRightButtons
      leftButtonText={props.cancelText ?? strings.cancel}
      rightButtonText={props.submitText ?? strings.save}
      onPressLeftButton={props.onCancel}
      onPressRightButton={props.onSubmit}
      buttonSize={props.buttonSize ?? "large"}
      rightButtonDisabled={props.submitDisabled}
    />
  );
});

interface LeftAndRightButtonsProps {
  rightButtonText: string;
  leftButtonText: string;
  onPressRightButton: () => void | Promise<void>;
  onPressLeftButton: () => void | Promise<void>;
  rightButtonDisabled?: boolean;
  buttonSize?: ButtonHeight;
}

export const LeftAndRightButtons = React.memo((props: LeftAndRightButtonsProps) => {
  const [waitingFor, setWaitingFor] = useState<"left" | "right" | undefined>();

  const onPressLeft = useCallback(async () => {
    try {
      setWaitingFor("left");
      await props.onPressLeftButton();
    } finally {
      setWaitingFor(undefined);
    }
  }, [props.onPressLeftButton]);

  const onPressRight = useCallback(async () => {
    try {
      setWaitingFor("right");
      await props.onPressRightButton();
    } finally {
      setWaitingFor(undefined);
    }
  }, [props.onPressRightButton]);

  const disabled = waitingFor !== undefined;

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <View style={{ flex: 1 }}>
        <ButtonRectangle
          onPress={onPressLeft}
          type="cancel"
          title={props.leftButtonText}
          disabled={disabled}
          waiting={waitingFor === "left"}
          size={props.buttonSize}
        />
      </View>
      <Spacer horizontal={1} />
      <View style={{ flex: 1 }}>
        <ButtonRectangle
          onPress={onPressRight}
          type="submit"
          title={props.rightButtonText}
          disabled={disabled || props.rightButtonDisabled}
          waiting={waitingFor === "right"}
          size={props.buttonSize}
        />
      </View>
    </View>
  );
});

export const SmallOvalButton = React.memo((props: CommonButtonProps & { title: string }) => {
  return (
    <Pressable onPress={props.onPress}>
      <View style={{ width: 130, height: 40, backgroundColor: "black", borderRadius: 20, justifyContent: "center" }}>
        {!!props.waiting && <Spinner color="light" />}
        {!props.waiting && (
          <TTertiary align="center" fontWeight="medium" color="white">
            {props.title}
          </TTertiary>
        )}
      </View>
    </Pressable>
  );
});

export const BuyBookButton = React.memo((props: { recipe: AppRecipe; bookPurchaseLink: UrlString }) => {
  const dispatch = useDispatch();

  const recipeId = props.recipe.id;

  const onPressBuyBook = useCallback(() => {
    const event = reportBookPurchaseLinkClicked({ recipe: props.recipe });
    dispatch(analyticsEvent(event));

    if (!props.bookPurchaseLink) {
      displayUnexpectedErrorAndLog("BuyBookButton: onPressBuyBook invoked but bookPurchaseLink is falsy", undefined, {
        bookPurchaseLink: props.bookPurchaseLink,
        recipeId,
      });
      return;
    }

    openWebpage(props.bookPurchaseLink);
  }, [props.bookPurchaseLink, recipeId]);

  return (
    <ButtonRectangle type="secondary" size="medium" title={strings.buyBook} onPress={onPressBuyBook} singlePress />
  );
});

export const PlayButton = React.memo(
  (props: {
    onPress: () => void;
    disabled?: boolean;
    noFeedback?: boolean;
    size?: "medium" | "xlarge";
    iconColor?: string;
    iconBackgroundColor?: string;
  }) => {
    const size = switchReturn(props.size ?? "medium", {
      medium: 48,
      xlarge: 72,
    });

    return (
      <Pressable
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 4,
          overflow: "hidden",
        }}
        onPress={props.onPress}
        disabled={props.disabled}
        noFeedback={props.noFeedback}
      >
        <View
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: props.iconBackgroundColor ?? globalStyleColors.rgba("blackSoft", "medium") },
          ]}
        />
        <IconPlayFilled size={size / 1.6} opacity="opaque" color={props.iconColor ?? globalStyleColors.white} />
      </Pressable>
    );
  }
);

interface FollowEntityButtonProps {
  entityId: SocialEntityId;
  context: AnalyticsFollowUnfollowContext;
  width?: "flexed" | "contained";
}

export const FollowEntityButton = React.memo((props: FollowEntityButtonProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const authedUserId = useAuthedUserId();
  const followersEnabled = !!useSystemSetting("followers");
  const knownFollowExplained = useCheckpointCompleted("known_follow_explained");
  const isFollowed = useIsFollowingEntity(props.entityId);
  const isAnonUser = useIsAnonymousUser();

  const [waiting, setWaiting] = useState(false);

  const doFollowUnfollow = useCallback(async () => {
    if (isAnonUser) {
      navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.followAction });
      return;
    }

    Haptics.feedback("itemStatusChanged");

    try {
      await dispatch(
        followUnfollow(
          { entityId: props.entityId, action: isFollowed ? "unfollow" : "follow" },
          props.context,
          setWaiting
        )
      );

      if (
        !knownFollowExplained &&
        !isFollowed &&
        (isKnownAuthorId(props.entityId) || isKnownPublisherId(props.entityId))
      ) {
        navToKnownFollowExplainerSheet(screen.nav);
      } else if (screen.nav.focused) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }
    } catch (err) {
      displayUnexpectedErrorAndLog("Error calling doFollowUnfollow() in FollowEntityButton component", err, {
        targetUserId: props.entityId,
        isFollowed,
      });
    }
  }, [isAnonUser, props.entityId, props.context, isFollowed, setWaiting, knownFollowExplained, screen.nav]);

  if (!followersEnabled) {
    return null;
  }

  if (props.entityId === authedUserId) {
    return null;
  }

  return (
    <FollowButton isFollowed={isFollowed} onFollowUnfollow={doFollowUnfollow} waiting={waiting} width={props.width} />
  );
});

const FollowButton = React.memo(
  (props: { isFollowed: boolean; onFollowUnfollow: () => void; waiting?: boolean; width?: "flexed" | "contained" }) => {
    const width = switchReturn(props.width ?? "flexed", {
      flexed: { flex: 1 },
      contained: { minWidth: 96, maxWidth: "50%" as const },
    });

    const onPress = useCallback(() => {
      if (props.isFollowed) {
        // Confirm unfollow
        Alert.alert(strings.unfollowAlert.title, strings.unfollowAlert.body, [
          {
            type: "save",
            text: strings.unfollowAlert.confirm,
            onPress: props.onFollowUnfollow,
          },
          {
            type: "cancel",
            text: strings.unfollowAlert.cancel,
            onPress: () => {},
          },
        ]);
      } else {
        props.onFollowUnfollow();
      }
    }, [props.isFollowed, props.onFollowUnfollow]);

    return (
      <View style={width}>
        <ButtonRectangle
          type={props.isFollowed ? "secondary" : "submit"}
          size="small"
          title={props.isFollowed ? strings.following : strings.follow}
          onPress={onPress}
          waiting={props.waiting}
          noFeedback
          icon={props.isFollowed && props.width !== "contained" ? "check" : undefined}
        />
      </View>
    );
  }
);

function navToKnownFollowExplainerSheet(nav: NavApi) {
  nav.modal(navTree.get.screens.bottomSheet, {
    height: 250,
    content: <KnownFollowExplainerSheet />,
  });
}

const KnownFollowExplainerSheet = React.memo(() => {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();

  const onPress = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
  }, [bottomSheet]);

  useEffect(() => {
    dispatch(checkpointsCompleted(["known_follow_explained"]));
  }, []);

  return (
    <View style={{ padding: globalStyleConstants.defaultPadding }}>
      <View>
        <THeading2 align="center">{strings.knownFollowSheet.headline}</THeading2>
        <Spacer vertical={1} />
        <TBody align="center">{strings.knownFollowSheet.subheadline}</TBody>
      </View>
      <Spacer vertical={3} />
      <ButtonRectangle type="submit" size="large" title={strings.knownFollowSheet.cta} onPress={onPress} />
    </View>
  );
});

export const TextButton = React.memo(
  (props: {
    onPress: () => void;
    text: string;
    color?: string;
    disabled?: boolean;
    bold?: boolean;
    underlined?: boolean;
  }) => {
    return (
      <Pressable onPress={props.onPress} disabled={props.disabled}>
        <TBody
          fontWeight={props.bold ? "medium" : undefined}
          underline={props.underlined}
          color={props.color ?? globalStyleColors.colorAction}
        >
          {props.text}
        </TBody>
      </Pressable>
    );
  }
);

type ButtonHeight = "small" | "medium" | "large";

function getHeight(buttonHeight: ButtonHeight) {
  return switchReturn(buttonHeight, {
    small: 36,
    medium: 48,
    large: 56,
  });
}

function getWidth(buttonHeight: ButtonHeight) {
  return switchReturn(buttonHeight, {
    small: 7 * globalStyleConstants.unitSize,
    medium: 12 * globalStyleConstants.unitSize,
    large: 14 * globalStyleConstants.unitSize,
  });
}

const styles = StyleSheet.create({
  rectangle: {
    width: "100%",
    flexShrink: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: globalStyleConstants.unitSize,
    // iPad
    maxWidth: smallScreenBreakpoint,
    alignSelf: "center",
  },
  shadow: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 8,
    shadowOpacity: 0.25,
  },
  submit: {
    backgroundColor: globalStyleColors.colorAction,
  },
  cancel: {
    backgroundColor: globalStyleColors.white,
    borderWidth: 1,
    borderColor: globalStyleColors.colorAccentCool,
  },
  secondary: {
    backgroundColor: globalStyleColors.white,
    borderColor: globalStyleColors.rgba("colorAction", "medium"),
    borderWidth: 1,
  },
  acknowledge: {
    backgroundColor: globalStyleColors.blackSoft,
  },
});
