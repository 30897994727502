import { useScreen } from "../../navigation/ScreenContainer";
import { useEffect, useState } from "react";
import { useSelector } from "../redux/Redux";
import { useCheckpointCompleted } from "../system/SystemSelectors";
import { daysBetween, defaultTimeProvider, EpochMs } from "@eatbetter/common-shared";
import { selectRecipeListSections } from "../composite/RecipeListSelectors";
import { useInitialRecipeLoadComplete } from "./RecipesSelectors";
import { getCreateSelectorWithCacheSize } from "../redux/CreateSelector";

export type RecipeLibraryHint = "highlightAddButton" | "highlightRecipe";

// show each hint no more than once per day (or when the app is restarted, since this is only in memory)
let lastShownAdd = 0 as EpochMs;
let lastShownRecipe = 0 as EpochMs;

export function useRecipeLibraryHint(): RecipeLibraryHint | undefined {
  const screen = useScreen();
  const [currentHint, setCurrentHint] = useState<RecipeLibraryHint | undefined>();
  const highlightAddSet = useSelector(s => !!s.recipes.highlightLibraryAddButton);
  const { hasSpotlightableRecipe, hasAnyRecipe } = useUserHasSpotlightableRecipe();
  const recipeViewed = useCheckpointCompleted("recipeViewedInLibrary");
  const recipesLoaded = useInitialRecipeLoadComplete();

  useEffect(() => {
    if (screen.nav.focused) {
      if (!recipesLoaded) {
        return;
      }

      // only show one hint per focus. If we already have one set, no need to reevalute.
      if (currentHint) {
        return;
      }

      if (highlightAddSet) {
        // always highlight if it's explicitly requested
        lastShownAdd = defaultTimeProvider();
        setCurrentHint("highlightAddButton");
      } else if (!hasAnyRecipe && daysBetween(defaultTimeProvider(), lastShownAdd) > 1) {
        lastShownAdd = defaultTimeProvider();
        setCurrentHint("highlightAddButton");
      } else if (hasSpotlightableRecipe && !recipeViewed && daysBetween(defaultTimeProvider(), lastShownRecipe) > 1) {
        lastShownRecipe = defaultTimeProvider();
        setCurrentHint("highlightRecipe");
      } else {
        setCurrentHint(undefined);
      }
    } else {
      // we only show one hint per focus, so clear it when we lose focus
      setCurrentHint(undefined);
    }
  }, [screen.nav.focused, hasSpotlightableRecipe, hasAnyRecipe, highlightAddSet, recipeViewed, recipesLoaded]);

  return currentHint;
}

const selectUserHasSpotlightableRecipe = getCreateSelectorWithCacheSize(1)(
  [s => selectRecipeListSections(s), s => s.recipes.entities],
  (sections, recipes) => {
    const hasInProgress = sections.cookingSessionRecipes.length > 0;
    const hasOnList = sections.groceryListRecipes.length > 0;
    const hasOther = sections.otherRecipes.length > 0;

    // count any section's recipes for hasAnyRecipe
    const hasAnyRecipe = hasOther || hasOnList || hasInProgress;

    // only count "other" recipes for actionable recipes.
    const firstRecipeId = sections.otherRecipes[0]?.recipeId;
    if (!firstRecipeId) {
      return { hasSpotlightableRecipe: false, hasAnyRecipe };
    }

    const recipe = recipes[firstRecipeId];

    if (!recipe) {
      return { hasSpotlightableRecipe: false, hasAnyRecipe };
    }

    const hasSpotlightableRecipe =
      recipe.status === "complete" && !!recipe.ingredients.sections[0]?.items[0] && !hasInProgress && !hasOnList;
    return { hasSpotlightableRecipe, hasAnyRecipe };
  }
);

/**
 * True if the *first* recipe in the default section of the recipe list is status completed and has at least one ingredient
 */
const useUserHasSpotlightableRecipe = (): { hasSpotlightableRecipe: boolean; hasAnyRecipe: boolean } =>
  useSelector(selectUserHasSpotlightableRecipe);
