import React from "react";
import { StyleSheet, View } from "react-native";
import { Pressable } from "../Pressable";
import { BlurOverlayComponent, BlurOverlayProps, BlurOverlayStyle } from "./BlurOverlayInterfaces";
import { switchReturn } from "@eatbetter/common-shared";

export const BlurOverlay: BlurOverlayComponent = React.memo((props: BlurOverlayProps) => {
  const blurCssValue = (blurAmountPx: number) =>
    `blur(${blurAmountPx}px) saturate(100%) contrast(60%) brightness(130%)`;

  const config = switchReturn<BlurOverlayStyle, { backdropFilter: string; WebkitBackdropFilter: string }>(
    props.style ?? "screenOverlay",
    {
      screenOverlay: {
        backdropFilter: blurCssValue(3),
        WebkitBackdropFilter: blurCssValue(3),
      },
      chrome: {
        backdropFilter: blurCssValue(100),
        WebkitBackdropFilter: blurCssValue(100),
      },
      photoBlur: {
        backdropFilter: blurCssValue(100),
        WebkitBackdropFilter: blurCssValue(100),
      },
    }
  );

  // z-index needs to be less than the z-index for the BottomSheet
  const zIndex = props.zIndex ?? 999;

  return (
    <View style={[StyleSheet.absoluteFill, { zIndex, ...config }]}>
      {!!props.onPress && <Pressable onPress={props.onPress} style={{ flex: 1 }} />}
    </View>
  );
});
