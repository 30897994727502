import { Reactor } from "../redux/Reactors";
import { loadRecipes, loadRecipesScript } from "./RecipesThunks";
import { EpochMs, nowOrLaterDelayFromLast } from "@eatbetter/common-shared";
import { Platform } from "react-native";
import { shouldFetch } from "../redux/ShouldFetch";
import { selectFetchIntervalForHouseholdUpdate, selectIsHouseholdUpdatePeriodActive } from "../system/SystemSelectors";

const fetchRecipesReactor: Reactor = state => {
  // the recipes were making web redux-persist crawl, so just skipping them for now
  if (Platform.OS === "web") {
    return undefined;
  }

  const fetchResult = shouldFetch("recipes.meta", state, s => s.recipes.meta, {
    staleThresholdSeconds: selectFetchIntervalForHouseholdUpdate(state, 300),
  });

  const fetchFullListNow =
    selectIsHouseholdUpdatePeriodActive(state) ||
    nowOrLaterDelayFromLast({
      last: state.recipes.meta.lastFullGet ?? (0 as EpochMs),
      delayInSeconds: 86400,
      timeNow: state.system.time,
    }).now;

  if (fetchResult.now) {
    return {
      dispatch: loadRecipes(fetchFullListNow ? "full" : "partial"),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const fetchRecipesScriptReactor: Reactor = state => {
  const fetchResult = shouldFetch("recipes.script", state, s => s.recipes.script, {
    staleThresholdSeconds: 43200,
  });

  if (fetchResult.now) {
    return { dispatch: loadRecipesScript() };
  }

  if (fetchResult.laterIn) {
    return { kickInMs: fetchResult.laterIn };
  }

  return undefined;
};

export const recipesReactors = [fetchRecipesReactor, fetchRecipesScriptReactor];
