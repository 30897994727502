import { useCallback, useMemo, useRef } from "react";
import { ScreenView } from "../components/ScreenView";
import { useIsAnonymousUser } from "../lib/system/SystemSelectors";
import { useTabRenderDelay } from "../lib/util/UseTabRenderDelay";
import { navTree } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { SocialFeed, SocialFeedImperativeHandle } from "../components/social/SocialFeed";
import { useDispatch } from "../lib/redux/Redux";
import { loadNewProfilePosts, loadOlderProfilePosts } from "../lib/social/SocialThunks";
import { log } from "../Log";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { profileFeed } from "../lib/social/SocialSlice";
import { usePostOptionsMenu } from "../components/social/SocialPost";
import { globalStyleColors } from "../components/GlobalStyles";
import { ProfileCard } from "../components/social/ProfileCard";
import { HeaderProps } from "../components/ScreenHeaders";
import React from "react";
import { TBody, THeading1 } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { navToAnonymousSignin } from "../lib/util/AnonymousSignIn";
import { useDebugFeedScrollText, useProfileInfo } from "../lib/social/SocialSelectors";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import {
  reportProfileRecipeCountPressed,
  reportProfileCookedCountPressed,
  reportProfileFollowingCountPressed,
} from "../lib/analytics/AnalyticsEvents";
import { shareAuthedUserProfileLink } from "../lib/share/ShareThunks";
import { useLibraryRecipeCount } from "../lib/recipes/RecipesSelectors";

const strings = {
  screenHeader: "My Profile",
  emptyState: {
    heading: "You haven't cooked anything yet",
    subhead: ["To get started, open any recipe in\n", "your library", " and tap "],
    startCooking: "Start Cooking.",
    cta: "Show me",
  },
  shareProfileAction: "share your profile",
};

export const ProfileScreen = withScreenContainer("ProfileScreen", () => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const shouldRender = useTabRenderDelay(500, screen.nav.focused);
  const profileInfo = useProfileInfo();
  const isAnonUser = useIsAnonymousUser();
  const libraryRecipeCount = useLibraryRecipeCount();
  const feedRef = useRef<SocialFeedImperativeHandle>(null);

  const onPressShareProfile = useCallback(async () => {
    if (isAnonUser) {
      navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.shareProfileAction });
      return;
    }

    await dispatch(shareAuthedUserProfileLink("Profile Screen Share Icon"));
  }, [dispatch]);

  const onPressRecipes = useCallback(() => {
    dispatch(analyticsEvent(reportProfileRecipeCountPressed("ownProfile")));
    screen.nav.switchTab("recipesTab", navTree.get.screens.recipesHome);
  }, [dispatch, screen.nav.switchTab]);

  const onPressCooks = useCallback(() => {
    dispatch(analyticsEvent(reportProfileCookedCountPressed("ownProfile")));
    feedRef.current?.scrollToFirstPost();
  }, [feedRef.current]);

  const onPressFollowing = useCallback(() => {
    dispatch(analyticsEvent(reportProfileFollowingCountPressed("ownProfile")));
    screen.nav.goTo("push", navTree.get.screens.followersFollowing);
  }, [dispatch, screen.nav.goTo]);

  const onPressSettings = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.settings);
  }, [screen.nav.goTo]);

  const onPullToRefresh = useCallback(() => {
    return dispatch(loadNewProfilePosts()).catch(err => {
      displayUnexpectedErrorAndLog(
        "Unexpected error calling loadNewProfilePosts in ProfileScreen pull to refresh",
        err
      );
    });
  }, [dispatch]);

  const onEndReached = useCallback(() => {
    dispatch(loadOlderProfilePosts()).catch(err =>
      log.errorCaught("Unexpected error calling loadOlderProfilePosts in ProfileScreen", err)
    );
  }, [dispatch]);

  const headerComponent = useMemo(
    () => (
      <ProfileCard
        photo={profileInfo?.user.photo}
        name={profileInfo?.user.name}
        username={profileInfo?.user.username}
        profileBio={profileInfo?.user.profileBio}
        profileLinks={{ links: profileInfo?.user.profileLinks.map(i => i.url) }}
        primaryStats={[
          { type: "recipes", value: profileInfo?.countRecipes ?? libraryRecipeCount, onPress: onPressRecipes },
          { type: "cooks", value: profileInfo?.countCooks ?? 0, onPress: onPressCooks },
          { type: "following", value: profileInfo?.countFollowing ?? 0, onPress: onPressFollowing },
        ]}
      />
    ),
    [profileInfo, libraryRecipeCount, onPressRecipes, onPressCooks, onPressFollowing]
  );

  const feedEmptyState = useMemo(() => <EmptyState />, []);

  const onPressOptionsMenuButton = usePostOptionsMenu();

  const debugFeedScrollText = useDebugFeedScrollText();

  const header = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      style: "tabRoot",
      title: debugFeedScrollText ?? strings.screenHeader,
      right: {
        type: "twoButtons",
        left: { type: "share", onPress: onPressShareProfile },
        right: { type: "settings", onPress: onPressSettings },
      },
      backgroundColor: globalStyleColors.white,
    };
  }, [onPressShareProfile, onPressSettings, debugFeedScrollText]);

  return (
    <ScreenView
      header={header}
      paddingHorizontal={false}
      paddingVertical={false}
      scrollView={false}
      loading={!shouldRender}
    >
      <SocialFeed
        ref={feedRef}
        feed={profileFeed}
        onEndReached={onEndReached}
        onPullToRefresh={onPullToRefresh}
        onPressOptionsMenuButton={onPressOptionsMenuButton}
        headerComponent={headerComponent}
        feedEmptyStateComponent={feedEmptyState}
        paddingTop={"headerHeight"}
        paddingBottom={"bottomTabBarHeight"}
      />
    </ScreenView>
  );
});

const EmptyState = React.memo(() => {
  const { nav } = useScreen();
  const onTapLibrary = () => {
    nav.switchTab("recipesTab", navTree.get.screens.recipesHome);
  };

  return (
    <>
      <Spacer vertical={1} />
      <THeading1 align="center" numberOfLines={1} adjustsFontSizeToFit>
        {strings.emptyState.heading}
      </THeading1>
      <Spacer vertical={0.5} />
      <TBody align="center">
        <TBody>{strings.emptyState.subhead[0]}</TBody>
        <TBody onPress={onTapLibrary} color={globalStyleColors.colorTextLink} fontWeight="medium">
          {strings.emptyState.subhead[1]}
        </TBody>
        <TBody>{strings.emptyState.subhead[2]}</TBody>
        <TBody fontWeight="medium">{strings.emptyState.startCooking}</TBody>
      </TBody>
    </>
  );
});
