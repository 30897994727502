import {
  defaultTimeProvider,
  EpochMs,
  LogContext,
  Logger,
  LogLevel,
  LogLine,
  setLogger,
} from "@eatbetter/common-shared";
import { CurrentEnvironment } from "./CurrentEnvironment";
import { RemoteLogger } from "./RemoteLogger";
import { NativeModules } from "react-native";
const { NativeUtil } = NativeModules;

/* eslint-disable no-console */

export interface SavedLogLine {
  time: EpochMs;
  level: LogLevel;
  message: string;
  context: LogContext;
}

const nativeLoggingEnabled = false;

const logNative = (level: LogLevel, message: string, context: LogContext, error?: any): void => {
  if (!nativeLoggingEnabled || !NativeUtil?.log) {
    return;
  }

  const ctx = JSON.stringify(context);
  let err = "";
  if (error) {
    err = ` ${formatError(error)}`;
  }
  const msg = `DeglazeApp ${level.toUpperCase()} ${message} ${ctx}${err}`;
  NativeUtil.log(msg);
};

class AppLogger implements Logger {
  private remoteLogger: RemoteLogger | undefined;
  private saveLogs = false;
  private savedLogs: SavedLogLine[] = [];

  error(msg: string, ctx: LogContext = {}): void {
    this.logConsole(console.error, msg, ctx);
    logNative("error", msg, ctx);
    this.remoteLogger?.log(
      {
        level: "error",
        message: msg,
        context: ctx,
      },
      false
    );
    this.saveLog("error", msg, ctx);
  }

  errorCaught(msg: string, err: any, ctx: LogContext = {}, level: LogLevel = "error"): void {
    this.logConsole(console[level], msg, ctx, err);
    logNative(level, `ERROR CAUGHT: ${msg}`, ctx);
    const context = { ...this.getRemoteContext(ctx), error: formatError(err) };
    if (level !== "debug" && this.remoteLogger) {
      this.remoteLogger.log(
        {
          level,
          message: msg,
          context,
        },
        false
      );
    }
    this.saveLog(level, msg, context);
  }

  debug(msg: string, ctx: LogContext = {}): void {
    this.logConsole(console.debug, `DEBUG ${msg}`, ctx);
    logNative("debug", msg, ctx);
    this.saveLog("debug", msg, ctx);
  }

  info(msg: string, ctx: LogContext = {}): void {
    this.logConsole(console.info, msg, ctx);
    logNative("info", msg, ctx);
    this.remoteLogger?.log(
      {
        level: "info",
        message: msg,
        context: this.getRemoteContext(ctx),
      },
      false
    );
    this.saveLog("info", msg, ctx);
  }

  warn(msg: string, ctx: LogContext = {}): void {
    this.logConsole(console.warn, msg, ctx);
    logNative("warn", msg, ctx);
    this.remoteLogger?.log(
      {
        level: "warn",
        message: msg,
        context: this.getRemoteContext(ctx),
      },
      false
    );
    this.saveLog("warn", msg, ctx);
  }

  logRemote(msg: string, ctx: LogContext = {}, level: LogLevel = "info") {
    let logFn;
    switch (level) {
      case "info":
        logFn = console.info;
        break;
      case "warn":
        logFn = console.warn;
        break;
      case "error":
        logFn = console.error;
        break;
      default:
        logFn = console.info;
    }

    this.logConsole(logFn, msg, ctx);
    logNative(level, msg, ctx);
    this.remoteLogger?.log(
      {
        level: level === "debug" ? "info" : level,
        message: msg,
        context: this.getRemoteContext(ctx),
      },
      true
    );
  }

  setRemoteLogger(l?: RemoteLogger) {
    this.remoteLogger = l;

    if (this.remoteLogger) {
      this.logRemote("Remote logging initiated");
    }
  }

  /**
   * Set the level to be sent, or undefined to use the default
   */
  setRemoteLogLevel(l?: LogLine["level"]) {
    this.remoteLogger?.setSendLogLevel(l);
  }

  setSaveLogs(save: boolean): void {
    this.saveLogs = save;
    if (!save) {
      this.savedLogs = [];
    }
  }

  getSaveLogs(): boolean {
    return this.saveLogs;
  }

  getSavedLogs(): SavedLogLine[] {
    return this.savedLogs;
  }

  private saveLog(level: LogLevel, message: string, context: LogContext) {
    if (!this.saveLogs) {
      return;
    }

    this.savedLogs.push({
      time: defaultTimeProvider(),
      level,
      message,
      context,
    });
  }

  private getRemoteContext(ctx: LogContext): LogContext {
    return { ...ctx, appTime: defaultTimeProvider() };
  }

  private logConsole(f: (...args: any[]) => void, ...args: any[]): void {
    if (CurrentEnvironment.debugBuild()) {
      const argsStringified = args.map(a => {
        if (typeof a === "object") {
          if (a instanceof Error) {
            return `${a.name}: ${a.message}\n${a.stack}`;
          }

          if (Object.keys(a).length === 0) {
            return undefined;
          }

          try {
            return JSON.stringify(a, null, 2);
          } catch (err) {
            return "[Circular]";
          }
        }

        return a;
      });
      argsStringified.unshift(new Date().toISOString());
      const str = argsStringified
        .filter(x => !!x)
        .map(a => `${a}`)
        .join();
      f(str);
    }
  }
}

function formatError(err: any) {
  if (err instanceof Error) {
    return {
      message: err.message,
      name: err.name,
      stack: err.stack,
    };
  }

  return `${err}`;
}

export const log = new AppLogger();
setLogger(log);
