import React, { useCallback, useEffect, useRef } from "react";
import { Animated, Easing, StyleSheet, View } from "react-native";
import { Opacity, globalStyleColors } from "./GlobalStyles";
import { Haptics } from "./Haptics";
import { Pressable } from "./Pressable";
import { TSecondary, TTertiary } from "./Typography";
import { bottomThrow } from "@eatbetter/common-shared";

const config = {
  large: { selectorHeight: 40, trackInset: 4, fontSize: "secondary" },
  small: { selectorHeight: 32, trackInset: 0, fontSize: "tertiary" },
  colors: {
    track: globalStyleColors.rgba("colorGreyDark", "light"),
    selector: globalStyleColors.rgba("colorAccentCool"),
    textSelected: globalStyleColors.rgba("white", "opaque"),
    textUnselected: globalStyleColors.rgba("black", "light"),
  },
};

export function getSegmentedControlHeight(heightKey: Props["height"]) {
  const config = getConfigValues(heightKey);
  return config.selectorHeight + 2 * config.trackInset;
}

function getConfigValues(heightKey: Props["height"]) {
  switch (heightKey) {
    case "large":
      return config.large;
    case "small":
      return config.small;
    default:
      bottomThrow(heightKey);
  }
}

interface Segment {
  label: string;
  onPress: () => void;
  isSelected?: boolean;
}

interface Props {
  segments: Segment[];
  height: "small" | "large";
  width: number;
  disabled?: boolean;
}

export const SegmentedControl = React.memo((props: Props) => {
  const segmentWidth = props.width / props.segments.length;
  const { selectorHeight, trackInset } = getConfigValues(props.height);
  const opacity = props.disabled ? Opacity.medium : Opacity.opaque;

  return (
    <View style={[styles.container, { width: props.width, opacity }]}>
      <View style={[styles.track, { padding: trackInset, borderRadius: (selectorHeight + 2 * trackInset) / 2 }]}>
        <View style={[styles.segmentWrap, { borderRadius: selectorHeight / 2 }]}>
          {props.segments.map((i, idx) => {
            return (
              <SegmentComponent
                key={i.label}
                index={idx}
                isSelected={i.isSelected}
                onPress={i.onPress}
                label={i.label}
                selectorSize={props.height}
                disabled={props.disabled}
              />
            );
          })}
          <Selector
            selectedIndex={props.segments.findIndex(i => i.isSelected)}
            segmentCount={props.segments.length}
            segmentWidth={segmentWidth}
            selectorHeight={props.height}
          />
        </View>
      </View>
    </View>
  );
});

interface SegmentProps {
  selectorSize: "large" | "small";
  index: number;
  label: string;
  onPress: () => void;
  isSelected?: boolean;
  disabled?: boolean;
}

const SegmentComponent = React.memo((props: SegmentProps) => {
  const onPress = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    props.onPress();
  }, [props.onPress]);

  const { selectorHeight, fontSize } = getConfigValues(props.selectorSize);
  const fontWeight = props.isSelected ? "medium" : "normal";
  const color = props.isSelected ? config.colors.textSelected : config.colors.textUnselected;

  return (
    <Pressable
      onPress={onPress}
      disabled={props.disabled || props.isSelected}
      noFeedback
      style={[styles.segment, { height: selectorHeight }]}
    >
      {fontSize === "secondary" && (
        <TSecondary fontWeight={fontWeight} color={color} opacity="opaque">
          {props.label}
        </TSecondary>
      )}
      {fontSize === "tertiary" && (
        <TTertiary fontWeight={fontWeight} color={color} opacity="opaque">
          {props.label}
        </TTertiary>
      )}
    </Pressable>
  );
});

const Selector = React.memo(
  (props: { selectedIndex: number; segmentCount: number; segmentWidth: number; selectorHeight: "large" | "small" }) => {
    const translateX = useRef(new Animated.Value(0)).current;

    const { selectorHeight } = getConfigValues(props.selectorHeight);

    useEffect(() => {
      Animated.timing(translateX, {
        toValue: (props.selectedIndex < 0 ? 0 : props.selectedIndex) * props.segmentWidth,
        duration: 150,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: true,
      }).start();
    }, [props.selectedIndex]);

    return (
      <Animated.View
        style={[
          styles.selector,
          { width: props.segmentWidth, height: selectorHeight, borderRadius: selectorHeight / 2 },
          {
            transform: [{ translateX }],
          },
        ]}
      />
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  track: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: config.colors.track,
  },
  segmentWrap: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  segment: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  selector: {
    position: "absolute",
    left: 0,
    backgroundColor: config.colors.selector,
  },
});
