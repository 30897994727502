import {
  AdminCreateExternalBookRecipeArgs,
  AdminEditExternalBookRecipeArgs,
  AdminEditExternalUrlRecipeArgs,
  AdminReprocessExternalRecipe,
  AdminStoreHtmlArgs,
  AdminStoreHtmlPreviewResult,
  AdminStoreHtmlResult,
  CreateExternalBookRecipeResult,
  EditRecipeArgs,
  RecipeErrorTypes,
  RecipeId,
  RecipeTagManifest,
  AppUserRecipe,
  AdminReprocessUserUrlRecipe,
  UserRecipeId,
  AdminDeleteUserRecipeArgs,
  AdminOrphanRecipeArgs,
  AdminSetUserRecipeFullAccessArgs,
} from "@eatbetter/recipes-shared";
import { ThunkAction } from "../AdminRedux";
import { SetWaitingHandler } from "@eatbetter/ui-shared";
import { Envelope, UrlString, UserId } from "@eatbetter/common-shared";
import { AdminGetRecipeResult } from "@eatbetter/composite-shared";
import { RecipeStoreDocuments } from "@eatbetter/recipes-server/dist/internal/RecipeRawDataStore";
import { AdminKnownAuthorAndPublisherData } from "@eatbetter/recipes-server";

export const getTagManifestOrThrow = (setWaiting?: SetWaitingHandler): ThunkAction<RecipeTagManifest> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).getAdminMetadata();
    return resp.data.metadata.recipes.tagManifest;
  };
};

export const getRecipe = (
  recipeId: RecipeId,
  setWaiting?: SetWaitingHandler
): ThunkAction<Envelope<AdminGetRecipeResult, RecipeErrorTypes>> => {
  return async (_dispatch, _getState, deps) => {
    return deps.api.withReturn(setWaiting).getRecipe({ recipeId });
  };
};

export const getUserRecipes = (userId: UserId, setWaiting?: SetWaitingHandler): ThunkAction<AppUserRecipe[]> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow(setWaiting).getUserRecipes({ userId });
    return res.data.recipes;
  };
};

export const getUserRecipe = (
  userId: UserId,
  recipeId: UserRecipeId,
  setWaiting?: SetWaitingHandler
): ThunkAction<AppUserRecipe> => {
  return async (_dispatch, _getState, deps) => {
    const res = await deps.api.withThrow(setWaiting).getUserRecipe({ userId, recipeId });
    return res.data;
  };
};

export const getRecipeOrThrow = (
  recipeId: RecipeId,
  setWaiting?: SetWaitingHandler
): ThunkAction<AdminGetRecipeResult> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).getRecipe({ recipeId });
    return result.data;
  };
};

export const getRecipeStoreDocs = (
  recipeId: RecipeId,
  setWaiting?: SetWaitingHandler
): ThunkAction<RecipeStoreDocuments> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).getRecipeStoreDocs({ recipeId });
    return result.data;
  };
};

export const findRecipeIdByUrl = (
  url: UrlString,
  setWaiting?: SetWaitingHandler
): ThunkAction<RecipeId | undefined> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).findRecipeByUrl({ url });
    return resp.data.id;
  };
};

export const editExternalBookRecipe = (
  args: AdminEditExternalBookRecipeArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    await deps.api.withThrow(setWaiting).editExternalBookRecipe(args);
  };
};

export const editExternalUrlRecipe = (
  args: AdminEditExternalUrlRecipeArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    await deps.api.withThrow(setWaiting).editExternalUrlRecipe(args);
  };
};

export const editUserRecipeOnBehalfOfUser = (
  args: EditRecipeArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    await deps.api.withThrow(setWaiting).editUserRecipeOnBehalfOfUser(args);
  };
};

export const setUserRecipeFullAccess = (
  args: AdminSetUserRecipeFullAccessArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<AppUserRecipe> => {
  return async (_dispatch, _getSTate, deps) => {
    const res = await deps.api.withThrow(setWaiting).setUserRecipeFullAccess(args);
    return res.data;
  };
};

export const addAndProcessExternalUrlRecipe = (
  url: UrlString,
  setWaiting?: SetWaitingHandler
): ThunkAction<RecipeId> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).addAndProcessExternalUrlRecipe({ url });
    return resp.data.recipeId;
  };
};

export const reprocessExternalUrlRecipe = (
  args: AdminReprocessExternalRecipe,
  setWaiting?: SetWaitingHandler
): ThunkAction<AdminGetRecipeResult> => {
  return async (_dispatch, _getState, deps) => {
    try {
      setWaiting?.(true);
      await deps.api.withThrow().reprocessExternalUrlRecipe(args);
      const resp = await deps.api.withThrow().getRecipe({ recipeId: args.recipeId });
      return resp.data;
    } finally {
      setWaiting?.(false);
    }
  };
};

export const reprocessUserUrlRecipe = (
  args: AdminReprocessUserUrlRecipe,
  setWaiting?: SetWaitingHandler
): ThunkAction<AppUserRecipe> => {
  return async (dispatch, _getState, deps) => {
    try {
      setWaiting?.(true);
      await deps.api.withThrow().reprocessUserUrlRecipe(args);
      return dispatch(getUserRecipe(args.userId, args.recipeId));
    } finally {
      setWaiting?.(false);
    }
  };
};

export const addExternalBookRecipe = (
  args: AdminCreateExternalBookRecipeArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<CreateExternalBookRecipeResult> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).createExternalBookRecipe(args);
    return result.data;
  };
};

export const storeHtml = (
  args: AdminStoreHtmlArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<AdminStoreHtmlResult> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).storeHtml(args);
    return result.data;
  };
};

export const storeHtmlPreview = (
  args: AdminStoreHtmlArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<AdminStoreHtmlPreviewResult> => {
  return async (_dispatch, _getState, deps) => {
    const result = await deps.api.withThrow(setWaiting).storeHtmlPreview(args);
    return result.data;
  };
};

export const saveRecipeCopy = (
  args: { userId: UserId; sourceRecipeId: RecipeId },
  setWaiting?: SetWaitingHandler
): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    await deps.api.withThrow(setWaiting).saveRecipeCopy(args);
  };
};

export const deleteUserRecipe = (
  args: AdminDeleteUserRecipeArgs,
  setWaiting?: SetWaitingHandler
): ThunkAction<AppUserRecipe> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).deleteUserRecipe(args);
    return resp.data;
  };
};

export const orphanRecipe = (args: AdminOrphanRecipeArgs, setWaiting?: SetWaitingHandler): ThunkAction<void> => {
  return async (_dispatch, _getState, deps) => {
    await deps.api.withThrow(setWaiting).orphanRecipe(args);
  };
};

export const getKnownData = (setWaiting?: SetWaitingHandler): ThunkAction<AdminKnownAuthorAndPublisherData> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).getKnownData();
    return resp.data;
  };
};
