import {
  createSelector1,
  createSelector3,
  createSelector4,
  getCreateSelectorWithCacheSize,
} from "../redux/CreateSelector";
import {
  GroceryListId,
  GroceryListItem,
  GroceryListItemId,
  GroceryListRecipe,
  GroceryListSort,
  GroceryListSuggestion,
  GroceryListSuggestionId,
  RecipeGroceryListItem,
  RecipeInstanceId,
} from "@eatbetter/lists-shared";
import { useMemo, useRef } from "react";
import { mergeItemWithUpdates } from "../redux/ItemWithUpdates";
import { AppGroceryListItem, itemSelectors, listSelectors } from "./ListsSlice";
import { RootState } from "../redux/RootReducer";
import groupBy from "lodash/groupBy";
import {
  addDays,
  capitalizeFirstLetter,
  daysBetween,
  defaultTimeProvider,
  discriminate,
  EpochMs,
  minutesBetween,
} from "@eatbetter/common-shared";
import { useSelector } from "../redux/Redux";
import { StandardPrimaryCategory } from "@eatbetter/items-shared";
import { RecipeId, UserRecipeId } from "@eatbetter/recipes-shared";
import { log } from "../../Log";
import { CurrentEnvironment } from "../../CurrentEnvironment";
import { getDateDisplayString } from "../util/DateUtilities";

interface SortedListIdsBase<TType extends GroceryListSort, TPending, TCompleted = TPending> {
  type: TType;
  pending: TPending;
  completed: TCompleted;
}

export interface TimeAndIds {
  time: EpochMs;
  timeDisplay: string;
  ids: GroceryListItemId[];
}

export type TimeSortedListIds = SortedListIdsBase<"time", TimeAndIds[], GroceryListItemId[]>;

export interface MergedListItem {
  groupName: string;
  ids: GroceryListItemId[];
  key: string;
}

export type ListItemIdOrMergedListItem = GroceryListItemId | MergedListItem;

export interface CategoryAndIds {
  category: string;
  categoryDisplay: string;
  ids: ListItemIdOrMergedListItem[];
}

export type CategorySortedListIds = SortedListIdsBase<"category", CategoryAndIds[], GroceryListItemId[]>;

export interface RecipeInstanceAndIds {
  recipeId: UserRecipeId;
  instanceId: RecipeInstanceId;
  timeAdded: EpochMs;
  timeCompleted?: EpochMs;
  ids: RecipeGroceryListItem[];
}

export type RecipeSortedItems = SortedListIdsBase<
  "recipe",
  { recipeInstancesAndIds: RecipeInstanceAndIds[]; nonRecipeIds: GroceryListItemId[] }
>;

export type SortedListIds = TimeSortedListIds | CategorySortedListIds | RecipeSortedItems;

export interface SortedListSections {
  activeSort: GroceryListSort;
  categorySorted: CategorySortedListIds;
  timeSorted: TimeSortedListIds;
  recipeSorted: RecipeSortedItems;
}

export const useSortedListSections = () => useSelector(selectSortedListSections);

export const listItemSelectorFactory = (id: GroceryListItemId) => {
  return createSelector1(
    s => itemSelectors.selectById(s.groceryLists.items, id)!,
    item => mergeItemWithUpdates(item)
  );
};

export const useListItem = (id: GroceryListItemId): GroceryListItem => {
  const selector = useRef(listItemSelectorFactory(id)).current;
  return useSelector(selector);
};

export const useListItemCategory = (id: GroceryListItemId): StandardPrimaryCategory | undefined => {
  const item = useListItem(id);
  return item.manualCategory ?? item.category?.primary;
};

export const selectItemAndUpdates = (state: RootState, id: GroceryListItemId): AppGroceryListItem | undefined => {
  return itemSelectors.selectById(state.groceryLists.items, id);
};

export const selectAllListItemsAndUpdates = (s: RootState) => itemSelectors.selectAll(s.groceryLists.items);

export const selectListItemsAndUpdates: (state: RootState, id: GroceryListId | undefined) => AppGroceryListItem[] =
  createSelector3(
    (_: RootState, id: GroceryListId | undefined) => id,
    s => listSelectors.selectEntities(s.groceryLists.lists),
    s => itemSelectors.selectEntities(s.groceryLists.items),
    (listId, lists, items) => {
      if (!listId) {
        if (lists && Object.keys(lists).length > 0) {
          log.error("ListId is undefined but one or more lists are present in state");
        }

        return [];
      }

      if (!lists[listId]) {
        log.error(`selectListItemsAndUpdates found no list for list ID ${listId}`);
        return [];
      }

      const ids = lists[listId]!.itemIds;
      return ids.map(id => items[id]!);
    }
  );

export const selectListRecipeColor = (state: RootState, recipeId: RecipeId | undefined): string | undefined => {
  if (!recipeId) {
    return undefined;
  }

  return state.groceryLists.lists.entities[state.groceryLists.selectedListId!]?.recipeColors[recipeId];
};

export const useListRecipeColor = (recipeId: RecipeId | undefined) => {
  return useSelector(s => selectListRecipeColor(s, recipeId));
};

export const selectMergedListItems: (state: RootState) => GroceryListItem[] = createSelector1(
  s => selectListItemsAndUpdates(s, s.groceryLists.selectedListId),
  items => {
    const merged = items.map<GroceryListItem>(mergeItemWithUpdates);
    return merged;
  }
);

const selectRecipeItems: (state: RootState) => RecipeGroceryListItem[] = createSelector1(
  s => selectMergedListItems(s),
  items => {
    const recipeItems = Object.values(items).filter(discriminate("type", "recipe"));
    return recipeItems;
  }
);

export const selectRecipeInstances: (state: RootState) => RecipeInstanceAndIds[] = createSelector1(
  s => selectRecipeItems(s),
  recipeItems => {
    const instancesAndItems = groupBy(recipeItems, i => i.recipeInstanceId);

    const recipeInstances = Object.entries(instancesAndItems).map<RecipeInstanceAndIds>(entry => {
      const [instanceId, instanceItems] = entry as [RecipeInstanceId, RecipeGroceryListItem[]];
      const { recipeId, created } = instanceItems[0]!;

      // figure out if the recipe instances are complete and when they were completed
      const isCompleted = instanceItems.every(i => i.status.status === "completed");
      const itemCompletedTimes = instanceItems.map(i => i.status.ts ?? (0 as EpochMs));
      const timeCompleted = isCompleted ? (Math.max(...itemCompletedTimes) as EpochMs) : undefined;

      return { recipeId, instanceId, timeAdded: created, timeCompleted, ids: instanceItems };
    });

    return recipeInstances;
  }
);

export const useListRecipe = (recipeId: UserRecipeId): GroceryListRecipe | undefined =>
  useSelector(s => {
    if (!s.groceryLists.selectedListId) {
      return undefined;
    }

    const list = s.groceryLists.lists.entities[s.groceryLists.selectedListId];

    return list?.recipes[recipeId];
  });

const selectSortedListSections: (s: RootState) => SortedListSections = createSelector4(
  s => selectMergedListItems(s),
  s => s.groceryLists.sort,
  s => selectRecipeInstances(s),
  s => !!s.system.systemSettings.groupGroceryItems,
  (items, activeSort, recipeInstances, groupGroceryItems) => {
    const pendingItems = items.filter(i => i.status.status === "pending").sort((a, b) => b.created - a.created);
    // Limit completed items to last 48 hours
    const completedItems = items
      .filter(i => i.status.status === "completed" && i.status.ts > addDays(defaultTimeProvider(), -2))
      .sort((a, b) => (b.status.ts ?? 0) - (a.status.ts ?? 0));
    const completedItemIds = completedItems.map(i => i.id);

    const timeSorted: TimeSortedListIds = {
      type: "time",
      pending: pendingTimeSort(pendingItems),
      completed: completedItemIds,
    };

    const categorySorted: CategorySortedListIds = {
      type: "category",
      pending: pendingCategorySort(pendingItems, groupGroceryItems),
      completed: completedItemIds,
    };

    const recipeSorted: RecipeSortedItems = {
      type: "recipe",
      pending: {
        recipeInstancesAndIds: getRecipeInstancesByStatus(recipeInstances, "pending").sort(
          (a, b) => (b.timeAdded ?? 0) - (a.timeAdded ?? 0)
        ),
        nonRecipeIds: pendingItems.filter(i => i.type !== "recipe").map(i => i.id),
      },
      completed: {
        recipeInstancesAndIds: getRecipeInstancesByStatus(recipeInstances, "completed")
          .filter(i => notStale(i.timeCompleted))
          .sort((a, b) => (b.timeCompleted ?? 0) - (a.timeCompleted ?? 0)),
        nonRecipeIds: completedItems.filter(i => i.type !== "recipe").map(i => i.id),
      },
    };

    return {
      activeSort,
      timeSorted,
      categorySorted,
      recipeSorted,
    };
  }
);

function getRecipeInstancesByStatus(recipes: RecipeInstanceAndIds[], status: "completed" | "pending") {
  const result = recipes.filter(r => {
    const allItemsCompleted = r.ids.every(i => i.status.status === "completed");
    return status === "pending" ? !allItemsCompleted : allItemsCompleted;
  });

  return result;
}

const categorySortMap: { [key in StandardPrimaryCategory]: number } = {
  produce: 0,
  meat: 1,
  fish: 2,
  dairy: 3,
  refrigerated: 4,
  pantry: 5,
  spices: 6,
  alcohol: 7,
  other: Number.MAX_SAFE_INTEGER - 2,
  frozen: Number.MAX_SAFE_INTEGER - 1,
};

const categoryNames: { [key in StandardPrimaryCategory]: string } = {
  produce: "Produce",
  meat: "Meat",
  fish: "Seafood",
  dairy: "Dairy & Eggs",
  refrigerated: "Refrigerated",
  pantry: "Pantry",
  spices: "Spices",
  alcohol: "Alcohol",
  other: "Other",
  frozen: "Frozen",
};

const getCategoryDisplay = (c: string | undefined): string => {
  const other: StandardPrimaryCategory = "other";
  return (
    categoryNames[(c ?? other) as StandardPrimaryCategory] ??
    (c ? `${c[0]?.toUpperCase()}${c.slice(1).toLowerCase()}` : categoryNames[other])
  );
};

export function useGroceryCategories(): Array<{ category: StandardPrimaryCategory; display: string }> {
  return useMemo(() => {
    const other: StandardPrimaryCategory = "other";
    return Object.entries(categorySortMap)
      .sort((a, b) => {
        // other should come last for the list of categories for selection
        // In the grocery list, froze comes last
        const aSort = a[0] === other ? Number.MAX_SAFE_INTEGER : a[1];
        const bSort = b[0] === other ? Number.MAX_SAFE_INTEGER : b[1];
        return aSort - bSort;
      })
      .map(e => {
        const category = e[0] as StandardPrimaryCategory;
        const display = categoryNames[category];
        return { category, display };
      });
  }, []);
}

function pendingTimeSort(pending: GroceryListItem[]): TimeAndIds[] {
  const getStartOfDay = (date: "today" | "yesterday" | Date = new Date()): EpochMs => {
    switch (date) {
      case "today": {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today.getTime() as EpochMs;
      }
      case "yesterday": {
        const yesterday = new Date(Date.now() - 86400000);
        yesterday.setHours(0, 0, 0, 0);
        return yesterday.getTime() as EpochMs;
      }
      default: {
        date.setHours(0, 0, 0, 0);
        return date.getTime() as EpochMs;
      }
    }
  };

  const todayItems: TimeAndIds = { time: getStartOfDay("today"), timeDisplay: "Today", ids: [] };
  const yesterdayItems: TimeAndIds = { time: getStartOfDay("yesterday"), timeDisplay: "Yesterday", ids: [] };
  const otherDateItems: Record<number, TimeAndIds> = {};

  for (const item of pending) {
    if (item.created >= todayItems.time) {
      todayItems.ids.push(item.id);
    } else if (item.created >= yesterdayItems.time) {
      yesterdayItems.ids.push(item.id);
    } else {
      const created = getStartOfDay(new Date(item.created));
      const dateEntry = otherDateItems[created];
      if (dateEntry) {
        dateEntry.ids.push(item.id);
      } else {
        otherDateItems[created] = { time: created, timeDisplay: getDateDisplayString(created), ids: [item.id] };
      }
    }
  }

  return [todayItems, yesterdayItems, ...Object.values(otherDateItems)].filter(i => i.ids.length > 0);
}

function pendingCategorySort(pending: GroceryListItem[], groupItems: boolean): CategoryAndIds[] {
  const other: StandardPrimaryCategory = "other";
  const groups = groupBy(pending, p => p.manualCategory ?? p.category?.primary ?? other);
  const categories = Object.entries(groups).map(e => {
    const [key, items] = e;
    const sorted = items.sort((a, b) => {
      // determine if we're using the manual category exclusively.
      // If the manual category matches the category, we should use the subcategory info as well.
      const aManual = a.manualCategory && a.manualCategory !== a.category?.primary;
      const bManual = b.manualCategory && b.manualCategory !== b.category?.primary;

      // sort first by subcategory
      const aSub = (aManual ? undefined : a.category?.sub) ?? Number.MAX_SAFE_INTEGER;
      const bSub = (bManual ? undefined : b.category?.sub) ?? Number.MAX_SAFE_INTEGER;
      if (aSub !== bSub) {
        return aSub - bSub;
      }

      // then by ingredient
      const aIng = (aManual ? undefined : a.ingredient?.id) ?? "zz";
      const bIng = (bManual ? undefined : b.ingredient?.id) ?? "zz";
      const ingSort = aIng.localeCompare(bIng);
      if (ingSort !== 0) {
        return ingSort;
      }

      // finally, sort by time asc;
      return a.created - b.created;
    });

    const ids: ListItemIdOrMergedListItem[] = [];
    for (let i = 0; i < sorted.length; i++) {
      const current = sorted[i]!;
      const next = sorted[i + 1];
      if (
        groupItems &&
        current.ingredient?.id &&
        current.ingredient.groupName &&
        next &&
        next.ingredient?.id === current.ingredient.id
      ) {
        // we have a group. Look for all matching items
        const itemIds: GroceryListItemId[] = [current.id, next.id];
        let j = i + 2;
        // increment i for the 2nd item
        i++;
        for (j; j < sorted.length; j++) {
          const candidate = sorted[j];
          if (candidate && candidate.ingredient?.id && candidate.ingredient.id === current.ingredient?.id) {
            itemIds.push(candidate.id);
            // increment i as well
            i++;
          }
        }

        ids.push({
          groupName: capitalizeFirstLetter(current.ingredient.groupName),
          ids: itemIds,
          key: `${key}:id:${current.ingredient.id}`,
        });
      } else {
        ids.push(current.id);
      }
    }

    return {
      category: key,
      categoryDisplay: getCategoryDisplay(key),
      ids,
    };
  });

  const categoryNames = categories.map(c => c.category).sort();

  const alphaSortMap: Record<string, number> = {};
  categoryNames.forEach((n, idx) => {
    if (n) {
      alphaSortMap[n] = 1000 + idx;
    }
  });

  function getCategorySortValue(name: string | undefined): number {
    if (!name) {
      // undefiend is listed as "Other" and should always be last, except for frozen, which should always be very last
      return categorySortMap["other"];
    }

    // in the normal case, the first map should hit. In the case that a new category is returned from the server
    // that the app doesn't know about, we fall back to alpha sort, where the alpha sort categories come after
    // all known categories except for "other" and "frozen". We should never fall back to the default provided here.
    return categorySortMap[name as StandardPrimaryCategory] ?? alphaSortMap[name] ?? categorySortMap["other"] - 1;
  }

  return categories.sort(({ category: a }, { category: b }) => {
    return getCategorySortValue(a) - getCategorySortValue(b);
  });
}

function notStale(completedTime: EpochMs | undefined): boolean {
  if (!completedTime) {
    return true;
  }

  return daysBetween(completedTime, defaultTimeProvider()) < 7;
}

type GroceryListSuggestionContext = { onList: boolean; recentlyCompleted?: EpochMs };
export type GroceryListSuggestionWithListContext = GroceryListSuggestion & GroceryListSuggestionContext;

const normalizeText = (str: string) => str.toLowerCase().trim();

const selectSuggestions: (s: RootState) => GroceryListSuggestionWithListContext[] = createSelector4(
  s => s.groceryLists.suggestions.data?.suggestions,
  s => s.groceryLists.items.entities,
  s => s.groceryLists.selectedListId,
  s => s.groceryLists.lists,

  (suggestions, items, listId, lists) => {
    // no suggestions yet
    if (!suggestions) {
      return [];
    }

    if (!listId) {
      log.warn("No selected list ID set in selectPillSuggestions");
      return [];
    }

    const list = lists.entities[listId];
    if (!list) {
      log.warn(`List ${listId} does not exist in selectPillSuggestions`);
      return [];
    }

    const contextBySuggestionId: Record<GroceryListSuggestionId, GroceryListSuggestionContext> = {};
    const contextByText: Record<string, GroceryListSuggestionContext> = {};

    const manualItems = list.itemIds
      .flatMap(id => {
        const item = items[id];
        if (!item) {
          return [];
        }

        const merged = mergeItemWithUpdates(item);

        if (merged.type !== "manual" || !merged.suggestionId) {
          return [];
        }

        return merged;
      })
      .sort((a, b) => {
        // sort completed items first
        const aVal = a.status.status === "completed" ? 1 : 0;
        const bVal = b.status.status === "completed" ? 1 : 0;

        return bVal - aVal;
      });

    // because we could have multiple instances of a suggestion ID or text, we process completed items first (see sort above)
    // This makes sure that, if an item is in both completed and pending, we correctly report it as on the list
    // This is necessary because we only keep track of a single context per ID/phrase
    manualItems.forEach(merged => {
      const onList = merged.status.status === "pending";
      const recentlyCompleted =
        merged.status.status === "completed" && isRecentlyCompleted(merged.status.ts) ? merged.status.ts : undefined;

      if (merged.suggestionId) {
        contextBySuggestionId[merged.suggestionId] = { onList, recentlyCompleted };
      }
      contextByText[normalizeText(merged.text)] = { onList, recentlyCompleted };
    });

    return suggestions
      .filter(s => s.listId === listId)
      .map(s => {
        let c: GroceryListSuggestionContext = { onList: false };
        const byId = contextBySuggestionId[s.id];
        const byText = contextByText[normalizeText(s.text)];
        if (byId && byText) {
          // if we have both, merge
          c = {
            onList: byId.onList || byText.onList,
            recentlyCompleted: byId.recentlyCompleted || byText.recentlyCompleted,
          };
        } else if (byId || byText) {
          c = (byId ?? byText)!;
        }

        return {
          ...s,
          ...c,
        };
      });
  }
);

const isRecentlyCompleted = (ts: EpochMs): boolean => {
  // use a 1-minute delta for testing purposes. Note that it might take longer than 1 minute
  // for suggestions to appear because the reselect selector does not take time into account.
  const timeFn = CurrentEnvironment.configEnvironment() === "prod" ? daysBetween : minutesBetween;
  return timeFn(defaultTimeProvider(), ts) < 1;
};

const selectPillSuggestions: (s: RootState) => GroceryListSuggestion[] = createSelector1(
  s => selectSuggestions(s),

  allSuggestions => {
    return allSuggestions.filter(s => s.showSuggestion && !s.onList && !s.recentlyCompleted);
  }
);

export const usePillSuggestions = () => useSelector(s => selectPillSuggestions(s));

const selectTypeaheadSuggestions: (s: RootState, str: string) => GroceryListSuggestionWithListContext[] =
  getCreateSelectorWithCacheSize(5)([(_s, str) => str, s => selectSuggestions(s)], (str, allSuggestions) => {
    const query = str.toLowerCase().trimStart();
    const suggestionsWithIndexAndMatchPriority = allSuggestions.map<
      [GroceryListSuggestionWithListContext, number, number]
    >((s, idx) => {
      let matchPriority = 0;
      const candidate = s.text.toLowerCase().trimStart();
      if (candidate.startsWith(query)) {
        matchPriority = 2;
      } else if (query.length >= 2 && candidate.includes(query)) {
        matchPriority = 1;
      }

      return [s, idx, matchPriority];
    });

    return suggestionsWithIndexAndMatchPriority
      .filter(s => s[2] > 0)
      .sort((a, b) => {
        // sort higher match priority first
        if (a[2] !== b[2]) {
          return b[2] - a[2];
        }

        // and then sort by order of suggestion, which is meaningful
        return a[1] - b[1];
      })
      .map(s => s[0]);
  });

export const useTypeaheadSuggestions: (str: string) => GroceryListSuggestionWithListContext[] = (str: string) =>
  useSelector(s => selectTypeaheadSuggestions(s, str));

export const useSpotlightGroceryIcon = () => useSelector(s => !!s.groceryLists.spotlightIcon);
