import { useDispatch } from "../lib/redux/Redux";
import { Platform, StyleSheet, View } from "react-native";
import {
  createAnonymousUser,
  showAnonymousOption,
  signInWithApple,
  signInWithGoogle,
} from "../lib/system/SystemThunks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Spacer } from "./Spacer";
import { TBody, TTertiary } from "./Typography";
import { Pressable } from "./Pressable";
import GoogleGLogo from "../assets/logo_google_g.svg";
import AppleLogo from "../assets/logo_apple.svg";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { useScreen } from "../navigation/ScreenContainer";
import { IconEmail, IconUserCircle } from "./Icons";
import { navTree } from "../navigation/NavTree";
import { FormValidationError } from "./FormValidationError";
import { useOpenInternalWebPage, webpageLoadedFromBackForwardOrRefresh } from "../lib/util/WebUtil";
import { AuthErrorType, userCanceledSignIn } from "../lib/system/SystemSlice";
import {
  useAuthError,
  useAuthStatus,
  useIsAnonymousUser,
  usePending3rdPartySignIn,
} from "../lib/system/SystemSelectors";
import { navHome } from "../navigation/NavThunks";
import { bottomThrow } from "@eatbetter/common-shared";
import { Spinner } from "./Spinner";
import { log } from "../Log";
import { CurrentEnvironment } from "../CurrentEnvironment";

const strings = {
  google: "Continue with Google",
  apple: "Continue with Apple",
  email: "Continue with email",
  signIn: "Sign in",
  incorrectEmailPassword: "Email or password is incorrect",
  authErrors: {
    general: "We couldn't sign you in. Please try again.",
    userDeleted: "This account has been deleted.",
  } satisfies { [key in AuthErrorType]-?: string },
  needHelp: "Need help signing in?",
  privacyAndTerms: ["By using Deglaze you agree to our\n", "Terms of Service", " and ", "Privacy Policy"],
  lookAround: "Continue as guest",
  preparing: "Preparing the app...",
};

export const SignIn = (props: { redirectPath?: string; allowAnonymousIfAvailable: boolean }) => {
  const screen = useScreen();
  const dispatch = useDispatch();
  const authStatus = useAuthStatus();
  const authError = useAuthError();
  const waitingForAuthLink = usePending3rdPartySignIn();
  const isAnon = useIsAnonymousUser();
  const anonMode = useRef(isAnon).current;
  const [showAnon, setShowAnon] = useState(false);
  const [waitingAnon, setWaitingAnon] = useState(false);

  useEffect(() => {
    log.info(`SignIn component has redirect: ${props.redirectPath}`);
  }, [props.redirectPath]);

  useEffect(() => {
    if (!anonMode) {
      dispatch(showAnonymousOption())
        .then(setShowAnon)
        .catch(err => {
          log.errorCaught("Error dispatching showAnonymousOption", err);
        });
    }
  }, [anonMode]);

  // Note that we disable auth status redirects in the container with the options below
  // because we need to nav in a case screen container doesn't handle:
  // initial status = pending and eventual status = signedIn/signedInNoAccount
  // IMPORTANT: Some of this is duplicated in LaunchScreen.tsx and in ScreenContainer.tsx.
  // Logic changes here might need to be ported there (or refactor into a function).
  useEffect(() => {
    switch (authStatus) {
      case "signedIn":
        if (!anonMode) {
          // in anon mode, we are signedIn, so we don't want to redirect
          dispatch(navHome(screen.nav, "SignIn", props.redirectPath));
        }
        break;
      case "signedInNoAccount": {
        // in non-anon mode, the user will end up in this state as soon as they sign in
        // in anon mode, we should end up here after we refetch the user once the link is
        // completed. This happens in the relevant system thunks (signInWithGoogle, etc.)
        dispatch(navHome(screen.nav, "SignIn", props.redirectPath));
        break;
      }
      case "pending":
      case "signingOut":
      case "signedOut":
        // nop
        break;
      default:
        bottomThrow(authStatus);
    }
  }, [authStatus, props.redirectPath]);

  useEffect(() => {
    // if we're on web, and the user hits back from google sign-in (or apple, or email sign-in link)
    // they have canceled the pending auth
    if (webpageLoadedFromBackForwardOrRefresh()) {
      dispatch(userCanceledSignIn());
    }
  }, []);

  // keep the spinner going until we navigate or until the 3rd party sign-in is canceled
  const waiting =
    authStatus === "pending" ||
    (authStatus === "signedIn" && !anonMode) ||
    authStatus === "signedInNoAccount" ||
    waitingForAuthLink ||
    waitingAnon;

  return (
    <>
      <View style={styles.signinWrap}>
        {waiting && <Spinner debugText={`SignIn ${authStatus} ${waitingForAuthLink ? "waitingForAuthLink" : ""}`} />}
        {!waiting && (
          <>
            {authError && (
              <>
                <FormValidationError message={strings.authErrors[authError]} />
                <Spacer vertical={2} />
              </>
            )}
            <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
              <Spacer vertical={1} />
              <SignInWithGoogle />
              <Spacer vertical={1} />
              <SignInWithApple />
              <Spacer vertical={1} />
              <SignInWithEmail redirectPath={props.redirectPath} navAction={anonMode ? "replace" : "push"} />
              {/* In anonMode, we never render AnonymousSignIn. We do render if showAnon is false so we can always have it in dev */}
              {!anonMode && Platform.OS !== "web" && (
                <>
                  <Spacer vertical={1} />
                  <AnonymousSignIn hide={!showAnon} waiting={waitingAnon} setWaiting={setWaitingAnon} />
                </>
              )}
              <Footer />
            </View>
          </>
        )}
      </View>
    </>
  );
};

const Footer = () => {
  return (
    <View style={{ alignItems: "center" }}>
      <Spacer vertical={1.75} />
      <NeedHelp />
      <Spacer vertical={1.5} />
      <PrivacyAndTerms />
    </View>
  );
};

const AnonymousSignIn = (props: { hide: boolean; waiting: boolean; setWaiting: (v: boolean) => void }) => {
  const dispatch = useDispatch();

  const onPress = () => {
    props.setWaiting(true);
    dispatch(createAnonymousUser())
      .then(() => {
        log.info("createAnonymousUser called successfully");
        // don't setWaiting(false) here we want the spinner to keep going until the nav happens
      })
      .catch(err => {
        props.setWaiting(false);
        log.errorCaught("Error dispatching createAnonymousUser", err);
      });
  };

  const renderProviderLogo = () => {
    return (
      <View style={{ width: 48, height: 48, justifyContent: "center", alignItems: "center" }}>
        <IconUserCircle opacity="opaque" />
      </View>
    );
  };

  const text = props.waiting ? strings.preparing : strings.lookAround;

  // we only truly hide the component only on prod. On dev, we render it with (hidden) to make testing
  // easier. We always render the text to prevent vertical jumps
  const hide = CurrentEnvironment.configEnvironment() === "prod" && props.hide;
  // append (hidden) if it would be hidden in prod
  const fullText = `${text}${props.hide ? " (hidden)" : ""}`;

  if (hide) {
    return null;
  }

  return <SigninButton text={fullText} onPress={onPress} renderProviderLogo={renderProviderLogo} />;
};

const SignInWithEmail = (props: { redirectPath?: string; navAction: "push" | "replace" }) => {
  const screen = useScreen();

  const onPress = useCallback(() => {
    screen.nav.goTo(props.navAction, navTree.get.screens.enterEmail, { redirectPath: props.redirectPath });
  }, [screen.nav.goTo, props.redirectPath]);

  const logoSize = 48;

  const renderProviderLogo = useCallback(() => {
    return (
      <View style={{ width: logoSize, height: logoSize, justifyContent: "center", alignItems: "center" }}>
        <IconEmail opacity="opaque" color={globalStyleColors.black} strokeWidth={1.5} size={20} />
      </View>
    );
  }, [logoSize]);

  return <SigninButton text={strings.email} onPress={onPress} renderProviderLogo={renderProviderLogo} />;
};

const SignInWithGoogle = () => {
  const dispatch = useDispatch();
  const { nav } = useScreen();
  const onPress = useCallback(async () => {
    await dispatch(signInWithGoogle(nav));
  }, [dispatch]);

  return <GoogleSigninButton onPress={onPress} />;
};

const SignInWithApple = () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const onPress = useCallback(async () => {
    await dispatch(signInWithApple(nav));
  }, [dispatch]);

  return <AppleSigninButton onPress={onPress} />;
};

export const NeedHelp = () => {
  const screen = useScreen();

  const onPress = useCallback(() => {
    screen.nav.modal(navTree.get.screens.unauthedSupport);
  }, [screen.nav.modal]);

  return (
    <Pressable onPress={onPress}>
      <TTertiary color={globalStyleColors.blackSoft} opacity="dark" underline>
        {strings.needHelp}
      </TTertiary>
    </Pressable>
  );
};

const PrivacyAndTerms = () => {
  const openPrivacy = useOpenInternalWebPage("privacyPolicy");
  const openTerms = useOpenInternalWebPage("termsOfService");

  return (
    <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
      <TTertiary align="center" opacity="dark" color={globalStyleColors.blackSoft}>
        <TTertiary>{strings.privacyAndTerms[0]}</TTertiary>
        <TTertiary underline onPress={openTerms} suppressHighlighting>
          {strings.privacyAndTerms[1]}
        </TTertiary>
        <TTertiary>{strings.privacyAndTerms[2]}</TTertiary>
        <TTertiary underline onPress={openPrivacy} suppressHighlighting>
          {strings.privacyAndTerms[3]}
        </TTertiary>
      </TTertiary>
    </View>
  );
};

// https://developers.google.com/identity/branding-guidelines
const GoogleSigninButton = React.memo((props: { onPress: () => void }) => {
  const logoSize = 48;

  const renderProviderLogo = useCallback(() => {
    return (
      <View style={{ width: logoSize, height: logoSize }}>
        <GoogleGLogo width={"100%"} height={"100%"} />
      </View>
    );
  }, []);

  return <SigninButton onPress={props.onPress} text={strings.google} renderProviderLogo={renderProviderLogo} />;
});

// https://developer.apple.com/design/resources/
const AppleSigninButton = React.memo((props: { onPress: () => void }) => {
  const logoSize = 48;

  const renderProviderLogo = useCallback(() => {
    return (
      <View style={{ width: logoSize, height: logoSize }}>
        <AppleLogo width={"100%"} height={"100%"} />
      </View>
    );
  }, []);

  return <SigninButton onPress={props.onPress} text={strings.apple} renderProviderLogo={renderProviderLogo} />;
});

const SigninButton = React.memo(
  (props: { onPress: () => void; text: string; renderProviderLogo: () => React.ReactNode }) => {
    return (
      <Pressable style={styles.signinButton} onPress={props.onPress}>
        {props.renderProviderLogo()}
        <View style={styles.signinButtonTextWrap}>
          <TBody color={globalStyleColors.blackSoft} fontWeight="medium" adjustsFontSizeToFit numberOfLines={1}>
            {props.text}
          </TBody>
        </View>
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  signinWrap: {
    minHeight: 265,
    maxWidth: 400,
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
  },
  signinButton: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 48,
    width: "100%",
    minWidth: 270,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("blackSoft", "medium"),
  },
  signinButtonTextWrap: {
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 48,
    justifyContent: "center",
    alignItems: "center",
  },
});
